import React from "react";
import "./CenterTitle.css"; // Import your CSS file for styling

interface CenteredTitleProps {
  title: string;
  subtitle?: string; // The subtitle is optional, hence the '?'
}

const CenterTitle: React.FC<CenteredTitleProps> = ({ title, subtitle }) => {
  return (
    <div className="centered-title">
      <h2 className="title">{title}</h2>
      <div className="dividerCenter"></div>
      {subtitle && <p className="subtitle">{subtitle}</p>}
    </div>
  );
};

export default CenterTitle;

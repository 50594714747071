import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import axios from "axios";
import { useNavigate } from "react-router";
import Footer from "../../../../../components/Footer/Footer";
import PageHero from "../../../../../components/Pagehero/PageHero";
import CenterTitle from "../../../../../components/CenterTitle/CenterTitle";
import "./DocumentSign.css";
import mailSuccess from "./mailSuccess.json";
import Lottie from "lottie-react";
import "./../../../../../theme/styles.css";
import { eraseCookie, getCookie } from "../../../../../cookieUtils";

interface DocumentSignResponse {
  signingUrl: string;
  contactEmail: string;
  userId: string;
  documentTemplateId: string;
}

interface Document {
  id: string;
  userId: string;
  changeContractRequestId: string;
  documentName: string;
  documentPath: string;
  status: number;
  dateTimeCreated: Date;
}

interface Contactgegevens {
  surname: string;
  name: string;
  email: string;
  phone: string;
}

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  password: string;
  roleId: number;
}

const DocumentSign: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState<string>("");
  const [documentId, setDocumentId] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [contactEmail, setContactEmail] = useState<string | null>(null);
  const [document, setDocument] = useState<Document | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate();

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    const userdata = getCookie("user");
    if (userdata) {
      const userinfo: User = JSON.parse(userdata);
      setUserId(userinfo.id);

      const fetchUserDocuments = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DB_URL}/documents/user/${userinfo.id}`,
          );
          const userDocuments: Document[] = response.data;

          setDocuments(userDocuments);

          // if (userDocuments.length > 0) {
          //   const lastCreatedDocument = userDocuments[userDocuments.length - 1];
          //   setDocument(lastCreatedDocument);
          // }

          const userDocumentsWithParsedDates = userDocuments.map((doc) => ({
            ...doc,
            dateTimeCreated: new Date(doc.dateTimeCreated), // Convert to Date object
          }));

          if (userDocuments.length > 0) {
            const userDocumentsWithParsedDates = userDocuments.map((doc) => ({
              ...doc,
              dateTimeCreated: new Date(doc.dateTimeCreated), // Convert to Date object
            }));
            console.log("working");
            // Sort documents based on dateTimeCreated in descending order
            const sortedDocuments = userDocumentsWithParsedDates
              .slice()
              .sort(
                (a, b) =>
                  b.dateTimeCreated.getTime() - a.dateTimeCreated.getTime(),
              );

            // Set the document with the most recent dateTimeCreated
            setDocument(sortedDocuments[0]);
          }
        } catch (error) {
          console.error("Error fetching user documents:", error);
          // Handle error state or display an error message
        }
      };

      fetchUserDocuments();

      const contactgegevens = getCookie("contactgegevens");

      if (contactgegevens) {
        const contactData: Contactgegevens = JSON.parse(contactgegevens);
        setContactEmail(contactData.email);
      }
    }
  }, []);

  useEffect(() => {
    fetchSignatureUrl();
  }, [document]);

  // Fetch the signature URL from the backend API
  const fetchSignatureUrl = async () => {
    try {
      if (document?.documentPath) {
        const savedDocumentTemplateId = getCookie("documentTemplateId");
        const response = await axios.post<DocumentSignResponse>(
          `${process.env.REACT_APP_DB_URL}/api/digisigner`,
          {
            documentPath: document?.documentPath,
            contactEmail: contactEmail,
            userId: userId,
            documentTemplateId: savedDocumentTemplateId,
          },
        );
        eraseCookie("documentTemplateId");
        const { signingUrl } = response.data;
        setSignatureUrl(signingUrl);
      }
    } catch (error) {
      console.error("Error fetching signature URL:", error);
      // Handle error state or display an error message
    }
  };

  const handleSignDocument = () => {
    // if (signatureUrl) {
    // window.location.href = signatureUrl; // Redirect the user to the signature page
    navigate("/DocumentCommit/");
    // }
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Onderteken document</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <h2 className="section-title">Bedankt voor uw aanvraag!</h2>
                  <div className="green-divider"></div>
                  <p className="section-paragraph">
                    Er werd een document toegestuurd op uw opgegeven email
                    adres. Vanaf dit getekend is kan u op de onderstaande knop
                    drukken om de aanvraag af te ronden.
                  </p>

                  <button
                    className="buttonstyle33"
                    onClick={handleSignDocument}
                  >
                    Teken uw document
                  </button>
                </div>
                <div className="image-column">
                  <Lottie animationData={mailSuccess} loop={false} autoplay />
                </div>
              </div>

              {/* <button onClick={handleNavigateToDocumentCommit}>Aanvraag afronden</button> */}
              {/* Add a button to trigger the signing process */}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default DocumentSign;

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./MarketPrice.css";
import NavigationLeft from "../../../components/Navigation/NavigationLeft/NavigationLeft";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import { set } from "react-hook-form";
import PageHero from "../../../components/Pagehero/PageHero";
import { getCookie } from "../../../cookieUtils";

interface EnergySupplier {
  id: string;
  name: string;
}

const MarketPrice = () => {
  const [belpexPriceCalculations, setBelpexPriceCalculations] = useState(0.01);
  const [belpexRLPPriceCalculations, setBelpexRLPPriceCalculations] =
    useState(0.01);
  const [endexPriceCalculations, setEndexPriceCalculations] = useState(0.01);
  const [ttfDamPriceCalculations, setTtfDamPriceCalculations] = useState(0.01);
  const [ttfrlpPriceCalculations, setTtfrlpPriceCalculations] = useState(0.01);
  const [ztpDamPriceCalculations, setZtpDamPriceCalculations] = useState(0.01);
  const [
    ttfHundredAndThreePriceCalculations,
    setTtfHundredAndThreePriceCalculations,
  ] = useState(0.01);

  const [belpexPriceCalculationsOrig, setBelpexPriceCalculationsOrig] =
    useState(0.01);
  const [belpexRLPPriceCalculationsOrig, setBelpexRLPPriceCalculationsOrig] =
    useState(0.01);
  const [endexPriceCalculationsOrig, setEndexPriceCalculationsOrig] =
    useState(0.01);
  const [ttfDamPriceCalculationsOrig, setTtfDamPriceCalculationsOrig] =
    useState(0.01);
  const [ttfrlpPriceCalculationsOrig, setTtfrlpPriceCalculationsOrig] =
    useState(0.01);
  const [ztpDamPriceCalculationsOrig, setZtpDamPriceCalculationsOrig] =
    useState(0.01);
  const [
    ttfHundredAndThreePriceCalculationsOrig,
    setTtfHundredAndThreePriceCalculationsOrig,
  ] = useState(0.01);

  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [showDropField, setShowDropField] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchPrices();
  }, []);

  const fetchPrices = async () => {
    try {
      const response1 = await axios.get("/belpexpricecalculations/latest");
      setBelpexPriceCalculationsOrig(response1.data.marketPrice);

      const response2 = await axios.get("/belpexrlppricecalculations/latest");
      setBelpexRLPPriceCalculationsOrig(response2.data.marketPrice);

      const response3 = await axios.get("/endexpricecalculations/latest");
      setEndexPriceCalculationsOrig(response3.data.marketPrice);

      const response4 = await axios.get("/ttfdampricecalculations/latest");
      setTtfDamPriceCalculationsOrig(response4.data.marketPrice);

      const response5 = await axios.get("/ttfrlppricecalculations/latest");
      setTtfrlpPriceCalculationsOrig(response5.data.marketPrice);

      const response6 = await axios.get("/ztpdampricecalculations/latest");
      setZtpDamPriceCalculationsOrig(response6.data.marketPrice);

      const response7 = await axios.get(
        "/ttfhundredandthreepricecalculations/latest",
      );
      setTtfHundredAndThreePriceCalculationsOrig(response7.data.marketPrice);
    } catch (error) {
      console.error("Error fetching market prices:", error);
    }
  };

  const addMarketPrice = async () => {
    // // Step 1: Create the belpexpricecalculations record
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_DB_URL}/belpexpricecalculations`,
    //     belpexPriceCalculations
    //   );
    handleAddBelpexPriceCalculations(belpexPriceCalculations);
    // Step 2: Create the belpexrlppricecalculations record
    const response2 = await axios.post(
      `${process.env.REACT_APP_DB_URL}/belpexrlppricecalculations`,
      belpexRLPPriceCalculations,
    );
    // Step 3: Create the endexpricecalculations record
    const response3 = await axios.post(
      `${process.env.REACT_APP_DB_URL}/endexpricecalculations`,
      endexPriceCalculations,
    );
    // Step 4: Create the ttfdampricecalculations record
    const response4 = await axios.post(
      `${process.env.REACT_APP_DB_URL}/ttfdampricecalculations`,
      ttfDamPriceCalculations,
    );
    // Step 5: Create the ttfrlppricecalculations record
    const response5 = await axios.post(
      `${process.env.REACT_APP_DB_URL}/ttfrlppricecalculations`,
      ttfrlpPriceCalculations,
    );
    // Step 6: Create the ztpdampricecalculations record
    const response6 = await axios.post(
      `${process.env.REACT_APP_DB_URL}/ztpdampricecalculations`,
      ztpDamPriceCalculations,
    );
    // Step 7: Create the ttfhundredandthreepricecalculations record
    const response7 = await axios.post(
      `${process.env.REACT_APP_DB_URL}/ttfhundredandthreepricecalculations`,
      ttfHundredAndThreePriceCalculations,
    );

    // navigate('/IndexAdministrator');
  };

  // const handleAddBelpexPriceCalculations = (event: any) => {
  //   const passingNumber = parseFloat(event.target.value);
  //   setBelpexPriceCalculations(passingNumber);

  // };

  const handleAddBelpexPriceCalculations = async (event: any) => {
    const passingNumber = parseFloat(event.target.value);
    setBelpexPriceCalculations(passingNumber);

    try {
      const currentDate = new Date(); // Get the current date and time
      await axios.post("/belpexpricecalculations", {
        marketPrice: passingNumber,
      });
      console.log("Belpex price calculation created successfully.");
    } catch (error) {
      console.error("Error creating Belpex price calculation:", error);
    }
  };

  const handleAddBelpexRLPPriceCalculations = (event: any) => {
    const passingNumber = parseFloat(event.target.value);
    setBelpexRLPPriceCalculations(passingNumber);
  };

  const handleAddEndexPriceCalculations = (event: any) => {
    const passingNumber = parseFloat(event.target.value);
    setEndexPriceCalculations(passingNumber);
  };

  const handleAddTtfDamPriceCalculations = (event: any) => {
    const passingNumber = parseFloat(event.target.value);
    setTtfDamPriceCalculations(passingNumber);
  };

  const handleAddTtfrlpPriceCalculations = (event: any) => {
    const passingNumber = parseFloat(event.target.value);
    setTtfrlpPriceCalculations(passingNumber);
  };

  const handleAddZtpDamPriceCalculations = (event: any) => {
    const passingNumber = parseFloat(event.target.value);
    setZtpDamPriceCalculations(passingNumber);
  };

  const handleAddTtfHundredAndThreePriceCalculations = (event: any) => {
    const passingNumber = parseFloat(event.target.value);
    setTtfHundredAndThreePriceCalculations(passingNumber);
  };

  const containerStyles = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleButtonClick = () => {
    setShowDropField(!showDropField);
  };

  console.log("belpex RLP:" + belpexRLPPriceCalculationsOrig);
  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Marktprijs aanpassen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              {/* <div className="contentContainer"> */}
              <div>
                <div className="formContainer">
                  <div className="userCardac">
                    <div className="userCardContainerac">
                      <div className="userCardColumnac">
                        <div className="form-group">
                          <Box
                            sx={{ alignItems: "flex-end", paddingBottom: 2 }}
                          >
                            <label>
                              Huidige Belpex prijs:{" "}
                              {belpexPriceCalculationsOrig}
                            </label>
                            <TextField
                              label="Belpex prijs"
                              variant="standard"
                              type="number"
                              id="nameFilter"
                              value={belpexPriceCalculations}
                              onChange={(event) =>
                                handleAddBelpexPriceCalculations(
                                  event.target.value,
                                )
                              }
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box
                            sx={{ alignItems: "flex-end", paddingBottom: 2 }}
                          >
                            <label>
                              Huidige Belpex RLP prijs:{" "}
                              {belpexRLPPriceCalculationsOrig}
                            </label>
                            <TextField
                              label="Belpex RLP prijs"
                              variant="standard"
                              type="number"
                              id="nameFilter"
                              value={belpexRLPPriceCalculations}
                              onChange={handleAddBelpexRLPPriceCalculations}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box
                            sx={{ alignItems: "flex-end", paddingBottom: 2 }}
                          >
                            <label>
                              Huidige Endex prijs: {endexPriceCalculationsOrig}
                            </label>
                            <TextField
                              label="Endex prijs"
                              variant="standard"
                              type="number"
                              id="nameFilter"
                              value={endexPriceCalculations}
                              onChange={handleAddEndexPriceCalculations}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box
                            sx={{ alignItems: "flex-end", paddingBottom: 2 }}
                          >
                            <label>
                              Huidige TTF Dam prijs:{" "}
                              {ttfDamPriceCalculationsOrig}
                            </label>
                            <TextField
                              label="TTF Dam prijs"
                              variant="standard"
                              type="number"
                              id="nameFilter"
                              value={ttfDamPriceCalculations}
                              onChange={handleAddTtfDamPriceCalculations}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box
                            sx={{ alignItems: "flex-end", paddingBottom: 2 }}
                          >
                            <label>
                              Huidige TTF RLP prijs:{" "}
                              {ttfrlpPriceCalculationsOrig}
                            </label>
                            <TextField
                              label="TTF RLP prijs"
                              variant="standard"
                              type="number"
                              id="nameFilter"
                              value={ttfrlpPriceCalculations}
                              onChange={handleAddTtfrlpPriceCalculations}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box
                            sx={{ alignItems: "flex-end", paddingBottom: 2 }}
                          >
                            <label>
                              Huidige ZTP DAM prijs:{" "}
                              {ztpDamPriceCalculationsOrig}
                            </label>
                            <TextField
                              label="ZTP DAM prijs"
                              variant="standard"
                              type="number"
                              id="nameFilter"
                              value={ztpDamPriceCalculations}
                              onChange={handleAddZtpDamPriceCalculations}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box
                            sx={{ alignItems: "flex-end", paddingBottom: 2 }}
                          >
                            <label>
                              Huidige TTF 103 prijs:{" "}
                              {ttfHundredAndThreePriceCalculationsOrig}
                            </label>
                            <TextField
                              label="TTF 103 prijs"
                              variant="standard"
                              type="number"
                              id="nameFilter"
                              value={ttfHundredAndThreePriceCalculations}
                              onChange={
                                handleAddTtfHundredAndThreePriceCalculations
                              }
                              fullWidth
                            />
                          </Box>
                        </div>
                        <button
                          className="updateButton"
                          onClick={addMarketPrice}
                        >
                          Prijs aanpassen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default MarketPrice;

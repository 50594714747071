import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import { useNavigate } from "react-router";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import "./ChangeEnergyContractCustomer.css";
import solarpanelsimage from "./zonnepanelen.jpg";
import { IoFlame } from "react-icons/io5";
import { getCookie } from "../../../../cookieUtils";

const ChangeEnergyContractCustomer: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles1: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles1: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles1: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  };

  // const squareContainerStyles1: React.CSSProperties = {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   marginTop: "20px",
  //   flexWrap: "wrap",
  //   width: "100%",
  // };

  // const squareStyles: React.CSSProperties = {
  //   width: '150px',
  //   height: '100px',
  //   backgroundColor: '#8dc63f',
  //   borderRadius: '10px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   paddingLeft: '30px',
  //   paddingRight: '30px',
  //   paddingBottom: '30px',
  //   paddingTop: '30px',
  //   margin: '10px',
  //   boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  // };

  // const iconTextStyles: React.CSSProperties = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   margin: '10px',
  //   color: '#ffffff',
  // };

  const squareStyles1: React.CSSProperties = {
    width: "150px",
    height: "100px",
    backgroundColor: "white", // Change to white background
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "30px",
    paddingTop: "30px",
    margin: "10px",
    border: "3px solid #8dc63f", // Change to green border
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const squareStylesG1: React.CSSProperties = {
    width: "150px",
    height: "100px",
    backgroundColor: "white", // Change to white background
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "30px",
    paddingTop: "30px",
    margin: "10px",
    border: "3px solid #ffd700", // Change to green gold
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const iconTextStyles1: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
    color: "black", // Change to black color
  };

  const buttonStyles1: React.CSSProperties = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    outline: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgb(211, 230, 243)",
    width: "100%",
    textTransform: "none",
    borderRadius: "4px",
    boxShadow: "none",
    padding: "0.6rem 1.5rem",
    fontSize: "12px",
    fontWeight: 600,
    minWidth: "84px",
    color: "rgb(18, 31, 67)",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleNavigateElectricity = () => {
    navigate("/ElectricityContractInput/");
  };

  const handleNavigateGas = () => {
    navigate("/GasContractInput/");
  };

  const handleNavigateCombi = () => {
    navigate("/CombiContractInput/");
  };

  const handleNavigateElectricityPro = () => {
    navigate("/ElectricityContractInputPro/");
  };

  const handleNavigateGasPro = () => {
    navigate("/GasContractInputPro/");
  };

  const handleNavigateCombiPro = () => {
    navigate("/CombiContractInputPro/");
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Energieleveranciers vergelijken</PageHero>
          <div className="pageContainer1" style={containerStyles1}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer1" style={contentContainerStyles1}>
              <div className="section-container1">
                <div className="text-column1">
                  <h2 className="section-title1">
                    Voor elke profiel een passend tarief!
                  </h2>
                  <div className="green-divider1"></div>
                  {/* <h2 className="section-title-sub1">Voor elk profiel een passend tarief!</h2>  */}
                  <p className="section-paragraph1">
                    Op deze vergelijkingspagina kunt u moeiteloos de
                    energieprijzen vergelijken om het meest kostenefficiënte
                    tarief te vinden dat perfect past bij uw behoeften. Of u nu
                    op zoek bent naar voordelige elektriciteitstarieven, scherp
                    geprijsde gascontracten, of een combinatie van beide, ons
                    platform biedt u de mogelijkheid om eenvoudig de prijzen en
                    aanbiedingen van diverse energieleveranciers te vergelijken.
                    Met een overvloed aan informatie binnen handbereik kunt u
                    weloverwogen beslissingen nemen voor uw energiebehoeften.{" "}
                  </p>
                  <p className="section-paragraph1">
                    Deze dienst geeft recht op een reclamevrije app-ervaring.
                  </p>
                  <h2 className="section-title-sub1">Voor particulieren</h2>
                  <div className="green-divider1"></div>
                  <div
                    className="squareContainerStyles1"
                    // style={squareContainerStyles1}
                  >
                    <div
                      className="square1"
                      style={squareStyles1}
                      onClick={() => handleNavigateElectricity()}
                    >
                      <div
                        className="icon-text-container1"
                        style={iconTextStyles1}
                      >
                        <ElectricBoltIcon
                          style={{ fontSize: "60px", color: "#8dc63f" }}
                        />
                        <h3>Elektriciteit</h3>
                      </div>
                    </div>
                    <div
                      className="square1"
                      style={squareStyles1}
                      onClick={() => handleNavigateGas()}
                    >
                      <div
                        className="icon-text-container1"
                        style={iconTextStyles1}
                      >
                        <IoFlame
                          style={{ fontSize: "60px", color: "#8dc63f" }}
                        />
                        <h3>Gas</h3>
                      </div>
                    </div>
                    <div
                      className="square1"
                      style={squareStyles1}
                      onClick={() => handleNavigateCombi()}
                    >
                      <div
                        className="icon-text-container1"
                        style={iconTextStyles1}
                      >
                        <EnergySavingsLeafIcon
                          style={{ fontSize: "60px", color: "#8dc63f" }}
                        />
                        <h3>Elektriciteit & Gas</h3>
                      </div>
                    </div>
                  </div>
                  <h2 className="section-title-sub1">Voor professionelen</h2>
                  <div className="green-divider1"></div>
                  <div
                    className="squareContainerStyles1"
                    // style={squareContainerStyles1}
                  >
                    <div
                      className="square1"
                      style={squareStylesG1}
                      onClick={() => handleNavigateElectricityPro()}
                    >
                      <div
                        className="icon-text-container1"
                        style={iconTextStyles1}
                      >
                        <ElectricBoltIcon
                          style={{ fontSize: "60px", color: "#ffd700" }}
                        />
                        <h3>Elektriciteit</h3>
                      </div>
                    </div>
                    <div
                      className="square1"
                      style={squareStylesG1}
                      onClick={() => handleNavigateGasPro()}
                    >
                      <div
                        className="icon-text-container1"
                        style={iconTextStyles1}
                      >
                        <IoFlame
                          style={{ fontSize: "60px", color: "#ffd700" }}
                        />
                        <h3>Gas</h3>
                      </div>
                    </div>
                    <div
                      className="square1"
                      style={squareStylesG1}
                      onClick={() => handleNavigateCombiPro()}
                    >
                      <div
                        className="icon-text-container1"
                        style={iconTextStyles1}
                      >
                        <EnergySavingsLeafIcon
                          style={{ fontSize: "60px", color: "#ffd700" }}
                        />
                        <h3>Elektriciteit & Gas</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="image-column-energycomparison1">
                  <img
                    src={solarpanelsimage}
                    alt="Section Image1"
                    className="section-image1"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default ChangeEnergyContractCustomer;

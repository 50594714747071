import React, { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import "./MyContracts.css";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import CenterTitle from "../../../../components/CenterTitle/CenterTitle";
import { GoClockFill } from "react-icons/go";
import {
  FaArchive,
  FaMapMarkerAlt,
  FaInfoCircle,
  FaTrash,
} from "react-icons/fa";
import { MdElectricMeter, MdGasMeter } from "react-icons/md";
import ContractPopup from "./popups/ContractPopup";
import ContractPopupGas from "./popups/ContractPopupGas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faFire,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getCookie } from "../../../../cookieUtils";

interface ContractInstance {
  id: string;
  contractId: string;
  userId: string;
  userAddressId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  startDate: Date;
  endDate: Date;
}

interface Contract {
  id: string;
  supplierId: string;
  documentTemplateId: string;
  contractDetailsId: string;
  name: string;
  description: string;
  category: number;
}

interface EnergySupplier {
  id: string;
  name: string;
  logo: string;
  active: number;
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

const MyContracts: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [contractInstances, setContractInstances] = useState<
    ContractInstance[]
  >([]);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [energySupplier, setEnergySupplier] = useState<EnergySupplier[]>([]);
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);
  const [selectedContractId, setSelectedContractId] = useState<string | null>(
    null,
  );
  const [selectedContractIdGas, setSelectedContractIdGas] = useState<
    string | null
  >(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchContractInstances();
    fetchEnergySuppliers(); // Fetch energy suppliers
  }, []);

  const fetchContractInstances = async () => {
    try {
      const storedUser = getCookie("user");
      if (storedUser) {
        const user = JSON.parse(storedUser);
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contractinstances/user/${user.id}`,
        );

        // Convert startDate and endDate strings to Date objects
        const instancesWithDates = response.data.map(
          (instance: ContractInstance) => ({
            ...instance,
            startDate: new Date(instance.startDate),
            endDate: new Date(instance.endDate),
          }),
        );

        setContractInstances(instancesWithDates);

        // Fetch the details of each contract instance
        const contractIds = instancesWithDates.map(
          (instance: any) => instance.contractId,
        );
        const contractDetails = await fetchContractDetails(contractIds);
        setContracts(contractDetails);
      }
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  // Function to fetch contract details based on contractIds
  const fetchContractDetails = async (contractIds: string[]) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractsAll?ids=${contractIds.join(
          ",",
        )}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error retrieving contract details:", error);
      return [];
    }
  };

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      setEnergySupplier(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
    }
  };

  const NavigateToElectricityContractInput = () => {
    navigate("/ElectricityContractInput");
  };

  const NavigateToGasContractInput = () => {
    navigate("/GasContractInput");
  };

  const NavigateToCombiContractInput = () => {
    navigate("/CombiContractInput");
  };

  const navigateToMyProfile = () => {
    navigate("/MyProfile");
  };

  const navigateToAddContractsElectricity = () => {
    navigate("/AddContractsElectricity");
  };

  const navigateToAddContractsGas = () => {
    navigate("/AddContractsGas");
  };

  const PopupOpen = (contractDetailsId: string, category: number) => {
    if (category == 1 || category == 2) {
      setSelectedContractId(contractDetailsId);
      setSelectedContractIdGas(null);
    } else if (category == 3 || category == 4) {
      setSelectedContractId(null);
      setSelectedContractIdGas(contractDetailsId);
    }
  };

  const handleDeleteContractInstance = async (contractInstanceId: string) => {
    try {
      // Make a DELETE request to the server to delete the contract instance
      await axios.delete(
        `${process.env.REACT_APP_DB_URL}/contractinstances/${contractInstanceId}`,
      );

      // Update the local state to reflect the deletion
      setContractInstances((prevInstances) =>
        prevInstances.filter((instance) => instance.id !== contractInstanceId),
      );

      console.log("Contract instance deleted successfully");
    } catch (error) {
      console.error("Error deleting contract instance:", error);
      // Handle error state or display an error message
    }
  };

  const renderContractCards = (contracts: Contract[]) => {
    return contractInstances.map((contractInstance) => {
      // Find the corresponding contract details using the contractId
      const contract = contracts.find(
        (contract) => contract.id === contractInstance.contractId,
      );

      // Find the corresponding energy supplier using the supplierId from contract
      const supplier = energySupplier.find(
        (supplier) => supplier.id === contract?.supplierId,
      );

      // Determine the icon based on the contract category
      let icon;
      switch (contract?.category) {
        case 1:
        case 2:
          icon = (
            <FontAwesomeIcon
              icon={faBolt}
              style={{ fontSize: "48px", color: "white" }}
            />
          );
          break;
        case 3:
        case 4:
          icon = (
            <FontAwesomeIcon
              icon={faFire}
              style={{ fontSize: "48px", color: "white" }}
            />
          );
          break;
        default:
          icon = (
            <FontAwesomeIcon
              icon={faBolt}
              style={{ fontSize: "48px", color: "white" }}
            />
          );
      }

      return (
        <div key={contractInstance.id} className="square" style={squareStyles}>
          {/* Display contract details if found */}
          {contract && (
            <React.Fragment>
              <div className="greenbg">
                <div className="paddingClassGreen">
                  {icon}
                  <h3 className="titleContract">{contract.name}</h3>
                  <p>{contract.description}</p>
                </div>
              </div>
              <div className="listleft">
                <p>
                  <FaArchive
                    style={{ marginRight: "10px", color: "#3D3E3D" }}
                  ></FaArchive>{" "}
                  {contract.category === 1
                    ? "Elektriciteit Vast"
                    : contract.category === 2
                      ? "Elektriciteit Variabel"
                      : contract.category === 3
                        ? "Gas Vast"
                        : contract.category === 4
                          ? "Gas Variabel"
                          : ""}
                </p>
                <p>
                  <FaMapMarkerAlt
                    style={{ marginRight: "10px", color: "#3D3E3D" }}
                  ></FaMapMarkerAlt>
                  {contractInstance.streetname} {contractInstance.housenumber},{" "}
                  {contractInstance.zipcode} {contractInstance.city}
                </p>
                {contractInstance.eane != "" && (
                  <p>
                    <MdElectricMeter
                      style={{ marginRight: "10px", color: "#3D3E3D" }}
                    ></MdElectricMeter>
                    EAN:{contractInstance.eane}{" "}
                  </p>
                )}
                {contractInstance.eang != "" && (
                  <p>
                    <MdGasMeter
                      style={{ marginRight: "10px", color: "#3D3E3D" }}
                    ></MdGasMeter>
                    EAN:{contractInstance.eang}{" "}
                  </p>
                )}
                <p>
                  <GoClockFill
                    style={{ marginRight: "10px", color: "#3D3E3D" }}
                  ></GoClockFill>{" "}
                  tot: {contractInstance.endDate.toLocaleDateString()}
                </p>

                {/* <button style={buttonStyles} onClick={() => PopupOpen(contract.contractDetailsId, contract.category)}>Prijsdetails</button>
                  <button style={buttonStyles} onClick={() => handleDeleteContractInstance(contractInstance.id)}>Delete Contract</button> */}
                <div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() =>
                          PopupOpen(
                            contract.contractDetailsId,
                            contract.category,
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{ marginRight: "10px", color: "blue" }}
                        />
                        <span style={{ marginRight: "10px" }}>
                          Prijsdetails
                        </span>
                      </a>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <a
                        href="#"
                        onClick={() =>
                          handleDeleteContractInstance(contractInstance.id)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ marginRight: "10px", color: "red" }}
                        />
                        <span style={{ marginRight: "10px" }}>
                          Delete Contract
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      );
    });
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  };

  const squareContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: "wrap", // Allow squares to wrap to the next line if needed
  };

  const squareStyles: React.CSSProperties = {
    width: "350px",
    height: "550px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    //  paddingLeft: '50px',
    //   paddingRight: '50px',
    // paddingBottom: '30px',
    // paddingTop: '100px',
    marginLeft: "25px",
    marginRight: "25px",
    marginTop: "50px",
    // marginTop: '100px',
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const squareContainerStylesNoti: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginTop: "20px",
  };

  const squareStylesNoti: React.CSSProperties = {
    width: "100%",
    height: "125px",
    backgroundColor: "#f8f9fa",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  };
  const dashboardContainerCouStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const buttonStyles: React.CSSProperties = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    outline: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgb(211, 230, 243)",
    width: "100%",
    textTransform: "none",
    borderRadius: "4px",
    boxShadow: "none",
    padding: "0.6rem 1.5rem",
    fontSize: "12px",
    fontWeight: 600,
    minWidth: "84px",
    color: "rgb(18, 31, 67)",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  // Get the current date and calculate the date that is 3 months in the future
  const currentDate = new Date();
  const futureDate = new Date(currentDate);
  futureDate.setMonth(currentDate.getMonth() + 3);

  // Check if the "Elektriciteit contract aanvragen" square should be shown
  const showElectricitySquare =
    contractInstances.length === 0 ||
    contractInstances.some((instance) => instance.endDate <= futureDate);

  // Check if the "Gas contract aanvragen" square should be shown
  const showGasSquare =
    contractInstances.length === 0 ||
    contractInstances.some((instance) => instance.endDate <= futureDate);

  // Check if the "Gas en elektriciteit aanvragen" square should be shown
  const showCombiSquare =
    contractInstances.length === 0 ||
    contractInstances.some((instance) => instance.endDate <= futureDate);

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Mijn diensten</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <CenterTitle
                title="Overzicht contracten"
                subtitle="U kan hier een overzicht terugvinden van uw afgesloten dienst contracten met de bijhorende informatie."
              ></CenterTitle>
              <div className="parent-container">
                <button
                  className="buttonstyleMiddle"
                  onClick={() => navigate("/ChangeEnergyContractCustomer")}
                >
                  <b>+</b>
                </button>
              </div>

              <div className="squareContainer" style={squareContainerStyles}>
                {renderContractCards(contracts)}
              </div>

              {selectedContractId && (
                <ContractPopup
                  contractId={selectedContractId}
                  onClose={() => setSelectedContractId(null)}
                />
              )}
              {selectedContractIdGas && (
                <ContractPopupGas
                  contractId={selectedContractIdGas}
                  onClose={() => setSelectedContractIdGas(null)}
                />
              )}

              {/* Doorverwijzingen */}
              <div
                className="dashboardContainer"
                style={dashboardContainerCouStyles}
              ></div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default MyContracts;

{
  /* {(showElectricitySquare || showGasSquare || showCombiSquare) && (
            <Typography variant="h6" style={{ marginTop: '50px' }}>
              Vergelijk uw contracten voor een voordeliger tarief:
            </Typography>
          )}

        <div className="squareContainerNoti" style={squareContainerStylesNoti}>
        {showElectricitySquare && (
          <div
            className="squareNoti"
            style={squareStylesNoti}
            onClick={NavigateToElectricityContractInput}
          >
            <PersonIcon style={{ fontSize: '50px', color: '#8dc63f' }} />
            <h4>Elektriciteit contract aanvragen</h4>
          </div>
        )}
        
        {showGasSquare && (
          <div
            className="squareNoti"
            style={squareStylesNoti}
            onClick={NavigateToGasContractInput}
          >
            <GroupIcon style={{ fontSize: '50px', color: '#8dc63f' }} />
            <h4>Gas contract aanvragen</h4>
          </div>
        )}

        {showCombiSquare && (
          <div
            className="squareNoti"
            style={squareStylesNoti}
            onClick={NavigateToCombiContractInput}
          >
            <GroupIcon style={{ fontSize: '50px', color: '#8dc63f' }} />
            <h4>Gas en elektriciteit aanvragen</h4>
          </div>
        )}
      </div> */
}

{
  /* <div className="squareNavStyles">
                  <div style={{ display: "flex", alignItems: "center" }}>
                   
                    <div>
                      
                      <h3 className="squareNavTextStyles">
                      Electriciteitscontract toevoegen
                      </h3>
                      <p className="squareNavParagraphsStyles">Voeg hier uw elektriciteitscontract toe voor een schatting van uw verbruik</p>
                      <button className="buttonWithArrow" onClick={() => navigateToAddContractsElectricity()} >Klik hier</button>
                    </div>
                   
                  </div>
                  
                </div>     


               

                  <div className="squareNavStyles" >
                  <div style={{ display: "flex", alignItems: "center" }}> 
                    <div>
                      
                    <h3 className="squareNavTextStyles">
                      Gascontract toevoegen
                      </h3>
                      <p className="squareNavParagraphsStyles">Voeg hier uw gascontract toe voor een schatting van uw verbruik</p>
                      <button className="buttonWithArrow" onClick={() => navigateToAddContractsGas()}>Klik hier</button>
                    </div>
                  </div>
      
                </div> 
                
              
                <div className="squareNavStyles">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    
                    <div>
                    
                    <h3 className="squareNavTextStyles">
                      Verbruiksadressen beheren
                      </h3>
                      <p className="squareNavParagraphsStyles">Beheer uw verbruiksadressen om uw energieverbruik te monitoren</p>
                      <button className="buttonWithArrow" onClick={() => navigateToMyProfile()}>Klik hier</button>
                    </div>
                  </div>
                  
                </div>  */
}

// const renderContractCards = (contracts: Contract[]) => {

//   return contractInstances.map((contractInstance) => {
//     // Find the corresponding contract details using the contractId
//     const contract = contracts.find((contract) => contract.id === contractInstance.contractId);

//     // Find the corresponding energy supplier using the supplierId from contract
//     const supplier = energySupplier.find((supplier) => supplier.id === contract?.supplierId);

//     return (
//       <div key={contractInstance.id} className="square" style={squareStyles}>
//         {/* Display the logo of the energy supplier if found */}
//         {supplier && (
//           <img src={supplier.logo} alt={supplier.name} style={{ width: '180px', height: '180px' }} />
//         )}

//         {/* Display contract details if found */}
//         {contract && (
//           <React.Fragment>
//             <h3>{contract.name}</h3>
//              {/* <p>{contract.description}</p>
//               */}
//             <p><FaArchive></FaArchive> {contract.category === 1 ? 'Elektriciteit Vast' : contract.category === 2 ? 'Elektriciteit Variabel' : contract.category === 3 ? 'Gas Vast' : contract.category === 4 ? 'Gas Variabel' : ''}</p>
//              <p><FaMapMarkerAlt></FaMapMarkerAlt>{contractInstance.streetname} {contractInstance.housenumber}, {contractInstance.zipcode} {contractInstance.city}</p>
//              { contractInstance.eane != '' && (<p><MdElectricMeter></MdElectricMeter>EAN:{contractInstance.eane} </p>)}
//              { contractInstance.eang != '' && (<p><MdGasMeter></MdGasMeter>EAN:{contractInstance.eang} </p>)}
//              <p><GoClockFill></GoClockFill> tot:  {contractInstance.endDate.toLocaleDateString()}</p>
//         <button style={buttonStyles} onClick={() => PopupOpen(contract.contractDetailsId, contract.category)} >Prijsdetails</button>
//         <button
//     style={buttonStyles}
//     onClick={() => handleDeleteContractInstance(contractInstance.id)}
//   >
//     Delete Contract
//   </button>
//           </React.Fragment>
//         )}

//       </div>

//     );
//   });

// };

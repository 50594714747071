import React, { useState } from "react";
import "./PasswordForgottenConfirm.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import YouTube from "react-youtube";

const PasswordForgottenConfirm: React.FC = () => {
  const { handleSubmit, register, setError } = useForm();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailFocused, setEmailFocused] = useState(false);
  const navigate = useNavigate();

  const handlePasswordForgotten = async (data: any) => {
    navigate("/Login");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleEmailFocus = () => {
    setEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setEmailFocused(true);
  };

  const opts = {
    playerVars: {
      autoplay: 1, // Autoplay the video
      controls: 0, // Hide the player controls
      modestbranding: 1, // Hide the YouTube logo
      loop: 1, // Play the video on repeat
      playlist: "wziCr2xFXX0", // Playlist to ensure the loop works
    },
  };

  return (
    <div className="login-page">
      {/* <div className="login-image"></div> */}
      <YouTube videoId="wziCr2xFXX0" className="login-video" opts={opts} />
      <div className="login-form">
        <form
          className="login-form"
          onSubmit={handleSubmit(handlePasswordForgotten)}
        >
          <h2 className="title">Check uw mailbox!</h2>
          <p>
            Er is een reset wachtwoord mail naar uw e-mail adres verzonden.
            Gelieve de stappen in de mail te volgen.
          </p>

          <button className="login-button" type="submit">
            Ga naar home
          </button>
        </form>
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default PasswordForgottenConfirm;

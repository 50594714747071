import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./AddContract.css";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface EnergySupplier {
  id: string;
  name: string;
}

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  fixedPriceGas: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

interface DocumentTemplate {
  id: string;
  documentPath: string;
  documentName: string;
  status: number;
}

const AddContractGas = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(0);
  const [supplierId, setSupplierId] = useState("");
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [yearlySubscription, setyearlySubscription] = useState(0.01);
  const [invoiceDiscount, setInvoiceDiscount] = useState(0.01);
  const [kwhConsumptionPrice, setkwhConsumptionPrice] = useState(0.01);
  const [kwhConsumptionPriceDiscount, setkwhConsumptionPriceDiscount] =
    useState(0.01);
  const [
    kwhConsumptionPriceAfterDiscount,
    setkwhConsumptionPriceAfterDiscount,
  ] = useState(0.01);
  const [fixedPriceGas, setFixedPriceGas] = useState(0.01);
  const [distributionCostPrice, setdistributionCostPrice] = useState(0.01);
  const [transportCostPrice, setTransportCostPrice] = useState(0.01);
  const [taxPrice, settaxPrice] = useState(0.01);
  const [totalYearlyCost, setTotalYearlyCost] = useState(0.01);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [hasUploadedDocument, setHasUploadedDocument] = useState(false);
  const [showDropField, setShowDropField] = useState(false);
  const [documentTemplateId, setDocumentTemplateId] = useState<string | null>(
    null,
  );

  const [minimalDurationLength, setMinimalDurationLength] = useState(0);
  const [commission, setCommission] = useState(0.01);
  const [clawback, setClawback] = useState(0.01);
  const [VME, setVME] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchEnergySuppliers();
  }, []);

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      const suppliers: EnergySupplier[] = response.data;
      setEnergySuppliers(suppliers);
    } catch (error) {
      console.error("Error fetching energy suppliers:", error);
    }
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleSupplierIdChange = (event: any) => {
    setSupplierId(event.target.value);
  };

  const handleYearlySubscriptionChange = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setyearlySubscription(categorychange);
  };

  const handleKwhConsumptionPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPrice(categorychange);
  };

  const handleFixedPriceGas = (event: React.ChangeEvent<HTMLInputElement>) => {
    const categorychange = parseFloat(event.target.value);
    setFixedPriceGas(categorychange);
  };

  const handleKwhConsumptionPriceDiscountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPriceDiscount(categorychange);
  };
  const handleKwhConsumptionPriceAfterDiscountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPriceAfterDiscount(categorychange);
  };
  const handleInvoiceDiscountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setInvoiceDiscount(categorychange);
  };
  const handleDistributionCostPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setdistributionCostPrice(categorychange);
  };

  const handleTransportCostPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setTransportCostPrice(categorychange);
  };

  const handleTaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const categorychange = parseFloat(event.target.value);
    settaxPrice(categorychange);
  };

  const handleTotalYearlyCostChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setTotalYearlyCost(categorychange);
  };

  const handleCategoryChange = (event: any) => {
    //  const categorychange = parseFloat(event.target.value);
    const categorychange = parseInt(event.target.value);
    setCategory(categorychange);
  };

  const handleMinimalDurationLength = (event: any) => {
    setMinimalDurationLength(event.target.value);
  };

  const handleCommission = (event: any) => {
    setCommission(event.target.value);
  };

  const handleClawback = (event: any) => {
    setClawback(event.target.value);
  };

  const handleVME = (event: any) => {
    setVME(event.target.value);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const formData = new FormData();

      acceptedFiles.forEach((file) => {
        if (file.type === "application/pdf") {
          formData.append("document", file);
          formData.append("documentName", file.name);
        } else {
          console.log("Invalid file format. Only PDF files are allowed.");
        }
      });

      if (formData.has("document")) {
        const documentTemplateId = uuidv4(); // Generate a UUID for the document
        const id = documentTemplateId.toString();
        setDocumentTemplateId(documentTemplateId);
        formData.append("id", id); // Include the UUID in the form data

        await axios.post(
          `${process.env.REACT_APP_DB_URL}/documentTemplates`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );

        console.log("Files uploaded successfully");
        setHasUploadedDocument(true);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  }, []);

  const handleAddContract = async () => {
    try {
      const contractId = uuidv4(); // Generate a unique ID for the contract
      const contractGasDetails = {
        contractId,
        yearlySubscription,
        invoiceDiscount,
        kwhConsumptionPrice,
        kwhConsumptionPriceDiscount,
        kwhConsumptionPriceAfterDiscount,
        fixedPriceGas,
        distributionCostPrice,
        transportCostPrice,
        taxPrice,
        totalYearlyCost,
      };

      // Step 1: Create the contractElectricityDetails record
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/contractGasDetails`,
        contractGasDetails,
      );
      const contractGasDetailsId = response.data.contractGasDetails.id;

      const id = uuidv4();
      // Step 2: Create the main contract
      const newContract = {
        id,
        name,
        description,
        category,
        supplierId,
        documentTemplateId: documentTemplateId,
        contractDetailsId: contractGasDetailsId,
        minimalDurationLength,
        commission,
        clawback,
        VME,
      };
      await axios.post(
        `${process.env.REACT_APP_DB_URL}/contracts`,
        newContract,
      );
      console.log("Contract created successfully");

      // Step 3: Update contractGasDetails with the correct contractId
      const updatedContractGasDetails = {
        contractId: newContract.id,
        // Add other properties as needed for updating the record
      };
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/contractGasDetails/${contractGasDetailsId}`,
        updatedContractGasDetails,
      );
      console.log("contractGasDetails updated successfully");

      navigate("/Contracts");
    } catch (error) {
      console.error("Error creating contract:", error);
      // Handle error state or display an error message
    }
  };

  const containerStyles = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleButtonClick = () => {
    setShowDropField(!showDropField);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Contract toevoegen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <hr className="dividerDashed" />
              {/* <div className="contentContainer"> */}
              <div>
                <div className="formContainer">
                  {errorMessage ? (
                    <p className="errorMessage">{errorMessage}</p>
                  ) : null}

                  <div className="userCardac">
                    <div className="userCardContainerac">
                      <div className="userCardColumnac">
                        {/* <h1 className="pageTitle" style={pageTitleStyles}>
            Contract informatie
          </h1> */}
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <ElectricBoltIcon
                              sx={{ color: "action.active", mr: 1, my: 0.5 }}
                            />
                            <TextField
                              label="Naam"
                              variant="standard"
                              type="text"
                              id="nameFilter"
                              value={name}
                              onChange={handleNameChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <ElectricBoltIcon
                              sx={{ color: "action.active", mr: 1, my: 0.5 }}
                            />
                            <TextField
                              label="Beschrijving"
                              variant="standard"
                              type="text"
                              value={description}
                              onChange={handleDescriptionChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="category"
                              select
                              label="Categorie"
                              value={category}
                              onChange={handleCategoryChange}
                              variant="standard"
                              fullWidth
                            >
                              <MenuItem value={0}>
                                Selecteer een categorie
                              </MenuItem>
                              <MenuItem value={3}>Gas Vast</MenuItem>
                              <MenuItem value={4}>Gas Variabel</MenuItem>
                              <MenuItem value={6}>Gas Dynamisch</MenuItem>
                              <MenuItem value={12}>
                                Gas Vast Professioneel
                              </MenuItem>
                              <MenuItem value={13}>
                                Gas Variabel Professioneel
                              </MenuItem>
                              <MenuItem value={15}>
                                Gas Dynamisch Professioneel
                              </MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="Energieleverancier"
                              select
                              label="Energieleverancier"
                              value={supplierId}
                              onChange={handleSupplierIdChange}
                              variant="standard"
                              fullWidth
                            >
                              <MenuItem value={0}>
                                Selecteer een energieleverancier
                              </MenuItem>
                              {energySuppliers.map((supplier) => (
                                <MenuItem key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Minimale looptijd"
                              variant="standard"
                              type="number"
                              id="minimaldurationlength"
                              value={minimalDurationLength}
                              onChange={handleMinimalDurationLength}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Commissie"
                              variant="standard"
                              type="number"
                              id="commission"
                              value={commission}
                              onChange={handleCommission}
                              fullWidth
                            />
                          </Box>
                        </div>
                        {/* { hasUploadedDocument === false ? (<button className="uploadButton" onClick={handleButtonClick}>
              {showDropField ? "Annuleren" : "Bestanden uploaden"}
            </button> ) : (<div></div>)} */}
                      </div>
                      {showDropField && hasUploadedDocument === false && (
                        <div
                          {...getRootProps()}
                          className={`dropzone ${isDragActive ? "active" : ""}`}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here...</p>
                          ) : (
                            <p>
                              Klik op deze tekst om een bestand te selecteren of
                              sleep het document in dit vak.
                            </p>
                          )}
                        </div>
                      )}
                      {/* </div> */}

                      {/* price details forms */}

                      <div className="userCardColumnac">
                        {/* <label className="label">Jaarlijks abonnement:</label>
             <input type="number" className="input" step={0.01} value={yearlySubscription} onChange={handleYearlySubscriptionChange} /> */}
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Clawback"
                              variant="standard"
                              type="number"
                              id="clawback"
                              value={clawback}
                              onChange={handleClawback}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="VME"
                              variant="standard"
                              type="number"
                              id="vme"
                              value={VME}
                              onChange={handleVME}
                              fullWidth
                            />
                          </Box>
                        </div>
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Jaarlijks abonnement"
                              variant="standard"
                              type="number"
                              value={yearlySubscription}
                              onChange={handleYearlySubscriptionChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="kWh verbruikskost"
                              variant="standard"
                              type="number"
                              value={kwhConsumptionPrice}
                              onChange={handleKwhConsumptionPriceChange}
                              fullWidth
                            />
                          </Box>
                        </div>
                        {/* <label className="label">Korting domiciliëring of e-factuur:</label>
             <input type="number" className="input" step={0.01} value={invoiceDiscount} onChange={handleInvoiceDiscountChange} />

              <label className="label">kWh verbruikskost:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPrice} onChange={handleKwhConsumptionPriceChange} />

              <label className="label">kWh korting op kWh-prijs*:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceDiscount} onChange={handleKwhConsumptionPriceDiscountChange} />

              <label className="label">Prijs na korting:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceAfterDiscount} onChange={handleKwhConsumptionPriceAfterDiscountChange} />
              
             <label className="label">Vaste vergoeding (Jaarlijks):</label>
             <input type="number" className="input" step={0.01} value={fixedPriceGas} onChange={handleFixedPriceGas} /> */}

                        {/* 
              <label className="label">Distributiekosten:</label>
             <input type="number" className="input" step={0.01} value={distributionCostPrice} onChange={handleDistributionCostPriceChange} />


              <label className="label">Transportkosten:</label>
             <input type="number" className="input" step={0.01} value={transportCostPrice} onChange={handleTransportCostPriceChange} />

                <label className="label">Heffingen:</label>
             <input type="number" className="input" step={0.01} value={taxPrice} onChange={handleTaxPriceChange} /> */}

                        {/* 
              <label className="label">Totale Jaarlijkse kostprijs:</label>
             <input type="number" className="input" step={0.01} value={totalYearlyCost} onChange={handleTotalYearlyCostChange} /> */}
                      </div>

                      <button
                        className="updateButton"
                        onClick={handleAddContract}
                      >
                        Contract toevoegen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default AddContractGas;

import React, { useEffect, useState } from "react";
import NavigationLeft from "../../../components/Navigation/NavigationLeft/NavigationLeft";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Link, useLocation, useNavigate } from "react-router-dom";
import solarpanelsimage from "./zonnepanelen.jpg";
import solarpanelscleaningimage from "./zonnepanelenReinigen.jpg";
import boilerimage from "./ketel.jpg";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import "./Quotes.css";
import "./../../../theme/styles.css";
import SectionHeroColored from "../../../components/SectionheroColored/SectionHeroColored";
import { getCookie } from "../../../cookieUtils";

const Quotes: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isLargeScreen = window.innerWidth > 768;

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  useEffect(() => {
    // Scroll to the element with ID "zonnepanelenreinigen" on mount
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top if no hash is provided
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  const navigateToSolarPanelsApplicationCustomer = () => {
    navigate("/SolarPanelsApplicationCustomer");
  };

  const navigateToSolarPanelsCleaningApplicationCustomer = () => {
    navigate("/SolarPanelCleaningApplicationCustomer");
  };

  const navigateToBoilerMaintenanceApllicationCustomer = () => {
    navigate("/BoilerMaintenanceApplicationCustomer");
  };

  const navigateToIsolationApllicationCustomer = () => {
    navigate("/IsolationApplicationCustomer");
  };

  const navigateToChargingStationApllicationCustomer = () => {
    navigate("/ChargingStationApplicationCustomer");
  };

  const navigateToHeatPumpApllicationCustomer = () => {
    navigate("/HeatPumpApplicationCustomer");
  };

  const navigateToAircoApllicationCustomer = () => {
    navigate("/AircoApplicationCustomer");
  };

  const navigateToEmsApllicationCustomer = () => {
    navigate("/EmsApplicationCustomer");
  };

  const navigateToInfraredPanelApllicationCustomer = () => {
    navigate("/InfraredPanelApplicationCustomer");
  };

  const navigateToHeatPumpBoilerApllicationCustomer = () => {
    navigate("/HeatPumpBoilerApplicationCustomer");
  };

  const navigateToEnergyContractsApllicationCustomer = () => {
    navigate("/ChangeEnergyContractCustomer");
  };

  const navigateToMaintentanceContractsApllicationCustomer = () => {
    navigate("/ChangeEnergyContractCustomer");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Offertes technieken</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            {/* <div className="contentContainer" style={contentContainerStyles}>  */}

            <div className="section-container">
              <div className="text-column">
                <h2 id="zonnepanelen" className="section-title">
                  Zonnepanelen & Thuisbatterijen
                </h2>
                <div className="green-divider"></div>
                <p className="section-paragraph">
                  Bent u klaar om een stap te zetten richting een groenere
                  toekomst? Ontdek de kracht van zonne-energie met onze
                  hoogwaardige zonnepanelen! Door nu een offerte aan te vragen,
                  opent u de deur naar een wereld van duurzame energieopwekking
                  en financiële besparingen.
                  <br></br> <br></br>
                  Door te kiezen voor zonnepanelen investeert u niet alleen in
                  een schonere planeet, maar ook in uw eigen financiële welzijn
                  op de lange termijn. Wacht niet langer! Vraag vandaag nog uw
                  offerte aan en ontdek hoe zonne-energie uw leven kan
                  transformeren.
                </p>

                <button
                  className="buttonstyle33"
                  onClick={navigateToSolarPanelsApplicationCustomer}
                >
                  Offerte aanvragen
                </button>
              </div>
              <div className="image-column">
                <div className="grid-container">
                  <div className="grid-row">
                    <img
                      src={solarpanelsimage}
                      alt="diensten"
                      className="section-image"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                  <div className="grid-row">
                    <img
                      src={solarpanelscleaningimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                </div>
              </div>
              {/* </div>  */}

              {/* <div className="section-container">
      <div className="text-column">
        <h2 className="section-title">Zonnepanelen</h2>
        <div className="green-divider"></div>
        <p className="section-paragraph">
        Bent u klaar om een stap te zetten richting een groenere toekomst? Ontdek de kracht van zonne-energie met onze hoogwaardige zonnepanelen! Door nu een offerte aan te vragen, opent u de deur naar een wereld van duurzame energieopwekking en financiële besparingen.
        <br></br> <br></br>
        Door te kiezen voor zonnepanelen investeert u niet alleen in een schonere planeet, maar ook in uw eigen financiële welzijn op de lange termijn. Wacht niet langer! Vraag vandaag nog uw offerte aan en ontdek hoe zonne-energie uw leven kan transformeren.
        </p>
        <button className='buttonstyle33' onClick={navigateToSolarPanelsApplicationCustomer}>Offerte aanvragen</button>
      </div>
      <div className="image-column">
        <img
          src={solarpanelsimage}
          alt="Section Image"
          className="section-image"
  
        />
      </div> */}

              <div className="image-columntwo">
                {/* <img
          src={solarpanelscleaningimage}
          alt="Section Image"
          className="section-image"
  
        /> */}
                <div className="grid-container">
                  <div className="grid-row">
                    <img
                      src={solarpanelsimage}
                      alt="diensten"
                      className="section-image"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                  <div className="grid-row">
                    <img
                      src={solarpanelscleaningimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="text-columntwo">
                <h2 id="zonnepanelenreinigen" className="section-title">
                  Zonnepanelen Reinigen
                </h2>
                <div className="green-divider"></div>
                <p className="section-paragraph">
                  Bent u op zoek naar een manier om de prestaties van uw
                  zonnepanelen te optimaliseren en uw energieopbrengst te
                  verhogen? Bij ons begrijpen we hoe belangrijk het is om uw
                  investering in duurzame energie te beschermen. Daarom bieden
                  we u de mogelijkheid om een offerte aan te vragen voor het
                  reinigen van uw zonnepanelen.
                  <br></br> <br></br>
                  In de loop van de tijd kunnen zonnepanelen vuil, stof en
                  andere verontreinigingen verzamelen, wat kan leiden tot
                  verminderde efficiëntie en opbrengst. Ons ervaren team van
                  professionals staat voor u klaar om uw zonnepanelen weer te
                  laten schitteren als nieuw. Door regelmatig onderhoud en
                  reiniging te laten uitvoeren, kunt u niet alleen de levensduur
                  van uw zonnepanelen verlengen, maar ook genieten van maximale
                  opbrengst en besparingen.
                </p>
                <button
                  className="buttonstyle33"
                  onClick={navigateToSolarPanelsCleaningApplicationCustomer}
                >
                  Offerte aanvragen
                </button>
              </div>
              {/* <SectionHeroColored subText="Bespaar maximaal door een concreet onderhoud en isolatieplan.">Onderhoud en isolatie</SectionHeroColored> */}

              <div className="text-columnthree">
                <h2 id="ketelonderhoud" className="section-title">
                  Ketelonderhoud{" "}
                </h2>
                <div className="green-divider"></div>
                <p className="section-paragraph">
                  Bent u op zoek naar een betrouwbare partner om de efficiëntie
                  en veiligheid van uw verwarmingssysteem te waarborgen? Bij ons
                  begrijpen we het belang van goed onderhoud voor de optimale
                  werking van uw ketel. Daarom bieden we u de gelegenheid om een
                  offerte aan te vragen voor professioneel ketelonderhoud.
                  <br></br> <br></br>
                  Uw ketel is het hart van uw verwarmingssysteem en zorgt voor
                  warmte en comfort, vooral tijdens de koude maanden. Regelmatig
                  onderhoud is essentieel om ervoor te zorgen dat uw ketel
                  betrouwbaar blijft werken en de hoogst mogelijke
                  energie-efficiëntie behoudt. Ons deskundige team staat voor u
                  klaar om uw ketel grondig te inspecteren, schoon te maken en
                  te onderhouden, zodat u kunt genieten van een warme en
                  zorgeloze leefomgeving.
                </p>
                <button
                  className="buttonstyle33"
                  onClick={navigateToBoilerMaintenanceApllicationCustomer}
                >
                  Offerte aanvragen
                </button>
              </div>
              <div className="image-columnthree">
                {/* <img
          src={boilerimage}
          alt="Section Image"
          className="section-image"
  
        /> */}
                <div className="grid-container">
                  <div className="grid-row">
                    <img
                      src={solarpanelsimage}
                      alt="diensten"
                      className="section-image"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                  <div className="grid-row">
                    <img
                      src={solarpanelscleaningimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                </div>
              </div>

              {/* Isolatie */}
              {/* <div className="image-columntwo">
      
        <div className="grid-container">
      <div className="grid-row">
        <img src={solarpanelsimage} alt="diensten" className="section-image" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
      <div className="grid-row">
        <img src={solarpanelscleaningimage} alt="diensten" className="section-image hide-on-mobile" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
    </div>
      </div>
      <div className="text-columntwo">
        <h2 id="isolatie" className="section-title">Isolatie</h2>
        <div className="green-divider"></div>
        <p className="section-paragraph">
        Bent u op zoek naar een manier om de prestaties van uw zonnepanelen te optimaliseren en uw energieopbrengst te verhogen? Bij ons begrijpen we hoe belangrijk het is om uw investering in duurzame energie te beschermen. Daarom bieden we u de mogelijkheid om een offerte aan te vragen voor het reinigen van uw zonnepanelen.
        <br></br> <br></br>
        In de loop van de tijd kunnen zonnepanelen vuil, stof en andere verontreinigingen verzamelen, wat kan leiden tot verminderde efficiëntie en opbrengst. Ons ervaren team van professionals staat voor u klaar om uw zonnepanelen weer te laten schitteren als nieuw. Door regelmatig onderhoud en reiniging te laten uitvoeren, kunt u niet alleen de levensduur van uw zonnepanelen verlengen, maar ook genieten van maximale opbrengst en besparingen.
        </p>
        <button className='buttonstyle33' onClick={navigateToIsolationApllicationCustomer}>Offerte aanvragen</button>
      </div> */}

              {/* Laadpalen */}
              {/* <div className="text-columnthree">
        <h2 id="laadpalen" className="section-title">Laadpalen </h2>
        <div className="green-divider"></div>
        <p className="section-paragraph">
        Bent u op zoek naar een betrouwbare partner om de efficiëntie en veiligheid van uw verwarmingssysteem te waarborgen? Bij ons begrijpen we het belang van goed onderhoud voor de optimale werking van uw ketel. Daarom bieden we u de gelegenheid om een offerte aan te vragen voor professioneel ketelonderhoud.
        <br></br> <br></br>
        Uw ketel is het hart van uw verwarmingssysteem en zorgt voor warmte en comfort, vooral tijdens de koude maanden. Regelmatig onderhoud is essentieel om ervoor te zorgen dat uw ketel betrouwbaar blijft werken en de hoogst mogelijke energie-efficiëntie behoudt. Ons deskundige team staat voor u klaar om uw ketel grondig te inspecteren, schoon te maken en te onderhouden, zodat u kunt genieten van een warme en zorgeloze leefomgeving.
        </p>
        <button className='buttonstyle33' onClick={navigateToChargingStationApllicationCustomer}>Offerte aanvragen</button>
      </div>
      <div className="image-columnthree">
        
        <div className="grid-container">
      <div className="grid-row">
        <img src={solarpanelsimage} alt="diensten" className="section-image" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
      <div className="grid-row">
        <img src={solarpanelscleaningimage} alt="diensten" className="section-image hide-on-mobile" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
    </div>
      </div> */}

              {/* Laadpalen */}
              <div className="image-columntwo">
                <div className="grid-container">
                  <div className="grid-row">
                    <img
                      src={solarpanelsimage}
                      alt="diensten"
                      className="section-image"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                  <div className="grid-row">
                    <img
                      src={solarpanelscleaningimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="text-columntwo">
                <h2 id="Laadstations" className="section-title">
                  Laadstations
                </h2>
                <div className="green-divider"></div>
                {/* <p className="section-paragraph">
        Bent u op zoek naar een betrouwbare partner om de efficiëntie en veiligheid van uw verwarmingssysteem te waarborgen? Bij ons begrijpen we het belang van goed onderhoud voor de optimale werking van uw ketel. Daarom bieden we u de gelegenheid om een offerte aan te vragen voor professioneel ketelonderhoud.
        <br></br> <br></br>
        Uw ketel is het hart van uw verwarmingssysteem en zorgt voor warmte en comfort, vooral tijdens de koude maanden. Regelmatig onderhoud is essentieel om ervoor te zorgen dat uw ketel betrouwbaar blijft werken en de hoogst mogelijke energie-efficiëntie behoudt. Ons deskundige team staat voor u klaar om uw ketel grondig te inspecteren, schoon te maken en te onderhouden, zodat u kunt genieten van een warme en zorgeloze leefomgeving.
        </p> */}
                <p className="section-paragraph">
                  De gewone laadpaal verschilt van de slimme laadpaal
                  hoofdzakelijk door het gebrek aan communicatie. Een gewone
                  laadpaal zet je net zoals de friteuse of microgolf aan/ af
                  zonder rekening te houden met andere factoren. Een slimme
                  laadpaal ‘weet’ anders dan een domme laadpaal dat hij het
                  beste tussen 11 uur ’s avonds en 7 uur ’s ochtends kan laden
                  in verband met het daltarief. Daardoor bespaart de gebruiker
                  enorm op energiekosten en wordt ook gelijk het stroomnet
                  ontzien tijdens een piek.
                  <br></br> <br></br>
                  Maar een slimme laadpaal kan met nog meer dingen rekening
                  houden. Heeft de gebruiker zonnepanelen op het dak? Dan kan er
                  voor gekozen worden om zoveel mogelijk gebruik te maken van de
                  duurzame stroom voor het opladen van de auto. Ook heeft een
                  slimme laadpaal vaak de optie Dynamic Load Balancing, Met deze
                  functie bepaalt de laadsnelheid van je elektrische wagen
                  automatisch in functie van de beschikbare capaciteit. Zo
                  geraakt de netwerkaansluiting nooit overbelast. Hoe lager het
                  elektriciteitsverbruik in huis, hoe hoger de laadsnelheid van
                  je wagen.
                </p>
                <button
                  className="buttonstyle33"
                  onClick={navigateToChargingStationApllicationCustomer}
                >
                  Offerte aanvragen
                </button>
              </div>

              {/* Warmtepomp Boiler */}
              <div className="text-columnthree">
                <h2 id="warmtepompboiler" className="section-title">
                  Warmtepompboiler{" "}
                </h2>
                <div className="green-divider"></div>
                <p className="section-paragraph">
                  Bent u op zoek naar een betrouwbare partner om de efficiëntie
                  en veiligheid van uw verwarmingssysteem te waarborgen? Bij ons
                  begrijpen we het belang van goed onderhoud voor de optimale
                  werking van uw ketel. Daarom bieden we u de gelegenheid om een
                  offerte aan te vragen voor professioneel ketelonderhoud.
                  <br></br> <br></br>
                  Uw ketel is het hart van uw verwarmingssysteem en zorgt voor
                  warmte en comfort, vooral tijdens de koude maanden. Regelmatig
                  onderhoud is essentieel om ervoor te zorgen dat uw ketel
                  betrouwbaar blijft werken en de hoogst mogelijke
                  energie-efficiëntie behoudt. Ons deskundige team staat voor u
                  klaar om uw ketel grondig te inspecteren, schoon te maken en
                  te onderhouden, zodat u kunt genieten van een warme en
                  zorgeloze leefomgeving.
                </p>
                <button
                  className="buttonstyle33"
                  onClick={navigateToHeatPumpBoilerApllicationCustomer}
                >
                  Offerte aanvragen
                </button>
              </div>
              <div className="image-columnthree">
                {/* <img
          src={boilerimage}
          alt="Section Image"
          className="section-image"
  
        /> */}
                <div className="grid-container">
                  <div className="grid-row">
                    <img
                      src={solarpanelsimage}
                      alt="diensten"
                      className="section-image"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                  <div className="grid-row">
                    <img
                      src={solarpanelscleaningimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                </div>
              </div>

              {/* Warmtepomp */}
              <div className="image-columntwo">
                {/* <img
          src={solarpanelscleaningimage}
          alt="Section Image"
          className="section-image"
  
        /> */}
                <div className="grid-container">
                  <div className="grid-row">
                    <img
                      src={solarpanelsimage}
                      alt="diensten"
                      className="section-image"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                  <div className="grid-row">
                    <img
                      src={solarpanelscleaningimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="text-columntwo">
                <h2 id="warmtepomp" className="section-title">
                  Warmtepomp
                </h2>
                <div className="green-divider"></div>
                <p className="section-paragraph">
                  Overweegt u de overstap naar een efficiënter en
                  milieuvriendelijker verwarmingssysteem? Een warmtepomp biedt
                  tal van voordelen die het een uitstekende keuze maken voor
                  zowel nieuwe als bestaande woningen.
                  <br></br> <br></br>
                  Een van de grootste voordelen van een warmtepomp is de
                  energie-efficiëntie. Warmtepompen gebruiken hernieuwbare
                  energiebronnen zoals lucht, water of grond om uw huis te
                  verwarmen, waardoor ze veel minder energie verbruiken dan
                  traditionele verwarmingssystemen. Dit kan resulteren in
                  aanzienlijke besparingen op uw energierekening.
                  <br></br> <br></br>
                  Warmtepompen zijn bovendien veelzijdig en bieden zowel
                  verwarming als koeling. Dit maakt ze een ideale oplossing voor
                  het creëren van een comfortabel binnenklimaat gedurende het
                  hele jaar. Met een warmtepomp hoeft u niet langer
                  afzonderlijke systemen voor verwarming en airconditioning te
                  installeren.
                  <br></br> <br></br>
                  Een ander voordeel is de lange levensduur en lage
                  onderhoudsbehoefte van warmtepompen. Met regelmatig onderhoud
                  kunnen ze tot 20 jaar of langer meegaan, wat ze tot een
                  duurzame investering maakt. Ons deskundige team staat voor u
                  klaar om uw warmtepomp te inspecteren, schoon te maken en te
                  onderhouden, zodat u zorgeloos kunt genieten van alle
                  voordelen die een warmtepomp te bieden heeft.
                </p>

                <button
                  className="buttonstyle33"
                  onClick={navigateToHeatPumpApllicationCustomer}
                >
                  Offerte aanvragen
                </button>
              </div>

              {/* EMS */}
              <div className="text-columnthree">
                <h2 id="energymanagementsystem" className="section-title">
                  Energy Management System{" "}
                </h2>
                <div className="green-divider"></div>
                <p className="section-paragraph">
                  Bent u op zoek naar een betrouwbare partner om de efficiëntie
                  en veiligheid van uw verwarmingssysteem te waarborgen? Bij ons
                  begrijpen we het belang van goed onderhoud voor de optimale
                  werking van uw ketel. Daarom bieden we u de gelegenheid om een
                  offerte aan te vragen voor professioneel ketelonderhoud.
                  <br></br> <br></br>
                  Uw ketel is het hart van uw verwarmingssysteem en zorgt voor
                  warmte en comfort, vooral tijdens de koude maanden. Regelmatig
                  onderhoud is essentieel om ervoor te zorgen dat uw ketel
                  betrouwbaar blijft werken en de hoogst mogelijke
                  energie-efficiëntie behoudt. Ons deskundige team staat voor u
                  klaar om uw ketel grondig te inspecteren, schoon te maken en
                  te onderhouden, zodat u kunt genieten van een warme en
                  zorgeloze leefomgeving.
                </p>
                <button
                  className="buttonstyle33"
                  onClick={navigateToEmsApllicationCustomer}
                >
                  Offerte aanvragen
                </button>
              </div>
              <div className="image-columnthree">
                {/* <img
          src={boilerimage}
          alt="Section Image"
          className="section-image"
  
        /> */}
                <div className="grid-container">
                  <div className="grid-row">
                    <img
                      src={solarpanelsimage}
                      alt="diensten"
                      className="section-image"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                  <div className="grid-row">
                    <img
                      src={solarpanelscleaningimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                </div>
              </div>

              {/* Airco */}
              <div className="image-columntwo">
                {/* <img
          src={solarpanelscleaningimage}
          alt="Section Image"
          className="section-image"
  
        /> */}
                <div className="grid-container">
                  <div className="grid-row">
                    <img
                      src={solarpanelsimage}
                      alt="diensten"
                      className="section-image"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                  <div className="grid-row">
                    <img
                      src={solarpanelscleaningimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                    <img
                      src={boilerimage}
                      alt="diensten"
                      className="section-image hide-on-mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="text-columntwo">
                <h2 id="airco" className="section-title">
                  Airco
                </h2>
                <div className="green-divider"></div>
                <p className="section-paragraph">
                  Bent u op zoek naar een manier om de prestaties van uw
                  zonnepanelen te optimaliseren en uw energieopbrengst te
                  verhogen? Bij ons begrijpen we hoe belangrijk het is om uw
                  investering in duurzame energie te beschermen. Daarom bieden
                  we u de mogelijkheid om een offerte aan te vragen voor het
                  reinigen van uw zonnepanelen.
                  <br></br> <br></br>
                  In de loop van de tijd kunnen zonnepanelen vuil, stof en
                  andere verontreinigingen verzamelen, wat kan leiden tot
                  verminderde efficiëntie en opbrengst. Ons ervaren team van
                  professionals staat voor u klaar om uw zonnepanelen weer te
                  laten schitteren als nieuw. Door regelmatig onderhoud en
                  reiniging te laten uitvoeren, kunt u niet alleen de levensduur
                  van uw zonnepanelen verlengen, maar ook genieten van maximale
                  opbrengst en besparingen.
                </p>
                <button
                  className="buttonstyle33"
                  onClick={navigateToAircoApllicationCustomer}
                >
                  Offerte aanvragen
                </button>
              </div>

              {/* Infrarood */}
              {/* <div className="image-columntwo">
      
        <div className="grid-container">
      <div className="grid-row">
        <img src={solarpanelsimage} alt="diensten" className="section-image" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
      <div className="grid-row">
        <img src={solarpanelscleaningimage} alt="diensten" className="section-image hide-on-mobile" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
    </div>
      </div>
      <div className="text-columntwo">
        <h2 id="infraroodpanelen" className="section-title">Infrarood Panelen</h2>
        <div className="green-divider"></div>
        <p className="section-paragraph">
        Bent u op zoek naar een manier om de prestaties van uw zonnepanelen te optimaliseren en uw energieopbrengst te verhogen? Bij ons begrijpen we hoe belangrijk het is om uw investering in duurzame energie te beschermen. Daarom bieden we u de mogelijkheid om een offerte aan te vragen voor het reinigen van uw zonnepanelen.
        <br></br> <br></br>
        In de loop van de tijd kunnen zonnepanelen vuil, stof en andere verontreinigingen verzamelen, wat kan leiden tot verminderde efficiëntie en opbrengst. Ons ervaren team van professionals staat voor u klaar om uw zonnepanelen weer te laten schitteren als nieuw. Door regelmatig onderhoud en reiniging te laten uitvoeren, kunt u niet alleen de levensduur van uw zonnepanelen verlengen, maar ook genieten van maximale opbrengst en besparingen.
        </p>
        <button className='buttonstyle33' onClick={navigateToInfraredPanelApllicationCustomer}>Offerte aanvragen</button>
      </div> */}

              {/* <div className="text-columnthree">
        <h2 id="energiecontracten" className="section-title">Energie contracten </h2>
        <div className="green-divider"></div>
        <p className="section-paragraph">
        Gebruik onze handige tool om op basis van uw verbruik een gepersonaliseerde offerte te krijgen.
        </p>
        <button className='buttonstyle33' onClick={navigateToEnergyContractsApllicationCustomer}>Offerte aanvragen</button>
      </div>
      <div className="image-columnthree">

        <div className="grid-container">
      <div className="grid-row">
        <img src={solarpanelsimage} alt="diensten" className="section-image" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
      <div className="grid-row">
        <img src={solarpanelscleaningimage} alt="diensten" className="section-image hide-on-mobile" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
    </div>
      </div>


 <div className="image-columntwo">
 
        <div className="grid-container">
      <div className="grid-row">
        <img src={solarpanelsimage} alt="diensten" className="section-image" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
      <div className="grid-row">
        <img src={solarpanelscleaningimage} alt="diensten" className="section-image hide-on-mobile" />
        <img src={boilerimage} alt="diensten" className="section-image hide-on-mobile" />
      </div>
    </div>
      </div>
      <div className="text-columntwo">
        <h2 id="onderhoudscontracten" className="section-title">Onderhouds contracten</h2>
        <div className="green-divider"></div>
        <p className="section-paragraph">
        Klik hier voor onze standaard formules. Indien u een andere vraag heeft Contacteer ons.</p>
        <button className='buttonstyle33' onClick={navigateToMaintentanceContractsApllicationCustomer}>Offerte aanvragen</button>
      </div>

      */}
            </div>
          </div>
          {/* </div>  */}
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Quotes;

import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { switchClasses } from "@mui/material/Switch";

interface SwitchTextTrackProps {
  checked: boolean;
  offText?: string;
  onText?: string;
  onClick: () => void;
}

const StyledSwitch = styled(Switch)<{ onText?: string; offText?: string }>(
  ({ theme, onText, offText }) => ({
    width: 120,
    height: 48,
    padding: 8,
    [`& .${switchClasses.switchBase}`]: {
      padding: 11,
      color: "#ff6a00",
    },
    [`& .${switchClasses.thumb}`]: {
      width: 26,
      height: 26,
      backgroundColor: "#fff",
    },
    [`& .${switchClasses.track}`]: {
      background: "linear-gradient(to right, #ee0979, #ff6a00)",
      opacity: "1 !important",
      borderRadius: 20,
      position: "relative",
      "&:before, &:after": {
        display: "inline-block",
        position: "absolute",
        top: "50%",
        width: "50%",
        transform: "translateY(-50%)",
        color: "#fff",
        textAlign: "center",
        fontSize: "0.75rem",
        fontWeight: 500,
      },
      "&:before": {
        content: `"${onText}"`,
        left: 4,
        opacity: 0,
      },
      "&:after": {
        content: `"${offText}"`,
        right: 4,
      },
    },
    [`& .${switchClasses.checked}`]: {
      [`&.${switchClasses.switchBase}`]: {
        color: "#185a9d",
        transform: "translateX(72px)",
        "&:hover": {
          backgroundColor: "rgba(24,90,257,0.08)",
        },
      },
      [`& .${switchClasses.thumb}`]: {
        backgroundColor: "#fff",
      },
      [`& + .${switchClasses.track}`]: {
        background: "linear-gradient(to right, #43cea2, #185a9d)",
        "&:before": {
          opacity: 1,
        },
        "&:after": {
          opacity: 0,
        },
      },
    },
  }),
);

const SwitchTextTrack: React.FC<SwitchTextTrackProps> = ({
  checked,
  offText = "OFF",
  onText = "ON",
  onClick,
}) => {
  return (
    <StyledSwitch
      onText={onText}
      offText={offText}
      checked={checked}
      onChange={onClick}
    />
  );
};

export default SwitchTextTrack;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AddEnergySupplier.css";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { IoMenu } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MenuItem from "@mui/material/MenuItem";
import PageHero from "../../../../components/Pagehero/PageHero";
import { Typography } from "@mui/material";
import { getCookie } from "../../../../cookieUtils";

const AddEnergySupplier = () => {
  const [name, setName] = useState("");
  const [active, setActive] = useState(0);
  const [evaluation, setEvaluation] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [image, setImage] = useState(null); // State for storing the selected image
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleImageChange = (event: any) => {
    setImage(event.target.files[0]);
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleActiveChange = (value: any) => {
    setActive(value);
  };

  const handleEvaluationChange = (event: any) => {
    setEvaluation(event.target.value);
  };

  const handleAddEnergySupplier = async () => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("active", active.toString());
      formData.append("evaluation", evaluation.toString());
      if (image) {
        formData.append("image", image); // Append the image data to the form data
      }

      await axios.post(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      console.log("Energy supplier created successfully");
      navigate("/EnergySuppliers");
    } catch (error) {
      console.error("Error creating energy supplier:", error);
      // Handle error state or display an error message
    }
  };

  const containerStyles = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Energieleverancier toevoegen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              {/* <hr className="dividerDashed" /> */}
              <div className="userCardas">
                <div className="formContainer">
                  {errorMessage ? (
                    <p className="errorMessage">{errorMessage}</p>
                  ) : null}

                  <div className="form-group">
                    <Typography>Upload een logo</Typography>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Box>
                  </div>
                  <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <ElectricBoltIcon
                        sx={{ color: "action.active", mr: 1, my: 0.5 }}
                      />
                      <TextField
                        label="Naam"
                        variant="standard"
                        type="text"
                        id="nameFilter"
                        value={name}
                        onChange={handleNameChange}
                        fullWidth
                      />
                    </Box>
                  </div>

                  <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Rol"
                        value={active}
                        onChange={(e) =>
                          handleActiveChange(Number(e.target.value))
                        }
                        variant="standard"
                        fullWidth
                      >
                        <MenuItem value="1">Actief</MenuItem>

                        <MenuItem value="0">Niet Actief</MenuItem>
                      </TextField>
                    </Box>
                  </div>

                  <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        id="evaluation-select"
                        select
                        label="Tevredenheidsscore"
                        value={evaluation}
                        onChange={handleEvaluationChange}
                        variant="standard"
                        fullWidth
                      >
                        {[0, 1, 2, 3, 4, 5].map((score) => (
                          <MenuItem key={score} value={score}>
                            {score}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </div>

                  <button
                    className="updateButton"
                    onClick={handleAddEnergySupplier}
                  >
                    Energieleverancier toevoegen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default AddEnergySupplier;

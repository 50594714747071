import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoClose } from "react-icons/io5";
import "./MeterReadingsPopup.css";
import _ from "lodash";

interface MeterReadingElectricity {
  id: string;
  userAddressId: string;
  userId: string;
  date: Date;
  meterReading: number;
}

interface UserConsumptionElectricity {
  userAddressId: string;
  id: string;
  meterReadingId: string;
  userId: string;
  date: Date;
  consumption: number;
}

interface MeterReadingDetailsProps {
  userAddressId: string;
  onClose: () => void;
}

const MeterReadingsPopup: React.FC<MeterReadingDetailsProps> = ({
  userAddressId,
  onClose,
}) => {
  const [meterReadingsDetails, setMeterReadingsDetails] = useState<
    MeterReadingElectricity[]
  >([]);
  const [userConsumptionElectricity, setUserConsumptionElectricity] = useState<
    UserConsumptionElectricity[]
  >([]);

  useEffect(() => {
    fetchMeterReadingsDetails();
  }, [userAddressId]);

  const handleDeleteMeterReading = async (readingId: string) => {
    try {
      const correspondingUserConsumptions = userConsumptionElectricity.filter(
        (consumption) => consumption.meterReadingId === readingId,
      );

      for (const consumption of correspondingUserConsumptions) {
        await axios.delete(
          `${process.env.REACT_APP_DB_URL}/user-consumption-electricity/${consumption.id}`,
        );
      }

      await axios.delete(
        `${process.env.REACT_APP_DB_URL}/meter-readings-electricity/${readingId}`,
      );

      // Update the state by filtering out the deleted item
      setMeterReadingsDetails((prevDetails) =>
        prevDetails.filter((reading) => reading.id !== readingId),
      );

      // If needed, you can also update userConsumptionElectricity state here similarly
      setUserConsumptionElectricity((prevConsumptions) =>
        prevConsumptions.filter(
          (consumption) => consumption.meterReadingId !== readingId,
        ),
      );
    } catch (error) {
      console.error("Error deleting Meter Reading:", error);
      // Handle error state or display an error message
    }
  };

  const handleClose = () => {
    onClose(); // Call the onClose function passed as a prop
    window.location.reload(); // Reload the page
  };

  const fetchMeterReadingsDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/meter-readings-electricity/address/${userAddressId}`,
      );
      setMeterReadingsDetails(response.data);

      const UserConsumptionElectricityResponse = await axios.get(
        `${process.env.REACT_APP_DB_URL}/user-consumption-electricity/address/${userAddressId}`,
      );
      const electricityData = UserConsumptionElectricityResponse.data.map(
        (item: UserConsumptionElectricity) => ({
          id: item.id,
          userAddressId: item.userAddressId,
          meterReadingId: item.meterReadingId,
          userId: item.userId,
          date: new Date(item.date),
          consumption: item.consumption,
        }),
      );
      setUserConsumptionElectricity(electricityData);
    } catch (error) {
      console.error("Error retrieving meter readings details:", error);
      // Handle error state or display an error message
    }
  };

  if (meterReadingsDetails.length === 0) {
    // Display a loading state while fetching the data
    return <div>Loading...</div>;
  }

  // Sort meterReadingsDetails by date before rendering
  const sortedMeterReadings = _.sortBy(meterReadingsDetails, "date").reverse();
  const mostRecentDate =
    sortedMeterReadings.length > 0 ? sortedMeterReadings[0].date : null;
  return (
    <div className="popupContainer">
      {/* Display the meter readings */}
      <div className="popupOverlay" onClick={handleClose}></div>
      <div className="popupContent">
        <TableContainer component={Paper} sx={{ border: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>Elektriciteit meterstanden</h2>
                </TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell align="right">
                  <IoClose size={20} onClick={handleClose} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Datum </TableCell>
                <TableCell align="right">Meterstand</TableCell>
                <TableCell align="right">Verwijderen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedMeterReadings.map((reading) => (
                <TableRow
                  key={reading.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {new Date(reading.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">{reading.meterReading}</TableCell>
                  <TableCell align="right">
                    {mostRecentDate === reading.date && (
                      <IoClose
                        size={20}
                        onClick={() => handleDeleteMeterReading(reading.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MeterReadingsPopup;

import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import { useNavigate } from "react-router";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Footer from "../../../../../components/Footer/Footer";
import PageHero from "../../../../../components/Pagehero/PageHero";
import CenterTitle from "../../../../../components/CenterTitle/CenterTitle";
import mailSuccess from "./success.json";
import Lottie from "lottie-react";
import "./../../../../../theme/styles.css";
import "./DocumentCommit.css";
import { eraseCookie, getCookie } from "../../../../../cookieUtils";

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  password: string;
  roleId: number;
}

interface DocumentSigned {
  id: string;
  userId: string;
  documentId: string;
}

interface statusRequest {
  userId: String;
  changeContractRequestId: string;
  signatureRequestId: string;
}

const DocumentCommit: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<String | null>(null);
  const [verhuis, setVerhuis] = useState<number | null> (null);
  const [newestDocumentSigned, setNewestDocumentSigned] = useState<DocumentSigned | null>(null);
  const [signingError, setSigningError] = useState<string | null>(null);

  const navigate = useNavigate();

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    const storedUser = getCookie("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setUser(user);
      setUserId(user.id);

      if (user.id) {
        axios
          .get(`${process.env.REACT_APP_DB_URL}/documentSigned/user/${user.id}`)
          .then((response) => {
            setNewestDocumentSigned(response.data);
          })
          .catch((error) => {
            console.error(
              "Error getting the newest document signed entry:",
              error,
            );
          });
      }
    }
  }, []);

  console.log(newestDocumentSigned?.id);

  const handleNavigateToIndex = () => {
    // Retrieve and log the contactgegevens from local storage
    const contactgegevens = getCookie("contactgegevens");
    console.log("Contactgegevens:", contactgegevens);

    // Retrieve and log the verandercontract from local storage
    const verandercontract = getCookie("verandercontract");
    console.log("Verandercontract:", verandercontract);

    // Retrieve and log the verandercontract from local storage
    const verandercontractgas = getCookie("verandercontractgas");
    console.log("Verandercontractgas:", verandercontractgas);

    const id = uuidv4();

    // Send data to the API endpoint when the button is pressed and both data are available
    if (contactgegevens) {
      const contactData = JSON.parse(contactgegevens);
      const requestData = {
        id,
        contractIdElectricity: verandercontract || '', // Use the stored verandercontract
        contractIdGas: verandercontractgas || '',
        userId: userId,
        verhuis: verhuis,
        ...contactData, // Spread the contactgegevens data
        status: 1, // Assuming you want to set the status to 1
      };

      // Make the API call to create a new ChangeContractRequest instance
      axios
        .post(
          `${process.env.REACT_APP_DB_URL}/ChangeContractRequests`,
          requestData,
        )
        .then((response) => {
          console.log("API Response:", response.data);

          // Now that the ChangeContractRequest is successful, fetch the signature request status
          if (newestDocumentSigned) {
            const signatureRequestId = newestDocumentSigned.documentId;

            const response = axios
              .post<statusRequest>(
                `${process.env.REACT_APP_DB_URL}/api/digisigner/status`,
                {
                  userId: userId,
                  changeContractRequestId: id,
                  signatureRequestId: signatureRequestId,
                },
              )
              .then((response) => {
                console.log("Signature Request Status:", response.data);
                // You can process the signature request status here as needed

                // Now you can navigate to the desired page after the API calls
                navigate("/IndexCustomer/");
              })
              .catch((error) => {
                console.error(
                  "Error fetching signature request status:",
                  error,
                );
              });
          }
          if (!newestDocumentSigned) {
            setSigningError("Gelieve eerst het document te ondertekenen");
            return;
          }
        })
        .catch((error) => {
          console.error("Error creating change contract request:", error);
        });

      eraseCookie("contactgegevens");
      eraseCookie("verandercontract");
      eraseCookie("verandercontractgas");
    }
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Geslaagd</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <h2 className="section-title">Bedankt voor uw aanvraag!</h2>
                  <div className="green-divider"></div>
                  <p className="section-paragraph">
                    Bedankt voor het indienen van deze aanvraag. Wij hebben alle
                    informatie correct ontvangen en gaan nu aan de slag om uw
                    aanvraag te verwerken.
                  </p>

                  {/* <button className='buttonstyle33' onClick={handleNavigateToIndex}>Ga verder</button> */}

                  <button
                    className="buttonstyle33"
                    onClick={handleNavigateToIndex}
                    disabled={signingError !== null}
                  >
                    Ga verder
                  </button>
                  {signingError && (
                    <p className="error-message">{signingError}</p>
                  )}
                </div>
                <div className="image-column">
                  <Lottie animationData={mailSuccess} loop={false} autoplay />
                </div>
              </div>

              {/* <button onClick={handleNavigateToDocumentCommit}>Aanvraag afronden</button> */}
              {/* Add a button to trigger the signing process */}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default DocumentCommit;

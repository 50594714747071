import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoClose } from "react-icons/io5";
import "./ContractPopup.css";
import { getCookie } from "../../../../../cookieUtils";

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface ContractDetailsProps {
  contractId: string;
  onClose: () => void;
}

const ContractPopup: React.FC<ContractDetailsProps> = ({
  contractId,
  onClose,
}) => {
  const [contractDetails, setContractDetails] =
    useState<ContractElectricityDetails | null>(null);
  const excludedVerbruikValue = getCookie("verbruikValue");

  const verbruikValue =
    excludedVerbruikValue !== null ? parseFloat(excludedVerbruikValue) : null;

  useEffect(() => {
    fetchContractDetails();
  }, [contractId]);

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/ContractElectricityDetails/${contractId}`,
      );
      setContractDetails(response.data);
    } catch (error) {
      console.error("Error retrieving contract details:", error);
      // Handle error state or display an error message
    }
  };

  if (!contractDetails) {
    // Display a loading state while fetching the contract details
    return <div>Loading...</div>;
  }

  return (
    <div className="popupContainer">
      {/* Display the contract details */}
      <div className="popupOverlay" onClick={onClose}></div>
      <div
        className="popupContent"
        style={{ boxShadow: "0 0 10px rgba(255, 255, 255, 0.5)" }}
      >
        <TableContainer component={Paper} sx={{ border: "none" }}>
          {/* <Table sx={{ minWidth: 650 }} aria-label="simple table"> */}
          <Table sx={{ minWidth: 650, boxShadow: 0 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>ContractDetails</h2>
                </TableCell>
                <TableCell align="right">
                  <IoClose size={20} onClick={onClose} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="right">
                  <b>Eenheidsprijs</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows.map((row) => ( */}
              <TableRow
                key="energieprijs"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Energieprijs</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow
                key="Jaarlijks abonnement"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Jaarlijks abonnement
                </TableCell>
                <TableCell align="right">
                  {contractDetails.yearlySubscription} €
                </TableCell>
              </TableRow>

              <TableRow
                key="kWh verbruikskost"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  kWh verbruikskost (enkelvoudige meter)
                </TableCell>
                <TableCell align="right">
                  {contractDetails.kwhConsumptionPrice.toLocaleString("nl-NL", {
                    minimumFractionDigits: 5,
                  })}{" "}
                  €/kWh
                </TableCell>
              </TableRow>

              <TableRow
                key="kWh verbruikskosttwoday"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  kWh verbruikskost dag (tweevoudige meter)
                </TableCell>
                <TableCell align="right">
                  {contractDetails.kwhConsumptionPriceTwoDay.toLocaleString(
                    "nl-NL",
                    { minimumFractionDigits: 5 },
                  )}{" "}
                  €/kWh
                </TableCell>
              </TableRow>

              <TableRow
                key="kWh verbruikskosttwonight"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  kWh verbruikskost nacht (tweevoudige meter)
                </TableCell>
                <TableCell align="right">
                  {contractDetails.kwhConsumptionPriceTwoNight.toLocaleString(
                    "nl-NL",
                    { minimumFractionDigits: 5 },
                  )}{" "}
                  €/kWh
                </TableCell>
              </TableRow>

              <TableRow
                key="kWh verbruikskostnight"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  kWh verbruikskost exclusief nacht
                </TableCell>
                <TableCell align="right">
                  {contractDetails.kwhConsumptionPriceNight.toLocaleString(
                    "nl-NL",
                    { minimumFractionDigits: 5 },
                  )}{" "}
                  €/kWh
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* <button onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};

export default ContractPopup;

{
  /* <TableRow
              key="kWh korting op kWh-prijs*"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                kWh korting op kWh-prijs*
              </TableCell>
              <TableCell align="right"> - {contractDetails.kwhConsumptionPriceDiscount}</TableCell>
              
            </TableRow> */
}

{
  /* <TableRow
              key="Bijdrage hernieuwbare energie + wwk"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Bijdrage "hernieuwbare energie + wwk"
              </TableCell>
              <TableCell align="right">{contractDetails.hewwkContribution}</TableCell>
              
            </TableRow> */
}

{
  /*             

            <TableRow
              key="belastingen"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Belastingen
              </TableCell>
              <TableCell align="right">+ {contractDetails.taxPrice}</TableCell>
              
            </TableRow> */
}

{
  /* <TableRow
              key="Totale kost verbruik"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong>Totale kost verbruik</strong>  
              </TableCell>
              <TableCell align="right">{contractDetails.totalConumptionCostPrice}</TableCell>
           
            </TableRow> */
}

{
  /* ))} */
}

import React, { useEffect, useState, useRef } from "react";
import { IoPersonCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import sitelogo from "../../assets/sitelogo.png";
import ProfilePopup from "./ProfilePopup";
import "./NavBar.css";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { AiOutlineAlignRight } from "react-icons/ai";
import { eraseCookie, getCookie, setCookie } from "../../cookieUtils";

interface NavBarProps {
  toggleMenu: () => void;
}

interface UserAddress {
  id: string;
  name: string;
  eane: string;
  eang: string;
}

const NavBar: React.FC<NavBarProps> = ({ toggleMenu }) => {
  const [isProfilePopupVisible, setProfilePopupVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [userAddresses, setUserAddresses] = useState<UserAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [contractInstances, setContractInstances] = useState<any[]>([]);
  const [contracts, setContracts] = useState<any[]>([]);
  const [contractInstanceElectricity, setContractInstanceElectricity] =
    useState<any>(null);
  const [electricityContract, setElectricityContract] = useState<any>(null);
  const [contractElectricityDetails, setContractElectricityDetails] =
    useState<any>(null);
  const [contractInstanceGas, setContractInstanceGas] = useState<any>(null);
  const [gasContract, setGasContract] = useState<any>(null);
  const [contractGasDetails, setContractGasDetails] = useState<any>(null);
  const profilePopupRef = useRef<HTMLDivElement>(null); // Reference for the profile popup

  const navigate = useNavigate();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    const storedUser = getCookie("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);

      const fetchUserAddresses = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DB_URL}/user-addresses/user/${user.id}`,
          );
          const data = await response.json();
          setUserAddresses(data);

          const storedSelectedAddressId = getCookie("selectedAddress");
          if (storedSelectedAddressId) {
            setSelectedAddress(storedSelectedAddressId);
          } else if (data.length > 0) {
            setSelectedAddress(data[0].id);
            setCookie("selectedAddress", data[0].id, 2);
          }
        } catch (error) {
          console.error("Error fetching user addresses:", error);
        }
      };

      fetchUserAddresses();

      const fetchContractInstances = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DB_URL}/contractinstances/user/${user.id}`,
          );
          const data = await response.json();
          setContractInstances(data);
          console.log(data);
        } catch (error) {
          console.error("Error fetching contractInstances:", error);
        }
      };
      fetchContractInstances();

      const fetchContracts = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DB_URL}/contracts`,
          );
          const data = await response.json();
          setContracts(data);
          console.log(data);
        } catch (error) {
          console.error("Error fetching contracts:", error);
        }
      };
      fetchContracts();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        eraseCookie("electricityDetailsPrice");
        eraseCookie("gasDetailsPrice");
        const response = await fetch(
          `${process.env.REACT_APP_DB_URL}/user-addresses/${selectedAddress}`,
        );
        const tempSelectedAddress = await response.json();

        const matchingElectricityInstances = contractInstances.filter(
          (instance) => instance.eane === tempSelectedAddress.eane,
        );
        const matchingGasInstances = contractInstances.filter(
          (instance) => instance.eang === tempSelectedAddress.eang,
        );

        if (matchingElectricityInstances.length > 0) {
          const matchingContractElectricity = contracts.find(
            (contract) =>
              contract.id === matchingElectricityInstances[0].contractId,
          );
          console.log(
            "matchingContractElectricity " + matchingContractElectricity,
          );
          if (matchingContractElectricity) {
            setContractInstanceElectricity(matchingElectricityInstances[0]);
            setElectricityContract(matchingContractElectricity);

            // Fetch electricity details based on contractDetailsId
            const responseElectricityDetails = await fetch(
              `${process.env.REACT_APP_DB_URL}/contractElectricityDetails/${matchingContractElectricity.contractDetailsId}`,
            );
            const electricityDetails = await responseElectricityDetails.json();
            setContractElectricityDetails(electricityDetails);

            // Store eane field in localStorage
            setCookie(
              "electricityDetailsPrice",
              electricityDetails.kwhConsumptionPrice,
              2,
            );
          }
        }

        if (matchingGasInstances.length > 0) {
          const matchingContractGas = contracts.find(
            (contract) => contract.id === matchingGasInstances[0].contractId,
          );
          console.log("matchingContractGas: " + matchingContractGas);
          if (matchingContractGas) {
            setContractInstanceGas(matchingGasInstances[0]);
            setGasContract(matchingContractGas);

            // Fetch gas details based on contractDetailsId
            const responseGasDetails = await fetch(
              `${process.env.REACT_APP_DB_URL}/contractGasDetails/${matchingContractGas.contractDetailsId}`,
            );
            const gasDetails = await responseGasDetails.json();
            setContractGasDetails(gasDetails);
            setCookie("gasDetailsPrice", gasDetails.kwhConsumptionPrice, 2);
          }
        }
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    };

    fetchData();
  }, [selectedAddress, contractInstances, contracts]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        profilePopupRef.current &&
        !profilePopupRef.current.contains(event.target as Node)
      ) {
        setProfilePopupVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profilePopupRef]);

  const navigationmenutop: React.CSSProperties = {
    backgroundColor: "#ffffff",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 100,
  };
  const selectedaddressdropdown: React.CSSProperties = {
    marginRight: "10px",
  };
  const personIconStyle: React.CSSProperties = {
    marginRight: "10px",
  };

  const handleToggleMenu = () => {
    toggleMenu();
    setProfilePopupVisible(false);
  };

  const handleProfileIconClick = () => {
    setProfilePopupVisible(!isProfilePopupVisible);
  };

  const handleSiteLogoClick = () => {
    if (roleId === 1) {
      navigate("/IndexAdministrator");
    } else if (roleId === 2) {
      navigate("/IndexCustomer");
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    setSelectedAddress(selectedValue);
    setCookie("selectedAddress", selectedValue, 2);
    window.location.reload(); // Reload the page to reflect the changes
  };

  return (
    <div className="navigationmenutop" style={navigationmenutop}>
      <img
        src={sitelogo}
        style={{ height: "50px", cursor: "pointer" }}
        alt="Site Logo"
        onClick={handleSiteLogoClick}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="select-container">
          {userAddresses.length > 0 && (
            <div className="form-group">
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  id="userAddress"
                  select
                  label="Verbruiksadres"
                  value={selectedAddress || ""}
                  onChange={handleSelectChange}
                  variant="standard"
                >
                  {userAddresses.map((address) => (
                    <MenuItem key={address.id} value={address.id}>
                      {address.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </div>
          )}
        </div>
        {roleId === 2 && (
          <div style={{ position: "relative" }}>
            <IoPersonCircleOutline
              size={30}
              style={personIconStyle}
              onClick={handleProfileIconClick}
            />
            {isProfilePopupVisible && (
              <ProfilePopup
                isVisible={isProfilePopupVisible}
                ref={profilePopupRef}
              />
            )}
          </div>
        )}
        <AiOutlineAlignRight
          size={40}
          onClick={handleToggleMenu}
          style={{ strokeWidth: "1px" }}
        />
      </div>
    </div>
  );
};

export default NavBar;

import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import SquareComponent from "../../../components/InfoSquares/InfoSquaresSales/InfoSquares";
import SquareComponentIt from "../../../components/InfoSquares/InfoSquaresIt/InfoSquaresIt";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import { MenuItem, Tooltip } from "@mui/material";
import "./../../../theme/styles.css";
import InfoIcon from "@mui/icons-material/Info";
import { getCookie } from "../../../cookieUtils";

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  password: string;
  roleId: number;
}

interface HeatPumpRequest {
  id: string;
  userId: string;
  connection: number;
  currentHeating: number;
  annualConsumption: string;
  application: number;
  type: number;
  budget: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: string;
  datetimeCreated: Date;
}

const HeatPumpApplicationCustomer: React.FC = () => {
  // params users
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [connection, setConnection] = useState(0);
  const [currentHeating, setCurrentHeating] = useState(0);
  const [annualConsumption, setAnnualConsumption] = useState("");
  const [application, setApplication] = useState(0);
  const [type, setType] = useState(0);
  const [budget, setBudget] = useState(0);
  const [streetname, setStreetname] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");

  const [isStreetnameTouched, setIsStreetnameTouched] = useState(false);
  const [isHousenumberTouched, setIsHousenumberTouched] = useState(false);
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false);
  const [isCityTouched, setIsCityTouched] = useState(false);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);

  // Get stored userdata
  const storedUser = getCookie("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const userId = user.id;

  // Handle data
  const handleConnectionChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newValue = parseInt(event.target.value as string, 10);
    setConnection(newValue);
  };

  const handleCurrentHeatingChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newValue = parseInt(event.target.value as string, 10);
    setCurrentHeating(newValue);
  };

  const handleAnnualConsumptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAnnualConsumption(event.target.value);
  };

  const handleApplicationChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newValue = parseInt(event.target.value as string, 10);
    setApplication(newValue);
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = parseInt(event.target.value as string, 10);
    setType(newValue);
  };

  const handleBudgetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = parseInt(event.target.value as string, 10);
    setType(newValue);
  };

  const handleStreetnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStreetname(event.target.value);
    setIsStreetnameTouched(true);
  };

  const handleHousenumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHousenumber(event.target.value);
    setIsHousenumberTouched(true);
  };

  const handleZipcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value);
    setIsZipcodeTouched(true);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
    setIsCityTouched(true);
  };

  const handleStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setStatus(newValue);
  };

  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const dashboardContainerCouStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);
  // Get all the data of user and predefine it
  // Set initial state values for the input fields

  useEffect(() => {
    if (user && !isStreetnameTouched) {
      setStreetname(user.streetname);
    }
    if (user && !isHousenumberTouched) {
      setHousenumber(user.housenumber);
    }
    if (user && !isZipcodeTouched) {
      setZipcode(user.zipcode);
    }
    if (user && !isCityTouched) {
      setCity(user.city);
    }
  }, [
    user,
    isStreetnameTouched,
    isHousenumberTouched,
    isZipcodeTouched,
    isCityTouched,
  ]);

  // Handle form submission
  const handleFormSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    // Create a payload object with form data
    const payload = {
      userId, // Set the user ID accordingly
      connection,
      currentHeating,
      annualConsumption,
      application,
      type,
      budget,
      streetname,
      housenumber,
      zipcode,
      city,
      comments: comment,
      status: 1, // Set the status accordingly
    };

    try {
      // Make the API call to create the solar panel request
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/HeatPumpRequest`,
        payload,
      );

      navigate("/QuotesSuccessPage/");
    } catch (error) {
      console.error("Error creating solar panel request:", error);
      setErrorMessage(
        "De verplichte velden mogen niet leeg zijn of enkel 0 bevatten.",
      );
    }
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Offerteaanvraag Warmtepomp</PageHero>
          <div className="pageContainerCleaning" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="parentContainer">
              <div
                className="contentContainersolar"
                style={contentContainerStyles}
              >
                {/* <div className="contentContainer" style={contentContainerStyles}> */}

                {/* <div className="contentContainer">
            <div className="formContainer"> */}
                <div className="userCardRequestsolarCleaning">
                  <div className="userCardRequestColumnssolarCleaning">
                    <div className="addressFieldsColumnCleaning">
                      {errorMessage ? (
                        <p className="errorMessage">{errorMessage}</p>
                      ) : null}

                      {/* </div> */}
                      <div className="addressFieldsColumnCleaning">
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="connection"
                              select
                              label="Aansluiting aan het net"
                              value={connection}
                              onChange={(event) => {
                                handleConnectionChange(event);
                              }}
                              variant="standard"
                              fullWidth
                              required
                            >
                              <MenuItem value={parseInt("1")}>
                                Monofase
                              </MenuItem>
                              <MenuItem value={parseInt("2")}>3 fase</MenuItem>
                              <MenuItem value={parseInt("3")}>
                                Onbekend
                              </MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="currentHeating"
                              select
                              label="Huidige verwarmingsbron"
                              value={currentHeating}
                              onChange={(event) => {
                                handleCurrentHeatingChange(event);
                              }}
                              variant="standard"
                              fullWidth
                              required
                            >
                              <MenuItem value={parseInt("1")}>Gas</MenuItem>
                              <MenuItem value={parseInt("2")}>Mazout</MenuItem>
                              <MenuItem value={parseInt("3")}>
                                Elektriciteit
                              </MenuItem>
                              <MenuItem value={parseInt("4")}>Andere</MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            {/* Information icon */}
                            <Tooltip
                              title="Deze info kan u terugvinden op eindafrekening energieleverancier/mijn fluvius portaal/…Bij verhuis: verschil tussen huidige meterstand en meterstand bij intrede omgerekend naar 12 maanden)"
                              placement="top"
                            >
                              <InfoIcon
                                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                              />
                            </Tooltip>
                            <TextField
                              label="Geschat jaarlijks verbruik in Kwh"
                              variant="standard"
                              type="number"
                              id="annualConsumption"
                              value={annualConsumption}
                              onChange={handleAnnualConsumptionChange}
                              required
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="application"
                              select
                              label="Toepassing"
                              value={application}
                              onChange={(event) => {
                                handleApplicationChange(event);
                              }}
                              variant="standard"
                              fullWidth
                              required
                            >
                              <MenuItem value={parseInt("1")}>
                                Warm water
                              </MenuItem>
                              <MenuItem value={parseInt("2")}>
                                Verwarming
                              </MenuItem>
                              <MenuItem value={parseInt("3")}>Alles</MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="type"
                              select
                              label="Gewenst type warmtepomp"
                              value={type}
                              onChange={(event) => {
                                handleTypeChange(event);
                              }}
                              variant="standard"
                              fullWidth
                              required
                            >
                              <MenuItem value={parseInt("1")}>
                                Water/water
                              </MenuItem>
                              <MenuItem value={parseInt("2")}>
                                Lucht/water
                              </MenuItem>
                              <MenuItem value={parseInt("3")}>
                                Lucht/lucht
                              </MenuItem>
                              <MenuItem value={parseInt("4")}>
                                Nog te bepalen
                              </MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="budget"
                              select
                              label="Budget"
                              value={budget}
                              onChange={(event) => {
                                handleBudgetChange(event);
                              }}
                              variant="standard"
                              fullWidth
                              required
                            >
                              <MenuItem value={parseInt("1")}>5000€</MenuItem>
                              <MenuItem value={parseInt("2")}>
                                5000-10.000€
                              </MenuItem>
                              <MenuItem value={parseInt("3")}>
                                10.000-15.000€
                              </MenuItem>
                              <MenuItem value={parseInt("4")}>
                                Hoger dan 15.000€
                              </MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Straatnaam"
                              variant="standard"
                              type="text"
                              id="streetname"
                              value={streetname}
                              onChange={handleStreetnameChange}
                              required
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Huisnummer"
                              variant="standard"
                              type="text"
                              id="housenumber"
                              value={housenumber}
                              onChange={handleHousenumberChange}
                              required
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Postcode"
                              variant="standard"
                              type="text"
                              id="zipcode"
                              value={zipcode}
                              onChange={handleZipcodeChange}
                              required
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Stad/Gemeente"
                              variant="standard"
                              type="text"
                              id="city"
                              value={city}
                              onChange={handleCityChange}
                              required
                              fullWidth
                            />
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        label="Opmerkingen"
                        variant="standard"
                        type="text"
                        rows={4}
                        multiline
                        id="comment"
                        value={comment}
                        onChange={handleComment}
                        fullWidth
                      />
                    </Box>
                  </div>
                  <button className="buttonstyle33" onClick={handleFormSubmit}>
                    Aanvraag indienen
                  </button>
                </div>
              </div>
              <div className="textColumnsolar">
                <div
                  className="dashboardContainer"
                  style={dashboardContainerCouStyles}
                >
                  <div className="squareNavStyles">
                    <div style={{ display: "flex", alignItems: "left" }}>
                      <div>
                        <h3 className="squareNavTextStyles">Meer informatie</h3>
                        <p className="squareNavParagraphsStyles">
                          Bekijk meer informatie over onze oplossingen omtrend
                          warmtepompen
                        </p>
                        <button
                          className="buttonWithArrow"
                          onClick={() => navigate("/Quotes#warmtepomp")}
                        >
                          Klik hier
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/*     
        <SquareComponent />
        <SquareComponentIt /> */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default HeatPumpApplicationCustomer;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoClose } from "react-icons/io5";
import "./ContractPopup.css";
import { getCookie } from "../../../../../cookieUtils";

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

interface ContractDetailsProps {
  contractId: string;
  onClose: () => void;
}

const ContractPopupGas: React.FC<ContractDetailsProps> = ({
  contractId,
  onClose,
}) => {
  const [contractDetails, setContractDetails] =
    useState<ContractGasDetails | null>(null);

  const consumptionPrice1 = contractDetails?.kwhConsumptionPrice ?? 0;
  const kwhConsumptionPriceDiscount1 =
    contractDetails?.kwhConsumptionPriceDiscount ?? 0;
  const priceAfterDiscount = consumptionPrice1 - kwhConsumptionPriceDiscount1;

  const distributionCostPrice1 = contractDetails?.distributionCostPrice ?? 0;
  const transportCostPrice1 = contractDetails?.transportCostPrice ?? 0;
  const subtotal = distributionCostPrice1 + transportCostPrice1;
  const excludedVerbruikValue = getCookie("verbruikValue");

  const verbruikValue =
    excludedVerbruikValue !== null ? parseFloat(excludedVerbruikValue) : null;

  useEffect(() => {
    fetchContractDetails();
  }, [contractId]);

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/ContractGasDetails/${contractId}`,
      );
      setContractDetails(response.data);
    } catch (error) {
      console.error("Error retrieving contract details:", error);
      // Handle error state or display an error message
    }
  };

  if (!contractDetails) {
    // Display a loading state while fetching the contract details
    return <div>Loading...</div>;
  }

  return (
    <div className="popupContainer">
      {/* Display the contract details */}
      <div className="popupOverlay" onClick={onClose}></div>
      <div className="popupContent">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>ContractDetails</h2>
                </TableCell>

                <TableCell align="right">
                  <IoClose size={20} onClick={onClose} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="right">Eenheidsprijs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows.map((row) => ( */}
              <TableRow
                key="energieprijs"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Energieprijs</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow
                key="Jaarlijks abonnement"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Jaarlijks abonnement
                </TableCell>

                <TableCell align="right">
                  {contractDetails.yearlySubscription.toLocaleString("nl-NL", {
                    minimumFractionDigits: 0,
                  })}{" "}
                  €
                </TableCell>
              </TableRow>

              {/* <TableRow
              key="korting domicilliering of e-factuur"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              korting domicilliëring of e-factuur
              </TableCell>
          
              <TableCell align="right">- {contractDetails.invoiceDiscount} €</TableCell>
            </TableRow> */}

              <TableRow
                key="kWh verbruikskost"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  kWh verbruikskost
                </TableCell>
                <TableCell align="right">
                  {contractDetails.kwhConsumptionPrice.toLocaleString("nl-NL", {
                    minimumFractionDigits: 5,
                  })}{" "}
                  €/kWh
                </TableCell>
              </TableRow>

              {/* <TableRow
              key="kWh korting op kWh-prijs*"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                kWh korting op kWh-prijs*
              </TableCell>
              <TableCell align="right">{contractDetails.kwhConsumptionPriceDiscount}</TableCell>
             
            </TableRow>

            <TableRow
              key="Prijs na korting"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Prijs na korting
              </TableCell>
              <TableCell align="right">{priceAfterDiscount}</TableCell>
          
            </TableRow>


            <TableRow
              key="Nettarieven"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong>Nettarieven</strong>  
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>

            <TableRow
              key="Distributiekosten"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                DistributieKosten
              </TableCell>
         
              <TableCell align="right">+ {contractDetails.distributionCostPrice} €</TableCell>
            </TableRow>


            <TableRow
              key="Transportkosten"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Transportkosten
              </TableCell>
          
              <TableCell align="right">+ {contractDetails.transportCostPrice} €</TableCell>
            </TableRow>


            <TableRow
              key="Subtotaal"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Subtotaal
              </TableCell>
            
              <TableCell align="right"> {subtotal} €</TableCell>
            </TableRow>

            <TableRow
              key="Heffingen"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong>Heffingen</strong>  
              </TableCell>
          
              <TableCell align="right"></TableCell>
            </TableRow>

            <TableRow
              key="belastingen"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Belastingen
              </TableCell>
         
              <TableCell align="right">+ {contractDetails.taxPrice} €</TableCell>
            </TableRow> */}
              {/* 
            <TableRow
              key="Totale kost verbruik"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong>Totale kost verbruik</strong>  
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow> */}

              {/* <TableRow
              key="Totale kost verbruik"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong>Totale Jaarlijkse kost </strong>  
              </TableCell>
              <TableCell align="right">{contractDetails.totalYearlyCost}</TableCell>
         
            </TableRow> */}

              {/* ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ContractPopupGas;

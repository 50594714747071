import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import "../Articles.css";
import solarpanelsimage from "./shutdownelectronics.jpg";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import { getCookie } from "../../../../../cookieUtils";

const ShutdownElectronicsArticle: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const subheadingStyles: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "30px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>
            Waarom is het belangrijk om apparaten volledig uit te schakelen en
            niet op stand-by te laten staan?
          </PageHeroColored>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <p className="section-paragraph">
                    <b>
                      {" "}
                      Veel mensen zijn zich er niet van bewust dat elektronische
                      apparaten, zelfs wanneer ze in stand-by modus staan, nog
                      steeds energie verbruiken. Het lijkt misschien handig om
                      apparaten in stand-by te laten staan zodat ze snel weer
                      ingeschakeld kunnen worden, maar dit kan leiden tot
                      onnodig energieverbruik en hogere energiekosten. In dit
                      artikel zullen we bespreken waarom het belangrijk is om
                      apparaten volledig uit te schakelen en welke voordelen dit
                      met zich meebrengt voor zowel je portemonnee als het
                      milieu.
                    </b>
                  </p>
                  <h2 style={subheadingStyles}>Energieverbruik in stand-by</h2>
                  <p className="section-paragraph">
                    Elektronische apparaten zoals televisies, computers,
                    gameconsoles en opladers blijven vaak in stand-by modus
                    staan wanneer ze niet actief worden gebruikt. Hoewel ze in
                    stand-by minder energie verbruiken dan wanneer ze volledig
                    zijn ingeschakeld, is het energieverbruik nog steeds
                    aanzienlijk. Dit wordt vaak aangeduid als "sluipverbruik" of
                    "stand-by verbruik".
                    <br></br>
                    <br></br>
                    Sluipverbruik kan op jaarbasis flink oplopen, vooral als je
                    meerdere elektronische apparaten in huis hebt. Onderzoek
                    heeft aangetoond dat sluipverbruik tot wel 10% van het
                    totale elektriciteitsverbruik van een huishouden kan
                    vertegenwoordigen. Door deze apparaten volledig uit te
                    schakelen wanneer je ze niet gebruikt, kun je dus
                    aanzienlijk besparen op je energierekening.
                  </p>
                </div>

                <div className="image-column">
                  <img
                    src={solarpanelsimage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
              </div>

              <div className="section-container-full">
                {/* Milieueffect */}
                <h2 style={subheadingStyles}>Milieueffect</h2>
                <div className="section-paragraph-full">
                  <p>
                    Naast de kostenbesparing heeft het uitschakelen van
                    apparaten ook een positief effect op het milieu.
                    Sluipverbruik zorgt voor onnodige CO2-uitstoot en draagt bij
                    aan klimaatverandering. Door bewust om te gaan met het
                    energieverbruik van je apparaten en ze volledig uit te
                    schakelen wanneer je ze niet gebruikt, verklein je je
                    ecologische voetafdruk en help je mee aan het verminderen
                    van de milieubelasting.
                  </p>
                </div>

                {/* Veiligheid */}
                <h2 style={subheadingStyles}>Veiligheid</h2>
                <div className="section-paragraph-full">
                  <p>
                    Naast de energie- en milieubesparingen, kan het volledig
                    uitschakelen van apparaten ook bijdragen aan een veiligere
                    omgeving. Elektronische apparaten die in stand-by modus
                    blijven staan, kunnen nog steeds warmte produceren en het
                    risico op brand of oververhitting vergroten. Door ze
                    volledig uit te schakelen, minimaliseer je deze risico's en
                    zorg je voor een veiliger huis.
                  </p>
                </div>

                {/* Conclusie */}
                <h2 style={subheadingStyles}>Conclusie</h2>
                <div className="section-paragraph-full">
                  <p>
                    Het volledig uitschakelen van elektronische apparaten is een
                    eenvoudige maar effectieve manier om energie te besparen, je
                    energierekening te verlagen en een positieve impact te
                    hebben op het milieu. Door bewust om te gaan met het
                    energieverbruik van je apparaten en ze niet in stand-by te
                    laten staan, kun je onnodig sluipverbruik voorkomen en je
                    ecologische voetafdruk verkleinen. Daarnaast draagt het
                    uitschakelen van apparaten bij aan een veiligere omgeving in
                    huis. Door deze kleine aanpassingen in je dagelijkse routine
                    te maken, kun je een verschil maken en bijdragen aan een
                    duurzamere toekomst.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default ShutdownElectronicsArticle;

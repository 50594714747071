import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import axios from "axios";
import ContractDetailsPopupGas from "../ChangeEnergyContractCustomer/popups/ContractDetailsPopupGas";
import { useNavigate } from "react-router";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import CenterTitle from "../../../../components/CenterTitle/CenterTitle";
import { getCookie, setCookie } from "../../../../cookieUtils";
import ScoreStars from "../../../../components/ScoreStars/ScoreStars";

interface Contract {
  id: string;
  supplierId: string;
  documentTemplateId: string;
  contractDetailsId: string;
  name: string;
  description: string;
  category: number;
}

interface EnergySupplier {
  id: string;
  name: string;
  logo: string;
  active: number;
}

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  fixedPriceGas: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

interface NetCostsZipCodeGas {
  zipcode: string;
  name: string;
  dataManagementPrice: number;
  distributionSmallFixedPrice: number;
  distributionSmallVariablePrice: number;
  distributionMediumFixedPrice: number;
  distributionMediumVariablePrice: number;
  distributionLargeFixedPrice: number;
  distributionLargeVariablePrice: number;
  transportCostPrice: number;
  taxAccijns: number;
  contributionEnergy: number;
  connectionFee: number;
}

const GasContractOverviewVastPro: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [netCostsZipCodeGas, setNetCostsZipCodeGas] =
    useState<NetCostsZipCodeGas | null>(null);
  const [nameFilter, setNameFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [selectedContractId, setSelectedContractId] = useState<string | null>(
    null,
  );
  const [roleId, setRoleId] = useState<number | null>(null);
  const excludedSupplierId = getCookie("supplierId");
  const navigate = useNavigate();
  const [contractGasDetails, setContractGasDetails] = useState<
    ContractGasDetails[]
  >([]);
  const excludedVerbruikValue = getCookie("verbruikValue");

  setCookie("contractType", "GP", 2);

  const verbruikValue = excludedVerbruikValue
    ? parseFloat(excludedVerbruikValue)
    : 0;

  const transportCostPrice = netCostsZipCodeGas?.transportCostPrice ?? 0;
  const dataManagementPrice = netCostsZipCodeGas?.dataManagementPrice ?? 0;
  const contributionEnergy = netCostsZipCodeGas?.contributionEnergy ?? 0;
  const taxAccijns = netCostsZipCodeGas?.taxAccijns ?? 0;
  const connectionFee = netCostsZipCodeGas?.connectionFee ?? 0;

  const distributionSmallFixedPrice =
    netCostsZipCodeGas?.distributionSmallFixedPrice ?? 0;
  const distributionSmallVariablePrice =
    netCostsZipCodeGas?.distributionSmallVariablePrice ?? 0;
  const distributionMediumFixedPrice =
    netCostsZipCodeGas?.distributionMediumFixedPrice ?? 0;
  const distributionMediumVariablePrice =
    netCostsZipCodeGas?.distributionMediumVariablePrice ?? 0;
  const distributionLargeFixedPrice =
    netCostsZipCodeGas?.distributionLargeFixedPrice ?? 0;
  const distributionLargeVariablePrice =
    netCostsZipCodeGas?.distributionLargeVariablePrice ?? 0;

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchContracts();
    fetchEnergySuppliers();
    fetchContractDetails();
  }, []);

  const fetchContracts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractsSortedPGVast`,
        {
          params: {
            verbruikValue: excludedVerbruikValue,
            nettoAfnameDag: 0,
            nettoAfnameWeekend: 0,
            nettoAfnameUitNacht: 0,
          },
        },
      );
      const fetchedContracts: Contract[] = response.data;

      // Filter out contracts with excludedSupplierId
      const filteredContracts = fetchedContracts.filter(
        (contract) => contract.supplierId !== excludedSupplierId,
      );

      setContracts(filteredContracts);
    } catch (error) {
      console.error("Error retrieving contracts:", error);
      // Handle error state or display an error message
    }
  };

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      setEnergySuppliers(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
      // Handle error state or display an error message
    }
  };

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractGasDetails`,
      );
      setContractGasDetails(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
      // Handle error state or display an error message
    }
  };

  const NavigateToDocumentSign = (
    contractId: string,
    documentTemplateid: string,
  ) => {
    setCookie("verandercontractgas", contractId, 2);
    navigate(`/DocumentFill/${documentTemplateid}`);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const squareContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: "wrap",
  };

  const squareStyles: React.CSSProperties = {
    width: "200px",
    height: "200px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "100px",
    paddingTop: "100px",
    margin: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const squareStylesPreferred: React.CSSProperties = {
    width: "200px",
    height: "200px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "100px",
    paddingTop: "100px",
    margin: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    border: "5px solid",
    borderImage: "linear-gradient(45deg, #269b66, #FFD700) 1",
    position: "relative",
  };

  const preferredTextStyles: React.CSSProperties = {
    position: "absolute",
    top: "-30px", // Slightly adjust to ensure visibility
    left: "50%",
    transform: "translateX(-50%)", // Center horizontally
    background: "linear-gradient(45deg, #269b66, #FFD700)", // Gradient background for the text
    padding: "10px 20px", // Increase padding to make the background bigger
    borderRadius: "10px", // Rounded corners for the text background
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional box shadow
    zIndex: 1, // Ensure the text is above other elements
    color: "#fff", // Text color for contrast
    fontSize: "18px", // Increase text size
    fontWeight: "bold", // Make the text bold
    textAlign: "center", // Center the text horizontally
  };

  const buttonStyles: React.CSSProperties = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    outline: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgb(211, 230, 243)",
    width: "100%",
    textTransform: "none",
    borderRadius: "4px",
    boxShadow: "none",
    padding: "0.6rem 1.5rem",
    fontSize: "12px",
    fontWeight: 600,
    minWidth: "84px",
    color: "rgb(18, 31, 67)",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  // const filteredContracts = contracts.filter((contract: Contract) => {
  //   const nameMatch = contract.name.toLowerCase().includes(nameFilter.toLowerCase());
  //   const descriptionMatch = contract.description.toLowerCase().includes(descriptionFilter.toLowerCase());

  //   // Filter contracts with category === 2
  //   const categoryMatch = contract.category === 3;

  //   return nameMatch && descriptionMatch && categoryMatch;
  // });

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Alle Gas contracten</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <CenterTitle
                title="Contracten vergelijken"
                subtitle="Hieronder worden alle contracten teruggegeven die interessant zijn in uw situatie. We raden aan alle details te bekijken."
              ></CenterTitle>
              <div className="squareContainer" style={squareContainerStyles}>
                {/* {filteredContracts.map((contract) => {
        const supplier = energySuppliers.find((supplier) => supplier.id === contract.supplierId);
        return (
          <div className="square" style={squareStyles}>
          <img src={supplier?.logo} alt={supplier?.name} style={{ height: '60px', marginRight: '10px' }}   onError={(e) => {
    // Handle image loading errors
    console.error('Error loading logo:', e);
  }} />
          <h2>{contract.name}</h2>
          <p>
           {supplier?.name}
          </p>
          <button style={buttonStyles} onClick={() => NavigateToDocumentSign(contract.id, contract.documentTemplateId)}>Contract Aanvragen</button>
          <p onClick={() => setSelectedContractId(contract.contractDetailsId)}><u>Details bekijken</u> </p>
          
        </div>
        );
      })} */}
                {contracts.map((contract, index) => {
                  const supplier = energySuppliers.find(
                    (supplier) => supplier.id === contract.supplierId,
                  );

                  // Find the ContractElectricityDetails associated with this contract
                  const contractElectricityDetail = contractGasDetails.find(
                    (detail) => detail.contractId === contract.id,
                  );

                  // Convert excludedVerbruikValue to a number if it's not null
                  const verbruikValue =
                    excludedVerbruikValue !== null
                      ? parseFloat(excludedVerbruikValue)
                      : null;

                  // Calculate the total consumption cost price
                  let totalConsumptionCostPrice = 0;
                  if (
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    verbruikValue < 5000
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue +
                      contractElectricityDetail.fixedPriceGas +
                      distributionSmallFixedPrice +
                      verbruikValue * distributionSmallVariablePrice +
                      transportCostPrice * verbruikValue +
                      dataManagementPrice +
                      contributionEnergy * verbruikValue +
                      taxAccijns * verbruikValue +
                      connectionFee * verbruikValue;
                  } else if (
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    verbruikValue > 5000 &&
                    verbruikValue < 150000
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue +
                      contractElectricityDetail.fixedPriceGas +
                      distributionMediumFixedPrice +
                      verbruikValue * distributionMediumVariablePrice +
                      transportCostPrice * verbruikValue +
                      dataManagementPrice +
                      contributionEnergy * verbruikValue +
                      taxAccijns * verbruikValue +
                      connectionFee * verbruikValue;
                  } else if (
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    verbruikValue > 150000
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue +
                      contractElectricityDetail.fixedPriceGas +
                      distributionLargeFixedPrice +
                      verbruikValue * distributionLargeVariablePrice +
                      transportCostPrice * verbruikValue +
                      dataManagementPrice +
                      contributionEnergy * verbruikValue +
                      taxAccijns * verbruikValue +
                      connectionFee * verbruikValue;
                  }

                  return index === 0 ? (
                    <div className="square" style={squareStylesPreferred}>
                      <div style={preferredTextStyles}>Beste keuze!</div>
                      <img
                        src={supplier?.logo}
                        alt={supplier?.name}
                        style={{ height: "60px", marginRight: "10px" }}
                        onError={(e) => {
                          console.error("Error loading logo:", e);
                        }}
                      />
                      <h2>{contract.name}</h2>
                      <p>{contract.description}</p>
                      <p>
                        Jaarlijks: €{totalConsumptionCostPrice.toFixed(2)} incl.
                        BTW
                      </p>
                      {supplier?.id ? (
                        <ScoreStars energySupplierId={supplier.id} /> // Pass supplier ID
                      ) : (
                        <div>Supplier information is not available</div>
                      )}
                      <button
                        style={buttonStyles}
                        onClick={() =>
                          NavigateToDocumentSign(
                            contract.id,
                            contract.documentTemplateId,
                          )
                        }
                      >
                        Contract Aanvragen
                      </button>
                      <p
                        onClick={() =>
                          setSelectedContractId(contract.contractDetailsId)
                        }
                      >
                        <u>Details bekijken</u>
                      </p>
                    </div>
                  ) : (
                    <div className="square" style={squareStyles}>
                      <img
                        src={supplier?.logo}
                        alt={supplier?.name}
                        style={{ height: "60px", marginRight: "10px" }}
                        onError={(e) => {
                          console.error("Error loading logo:", e);
                        }}
                      />
                      <h2>{contract.name}</h2>
                      <p>{contract.description}</p>
                      <p>
                        Jaarlijks: €{totalConsumptionCostPrice.toFixed(2)} incl.
                        BTW
                      </p>
                      {supplier?.id ? (
                        <ScoreStars energySupplierId={supplier.id} /> // Pass supplier ID
                      ) : (
                        <div>Supplier information is not available</div>
                      )}
                      <button
                        style={buttonStyles}
                        onClick={() =>
                          NavigateToDocumentSign(
                            contract.id,
                            contract.documentTemplateId,
                          )
                        }
                      >
                        Contract Aanvragen
                      </button>
                      <p
                        onClick={() =>
                          setSelectedContractId(contract.contractDetailsId)
                        }
                      >
                        <u>Details bekijken</u>
                      </p>
                    </div>
                  );
                })}
              </div>
              {selectedContractId && (
                <ContractDetailsPopupGas
                  contractId={selectedContractId}
                  onClose={() => setSelectedContractId(null)}
                />
              )}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default GasContractOverviewVastPro;

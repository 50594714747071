import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import Checkbox from "@mui/material/Checkbox";
import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import MenuItem from '@mui/material/MenuItem';
import { Select } from '@mui/material';
import Footer from '../../../../../components/Footer/Footer';
import PageHero from '../../../../../components/Pagehero/PageHero';
import './DocumentFill.css'
import SquareComponentIt from '../../../../../components/InfoSquares/InfoSquaresIt/InfoSquaresIt';
import SquareComponent from '../../../../../components/InfoSquares/InfoSquaresSales/InfoSquares';
import './../../../../../theme/styles.css'
import { getCookie, setCookie } from '../../../../../cookieUtils';
import {useFormik} from 'formik';
import * as Yup from 'yup';

interface DocumentTemplate {
  id: string;
  documentPath: string;
  documentName: string;
  status: number;
}

const DocumentFill: React.FC = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentDayString = currentDay.toString();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
  const currentMonthString = currentMonth.toString();
  const currentYear = currentDate.getFullYear();
  const currentYearString = currentYear.toString();

  const contractType = getCookie("contractType");

  const [isMenuVisible, setMenuVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [showDropField, setShowDropField] = useState(false);
  const [documentTemplate, setDocumentTemplate] =
    useState<DocumentTemplate | null>(null);
  const [hasUploadedDocument, setHasUploadedDocument] = useState(false);
  const { documentTemplateId } = useParams<{ documentTemplateId?: string }>(); // Extract the contract ID from the URL
  const [userId, setUserid] = useState("");
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [streetname, setStreetname] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [eane, setEane] = useState("54");
  const [eang, setEang] = useState("54");
  const navigate = useNavigate();
  const [identitynumber, setIdentitynumber] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [birthdatedd, setBirthdatedd] = useState("");
  const [birthdatemm, setBirthdatemm] = useState("");
  const [birthdateyy, setBirthdateyy] = useState("");
  const [dhr, setDhr] = useState("");
  const [mevr, setMevr] = useState("");
  const [gender, setGender] = useState("");
  const [bus, setBus] = useState("");
  const [telphone, setTelphone] = useState("");
  const [installaddress, setInstalladdress] = useState("");
  const [installnumber, setInstallnumber] = useState("");
  const [installbus, setInstallbus] = useState("");
  const [installfloor, setInstallfloor] = useState("");
  const [installzipcode, setInstallzipcode] = useState("");
  const [installcity, setInstallcity] = useState("");
  const [eba, setEba] = useState("");
  const [ebn, setEbn] = useState("");
  const [ebo, setEbo] = useState("");
  const [gba, setGba] = useState("");
  const [gbn, setGbn] = useState("");
  const [gbo, setGbo] = useState("");
  const [leverancier, setLeverancier] = useState("");
  const [netbeheerder, setNetbeheerder] = useState("");
  const [datumoverschakeling, setDatumoverschakeling] = useState("");
  const [datumoverschakelingdd, setDatumoverschakelingdd] = useState("");
  const [datumoverschakelingmm, setDatumoverschakelingmm] = useState("");
  const [datumoverschakelingyy, setDatumoverschakelingyy] = useState("");
  const [verhuis, setVerhuis] = useState(0);
  const [verhuisJa, setVerhuisJa] = useState("");
  const [verhuisNee, setVerhuisNee] = useState("");

  // Luminus
  const [leverancierg, setLeverancierg] = useState("");
  const [netbeheerderg, setNetbeheerderg] = useState("");
  const [datumoverschakelingG, setDatumoverschakelingG] = useState("");
  const [datumoverschakelingddg, setDatumoverschakelingddg] = useState("");
  const [datumoverschakelingmmg, setDatumoverschakelingmmg] = useState("");
  const [datumoverschakelingyyg, setDatumoverschakelingyyg] = useState("");
  const [df, setDf] = useState("");
  const [dom, setDom] = useState("");
  const [vsa, setVsa] = useState("");
  const [vse, setVse] = useState("");
  const [ove, setOve] = useState("");
  const [jed, setJed] = useState("");
  const [jen, setJen] = useState("");
  const [jeen, setJeen] = useState("");
  const [sjep, setSjep] = useState("");
  const [mve, setMve] = useState("");
  const [jg, setJg] = useState("");
  const [sjgp, setSjgp] = useState("");
  const [mvg, setMvg] = useState("");
  const [vs, setVs] = useState("");
  const [vh, setVh] = useState("");
  const [maandelijks, setMaandelijks] = useState("");
  const [tweemaandelijks, setTweemaandelijks] = useState("");
  const [driemaandelijks, setDriemaandelijks] = useState("");
  const [namesign, setNamesign] = useState("");
  const [surnamesign, setSurnamesign] = useState("");
  const [ddtod, setDdtod] = useState(currentDayString);
  const [mmtod, setMmtod] = useState(currentMonthString);
  const [yytod, setYytod] = useState(currentYearString);
  const [citysign, setCitysign] = useState("");
  // luminus pro
  const [bedrijfsnaam, setBedrijfsnaam] = useState("");
  const [vennootschapsvorm, setVennootschapsvorm] = useState("");
  const [ondernemingsnummer, setOndernemingsnummer] = useState("BE");
  // Engie
  const [verzendname, setVerzendname] = useState("");
  const [verzendsurname, setVerzendsurname] = useState("");
  const [klantnummer, setKlantnummer] = useState("");
  const [eann, setEann] = useState("54");
  const [ace, setAce] = useState("");
  const [gce, setGce] = useState("");
  const [neg, setNeg] = useState("");
  const [vb, setVb] = useState("");
  const [ela, setEla] = useState("");
  const [bew, setBew] = useState("");
  const [een, setEen] = useState("");
  const [vbg, setVbg] = useState("");
  const [elag, setElag] = useState("");
  const [bewg, setBewg] = useState("");
  const [eeng, setEeng] = useState("");
  const [voore, setVoore] = useState("");
  const [voorg, setVoorg] = useState("");
  const [pp, setPp] = useState("");
  const [ib, setIb] = useState("");
  const [iban, setIban] = useState("");

  // Engie pro here
  const [RPR, setRPR] = useState("");
  const [nace, setNace] = useState("");
  const [verzendfirmname, setVerzendfirmname] = useState("");
  const [verzendjurform, setVerzendjurform] = useState("");
  const [gpv, setGpv] = useState("");
  const [upv, setUpv] = useState("");

  // Eneco
  const [ver, setVer] = useState('');
  const [move, setMove] = useState('');
  const [ddver, setDdver] = useState('');
  const [mmver, setMmver] = useState('');
  const [yyver, setYyver] = useState('');
  const [maj, setMaj] = useState('');
  const [man, setMan] = useState('');
  const [productname, setProductname] = useState('');
  const [productnameg, setProductnameg] = useState('');
  const [cme, setCme] = useState('');
  const [cmedd, setCmedd] = useState('');
  const [cmemm, setCmemm] = useState('');
  const [cmeyy, setCmeyy] = useState('');
  const [oec, setOec] = useState('');
  const [osm, setOsm] = useState('');
  const [cmeg, setCmeg] = useState('');
  const [cmeddg, setCmeddg] = useState('');
  const [cmemmg, setCmemmg] = useState('');
  const [cmeyyg, setCmeyyg] = useState('');
  const [oecg, setOecg] = useState('');
  const [osmg, setOsmg] = useState('');
  const [domgf, setDomgf] = useState('');
  const [domwf, setDomwf] = useState('');
  const [vmae, setVmae] = useState('');
  const [vmaegf, setVmaegf] = useState('');
  const [vmaewf, setVmaewf] = useState('');
  const [negg, setNegg] = useState('');
  





  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchDocumentTemplate();
    fetchUserData();
  }, []);

  useEffect(() => {
    if (documentTemplateId === "191daa35-8613-5bce-aa7d-z8c86p88n100") {
      window.location.href =
        "https://www.energie.be/nl/klant-worden/contact-info?referer=100000";
    }
  }, [documentTemplateId]);

  useEffect(() => {
    // Calculate the default date (1st of the month after the following month)
    const today = new Date();
    const nextMonth = today.getMonth() + 2; // Following month + 1
    const year = today.getFullYear() + Math.floor(nextMonth / 12);
    const month = nextMonth % 12 || 12; // Correct for month overflow (12 => December)
    const defaultDate = `01/${month.toString().padStart(2, "0")}/${year}`;

    setDatumoverschakeling(defaultDate);
    setDatumoverschakelingG(defaultDate);
  }, []);

  const fetchDocumentTemplate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/documentTemplates/${documentTemplateId}`,
      );
      const fetchedDocumentTemplate: DocumentTemplate = response.data;
      setDocumentTemplate(fetchedDocumentTemplate);
      console.log(fetchedDocumentTemplate);
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const storedUser = getCookie("user");
      if (storedUser) {
        const user = JSON.parse(storedUser);
        setUserid(user.id);
        setSurname(user.surname);
        setName(user.name);
        setEmail(user.email);
        setPhone(user.phone);
        setStreetname(user.streetname);
        setHousenumber(user.housenumber);
        setZipcode(user.zipcode);
        setCity(user.city);
      }
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const downloadDocument = (documentPath: string) => {
    window.open(documentPath, "_blank");
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
    setNamesign(event.target.value);
    setVerzendname(event.target.value);
  };

  const handleSurnameChange = (event: any) => {
    setSurname(event.target.value);
    setSurnamesign(event.target.value);
    setVerzendsurname(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event: any) => {
    setPhone(event.target.value);
  };
  const handleStreetnameChange = (event: any) => {
    setStreetname(event.target.value);
  };

  // const handleRPRChange = (event: any) => {
  //   setRPR(event.target.value);
  // };
  const handleNaceChange = (event: any) => {
    setNace(event.target.value);
  };

  const handleVerzendfirmnameChange = (event: any) => {
    setVerzendfirmname(event.target.value);
  };

  const handleVerzendjurformChange = (event: any) => {
    setVerzendjurform(event.target.value);
  };

  const handleHousenumberChange = (event: any) => {
    setHousenumber(event.target.value);
  };

  const handleZipcodeChange = (event: any) => {
    setZipcode(event.target.value);
  };

  const handleCityChange = (event: any) => {
    setCity(event.target.value);
    setCitysign(event.target.value);
  };

  const handleEaneChange = (event: any) => {
    setEane(event.target.value);
  };

  const handleEangChange = (event: any) => {
    setEang(event.target.value);
  };

  const handleIdentityNumberChange = (event: any) => {
    setIdentitynumber(event.target.value);
  };

  const handleBirthdateDDChange = (event: any) => {
    const bdd = event.target.value.toString();
    setBirthdatedd(bdd);
  };

  const handleBirthdateMMChange = (event: any) => {
    const bdd = event.target.value.toString();
    setBirthdatemm(bdd);
  };

  const handleBirthdateYYChange = (event: any) => {
    const bdd = event.target.value.toString();
    setBirthdateyy(bdd);
  };

  const handleSetVerhuisChange = (selectedVerhuis: any) => {
    if (selectedVerhuis === "Ja") {
      setVerhuisJa("X");
      setVerhuisNee("");
      setVerhuis(1);
    } else if (selectedVerhuis === "Nee") {
      setVerhuisJa("");
      setVerhuisNee("X");
      setVerhuis(0);
    }
  };

  const handleDhrChange = () => {
    setDhr("X");
    setMevr("");
  };

  const handleMevrChange = () => {
    setMevr("X");
    setDhr("");
  };

  const handleBusChange = (event: any) => {
    setBus(event.target.value);
  };

  const handleTelphoneChange = (event: any) => {
    setTelphone(event.target.value);
  };

  const handleInstallAddressChange = (event: any) => {
    setInstalladdress(event.target.value);
  };

  const handleInstallNumberChange = (event: any) => {
    setInstallnumber(event.target.value);
  };

  const handleInstallBusChange = (event: any) => {
    setInstallbus(event.target.value);
  };

  const handleInstallFloorChange = (event: any) => {
    setInstallfloor(event.target.value);
  };

  const handleInstallZipcodeChange = (event: any) => {
    setInstallzipcode(event.target.value);
  };

  const handleInstallCityChange = (event: any) => {
    setInstallcity(event.target.value);
    setCitysign(event.target.value);
  };

  const handleEbaChange = () => {
    setEba("X");
    setEbn("");
    setEbo("");
  };

  const handleEbnChange = () => {
    setEba("");
    setEbn("X");
    setEbo("");
  };

  const handleEboChange = () => {
    setEba("");
    setEbn("");
    setEbo("X");
  };

  const handleEbaEbnEboChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "eba") {
      handleEbaChange();
    } else if (selectedValue === "ebn") {
      handleEbnChange();
    } else if (selectedValue === "ebo") {
      handleEboChange();
    }
  };

  const handleGbaGbnGboChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "gba") {
      handleGbaChange();
    } else if (selectedValue === "gbn") {
      handleGbnChange();
    } else if (selectedValue === "gbo") {
      handleGboChange();
    }
  };

  const handleMaandChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "maandelijks") {
      setMaandelijks("X");
      setTweemaandelijks("");
      setDriemaandelijks("");
    } else if (selectedValue === "tweemaandelijks") {
      setMaandelijks("");
      setTweemaandelijks("X");
      setDriemaandelijks("");
    } else if (selectedValue === "driemaandelijks") {
      setMaandelijks("");
      setTweemaandelijks("");
      setDriemaandelijks("X");
    }
  };

  const handleCorrespondentieChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "df") {
      setDf("X");
      setPp("");
    } else if (selectedValue === "pp") {
      setDf("");
      setPp("X");
    }
  };

  const handleBetalingChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "ove") {
      setOve("X");
      setDom("");
      setIb("");
    } else if (selectedValue === "dom") {
      setOve("");
      setDom("X");
      setIb("");
    } else if (selectedValue === "ib") {
      setOve("");
      setDom("");
      setIb("X");
    }
  };

  const handleGbaChange = () => {
    setGba("X");
    setGbn("");
    setGbo("");
  };

  const handleGbnChange = () => {
    setGba("");
    setGbn("X");
    setGbo("");
  };

  const handleGboChange = () => {
    setGba("");
    setGbn("");
    setGbo("X");
  };

  const handleLeverancierChange = (event: any) => {
    setLeverancier(event.target.value);
  };
  const handleLeverancierGChange = (event: any) => {
    setLeverancierg(event.target.value);
  };

  const handleNetbeheerderChange = (event: any) => {
    setNetbeheerder(event.target.value);
  };
  const handleNetbeheerderGChange = (event: any) => {
    setNetbeheerderg(event.target.value);
  };

  const handleDatumOverschakelingDDChange = (event: any) => {
    const doc = event.target.value.toString();
    setDatumoverschakelingdd(doc);
  };

  const handleDatumOverschakelingMMChange = (event: any) => {
    const doc = event.target.value.toString();
    setDatumoverschakelingmm(doc);
  };

  const handleDatumOverschakelingYYChange = (event: any) => {
    const doc = event.target.value.toString();
    setDatumoverschakelingyy(doc);
  };

  const handleLeveranciergChange = (event: any) => {
    setLeverancierg(event.target.value);
  };

  const handleNetbeheerdergChange = (event: any) => {
    setNetbeheerderg(event.target.value);
  };

  const handleDatumoverschakelingddgChange = (event: any) => {
    const doc = event.target.value.toString();
    setDatumoverschakelingddg(doc);
  };

  const handleDatumoverschakelingmmgChange = (event: any) => {
    const doc = event.target.value.toString();
    setDatumoverschakelingmmg(doc);
  };

  const handleDatumoverschakelingyygChange = (event: any) => {
    const doc = event.target.value.toString();
    setDatumoverschakelingyyg(doc);
  };

  const handleDfChange = (event: any) => {
    setDf(event.target.value);
  };

  const handleDfDomOveChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "df") {
      setDf("X");
      setDom("");
      setOve("");
    } else if (selectedValue === "dom") {
      setDf("");
      setDom("X");
      setOve("");
    } else if (selectedValue === "ove") {
      setDf("");
      setDom("");
      setOve("X");
    }
  };

  const handleDomChange = () => {
    setDom("X");
    setVmae("");
    setOve("");
  };

  const handleVsaChange = (event: any) => {
    setVsa(event.target.value);
  };

  const handleVseChange = (event: any) => {
    setVse(event.target.value);
  };

  const handleVsaVseChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vsa") {
      setVsa("X");
      setVse("");
    } else if (selectedValue === "vse") {
      setVsa("");
      setVse("X");
    }
  };

  const handleOveChange = () => {
    setDom("");
    setVmae("");
    setOve("X");
  };

  const handleJedChange = (event: any) => {
    setJed(event.target.value);
  };

  const handleJenChange = (event: any) => {
    setJen(event.target.value);
  };

  const handleJeenChange = (event: any) => {
    setJeen(event.target.value);
  };

  const handleSjepChange = (event: any) => {
    setSjep(event.target.value);
  };

  const handleMveChange = (event: any) => {
    setMve(event.target.value);
  };

  const handleJgChange = (event: any) => {
    setJg(event.target.value);
  };

  const handleSjgpChange = (event: any) => {
    setSjgp(event.target.value);
  };

  const handleMvgChange = (event: any) => {
    setMvg(event.target.value);
  };

  const handleVsChange = () => {
    setVs("X");
    setVh("");
  };

  const handleVhChange = () => {
    setVh("X");
    setVs("");
  };

  const handleVsVhChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vs") {
      handleVsChange();
    } else if (selectedValue === "vh") {
      handleVhChange();
    }
  };

  const handleMaandelijksChange = (event: any) => {
    setMaandelijks(event.target.value);
  };

  const handleTweemaandelijksChange = (event: any) => {
    setTweemaandelijks(event.target.value);
  };

  const handleDriemaandelijksChange = (event: any) => {
    setDriemaandelijks(event.target.value);
  };

  const handleNamesignChange = (event: any) => {
    setNamesign(event.target.value);
  };

  const handleSurnamesignChange = (event: any) => {
    setSurnamesign(event.target.value);
  };

  // const handleDdtodChange = (event: any) => {
  //   setDdtod(event.target.value);
  // };

  // const handleMmtodChange = (event: any) => {
  //   setMmtod(event.target.value);
  // };

  // const handleYytodChange = (event: any) => {
  //   setYytod(event.target.value);
  // };

  const handleCitysignChange = (event: any) => {
    setCitysign(event.target.value);
  };

  const handleBedrijfsnaamChange = (event: any) => {
    setBedrijfsnaam(event.target.value);
  };

  const handleVennootschapsvormChange = (event: any) => {
    setVennootschapsvorm(event.target.value);
  };

  const handleOndernemingsnummerChange = (event: any) => {
    setOndernemingsnummer(event.target.value);
    setRPR(event.target.value);
  };

  const handleVerzendnameChange = (event: any) => {
    setVerzendname(event.target.value);
  };

  const handleVerzendsurnameChange = (event: any) => {
    setVerzendsurname(event.target.value);
  };

  const handleKlantnummerChange = (event: any) => {
    setKlantnummer(event.target.value);
  };

  const handleEannChange = (event: any) => {
    setEann(event.target.value);
  };

  const handleAceChange = () => {
    setAce("X");
    setGce("");
    setNeg("");
  };

  const handleGceChange = () => {
    setAce("");
    setGce("X");
    setNeg("");
  };

  const handleNegChange = () => {
    setAce("");
    setGce("");
    setNeg("X");
    setCme("");
  };

  const handleAceGceNegChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "ace") {
      handleAceChange();
    } else if (selectedValue === "gce") {
      handleGceChange();
    } else if (selectedValue === "neg") {
      handleNegChange();
    }
  };

  const handleNeggChange = (event: any) => {
    setNegg(event.target.value);
  };

  const handleVbChange = () => {
    setVb("X");
    setEla("");
    setBew("");
    setEen("");
  };

  const handleElaChange = () => {
    setVb("");
    setEla("X");
    setBew("");
    setEen("");
  };

  const handleBewChange = () => {
    setVb("");
    setEla("");
    setBew("X");
    setEen("");
  };

  const handleEenChange = () => {
    setVb("");
    setEla("");
    setBew("");
    setEen("X");
  };

  const handleGpvChange = () => {
    setGpv("X");
    setUpv("");
  };

  const handleUpvChange = () => {
    setGpv("");
    setUpv("X");
  };

  const handleVbElaBewEenChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vb") {
      handleVbChange();
    } else if (selectedValue === "ela") {
      handleElaChange();
    } else if (selectedValue === "bew") {
      handleBewChange();
    } else if (selectedValue === "een") {
      handleEenChange();
    }
  };

  const handleGpvUpvChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "gpv") {
      handleGpvChange();
    } else if (selectedValue === "upv") {
      handleUpvChange();
    }
  };

  const handleVbgElagBewgEengChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vbg") {
      handleVbgChange();
    } else if (selectedValue === "elag") {
      handleElagChange();
    } else if (selectedValue === "bewg") {
      handleBewgChange();
    } else if (selectedValue === "eeng") {
      handleEengChange();
    }
  };

  const handleVbgChange = () => {
    setVbg("X");
    setElag("");
    setBewg("");
    setEeng("");
  };

  const handleElagChange = () => {
    setVbg("");
    setElag("X");
    setBewg("");
    setEeng("");
  };

  const handleBewgChange = () => {
    setVbg("");
    setElag("");
    setBewg("X");
    setEeng("");
  };

  const handleEengChange = () => {
    setVbg("");
    setElag("");
    setBewg("");
    setEeng("X");
  };

  const handleVooreChange = (event: any) => {
    // setVoore(event.target.value);
    setVoore(" ");
  };

  const handleVoorgChange = (event: any) => {
    // setVoorg(event.target.value);
    setVoorg(" ");
  };

  const handleVerChange = (event: any) => {
    const newValue = event.target.checked ? "X" : "";
    setVer(newValue);
  };
{/*
  const handleDdverChange = (event: any) => {
    const ver = event.target.value.toString();
    setDdver(ver);
  };

  const handleMmverChange = (event: any) => {
    const ver = event.target.value.toString();
    setMmver(ver);
  };

  const handleYyverChange = (event: any) => {
    const ver = event.target.value.toString();
    setYyver(ver);
  };
  */}

  const handleMajChange = () => {
    setMaj("X");
    setMan("");
  };

  const handleManChange = () => {
    setMaj("");
    setMan("X");
  };

  const handleMajManChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "maj") {
      handleMajChange();
    } else if (selectedValue === "man") {
      handleManChange();
    }
  };

  const handleProductnameChange = (event: any) => {
    setProductname(event.target.value);
  };

  const handleProductnamegChange = (event: any) => {
    setProductnameg(event.target.value);
  };

  const handleCmeChange = () => {
    setNeg("");
    setCme("X");
  };


  const handleBirthdateChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(
        4,
        8,
      )}`;
    }

    setBirthdate(formattedValue);

    // Update day, month, year based on the formatted value
    const [day, month, year] = formattedValue.split("/");
    setBirthdatedd(day || "");
    setBirthdatemm(month || "");
    setBirthdateyy(year || "");
  };

  const handleDatumoverschakelingChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(
        4,
        8,
      )}`;
    }

    setDatumoverschakeling(formattedValue);

    // Update day, month, year based on the formatted value
    const [day, month, year] = formattedValue.split("/");
    setDatumoverschakelingdd(day || "");
    setDatumoverschakelingmm(month || "");
    setDatumoverschakelingyy(year || "");
  };

  const handleMoveChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
    }

   setMove(formattedValue);

    // Update day, month, year based on the formatted value
    const [day, month, year] = formattedValue.split('/');
    setDdver(day || '');
    setMmver(month || '');
    setYyver(year || '');
  };




  const handleDatumoverschakelingGChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(
        4,
        8,
      )}`;
    }

    setDatumoverschakelingG(formattedValue);

    // Update day, month, year based on the formatted value
    const [day, month, year] = formattedValue.split("/");
    setDatumoverschakelingddg(day || "");
    setDatumoverschakelingmmg(month || "");
    setDatumoverschakelingyyg(year || "");
  };

  const handleNegCmeChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "neg") {
      handleNegChange();
    } else if (selectedValue === "cme") {
      handleCmeChange();
    }
  };

  const handleNeggCmegChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "negg") {
      setNegg("X");
      setCmeg("");
    } else if (selectedValue === "cmeg") {
      setNegg("");
      setCmeg("X");
    }
  };

  const handleCmeddChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmedd(ver);
  };

  const handleCmemmChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmemm(ver);
  };

  const handleCmeyyChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmeyy(ver);
  };

  const handleOecChange = () => {
    setOec("X");
    setOsm("");
  };

  const handleOsmChange = () => {
    setOsm("X");
    setOec("");
  };

  const handleOecOsmChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "oec") {
      handleOecChange();
    } else if (selectedValue === "osm") {
      handleOsmChange();
    }
  };

  const handleOecgOsmgChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "oecg") {
      setOecg("X");
      setOsmg("");
    } else if (selectedValue === "osmg") {
      setOecg("");
      setOsmg("X");
    }
  };

  const handleCmegChange = (event: any) => {
    setCmeg(event.target.value);
  };

  const handleCmeddgChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmeddg(ver);
  };

  const handleCmemmgChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmemmg(ver);
  };

  const handleCmeyygChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmeyyg(ver);
  };

  const handleOecgChange = (event: any) => {
    setOecg(event.target.value);
  };

  const handleOsmgChange = (event: any) => {
    setOsmg(event.target.value);
  };

  const handleDomgfChange = () => {
    setDomgf("X");
    setDomwf("");
    setVmaegf("");
    setVmaewf("");
  };

  const handleDomwfChange = () => {
    setDomgf("");
    setDomwf("X");
    setVmaegf("");
    setVmaewf("");
  };

  const handleDomgfDomwfChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "domgf") {
      handleDomgfChange();
    } else if (selectedValue === "domwf") {
      handleDomwfChange();
    }
  };

  const handleVmaeChange = () => {
    setDom("");
    setVmae("X");
    setOve("");
  };

  const handleDomVmaeOveChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "dom") {
      handleDomChange();
    } else if (selectedValue === "vmae") {
      handleVmaeChange();
    } else if (selectedValue === "ove") {
      handleOveChange();
    }
  };

  const handleVmaegfChange = () => {
    setDomgf("");
    setDomwf("");
    setVmaegf("X");
    setVmaewf("");
  };

  const handleVmaewfChange = () => {
    setVmaegf("");
    setVmaewf("X");
    setDomgf("");
    setDomwf("");
  };

  const handleVmaegfVmaewfChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vmaegf") {
      handleVmaegfChange();
    } else if (selectedValue === "vmaewf") {
      handleVmaewfChange();
    }
  };

  const handlePpChange = (event: any) => {
    setPp(event.target.value);
  };

  const handleIbChange = (event: any) => {
    setIb(event.target.value);
  };

  const handleIbanChange = (event: any) => {
    setIban(event.target.value);
  };

  const handleGenderChange = (selectedGender: string) => {
    if (selectedGender === "dhr") {
      setMevr("");
      setDhr("X");
    } else if (selectedGender === "mevr") {
      setMevr("X");
      setDhr("");
    }
  };

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);

    if (event.target.checked) {
      // Fill in installation address fields based on billing address fields
      setInstalladdress(streetname);
      setInstallnumber(housenumber);
      setInstallbus(bus);
      setInstallzipcode(zipcode);
      setInstallcity(city);
    } else {
      // Reset installation address fields if checkbox is unchecked
      setInstalladdress("");
      setInstallnumber("");
      setInstallbus("");
      setInstallzipcode("");
      setInstallcity("");
    }
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
    alignItems: "flex-start", // To place the dynamic form on top
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleButtonClick = () => {
    setShowDropField(!showDropField);
  };

  useEffect(() => {
    console.log("mevr changed:", mevr);
  }, [mevr]);

  useEffect(() => {
    console.log("dhr changed:", dhr);
  }, [dhr]);

  useEffect(() => {
    console.log("eba changed:", eba);
  }, [eba]);

  useEffect(() => {
    console.log("ebn changed:", ebn);
  }, [ebn]);

  const handleSaveFilledDocument = async () => {
    try {
      if (documentTemplateId) {
        setCookie("documentTemplateId", documentTemplateId, 2);
        console.log(documentTemplateId);
      } else {
        // Handle the case where documentTemplateId is undefined.
        console.error("documentTemplateId is undefined!");
      }
      setCookie(
        "contactgegevens",
        JSON.stringify({
          surname,
          name,
          email,
          phone,
          streetname,
          housenumber,
          zipcode,
          city,
          eane,
          eang,
          verhuis,
        }),
        2,
      );

      if (
        documentTemplate &&
        surname &&
        name &&
        email &&
        phone &&
        streetname &&
        housenumber &&
        zipcode &&
        city
      ) {
        const trimmedEane = eane.substring(2);
        const trimmedEang = eang.substring(2);
        const trimmedEann = eann.substring(2);
        const trimmedOndernemingsnummer = ondernemingsnummer.substring(2);
        const response = await axios.post(
          `${process.env.REACT_APP_DB_URL}/generateAndSavePDF/`,
          {
            userId: userId,
            documentTemplateId: documentTemplateId,
            surname,
            name,
            email,
            phone,
            streetname,
            housenumber,
            zipcode,
            city,
            eane: trimmedEane,
            eang: trimmedEang,
            identitynumber,
            birthdatedd,
            birthdatemm,
            birthdateyy,
            dhr,
            mevr,
            bus,
            telphone,
            installaddress,
            installnumber,
            installbus,
            installfloor,
            installzipcode,
            installcity,
            eba,
            ebn,
            ebo,
            gba,
            gbn,
            gbo,
            leverancier,
            netbeheerder,
            datumoverschakelingdd,
            datumoverschakelingmm,
            datumoverschakelingyy,
            leverancierg,
            netbeheerderg,
            datumoverschakelingddg,
            datumoverschakelingmmg,
            datumoverschakelingyyg,
            df,
            dom,
            vsa,
            vse,
            ove,
            jed,
            jen,
            jeen,
            sjep,
            mve,
            jg,
            sjgp,
            mvg,
            vs,
            vh,
            maandelijks,
            tweemaandelijks,
            driemaandelijks,
            namesign,
            surnamesign,
            ddtod,
            mmtod,
            yytod,
            citysign,
            bedrijfsnaam,
            vennootschapsvorm,
            ondernemingsnummer: trimmedOndernemingsnummer,
            verzendname,
            verzendsurname,
            klantnummer,
            eann: trimmedEann,
            ace,
            gce,
            neg,
            negg,
            vb,
            ela,
            bew,
            een,
            vbg,
            elag,
            bewg,
            eeng,
            voore,
            voorg,
            ver,
            ddver,
            mmver,
            yyver,
            maj,
            man,
            productname,
            productnameg,
            cme,
            cmedd,
            cmemm,
            cmeyy,
            oec,
            osm,
            cmeg,
            cmeddg,
            cmemmg,
            cmeyyg,
            oecg,
            osmg,
            domgf,
            domwf,
            vmae,
            vmaegf,
            vmaewf,
            // verhuisJa,
            //verhuisNee,
            pp,ib,iban, RPR, nace, verzendfirmname, verzendjurform, gpv,upv
          }
        );

        console.log("Filled document saved:", response.data);

        navigate("/DocumentSign");
      } else {
        console.log("Fill in all the required fields before saving.");
      }
    } catch (error) {
      console.error("Error saving filled document:", error);
    }
  };


  const validationSchema = Yup.object({
    bedrijfsnaam: Yup.string().required('Bedrijfsnaam is verplicht'),
    vennootschapsvorm: Yup.string().required('Vennootschapsvorm is verplicht'),
     ondernemingsnummer: Yup.string()
  .matches(/^[0-9]+$/, 'Ondernemingsnummer mag alleen cijfers bevatten')
  .required('Ondernemingsnummer is verplicht'),
    verhuis: Yup.string()
    .oneOf(['Ja', 'Nee'], 'Kies een geldige optie')
    .required('Verhuis keuze is verplicht'),
  gender: Yup.string()
    .oneOf(['dhr', 'mevr'], 'Kies een geldige optie')
    .required('Geslacht is verplicht'),
    surname: Yup.string().required('Voornaam is verplicht'),
    name: Yup.string().required('Naam is verplicht'),
    email: Yup.string().email('Ongeldig emailadres').required('Email is verplicht'),
    phone: Yup.string()
      .matches(/^(\+|0)[0-9]+$/, 'Telefoonnummer moet beginnen met + of 0 en alleen cijfers bevatten')
      .required('Telefoonnummer is verplicht'),
    streetname: Yup.string().required('Straatnaam is verplicht'),
    housenumber: Yup.string()
      .matches(/^[0-9]+$/, 'Huisnummer mag alleen cijfers bevatten')
      .required('Huisnummer is verplicht'),
    bus: Yup.string().optional(),
    zipcode: Yup.string()
      .matches(/^[0-9]+$/, 'Postcode mag alleen cijfers bevatten')
      .required('Postcode is verplicht'),
    city: Yup.string().required('Stad / Gemeente is verplicht'),
    identitynumber: Yup.string().required('Identiteitskaartnummer is verplicht'),
    telphone: Yup.string().required('Vaste Lijn is verplicht'),
     birthdate: Yup.string()
  .matches(/^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/, 'Ongeldige geboortedatum')
  .required('Geboortedatum is verplicht'),
installaddress: Yup.string().required('Straatnaam Afnamepunt is verplicht'),
installnumber: Yup.string()
  .matches(/^[0-9]+$/, 'Huisnummer Afnamepunt mag alleen cijfers bevatten')
  .required('Huisnummer Afnamepunt is verplicht'),
installbus: Yup.string().optional(),
installfloor: Yup.string().optional(),
installzipcode: Yup.string()
  .matches(/^[0-9]+$/, 'Postcode Afnamepunt mag alleen cijfers bevatten')
  .required('Postcode Afnamepunt is verplicht'),
installcity: Yup.string().required('Gemeente Afnamepunt is verplicht'),
aansluiting: Yup.string()
  .oneOf(['eba', 'ebn', 'gba', 'gbn'], 'Kies een geldige aansluiting')
  .required('Aansluiting is verplicht'),
leverancier: Yup.string().required('Huidige leverancier is verplicht'),
meternummer: Yup.string().required('Meternummer is verplicht'),
datumoverschakeling: Yup.string()
  .matches(/^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/, 'Ongeldige datum. Gebruik het formaat dd/mm/yyyy')
  .required('Datum gewenste overschakeling is verplicht'),
verzendwijze: Yup.string()
  .oneOf(['dom', 'df', 'ove'], 'Kies een geldige verzend- en betalingswijze')
  .required('Verzend- en betalingswijze is verplicht'),
voorschotbedrag: Yup.string()
.oneOf(['dom', 'df', 'ove'], 'Kies een geldige verzend- en betalingswijze')
.required('Verzend- en betalingswijze is verplicht'),
    aansluitingG: Yup.string().oneOf(['gba', 'gbn'], 'Kies een geldige optie').required('Aansluiting is verplicht'),
leverancierG: Yup.string().required('Huidige leverancier is verplicht'),
meternummerG: Yup.string().required('Meternummer is verplicht'),
datumoverschakelingG: Yup.string()
.matches(/^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/, 'Datum moet het formaat dd/mm/yyyy hebben')
.required('Datum gewenste overschakeling is verplicht'),
jed: Yup.string()
.transform(value => (value ? parseFloat(value) : ''))
.matches(/^\d+(\.\d+)?$/, 'Jaarverbruik elektriciteit (dag) moet een geldig getal zijn')
.required('Jaarverbruik elektriciteit (dag) is verplicht'),
jen: Yup.string()
.transform(value => (value ? parseFloat(value) : ''))
.matches(/^\d+(\.\d+)?$/, 'Jaarverbruik elektriciteit (nacht) moet een geldig getal zijn')
.required('Jaarverbruik elektriciteit (nacht) is verplicht'),
jeen: Yup.string()
.transform(value => (value ? parseFloat(value) : ''))
.matches(/^\d+(\.\d+)?$/, 'Jaarverbruik elektriciteit (excl. nacht) moet een geldig getal zijn')
.required('Jaarverbruik elektriciteit (excl. nacht) is verplicht'),
jg: Yup.string()
.transform(value => (value ? parseFloat(value) : ''))
.matches(/^\d+(\.\d+)?$/, 'Jaarverbruik gas moet een geldig getal zijn')
.required('Jaarverbruik gas is verplicht'),
mve: Yup.string()
.transform(value => (value ? parseFloat(value) : ''))
.matches(/^\d+(\.\d+)?$/, 'Maandelijks voorschotbedrag elektriciteit moet een geldig getal zijn')
.required('Maandelijks voorschotbedrag elektriciteit is verplicht'),
mvg: Yup.string()
.transform(value => (value ? parseFloat(value) : ''))
.matches(/^\d+(\.\d+)?$/, 'Maandelijks voorschotbedrag gas moet een geldig getal zijn')
.required('Maandelijks voorschotbedrag gas is verplicht'),
sjep: Yup.number()
.typeError('Voer een geldig getal in voor schatting jaarlijkse energieprijs (elektriciteit)')
.positive('Waarde moet positief zijn')
.required('Schatting jaarlijkse energieprijs elektriciteit is verplicht'),
sjgp: Yup.number()
.typeError('Voer een geldig getal in voor schatting jaarlijkse energieprijs (gas)')
.positive('Waarde moet positief zijn')
.required('Schatting jaarlijkse energieprijs gas is verplicht'),

  });

  const formik = useFormik({
    initialValues: {
      bedrijfsnaam: '',
      vennootschapsvorm: '',
      ondernemingsnummer: '',
      verhuis: '',
      gender: '',
      surname: '',
      name: '',
      email: '',
      phone: '',
      streetname: '',
      housenumber: '',
      bus: '',
      zipcode: '',
      city: '',
      identitynumber: '',
      telphone: '',
      birthdate: '',
  installaddress: '',
  installnumber: '',
  installbus: '',
  installfloor: '',
  installzipcode: '',
  installcity: '',
  aansluiting: '',
  leverancier: '',
  meternummer: '',
  datumoverschakeling: '',
  verzendwijze: 'dom',
  voorschotbedrag: 'dom',
  aansluitingG: '',
  leverancierG: '',
  meternummerG: '',
  datumoverschakelingG: '',
  jed: '',
jen: '',
jeen: '',
jg: '',
sjep: '',
sjgp: '',
mve: '',
mvg: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Submit handler
      console.log('Form data:', values);
    },
  });


  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Contractgegevens invullen</PageHero>
          <div className="pageContainerF" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />

            <div className="parentContainer">
              <div
                className="contentContainersolar"
                style={contentContainerStyles}
              >
                <div className="userCardRequestsolarF">
                  {/* <h1 className="pageTitle">Vul onderstaande gegevens in</h1>
          <p>Wanneer deze gegevens correct ingevuld zijn zullen uw contracten gegenereert worden</p>
          
          */}
          
              
  {/* Luminus */}
     { documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d861" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d120" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d121" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d122" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d123" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d124" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d125" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d130" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d131" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d132" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d126" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d127" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d128" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d133" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d134" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d135" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d136" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d137" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d138" ?  (  <><h3>Identificatie</h3>
     <div className="userCardRequestColumnssolarF">
          <div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">
     { contractType === "EP" || contractType === "GP" || contractType === "CP" ? ( <>
      <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="verhuis"
                    select
                    label="Is dit een verhuis?"
                    // value={birthdatedd}
                    onChange={(event) => handleSetVerhuisChange(event.target.value)}
                    variant="standard"
                    fullWidth
                  >
        <MenuItem value={'Ja'}>Ja</MenuItem>
        <MenuItem value={'Nee'}>Nee</MenuItem>

                  </TextField>
                </Box>
              </div>

      <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

             {/*     <TextField
                    label="Bedrijfsnaam"
                    variant="standard"
                    type="text"
                    id="Bedrijfsnaam"
                    value={bedrijfsnaam}
                    onChange={handleBedrijfsnaamChange}
                    required
                    fullWidth

                  /> */}
                   <TextField
            label="Bedrijfsnaam"
            variant="standard"
            id="Bedrijfsnaam"
            {...formik.getFieldProps('bedrijfsnaam')}
            error={formik.touched.bedrijfsnaam && Boolean(formik.errors.bedrijfsnaam)}
            helperText={formik.touched.bedrijfsnaam && formik.errors.bedrijfsnaam}
            fullWidth
          />
                </Box>
                </div>

                 <div className="form-group">
    {/*  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          id="Vennootschapsvorm"
          select
          label="Vennootschapsvorm"
          value={vennootschapsvorm}
          onChange={handleVennootschapsvormChange}
          variant="standard"
          fullWidth
          required
        >
          <MenuItem value='Eenmanszaak'>Eenmanszaak</MenuItem>
          <MenuItem value='Vennootschap met beperkte aansprakelijkheid (BV)'>
            Vennootschap met beperkte aansprakelijkheid (BV)
          </MenuItem>
          <MenuItem value='Naamloze vennootschap (NV)'>
            Naamloze vennootschap (NV)
          </MenuItem>
          <MenuItem value='Coöperatieve vennootschap (CV)'>
            Coöperatieve vennootschap (CV)
          </MenuItem>
          <MenuItem value='Vennootschap onder firma (VOF)'>
            Vennootschap onder firma (VOF)
          </MenuItem>
          <MenuItem value='Commanditaire vennootschap (CommV)'>
            Commanditaire vennootschap (CommV)
          </MenuItem>
          <MenuItem value='Maatschap'>Maatschap</MenuItem>
          <MenuItem value='Europese vennootschap (SE)'>
            Europese vennootschap (SE)
          </MenuItem>
          <MenuItem value='Vereniging zonder winstoogmerk (VZW)'>
            Vereniging zonder winstoogmerk (vzw)
          </MenuItem>
        </TextField>
      </Box> */}

       <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
         id="Vennootschapsvorm"
         select
         label="Vennootschapsvorm"
         {...formik.getFieldProps('vennootschapsvorm')}
         error={formik.touched.vennootschapsvorm && Boolean(formik.errors.vennootschapsvorm)}
         helperText={formik.touched.vennootschapsvorm && formik.errors.vennootschapsvorm}
         variant="standard"
         fullWidth
        >
          <MenuItem value='Eenmanszaak'>Eenmanszaak</MenuItem>
          <MenuItem value='Vennootschap met beperkte aansprakelijkheid (BV)'>
            Vennootschap met beperkte aansprakelijkheid (BV)
          </MenuItem>
          <MenuItem value='Naamloze vennootschap (NV)'>
            Naamloze vennootschap (NV)
          </MenuItem>
          <MenuItem value='Coöperatieve vennootschap (CV)'>
            Coöperatieve vennootschap (CV)
          </MenuItem>
          <MenuItem value='Vennootschap onder firma (VOF)'>
            Vennootschap onder firma (VOF)
          </MenuItem>
          <MenuItem value='Commanditaire vennootschap (CommV)'>
            Commanditaire vennootschap (CommV)
          </MenuItem>
          <MenuItem value='Maatschap'>Maatschap</MenuItem>
          <MenuItem value='Europese vennootschap (SE)'>
            Europese vennootschap (SE)
          </MenuItem>
          <MenuItem value='Vereniging zonder winstoogmerk (VZW)'>
            Vereniging zonder winstoogmerk (vzw)
          </MenuItem>
        </TextField>
      </Box>
    </div>





                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        
              {/*    <TextField
                    label="Ondernemingsnummer"
                    variant="standard"
                    type="text"
                    id="Ondernemingsnummer"
                    value={ondernemingsnummer}
                    onChange={handleOndernemingsnummerChange}
                    required
                    fullWidth
                  /> */}
                   <TextField
      label="Ondernemingsnummer"
      variant="standard"
      type="text"
      id="Ondernemingsnummer"
      {...formik.getFieldProps('ondernemingsnummer')}
      error={formik.touched.ondernemingsnummer && Boolean(formik.errors.ondernemingsnummer)}
      helperText={formik.touched.ondernemingsnummer && formik.errors.ondernemingsnummer}
      fullWidth
    />
                </Box>
                </div>
     </> ): <div></div>}


    
    <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                {/*  <TextField
                    id="gender"
                    select
                    label="Geslacht"
                    // value={birthdatedd}
                    onChange={(event) => handleGenderChange(event.target.value)}
                    variant="standard"
                    fullWidth
                  >


        <MenuItem value={'dhr'}>Dhr</MenuItem>
        <MenuItem value={'mevr'}>Mevr</MenuItem>

                  </TextField> */}
                  <TextField
      id="gender"
      select
      label="Geslacht"
      {...formik.getFieldProps('gender')}
      error={formik.touched.gender && Boolean(formik.errors.gender)}
      helperText={formik.touched.gender && formik.errors.gender}
      variant="standard"
      fullWidth
    >
      <MenuItem value="dhr">Dhr</MenuItem>
      <MenuItem value="mevr">Mevr</MenuItem>
    </TextField>
                </Box>
              </div>


   
          <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        
                <TextField
                    label="Voornaam"
                    variant="standard"
                    type="text"
                    id="surNameFilter"
                    value={surname}
                    onChange={handleSurnameChange}
                    required
                    fullWidth
                  />
                    {/* <TextField
      label="Voornaam"
      variant="standard"
      type="text"
      id="surNameFilter"
      {...formik.getFieldProps('surname')}
      error={formik.touched.surname && Boolean(formik.errors.surname)}
      helperText={formik.touched.surname && formik.errors.surname}
      fullWidth
    /> */}
                </Box>
                </div>

                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             
                  <TextField
                    label="Naam"
                    variant="standard"
                    type="text"
                    id="nameFilter"
                    value={name}
                    onChange={handleNameChange}
                    required
                    fullWidth
                  />
                  {/*  <TextField
      label="Naam"
      variant="standard"
      type="text"
      id="nameFilter"
      {...formik.getFieldProps('name')}
      error={formik.touched.name && Boolean(formik.errors.name)}
      helperText={formik.touched.name && formik.errors.name}
      fullWidth
    /> */}
                </Box>
                </div>
                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                  <TextField
                    label="Email"
                    variant="standard"
                    type="text"
                    id="emailFilter"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    fullWidth
                  />
                {/*     <TextField
      label="Email"
      variant="standard"
      type="text"
      id="emailFilter"
      {...formik.getFieldProps('email')}
      error={formik.touched.email && Boolean(formik.errors.email)}
      helperText={formik.touched.email && formik.errors.email}
      fullWidth
    /> */}
                </Box>
                </div>

{ contractType === "E" || contractType === "C" ? (
    <>
                <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        {/*      <TextField
                label="Identiteitskaartnummer"
                variant="standard"
                type="text"
                id="identitynumber"
                value={identitynumber}
                onChange={handleIdentityNumberChange}
                required
                fullWidth
              /> */}

<TextField
            label="Identiteitskaartnummer"
            variant="standard"
            {...formik.getFieldProps('identitynumber')}
            error={formik.touched.identitynumber && Boolean(formik.errors.identitynumber)}
            helperText={formik.touched.identitynumber && formik.errors.identitynumber}
            fullWidth
          />
            </Box>
            </div>
            </>
                ): <div></div>}
                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                  {/* <TextField
                    label="Telefoonnummer"
                    variant="standard"
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    required
                    fullWidth
                  /> */}
                 {/*   <TextField
            label="Telefoonnummer"
            variant="standard"
            {...formik.getFieldProps('phone')}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            fullWidth
          /> */}
                </Box>
</div>


<div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             {/* <TextField
                label="Vaste Lijn"
                variant="standard"
                type="text"
                id="telphone"
                value={telphone}
                onChange={handleTelphoneChange}
                required
                fullWidth
              /> */}

<TextField
            label="Vaste Lijn"
            variant="standard"
            {...formik.getFieldProps('telphone')}
            error={formik.touched.telphone && Boolean(formik.errors.telphone)}
            helperText={formik.touched.telphone && formik.errors.telphone}
            fullWidth
          />

            </Box>
            </div>
<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                  <TextField
                    label="Straatnaam"
                    variant="standard"
                    type="text"
                    id="streetname"
                    value={streetname}
                    onChange={handleStreetnameChange}
                    required
                    fullWidth
                  />
  {/*
<TextField
            label="Straatnaam"
            variant="standard"
            {...formik.getFieldProps('streetname')}
            error={formik.touched.streetname && Boolean(formik.errors.streetname)}
            helperText={formik.touched.streetname && formik.errors.streetname}
            fullWidth
          />
*/}
                </Box>
                </div>
                </div>
                <div className="columnFill">
                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                  <TextField
                    label="Huisnummer"
                    variant="standard"
                    type="text"
                    id="housenumber"
                    value={housenumber}
                    onChange={handleHousenumberChange}
                    required
                    fullWidth
                  />
  {/*
<TextField
            label="Huisnummer"
            variant="standard"
            {...formik.getFieldProps('housenumber')}
            error={formik.touched.housenumber && Boolean(formik.errors.housenumber)}
            helperText={formik.touched.housenumber && formik.errors.housenumber}
            fullWidth
          /> */}
                </Box>
                </div>

                <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            {/*  <TextField
                label="Bus"
                variant="standard"
                type="text"
                id="bus"
                value={bus}
                onChange={handleBusChange}
                fullWidth
              /> */}

<TextField
            label="Bus"
            variant="standard"
            {...formik.getFieldProps('bus')}
            error={formik.touched.bus && Boolean(formik.errors.bus)}
            helperText={formik.touched.bus && formik.errors.bus}
            fullWidth
          />
            </Box>
            </div>

            
                <div className="form-group"> 
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              
              <TextField
                label="Postcode"
                variant="standard"
                type="text"
                id="zipcode"
                value={zipcode}
                onChange={handleZipcodeChange}
                required
                fullWidth
              />
  {/*
<TextField
            label="Postcode"
            variant="standard"
            {...formik.getFieldProps('zipcode')}
            error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
            helperText={formik.touched.zipcode && formik.errors.zipcode}
            fullWidth
          />
*/}
            </Box>
            </div>
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>

              <TextField
                label="Stad / Gemeente"
                variant="standard"
                type="text"
                id="city"
                value={city}
                onChange={handleCityChange}
                required
                fullWidth
              />
               {/*  <TextField
                label="Stad / Gemeente"
                variant="standard"
                {...formik.getFieldProps('city')}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
                fullWidth
              /> */}
            </Box>
            </div>


{contractType === "E" || contractType === "C" ? (
       <div className="form-group">
       <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        {/* <TextField
           id="Birthdate"
           label="Geboortedatum (dd/mm/yyyy)"
           value={birthdate}
           onChange={handleBirthdateChange}
           variant="standard"
           required
           fullWidth
          placeholder="dd/mm/yyyy"
          //  inputProps={{
          //    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
          //    placeholder: "dd/mm/yyyy",
          //  }}
         /> */}
         <TextField
  id="birthdate"
  label="Geboortedatum (dd/mm/yyyy)"
  value={formik.values.birthdate}
  onChange={formik.handleChange}
  variant="standard"
  required
  fullWidth
  placeholder="dd/mm/yyyy"
  error={formik.touched.birthdate && Boolean(formik.errors.birthdate)}
  helperText={formik.touched.birthdate && formik.errors.birthdate}
/>
       </Box>
     </div>
      ) : (
        <div></div>
      )}
                  
              </div>
              
</div>

</div>
{/* Checkbox adres luminus */}
<div className="form-group">
            <Box sx={{ display: "flex", alignItems: "center" }}>
            
            <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      Het installatieadres is dezelfde als het facturatieadres.
            </Box>
            </div>


            <h3>Informatie Installatieadres</h3>
            <div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Straatnaam Afnamepunt"
                variant="standard"
                type="text"
                id="installaddress"
                value={installaddress}
                onChange={handleInstallAddressChange}
                required
                fullWidth
              />
             {/*   <TextField
  id="installaddress"
  label="Straatnaam Afnamepunt"
  variant="standard"
  type="text"
  value={formik.values.installaddress}
  onChange={formik.handleChange}
  required
  fullWidth
  error={formik.touched.installaddress && Boolean(formik.errors.installaddress)}
  helperText={formik.touched.installaddress && formik.errors.installaddress}
/> */}
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Huisnummer Afnamepunt"
                variant="standard"
                type="text"
                id="installnumber"
                value={installnumber}
                onChange={handleInstallNumberChange}
                required
                fullWidth
              />
  {/*
<TextField
  id="installnumber"
  label="Huisnummer Afnamepunt"
  variant="standard"
  type="text"
  value={formik.values.installnumber}
  onChange={formik.handleChange}
  required
  fullWidth
  error={formik.touched.installnumber && Boolean(formik.errors.installnumber)}
  helperText={formik.touched.installnumber && formik.errors.installnumber}
/> */}
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           {/*   <TextField
                label="Bus Afnamepunt"
                variant="standard"
                type="text"
                id="installbus"
                value={installbus}
                onChange={handleInstallBusChange}
                fullWidth

              /> */}
               <TextField
  id="installbus"
  label="Bus Afnamepunt"
  variant="standard"
  type="text"
  value={formik.values.installbus}
  onChange={formik.handleChange}
  fullWidth
  error={formik.touched.installbus && Boolean(formik.errors.installbus)}
  helperText={formik.touched.installbus && formik.errors.installbus}
/>
            </Box>
            </div>
            </div>

     <div className="columnFill">

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          {/*    <TextField
                label="Verdiep Afnamepunt"
                variant="standard"
                type="text"
                id="installfloor"
                value={installfloor}
                onChange={handleInstallFloorChange}
                fullWidth
              /> */}
              <TextField
  id="installfloor"
  label="Verdiep Afnamepunt"
  variant="standard"
  type="text"
  value={formik.values.installfloor}
  onChange={formik.handleChange}
  fullWidth
  error={formik.touched.installfloor && Boolean(formik.errors.installfloor)}
  helperText={formik.touched.installfloor && formik.errors.installfloor}
/>
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Postcode Afnamepunt"
                variant="standard"
                type="text"
                id="installzipcode"
                value={installzipcode}
                onChange={handleInstallZipcodeChange}
                required
                fullWidth
              />
            {/*    <TextField
  id="installzipcode"
  label="Postcode Afnamepunt"
  variant="standard"
  type="text"
  value={formik.values.installzipcode}
  onChange={formik.handleChange}
  required
  fullWidth
  error={formik.touched.installzipcode && Boolean(formik.errors.installzipcode)}
  helperText={formik.touched.installzipcode && formik.errors.installzipcode}
/> */}
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             <TextField
                label="Gemeente Afnamepunt"
                variant="standard"
                type="text"
                id="installcity"
                value={installcity}
                onChange={handleInstallCityChange}
                required
                fullWidth
              />
              {/*  <TextField
  id="installcity"
  label="Gemeente Afnamepunt"
  variant="standard"
  type="text"
  value={formik.values.installcity}
  onChange={formik.handleChange}
  required
  fullWidth
  error={formik.touched.installcity && Boolean(formik.errors.installcity)}
  helperText={formik.touched.installcity && formik.errors.installcity}
/> */}
            </Box>
            </div>
            </div>
            </div>

                      {contractType === "E" ||
                      contractType === "C" ||
                      contractType === "EP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Elektriciteit</h3>

<div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">


     <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 {/* <TextField
                    id="AansluitingRadio"
                    select
                    label="Aansluiting *"
                    fullWidth
                    onChange={(event) => handleEbaEbnEboChange(event)}
                    variant="standard"

                  >


        <MenuItem value={'eba'}>Bestaande aansluiting</MenuItem>
        <MenuItem value={'ebn'}>Nieuwe aansluiting</MenuItem>


                  </TextField> */}
                  <TextField
  id="AansluitingRadio"
  select
  label="Aansluiting *"
  fullWidth
  value={formik.values.aansluiting}
  onChange={formik.handleChange}
  onBlur={formik.handleBlur}
  error={formik.touched.aansluiting && Boolean(formik.errors.aansluiting)}
  helperText={formik.touched.aansluiting && formik.errors.aansluiting}
  variant="standard"
>
  <MenuItem value={'eba'}>Bestaande aansluiting</MenuItem>
  <MenuItem value={'ebn'}>Nieuwe aansluiting</MenuItem>
</TextField>


                </Box>
              </div>



            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             {/* <TextField
                label="Huidige leverancier"
                variant="standard"
                type="text"
                id="leverancier"
                value={leverancier}
                onChange={handleLeverancierChange}
                fullWidth

              /> */}

<TextField
  label="Huidige leverancier"
  variant="standard"
  type="text"
  id="leverancier"
  fullWidth
  value={formik.values.leverancier}
  onChange={formik.handleChange}
  onBlur={formik.handleBlur}
  error={formik.touched.leverancier && Boolean(formik.errors.leverancier)}
  helperText={formik.touched.leverancier && formik.errors.leverancier}
/>
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             {/* <TextField
                label="Meternummer *"
                variant="standard"
                type="text"
                id="netbeheerder"
                value={netbeheerder}
                onChange={handleNetbeheerderChange}
                fullWidth

              /> */}
              <TextField
  label="Meternummer *"
  variant="standard"
  type="text"
  id="meternummer"
  fullWidth
  value={formik.values.meternummer}
  onChange={formik.handleChange}
  onBlur={formik.handleBlur}
  error={formik.touched.meternummer && Boolean(formik.errors.meternummer)}
  helperText={formik.touched.meternummer && formik.errors.meternummer}
/>
            </Box>
            </div>
            <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Ean elektriciteit *"
      variant="standard"
      type="text"
      id="eane"
      value={eane}
      onChange={handleEaneChange}
      fullWidth
      inputProps={{
        pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
      }}
    />
   
   
  </Box>
</div>

                              {eane.length !== 18 && (
                                <span style={{ color: "red" }}>
                                  Vul alsjeblieft 18 getallen in startende met
                                  54.
                                </span>
                              )}
                            </div>

     <div className="columnFill">


 <div className="form-group">
       <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        {/* <TextField
           id="datumoverschakeling"
           label="Datum gewenste overschakeling (dd/mm/yyyy)"
           value={datumoverschakeling}
           onChange={handleDatumoverschakelingChange}
           variant="standard"
           required
           fullWidth
          placeholder="dd/mm/yyyy"
          //  inputProps={{
          //    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
          //    placeholder: "dd/mm/yyyy",
          //  }}
         /> */}
       </Box>
     </div>
</div>
</div>
</>
): <div></div>}


{ contractType === "G" || contractType === "C" || contractType === "GP" || contractType === "CP" ? ( <>
<h3>Gas</h3>
<div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">


     <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                {/*  <TextField
                    id="AansluitingRadio"
                    select
                    label="Aansluiting *"
                    fullWidth
                    onChange={(event) => handleEbaEbnEboChange(event)}
                    variant="standard"
                  >


        <MenuItem value={'gba'}>Bestaande aansluiting</MenuItem>
        <MenuItem value={'gbn'}>Nieuwe aansluiting</MenuItem>


                  </TextField> */}

<TextField
    id="aansluitingG"
    select
    label="Aansluiting *"
    fullWidth
    name="aansluitingG"
    value={formik.values.aansluitingG}
    onChange={formik.handleChange}
    variant="standard"
    error={formik.touched.aansluitingG && Boolean(formik.errors.aansluitingG)}
    helperText={formik.touched.aansluitingG && formik.errors.aansluitingG}
  >
    <MenuItem value="gba">Bestaande aansluiting</MenuItem>
    <MenuItem value="gbn">Nieuwe aansluiting</MenuItem>
  </TextField>

                </Box>
              </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             {/* <TextField
                label="Huidige leverancier"
                variant="standard"
                type="text"
                id="leverancier"
                value={leverancierg}
                onChange={handleLeverancierGChange}
                fullWidth
              /> */}

<TextField
    label="Huidige leverancier"
    variant="standard"
    type="text"
    id="leverancierG"
    name="leverancierG"
    value={formik.values.leverancierG}
    onChange={formik.handleChange}
    fullWidth
    error={formik.touched.leverancierG && Boolean(formik.errors.leverancierG)}
    helperText={formik.touched.leverancierG && formik.errors.leverancierG}
  />

            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              {/* <TextField
                label="Meternummer *"
                variant="standard"
                type="text"
                id="netbeheerder"
                value={netbeheerderg}
                onChange={handleNetbeheerderGChange}
                fullWidth
              /> */}
               <TextField
    label="Meternummer *"
    variant="standard"
    type="text"
    id="meternummerG"
    name="meternummerG"
    value={formik.values.meternummerG}
    onChange={formik.handleChange}
    fullWidth
    error={formik.touched.meternummerG && Boolean(formik.errors.meternummerG)}
    helperText={formik.touched.meternummerG && formik.errors.meternummerG}
  />
            </Box>
            </div>


            <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
        label="Ean gas *"
        variant="standard"
        type="text"
        id="eang"
        value={eang}
        onChange={handleEangChange}
        fullWidth
      inputProps={{
        pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
      }}
    />

  </Box>
</div>
{eang.length !== 18 && (
      <span style={{ color: "red" }}>
        Vul alsjeblieft 18 getallen in startende met 54.
      </span>
  )}
</div>
     <div className="columnFill">



 <div className="form-group">
       <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        {/* <TextField
           id="datumoverschakeling"
           label="Datum gewenste overschakeling (dd/mm/yyyy)"
           value={datumoverschakelingG}
           onChange={handleDatumoverschakelingGChange}
           variant="standard"
           required
           fullWidth
          placeholder="dd/mm/yyyy"
          //  inputProps={{
          //    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
          //    placeholder: "dd/mm/yyyy",
          //  }}
         /> */}
         <TextField
  id="datumoverschakeling"
  label="Datum gewenste overschakeling (dd/mm/yyyy)"
  value={datumoverschakelingG}
  onChange={handleDatumoverschakelingGChange}
  variant="standard"
  required
  fullWidth
  placeholder="dd/mm/yyyy"
  inputProps={{
    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
    title: "Datum moet het formaat dd/mm/yyyy hebben",
  }}
/>
       </Box>
     </div>
</div>

</div>
</>
): <div></div>}
{/* </>
)} */}
                      <h3>
                        Verzend- en betalingswijze van de facturen voor
                        elektriciteit en/of gas
                      </h3>

<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="VoorschotbedragRadio"
                    select
                    label="Verzend- en betalingswijze"
                    onChange={(event) => handleDfDomOveChange(event)}
                    variant="standard"
                    required
                    fullWidth
                    defaultValue="dom"
                  >

<MenuItem value={'dom'}>Domiciliëring. Tijdens de duur van mijn contract worden mijn facturen via mijn bankrekening verrekend.</MenuItem>
        <MenuItem value={'df'}>Digitale factuur. Ik ontvang al mijn facturen via e-mail.</MenuItem>
        <MenuItem value={'ove'}>Overschrijving</MenuItem>
     
      
                  </TextField>
          {/*       <TextField
      id="VoorschotbedragRadio"
      select
      label="Verzend- en betalingswijze"
      value={formik.values.voorschotbedrag}
      onChange={formik.handleChange}
      variant="standard"
      required
      fullWidth
      name="voorschotbedrag"
    >
      <MenuItem value={'dom'}>Domiciliëring. Tijdens de duur van mijn contract worden mijn facturen via mijn bankrekening verrekend.</MenuItem>
      <MenuItem value={'df'}>Digitale factuur. Ik ontvang al mijn facturen via e-mail.</MenuItem>
      <MenuItem value={'ove'}>Overschrijving</MenuItem>
    </TextField>
    {formik.touched.voorschotbedrag && formik.errors.voorschotbedrag && (
      <div style={{ color: 'red' }}>{formik.errors.voorschotbedrag}</div>
    )} */}
                </Box>
              </div>


    {dom === 'X' && ( // Render the nested radio buttons only when dom is checked
  <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      id="VoorschotfacRadio"
      select
      label="Voor"
     required
      onChange={(event) => handleVsaVseChange(event)}
      variant="standard"
      fullWidth
    >


<MenuItem value={'vsa'}>Voor mijn voorschotfacturen en mijn afrekeningen.</MenuItem>
<MenuItem value={'vse'}>Alleen voor mijn voorschotfacturen en niet voor mijn afrekeningen.</MenuItem>


    </TextField>

  </Box>
</div>
  // <div>
            //   <label>
            //     <input
            //       type="radio"
            //       name="vsa"
            //       value="vsa"
            //       checked={vsa === 'X'}
            //       onChange={() => {
            //         setVsa('X');
            //         setVse('');
            //       }}
            //     />
            //     Voor mijn voorschotfacturen en mijn afrekeningen.
            //   </label>
            //   <label>
            //     <input
            //       type="radio"
            //       name="vse"
            //       value="vse"
            //       checked={vse === 'X'}
            //       onChange={() => {
            //         setVse('X');
            //         setVsa('')
            //       }}
            //     />
            //     Alleen voor mijn voorschotfacturen en niet voor mijn afrekeningen.
            //   </label>
            // </div>
          )}
{/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <div>
          <label>
            <input
              type="radio"
              name="df"
              value="df"
              checked={df === 'X'}
              onChange={() => {
                setDf('X');
                setDom('');
                setOve('');
              }}
            />
            Digitale factuur. Ik ontvang al mijn facturen via e-mail.
          </label>
          <label>
            <input
              type="radio"
              name="dom"
              value="dom"
              checked={dom === 'X'}
              onChange={() => {
                setDf('');
                setDom('X');
                setOve('');
              }}
            />
            Domiciliëring. Tijdens de duur van mijn contract worden mijn facturen via mijn bankrekening verrekend...
          </label>
          <label>
            <input
              type="radio"
              name="ove"
              value="ove"
              checked={ove === 'X'}
              onChange={() => {
                setDf('');
                setDom('');
                setOve('X');
              }}
            />
            Overschrijving
          </label>
        </div>
      </Box>
    </div> */}
 { contractType === "E" || contractType === "G" || contractType === "C" ? ( <>
<h3>Schatting van de jaarlijkse energieprijs en voorschotbedrag</h3>
<h5>Schatting jaarverbruik</h5>
<div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">
<div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              {/* <TextField
                label="kWh dag (Elektriciteit)"
                variant="standard"
                type="text"
                id="jed"
                value={jed}
                onChange={handleJedChange}
                fullWidth
              /> */}
               <TextField
      label="kWh dag (Elektriciteit)"
      variant="standard"
      type="text"
      id="jed"
      name="jed"
      value={formik.values.jed}
      onChange={formik.handleChange}
      fullWidth
    />
    {formik.touched.jed && formik.errors.jed && (
      <div style={{ color: 'red' }}>{formik.errors.jed}</div>
    )}
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           {/*   <TextField
                label="kWh nacht (Elektriciteit)"
                variant="standard"
                type="text"
                id="jen"
                value={jen}
                onChange={handleJenChange}
                fullWidth
              /> */}
               <TextField
      label="kWh nacht (Elektriciteit)"
      variant="standard"
      type="text"
      id="jen"
      name="jen"
      value={formik.values.jen}
      onChange={formik.handleChange}
      fullWidth
    />
    {formik.touched.jen && formik.errors.jen && (
      <div style={{ color: 'red' }}>{formik.errors.jen}</div>
    )}
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           {/*   <TextField
                label="kWh excl. nacht (Elektriciteit)"
                variant="standard"
                type="text"
                id="jeen"
                value={jeen}
                onChange={handleJeenChange}
                fullWidth
              /> */}
                <TextField
      label="kWh excl. nacht (Elektriciteit)"
      variant="standard"
      type="text"
      id="jeen"
      name="jeen"
      value={formik.values.jeen}
      onChange={formik.handleChange}
      fullWidth
    />
    {formik.touched.jeen && formik.errors.jeen && (
      <div style={{ color: 'red' }}>{formik.errors.jeen}</div>
    )}
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
         {/*     <TextField
                label="kWh verbruik Gas"
                variant="standard"
                type="text"
                id="jg"
                value={jg}
                onChange={handleJgChange}
                fullWidth
              /> */}
                <TextField
      label="kWh verbruik Gas"
      variant="standard"
      type="text"
      id="jg"
      name="jg"
      value={formik.values.jg}
      onChange={formik.handleChange}
      fullWidth
    />
    {formik.touched.jg && formik.errors.jg && (
      <div style={{ color: 'red' }}>{formik.errors.jg}</div>
    )}
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             {/* <TextField
                label="Schatting jaarlijkse energieprijs voor elektriciteit (alle taksen inbegrepen)"
                variant="standard"
                type="text"
                id="sjep"
                value={sjep}
                onChange={handleSjepChange}
                fullWidth
              /> */}
               <TextField
      label="Schatting jaarlijkse energieprijs voor elektriciteit (alle taksen inbegrepen)"
      variant="standard"
      type="text"
      id="sjep"
      name="sjep"
      value={formik.values.sjep}
      onChange={formik.handleChange}
      fullWidth
    />
    {formik.touched.sjep && formik.errors.sjep && (
      <div style={{ color: 'red' }}>{formik.errors.sjep}</div>
    )}
            </Box>
            </div>
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           {/*   <TextField
                label="Schatting jaarlijkse energieprijs voor gas (alle taksen inbegrepen)"
                variant="standard"
                type="text"
                id="sjgp"
                value={sjgp}
                onChange={handleSjgpChange}
                fullWidth
              /> */}
              <TextField
      label="Schatting jaarlijkse energieprijs voor gas (alle taksen inbegrepen)"
      variant="standard"
      type="text"
      id="sjgp"
      name="sjgp"
      value={formik.values.sjgp}
      onChange={formik.handleChange}
      fullWidth
    />
    {formik.touched.sjgp && formik.errors.sjgp && (
      <div style={{ color: 'red' }}>{formik.errors.sjgp}</div>
    )}
            </Box>
            </div>
</div>
     <div className="columnFill">
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           {/*   <TextField
                label="Maandelijks voorschotbedrag elektriciteit"
                variant="standard"
                type="text"
                id="mve"
                value={mve}
                onChange={handleMveChange}
                required
                fullWidth
              /> */}
               <TextField
      label="Maandelijks voorschotbedrag elektriciteit"
      variant="standard"
      type="text"
      id="mve"
      name="mve"
      value={formik.values.mve}
      onChange={formik.handleChange}
      required
      fullWidth
    />
    {formik.touched.mve && formik.errors.mve && (
      <div style={{ color: 'red' }}>{formik.errors.mve}</div>
    )}
            </Box>
            </div>
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        {/*      <TextField
                label="Maandelijks voorschotbedrag gas"
                variant="standard"
                type="text"
                id="mvg"
                value={mvg}
                onChange={handleMvgChange}
                required
                fullWidth
              /> */}
                <TextField
      label="Maandelijks voorschotbedrag gas"
      variant="standard"
      type="text"
      id="mvg"
      name="mvg"
      value={formik.values.mvg}
      onChange={formik.handleChange}
      required
      fullWidth
    />
    {formik.touched.mvg && formik.errors.mvg && (
      <div style={{ color: 'red' }}>{formik.errors.mvg}</div>
    )}
            </Box>
            </div>

            { contractType === "E" || contractType === "C" ? (
    <>
<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
               {/*   <TextField
                    id="VoorschotbedragRadio"
                    select
                    label="Mijn voorschotbedrag wordt"
                   required
                    onChange={(event) => handleVsVhChange(event)}
                    variant="standard"
                    fullWidth
                  >


        <MenuItem value={'vs'}>bepaald in samenspraak met Luminus</MenuItem>
        <MenuItem value={'vh'}>gebaseerd op wat ik nu betaal bij mijn huidige leverancier</MenuItem>


                  </TextField> */}
                    <TextField
        id="VoorschotbedragRadio"
        select
        label="Mijn voorschotbedrag wordt"
        value={formik.values.voorschotbedrag}
        onChange={formik.handleChange}
        variant="standard"
        required
        fullWidth
        name="voorschotbedrag"
      >
        <MenuItem value={'vs'}>bepaald in samenspraak met Luminus</MenuItem>
        <MenuItem value={'vh'}>gebaseerd op wat ik nu betaal bij mijn huidige leverancier</MenuItem>
      </TextField>
      {formik.touched.voorschotbedrag && formik.errors.voorschotbedrag && (
        <div style={{ color: 'red' }}>{formik.errors.voorschotbedrag}</div>
      )}
                </Box>
              </div>
              </>
            ) : <div></div>}
            {/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <InputLabel htmlFor="voorschotsbepalingRadio">Mijn voorschotbedrag wordt:</InputLabel>
    <div>
      <label>
        <input
          type="radio"
          name="vs"
          value="vs"
          checked={vs === 'X'}
          onChange={handleVsChange}
        />
        bepaald in samenspraak met Luminus
      </label>
      <label>
        <input
          type="radio"
          name="vh"
          value="vh"
          checked={vh === 'X'}
          onChange={handleVhChange}
        />
        gebaseerd op wat ik nu betaal bij mijn huidige leverancier
      </label>
    </div>
  </Box>
</div> */}

                              <div className="form-group">
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      id="FrequentieRadio"
                                      select
                                      label="Frequentie van voorschotten"
                                      required
                                      onChange={(event) =>
                                        handleMaandChange(event)
                                      }
                                      variant="standard"
                                      fullWidth
                                      value="maandelijks" // Set the value directly
                                      disabled // Make the field uneditable
                                    >
                                      <MenuItem value={"maandelijks"}>
                                        maandelijks
                                      </MenuItem>
                                      {/* <MenuItem value={'tweemaandelijks'}>2 maandelijks</MenuItem>
        <MenuItem value={'driemaandelijks'}>3 maandelijks</MenuItem>
       */}
                                    </TextField>
                                  </Box>
                                </div>
                                {/* <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <InputLabel htmlFor="voorschotfrequentieRadio">Frequentie voorschotten:</InputLabel>
    */}
                                {/* <div>
      <label>
        <input
          type="radio"
          name="maandelijks"
          value="maandelijks"
          checked={maandelijks === 'X'}
          onChange={() => {
          setMaandelijks('X');
            setTweemaandelijks('');
            setDriemaandelijks('');
          }}
        />
        Maandelijks
      </label>
      <label>
        <input
          type="radio"
          name="tweemaandelijks"
          value="tweemaandelijks"
          checked={tweemaandelijks === 'X'}
          onChange={() => {
          setMaandelijks('');
            setTweemaandelijks('X');
            setDriemaandelijks('');
          }}
        />
        2 Maandelijks
      </label>
      <label>
        <input
          type="radio"
          name="driemaandelijks"
          value="driemaandelijks"
          checked={driemaandelijks === 'X'}
          onChange={() => {
          setMaandelijks('');
            setTweemaandelijks('');
            setDriemaandelijks('X');
          }}
        />
        3 Maandelijks
      </label>
    </div>
  </Box> */}
</div> 
</div>
</div>
</>):<div></div>}
{/*// Error messaging hieronder bekijken */}
{ contractType === "EP" || contractType === "GP" || contractType === "CP" ? ( <>

<h3>Voorschotbedrag energie (incl. btw)</h3>
<div className="userCardRequestColumnssolarCleaning">
     <div className="columnFill">
<div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            {/*  <TextField
                label="Jaarverbruik elektriciteit (dag)"
                variant="standard"
                type="text"
                id="jed"
                value={jed}
                onChange={handleJedChange}
                fullWidth
              /> */}
                <TextField
            label="Jaarverbruik elektriciteit (dag)"
            variant="standard"
            type="text"
            id="jed"
            name="jed"
            value={formik.values.jed}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.jed && Boolean(formik.errors.jed)}
            helperText={formik.touched.jed && formik.errors.jed}
          />
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            {/*  <TextField
                label="Jaarverbruik elektriciteit (nacht)"
                variant="standard"
                type="text"
                id="jen"
                value={jen}
                onChange={handleJenChange}
                fullWidth
              /> */}
               <TextField
            label="Jaarverbruik elektriciteit (nacht)"
            variant="standard"
            type="text"
            id="jen"
            name="jen"
            value={formik.values.jen}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.jen && Boolean(formik.errors.jen)}
            helperText={formik.touched.jen && formik.errors.jen}
          />
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            {/*  <TextField
                label="Jaarverbruik elektriciteit (excl. nacht)"
                variant="standard"
                type="text"
                id="jeen"
                value={jeen}
                onChange={handleJeenChange}
                fullWidth
              /> */}
               <TextField
            label="Jaarverbruik elektriciteit (excl. nacht)"
            variant="standard"
            type="text"
            id="jeen"
            name="jeen"
            value={formik.values.jeen}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.jeen && Boolean(formik.errors.jeen)}
            helperText={formik.touched.jeen && formik.errors.jeen}
          />
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            {/*  <TextField
                label="Jaarverbruik gas"
                variant="standard"
                type="text"
                id="jg"
                value={jg}
                onChange={handleJgChange}
                fullWidth
              />
*/}
 <TextField
            label="Jaarverbruik gas"
            variant="standard"
            type="text"
            id="jg"
            name="jg"
            value={formik.values.jg}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.jg && Boolean(formik.errors.jg)}
            helperText={formik.touched.jg && formik.errors.jg}
          />
</Box>
            </div>
</div>
     <div className="columnFill">
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
   {/*           <TextField
                label="Maandelijks voorschotbedrag elektriciteit"
                variant="standard"
                type="text"
                id="mve"
                value={mve}
                onChange={handleMveChange}
                required
                fullWidth
              />
*/}
 <TextField
              label="Maandelijks voorschotbedrag elektriciteit"
              variant="standard"
              type="text"
              id="mve"
              name="mve"
              value={formik.values.mve}
              onChange={formik.handleChange}
              required
              fullWidth
              error={formik.touched.mve && Boolean(formik.errors.mve)}
              helperText={formik.touched.mve && formik.errors.mve}
            />
</Box>
            </div>
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
   {/*           <TextField
                label="Maandelijks voorschotbedrag gas"
                variant="standard"
                type="text"
                id="mvg"
                value={mvg}
                onChange={handleMvgChange}
                required
                fullWidth
              /> */}

<TextField
              label="Maandelijks voorschotbedrag gas"
              variant="standard"
              type="text"
              id="mvg"
              name="mvg"
              value={formik.values.mvg}
              onChange={formik.handleChange}
              required
              fullWidth
              error={formik.touched.mvg && Boolean(formik.errors.mvg)}
              helperText={formik.touched.mvg && formik.errors.mvg}
            />
            </Box>
            </div>



</div>
</div>
</>):<div></div>}

          </>) : (<div></div>)}
{/* Eneco */}
          { documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d881" || documentTemplateId === "880daa35-8613-4bce-aa7d-e7a22f79d881" || documentTemplateId === "881daa35-8613-4bce-aa7d-e7a24f79d881" || documentTemplateId === "890daa35-8613-4bce-aa7d-e7a22f79d881"|| documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d881"|| documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d882"|| documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d883" ||      documentTemplateId === "47312335-8613-4bce-aa7d-e7a22f79d881" || documentTemplateId === "88012335-8613-4bce-aa7d-e7a22f79d881" || documentTemplateId === "88112335-8613-4bce-aa7d-e7a24f79d881" || documentTemplateId === "89012335-8613-4bce-aa7d-e7a22f79d881"|| documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d881"|| documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d882"|| documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d883" ?  (  <><h3>Klantgegevens</h3>
          {/* <div className="userCardRequestColumnssolarF"> */}
          <div className="userCardRequestColumnssolarCleaning">
     <div className="columnFill">

     <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              {/*    <TextField
                    id="verhuis"
                    select
                    label="Is dit een verhuis?"
                    // value={birthdatedd}
                    onChange={(event) => handleSetVerhuisChange(event.target.value)}
                    variant="standard"
                    fullWidth
                  >
        <MenuItem value={'Ja'}>Ja</MenuItem>
        <MenuItem value={'Nee'}>Nee</MenuItem>

                  </TextField> */}
                     <TextField
                    id="verhuis"
                    select
                    label="Is dit een verhuis?"
                    // value={birthdatedd}
                    onChange={(event) => handleSetVerhuisChange(event.target.value)}
                    variant="standard"
                    fullWidth
                  >
        <MenuItem value={'Ja'}>Ja</MenuItem>
        <MenuItem value={'Nee'}>Nee</MenuItem>

                  </TextField>
                </Box>
              </div>

    <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
               {/*   <TextField
                    id="gender"
                    select
                    label="Geslacht"
                    // value={birthdatedd}
                    onChange={(event) => handleGenderChange(event.target.value)}
                    variant="standard"
                    required
                    fullWidth
                  >


        <MenuItem value={'dhr'}>Dhr</MenuItem>
        <MenuItem value={'mevr'}>Mevr</MenuItem>

                  </TextField> */}
                  <TextField
      id="gender"
      select
      label="Geslacht"
      {...formik.getFieldProps('gender')}
      error={formik.touched.gender && Boolean(formik.errors.gender)}
      helperText={formik.touched.gender && formik.errors.gender}
      variant="standard"
      fullWidth
    >
      <MenuItem value="dhr">Dhr</MenuItem>
      <MenuItem value="mevr">Mevr</MenuItem>
    </TextField>
                </Box>
              </div>


    <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                      <TextField
                        label="Naam"
                        variant="standard"
                        type="text"
                        id="nameFilter"
                        value={name}
                        onChange={handleNameChange}
                        required
                        fullWidth
                      />
                    </Box>
                    </div>

              <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                      <TextField
                        label="Voornaam"
                        variant="standard"
                        type="text"
                        id="surNameFilter"
                        value={surname}
                        onChange={handleSurnameChange}
                        required
                        fullWidth
                      />
                    </Box>
                    </div>
                    <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                      <TextField
                        label="Telefoonnummer"
                        variant="standard"
                        type="text"
                        id="phone"
                        value={phone}
                        onChange={handlePhoneChange}
                        required
                        fullWidth
                      />
                    </Box>
    </div>



                    <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                      <TextField
                        label="Email"
                        variant="standard"
                        type="text"
                        id="emailFilter"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        fullWidth
                      />
                    </Box>
                    </div>
                    </div>
<div className="columnFill">

{ contractType === "E" || contractType === "G" || contractType === "C" ? ( <>
                    <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                   {/*   <TextField
                        label="Rijksregisternummer"
                        variant="standard"
                        type="text"
                        id="identitynumber"
                        value={identitynumber}
                        onChange={handleIdentityNumberChange}
                        required
                        fullWidth
                      /> */}
                      <TextField
            label="Rijksregisternummer"
            variant="standard"
            {...formik.getFieldProps('identitynumber')}
            error={formik.touched.identitynumber && Boolean(formik.errors.identitynumber)}
            helperText={formik.touched.identitynumber && formik.errors.identitynumber}
            fullWidth
          />

                    </Box>
                    </div>
                    
                {/* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="Birthdatedd"
                    select
                    label="Geboorte dag"
                    value={birthdatedd}
                    onChange={handleBirthdateDDChange}
                    variant="standard"
                    required
                    fullWidth
                  >

{Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
        <MenuItem key={day} value={day}>{day}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div> 
         
              <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="Birthdatemm"
                    select
                    label="Geboorte maand"
                    value={birthdatemm}
                    onChange={handleBirthdateMMChange}
                    variant="standard"
                    required
                    fullWidth
                  >

{Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
        <MenuItem key={month} value={month}>{month}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div> 
        
              <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="Birthdateyy"
                    select
                    label="Geboorte jaar"
                    value={birthdateyy}
                    onChange={handleBirthdateYYChange}
                    variant="standard"
                    required
                    fullWidth
                  >

{Array.from({ length: new Date().getFullYear() - 1900 }, (_, index) => 1900 + index).map(year => (
        <MenuItem key={year} value={year}>{year}</MenuItem>
      ))}
                  </TextField>
                </Box>
                </div> */}


<div className="form-group">
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          {/*  <TextField
              id="Birthdate"
              label="Geboortedatum (dd/mm/yyyy)"
              value={birthdate}
              onChange={handleBirthdateChange}
              variant="standard"
              required
              fullWidth
              placeholder='dd/mm/yyyy'
              // inputProps={{
              //   pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
              //   placeholder: "dd/mm/yyyy",
              // }}
            /> */}
             <TextField
  id="birthdate"
  label="Geboortedatum (dd/mm/yyyy)"
  value={formik.values.birthdate}
  onChange={formik.handleChange}
  variant="standard"
  required
  fullWidth
  placeholder="dd/mm/yyyy"
  error={formik.touched.birthdate && Boolean(formik.errors.birthdate)}
  helperText={formik.touched.birthdate && formik.errors.birthdate}
/>
          </Box>
        </div>

                    </>):<div></div>}
                    { contractType === "EP" || contractType === "GP" || contractType === "CP" ? ( <>
                      <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                {/*  <TextField
                    label="Bedrijfsnaam"
                    variant="standard"
                    type="text"
                    id="Bedrijfsnaam"
                    value={bedrijfsnaam}
                    onChange={handleBedrijfsnaamChange}
                    required
                    fullWidth
                  />*/}
                     <TextField
            label="Bedrijfsnaam"
            variant="standard"
            id="Bedrijfsnaam"
            {...formik.getFieldProps('bedrijfsnaam')}
            error={formik.touched.bedrijfsnaam && Boolean(formik.errors.bedrijfsnaam)}
            helperText={formik.touched.bedrijfsnaam && formik.errors.bedrijfsnaam}
            fullWidth
          />
                </Box>
                </div>

                    <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      {/*  <TextField
          id="Vennootschapsvorm"
          select
          label="Vennootschapsvorm"
          value={vennootschapsvorm}
          onChange={handleVennootschapsvormChange}
          variant="standard"
          fullWidth
          required
        >
          <MenuItem value='Eenmanszaak'>Eenmanszaak</MenuItem>
          <MenuItem value='Vennootschap met beperkte aansprakelijkheid (BV)'>
            Vennootschap met beperkte aansprakelijkheid (BV)
          </MenuItem>
          <MenuItem value='Naamloze vennootschap (NV)'>
            Naamloze vennootschap (NV)
          </MenuItem>
          <MenuItem value='Coöperatieve vennootschap (CV)'>
            Coöperatieve vennootschap (CV)
          </MenuItem>
          <MenuItem value='Vennootschap onder firma (VOF)'>
            Vennootschap onder firma (VOF)
          </MenuItem>
          <MenuItem value='Commanditaire vennootschap (CommV)'>
            Commanditaire vennootschap (CommV)
          </MenuItem>
          <MenuItem value='Maatschap'>Maatschap</MenuItem>
          <MenuItem value='Europese vennootschap (SE)'>
            Europese vennootschap (SE)
          </MenuItem>
          <MenuItem value='Vereniging zonder winstoogmerk (VZW)'>
            Vereniging zonder winstoogmerk (vzw)
          </MenuItem>
        </TextField> */}
         <TextField
         id="Vennootschapsvorm"
         select
         label="Vennootschapsvorm"
         {...formik.getFieldProps('vennootschapsvorm')}
         error={formik.touched.vennootschapsvorm && Boolean(formik.errors.vennootschapsvorm)}
         helperText={formik.touched.vennootschapsvorm && formik.errors.vennootschapsvorm}
         variant="standard"
         fullWidth
        >
          <MenuItem value='Eenmanszaak'>Eenmanszaak</MenuItem>
          <MenuItem value='Vennootschap met beperkte aansprakelijkheid (BV)'>
            Vennootschap met beperkte aansprakelijkheid (BV)
          </MenuItem>
          <MenuItem value='Naamloze vennootschap (NV)'>
            Naamloze vennootschap (NV)
          </MenuItem>
          <MenuItem value='Coöperatieve vennootschap (CV)'>
            Coöperatieve vennootschap (CV)
          </MenuItem>
          <MenuItem value='Vennootschap onder firma (VOF)'>
            Vennootschap onder firma (VOF)
          </MenuItem>
          <MenuItem value='Commanditaire vennootschap (CommV)'>
            Commanditaire vennootschap (CommV)
          </MenuItem>
          <MenuItem value='Maatschap'>Maatschap</MenuItem>
          <MenuItem value='Europese vennootschap (SE)'>
            Europese vennootschap (SE)
          </MenuItem>
          <MenuItem value='Vereniging zonder winstoogmerk (VZW)'>
            Vereniging zonder winstoogmerk (vzw)
          </MenuItem>
        </TextField>
      </Box>
    </div>
                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                 {/* <TextField
                    label="Ondernemingsnummer"
                    variant="standard"
                    type="text"
                    id="Ondernemingsnummer"
                    value={ondernemingsnummer}
                    onChange={handleOndernemingsnummerChange}
                    required
                    fullWidth
                  /> */}
                   <TextField
      label="Ondernemingsnummer"
      variant="standard"
      type="text"
      id="Ondernemingsnummer"
      {...formik.getFieldProps('ondernemingsnummer')}
      error={formik.touched.ondernemingsnummer && Boolean(formik.errors.ondernemingsnummer)}
      helperText={formik.touched.ondernemingsnummer && formik.errors.ondernemingsnummer}
      fullWidth
    />
                </Box>
                </div>
</>):<div></div>}         
                    </div>
                    </div>

                      <h3>Facturatieadres</h3>
                      <div className="userCardRequestColumnssolarF">
                        <div className="columnFill">
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Straatnaam"
                                variant="standard"
                                type="text"
                                id="streetname"
                                value={streetname}
                                onChange={handleStreetnameChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Huisnummer"
                                variant="standard"
                                type="text"
                                id="housenumber"
                                value={housenumber}
                                onChange={handleHousenumberChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Bus"
                                variant="standard"
                                type="text"
                                id="bus"
                                value={bus}
                                onChange={handleBusChange}
                                fullWidth
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="columnFill">
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Postcode"
                                variant="standard"
                                type="text"
                                id="zipcode"
                                value={zipcode}
                                onChange={handleZipcodeChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Stad / Gemeente"
                                variant="standard"
                                type="text"
                                id="city"
                                value={city}
                                onChange={handleCityChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                        </div>
                      </div>

                      {/* Checkbox adres Eneco */}
                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          Het aansluitingsadres is dezelfde als het
                          facturatieadres.
                        </Box>
                      </div>

                      <h3>Aansluitingsadres</h3>
                      <div className="userCardRequestColumnssolarCleaning">
                        <div className="columnFill">
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Straatnaam Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installaddress"
                                value={installaddress}
                                onChange={handleInstallAddressChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Huisnummer Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installnumber"
                                value={installnumber}
                                onChange={handleInstallNumberChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Bus Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installbus"
                                value={installbus}
                                onChange={handleInstallBusChange}
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Postcode Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installzipcode"
                                value={installzipcode}
                                onChange={handleInstallZipcodeChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="columnFill">
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Gemeente Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installcity"
                                value={installcity}
                                onChange={handleInstallCityChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                          {contractType === "E" ||
                          contractType === "G" ||
                          contractType === "C" ? (
                            <>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <InputLabel htmlFor="aansluitingRadio"></InputLabel>
                                  <div>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={ver === "X"}
                                        onChange={handleVerChange}
                                      />
                                      Ik verhuis/Ik ben afgelopen maand verhuist
                                      naar dit adres.
                                    </label>
                                  </div>
                                </Box>
                              </div>

{ver === 'X' && (
  <div>
     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
         <TextField
           id="move"
           label="Datum van verhuis (dd/mm/yyyy)"
           value={move}
           onChange={handleMoveChange}
           variant="standard"
           required
           fullWidth
          placeholder="dd/mm/yyyy"

         />

       </Box>
{/*
<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="datumverhuisdd"
                    select
                    label="Dag van verhuis"
                    value={ddver}
                    onChange={handleDdverChange}
                    variant="standard"
                    fullWidth
                  >

{Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
        <MenuItem key={day} value={day}>{day}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div> 



                                  <div className="form-group">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <TextField
                                        id="datumverhuismm"
                                        select
                                        label="Maand van verhuis"
                                        value={mmver}
                                        onChange={handleMmverChange}
                                        variant="standard"
                                        fullWidth
                                      >
                                        {Array.from(
                                          { length: 12 },
                                          (_, index) => index + 1,
                                        ).map((month) => (
                                          <MenuItem key={month} value={month}>
                                            {month}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Box>
                                  </div>


<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="datumoverhuisyy"
                    select
                    label="Jaar van verhuis"
                    value={yyver}
                    onChange={handleYyverChange}
                    variant="standard"
                    fullWidth
                  >

{Array.from({ length: 2 }, (_, index) => new Date().getFullYear() + index).map(year => (
        <MenuItem key={year} value={year}>{year}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div>
              */}

</div>
)}
 

                              {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="majmanRadio">Kan op dit adres al elektriciteit en/of aardgas afgenomen worden? </InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="maj"
              value="maj"
              checked={maj === 'X'}
              onChange={handleMajChange}
            />
            Ja
          </label>
          <label>
            <input
              type="radio"
              name="man"
              value="man"
              checked={man === 'X'}
              onChange={handleManChange}
            />
            Nee, de meter is (nog) niet aangesloten
          </label>
        </div>
      </Box>
    </div> */}
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="majmanradio"
                                    select
                                    label="Kan op dit adres al elektriciteit en/of aardgas afgenomen worden?"
                                    onChange={(event) =>
                                      handleMajManChange(event)
                                    }
                                    variant="standard"
                                    fullWidth
                                  >
                                    <MenuItem value={"maj"}>Ja</MenuItem>
                                    <MenuItem value={"man"}>
                                      Nee, de meter is (nog) niet aangesloten
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      {contractType === "E" ||
                      contractType === "C" ||
                      contractType === "EP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Elektriciteit</h3>
                          <div className="userCardRequestColumnssolarCleaning">
                            <div className="columnFill">
                              {/* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Ik kies voor product"
                    variant="standard"
                    type="text"
                    id="productname"
                    value={productname}
                    onChange={handleProductnameChange}
                  />
                </Box>
                </div> */}

                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="EAN-code dag-nacht *"
                                    variant="standard"
                                    type="text"
                                    id="eane"
                                    value={eane}
                                    onChange={handleEaneChange}
                                    fullWidth
                                    inputProps={{
                                      pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                    }}
                                  />
                                </Box>
                              </div>
                              {eane.length !== 18 && (
                                <span style={{ color: "red" }}>
                                  Vul alsjeblieft 18 getallen in startende met
                                  54.
                                </span>
                              )}
                            </div>
                            <div className="columnFill">
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="EAN-code excl. nacht"
                                    variant="standard"
                                    type="text"
                                    id="eann"
                                    value={eann}
                                    onChange={handleEannChange}
                                    fullWidth
                                    inputProps={{
                                      pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                    }}
                                  />
                                </Box>
                              </div>
                              {eann.length !== 18 && (
                                <span style={{ color: "red" }}>
                                  Vul alsjeblieft 18 getallen in startende met
                                  54.
                                </span>
                              )}
                            </div>
                          </div>

                          <h5>Voor de levering van Elektriciteit</h5>
                          {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="elektriciteitRadio"></InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="neg"
              value="neg"
              checked={neg === 'X'}
              onChange={handleNegChange}
            />
            Heb ik geen contract
          </label>
          <label>
            <input
              type="radio"
              name="cme"
              value="cme"
              checked={cme === 'X'}
              onChange={handleCmeChange}
            />
           Heb ik een contract met einddatum
          </label>
          </div>
          </Box>
    </div> */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="elektriciteitRadio"
                                select
                                label="Bestaand contract"
                                required
                                onChange={(event) => handleNegCmeChange(event)}
                                variant="standard"
                                fullWidth
                              >
                                <MenuItem value={"neg"}>
                                  Heb ik geen contract
                                </MenuItem>
                                <MenuItem value={"cme"}>
                                  Heb ik een contract met einddatum
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}

                      {cme === "X" && (
                        <div>
                          {/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <InputLabel htmlFor="overstapdd">Dag einddatum</InputLabel>
    <Select
      native
      value={cmedd}
      onChange={handleCmeddChange}
      inputProps={{
        name: 'cmedd',
        id: 'cmedd',
      }}
    >
      {Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
        <option key={day} value={day}>{day}</option>
      ))}
    </Select>
  </Box>
</div> */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapdd"
                                select
                                label="Dag einddatum"
                                value={cmedd}
                                onChange={handleCmeddChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 31 },
                                  (_, index) => index + 1,
                                ).map((day) => (
                                  <MenuItem key={day} value={day}>
                                    {day}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          {/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <InputLabel htmlFor="overstapmm">Maand einddatum</InputLabel>
    <Select
      native
      value={cmemm}
      onChange={handleCmemmChange}
      inputProps={{
        name: 'cmemm',
        id: 'cmemm',
      }}
    >
      {Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
        <option key={month} value={month}>{month}</option>
      ))}
    </Select>
  </Box>
</div> */}

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapmm"
                                select
                                label="Maand einddatum"
                                value={cmemm}
                                onChange={handleCmemmChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 12 },
                                  (_, index) => index + 1,
                                ).map((month) => (
                                  <MenuItem key={month} value={month}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          {/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <InputLabel htmlFor="overstapyy">Jaar einddatum</InputLabel>
    <Select
      native
      value={cmeyy}
      onChange={handleCmeyyChange}
      inputProps={{
        name: 'cmeyy',
        id: 'cmeyy',
      }}
    >
      {Array.from({ length: 2 }, (_, index) => new Date().getFullYear() + index).map(year => (
        <option key={year} value={year}>{year}</option>
      ))}
    </Select>
  </Box>
</div> */}

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapyy"
                                select
                                label="Jaar einddatum"
                                value={cmeyy}
                                onChange={handleCmeyyChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 2 },
                                  (_, index) =>
                                    new Date().getFullYear() + index,
                                ).map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapRadio"
                                select
                                label="Overstap"
                                fullWidth
                                onChange={(event) => handleOecOsmChange(event)}
                                variant="standard"
                              >
                                <MenuItem value={"oec"}>
                                  Ik wens over te stappen na de einddatum van
                                  mijn huidig contract
                                </MenuItem>
                                <MenuItem value={"osm"}>
                                  Ik wens zo snel mogelijk over te stappen naar
                                  Eneco
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </div>
                      )}
                      {contractType === "G" ||
                      contractType === "C" ||
                      contractType === "GP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Gas</h3>
                          <div className="userCardRequestColumnssolarCleaning">
                            <div className="columnFill">
                              {/* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Ik kies voor product"
                    variant="standard"
                    type="text"
                    id="productname"
                    value={productname}
                    onChange={handleProductnameChange}
                  />
                </Box>
                </div> */}

                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="EAN-code gas *"
                                    variant="standard"
                                    type="text"
                                    id="eang"
                                    value={eang}
                                    onChange={handleEangChange}
                                    fullWidth
                                    inputProps={{
                                      pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                    }}
                                  />
                                </Box>
                              </div>
                              {eang.length !== 18 && (
                                <span style={{ color: "red" }}>
                                  Vul alsjeblieft 18 getallen in startende met
                                  54.
                                </span>
                              )}
                            </div>
                            {/* <div className="columnFill">
                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="EAN-code excl. nacht"
                    variant="standard"
                    type="text"
                    id="eann"
                    value={eann}
                    onChange={handleEannChange}
                  />
                </Box>
                </div> */}
                            {/* </div> */}
                          </div>
                          <h5>Voor de levering van Gas</h5>
                          {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="elektriciteitRadio"></InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="neg"
              value="neg"
              checked={neg === 'X'}
              onChange={handleNegChange}
            />
            Heb ik geen contract
          </label>
          <label>
            <input
              type="radio"
              name="cme"
              value="cme"
              checked={cme === 'X'}
              onChange={handleCmeChange}
            />
           Heb ik een contract met einddatum
          </label>
          </div>
          </Box>
    </div> */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="elektriciteitRadio"
                                select
                                label="Bestaand contract"
                                required
                                onChange={(event) =>
                                  handleNeggCmegChange(event)
                                }
                                variant="standard"
                                fullWidth
                              >
                                <MenuItem value={"negg"}>
                                  Heb ik geen contract
                                </MenuItem>
                                <MenuItem value={"cmeg"}>
                                  Heb ik een contract met einddatum
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}
                      {cmeg === "X" && (
                        <div>
                          {/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <InputLabel htmlFor="overstapdd">Dag einddatum</InputLabel>
    <Select
      native
      value={cmedd}
      onChange={handleCmeddChange}
      inputProps={{
        name: 'cmedd',
        id: 'cmedd',
      }}
    >
      {Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
        <option key={day} value={day}>{day}</option>
      ))}
    </Select>
  </Box>
</div> */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapdd"
                                select
                                label="Dag einddatum"
                                value={cmeddg}
                                onChange={handleCmeddgChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 31 },
                                  (_, index) => index + 1,
                                ).map((day) => (
                                  <MenuItem key={day} value={day}>
                                    {day}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          {/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <InputLabel htmlFor="overstapmm">Maand einddatum</InputLabel>
    <Select
      native
      value={cmemm}
      onChange={handleCmemmChange}
      inputProps={{
        name: 'cmemm',
        id: 'cmemm',
      }}
    >
      {Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
        <option key={month} value={month}>{month}</option>
      ))}
    </Select>
  </Box>
</div> */}

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapmm"
                                select
                                label="Maand einddatum"
                                value={cmemmg}
                                onChange={handleCmemmgChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 12 },
                                  (_, index) => index + 1,
                                ).map((month) => (
                                  <MenuItem key={month} value={month}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          {/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <InputLabel htmlFor="overstapyy">Jaar einddatum</InputLabel>
    <Select
      native
      value={cmeyy}
      onChange={handleCmeyyChange}
      inputProps={{
        name: 'cmeyy',
        id: 'cmeyy',
      }}
    >
      {Array.from({ length: 2 }, (_, index) => new Date().getFullYear() + index).map(year => (
        <option key={year} value={year}>{year}</option>
      ))}
    </Select>
  </Box>
</div> */}

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapyy"
                                select
                                label="Jaar einddatum"
                                value={cmeyyg}
                                onChange={handleCmeyygChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 2 },
                                  (_, index) =>
                                    new Date().getFullYear() + index,
                                ).map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapRadio"
                                select
                                label="Overstap"
                                fullWidth
                                onChange={(event) =>
                                  handleOecgOsmgChange(event)
                                }
                                variant="standard"
                              >
                                <MenuItem value={"oecg"}>
                                  Ik wens over te stappen na de einddatum van
                                  mijn huidig contract
                                </MenuItem>
                                <MenuItem value={"osmg"}>
                                  Ik wens zo snel mogelijk over te stappen naar
                                  Eneco
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </div>
                      )}
                      {contractType === "E" ||
                      contractType === "G" ||
                      contractType === "C" ? (
                        <>
                          <h5>Wijze van betaling</h5>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="VoorschotbedragRadio"
                                select
                                label="Wijze van betaling"
                                required
                                onChange={(event) =>
                                  handleDomVmaeOveChange(event)
                                }
                                variant="standard"
                                fullWidth
                                defaultValue="dom"
                              >
                                <MenuItem value={"dom"}>
                                  Maandelijks via bankdomiciliëring
                                </MenuItem>
                                <MenuItem value={"vmae"}>
                                  Maandelijks via Visa/MasterCard/American
                                  Express
                                </MenuItem>
                                <MenuItem value={"ove"}>
                                  Maandelijks via overschrijving
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>

                          {dom === "X" && (
                            <div>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="bankRadio"
                                    select
                                    label="Tussentijdse facturen"
                                    required
                                    onChange={(event) =>
                                      handleDomgfDomwfChange(event)
                                    }
                                    variant="standard"
                                    fullWidth
                                    defaultValue={domwf}
                                  >
                                    <MenuItem value={"domgf"}>
                                      Ik ontvang geen tussentijdse Eneco
                                      facturen
                                    </MenuItem>
                                    <MenuItem value={"domwf"}>
                                      Ik ontvang wel tussentijdse Eneco facturen
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              </div>
                              {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="bankRadio"></InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="domgf"
              value="domgf"
              checked={domgf === 'X'}
              onChange={handleDomgfChange}
            />
          Ik ontvang geen tussentijdse Eneco facturen
          </label>
          <label>
            <input
              type="radio"
              name="domwf"
              value="domwf"
              checked={domwf === 'X'}
              onChange={handleDomwfChange}
            />
           Ik ontvang wel tussentijdse Eneco facturen
          </label>
          </div>
          </Box>
          </div> */}
                            </div>
                          )}
                        </>
                      ) : (
                        <div></div>
                      )}
                      {contractType === "EP" ||
                      contractType === "GP" ||
                      contractType === "CP" ? (
                        <>
                          <h5>Wijze van betaling</h5>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="VoorschotbedragRadio"
                                select
                                label="Wijze van betaling"
                                required
                                onChange={(event) =>
                                  handleDomVmaeOveChange(event)
                                }
                                variant="standard"
                                fullWidth
                                defaultValue="dom"
                              >
                                <MenuItem value={"dom"}>
                                  Maandelijks via bankdomiciliëring
                                </MenuItem>
                                <MenuItem value={"ove"}>
                                  Maandelijks via overschrijving
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}

                      {vmae === "X" && (
                        <div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="vmaeRadio"
                                select
                                label="Tussentijdse facturen"
                                required
                                onChange={(event) =>
                                  handleVmaegfVmaewfChange(event)
                                }
                                variant="standard"
                                fullWidth
                              >
                                <MenuItem value={"vmaegf"}>
                                  Ik ontvang geen tussentijdse Eneco facturen
                                </MenuItem>
                                <MenuItem value={"vmaewf"}>
                                  Ik ontvang wel tussentijdse Eneco facturen
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                          {/*
<div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="vmaeRadio"></InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="vmaegf"
              value="vmaegf"
              checked={vmaegf === 'X'}
              onChange={handleVmaegfChange}
            />
          Ik ontvang geen tussentijdse Eneco facturen
          </label>
          <label>
            <input
              type="radio"
              name="vmaewf"
              value="vmaewf"
              checked={vmaewf === 'X'}
              onChange={handleVmaewfChange}
            />
           Ik ontvang wel tussentijdse Eneco facturen
          </label>
          </div>
          </Box>
          </div> */}
                        </div>
                      )}
                      {/*
<div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="betalingRadio"></InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="dom"
              value="dom"
              checked={dom === 'X'}
              onChange={handleDomChange}
            />
            Maandelijks via bankdomiciliëring
          </label>
          
          <label>
            <input
              type="radio"
              name="vmae"
              value="vmae"
              checked={vmae === 'X'}
              onChange={handleVmaeChange}
            />
           Maandelijks via Visa/MasterCard/American Express
          </label>
     
          <label>
            <input
              type="radio"
              name="ove"
              value="ove"
              checked={ove === 'X'}
              onChange={handleOveChange}
            />
           Maandelijks via overschrijving
          </label>
          </div>
          </Box>
    </div> */}

                      {/* </div> */}
                    </>
                  ) : (
                    <div></div>
                  )}
                  {/* Engie */}
                  {documentTemplateId ===
                    "473daa35-8613-4bce-aa7d-e7a22f79d871" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d110" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d111" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d112" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d113" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d114" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d115" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d116" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d117" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d118" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d100" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d101" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d102" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d103" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d104" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d105" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d106" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d107" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d108" ? (
                    <>
                      <h3>Identificatie</h3>

                      <div className="userCardRequestColumnssolarCleaning">
                        <div className="columnFill">
                          {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="genderRadio">Geslacht</InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="gender"
              value="dhr"
              checked={dhr === 'X'}
              onChange={() => handleGenderChange('dhr')}
            />
            Dhr
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              value="mevr"
              checked={mevr === 'X'}
              onChange={() => handleGenderChange('mevr')}
            />
            Mevr
          </label>
        </div>
      </Box>
    </div> */}

<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 {/* <TextField
                    id="verhuis"
                    select
                    label="Is dit een verhuis?"
                    // value={birthdatedd}
                    onChange={(event) => handleSetVerhuisChange(event.target.value)}
                    variant="standard"
                    fullWidth
                  >
        <MenuItem value={'Ja'}>Ja</MenuItem>
        <MenuItem value={'Nee'}>Nee</MenuItem>

                  </TextField> */}
                     <TextField
                    id="verhuis"
                    select
                    label="Is dit een verhuis?"
                    // value={birthdatedd}
                    onChange={(event) => handleSetVerhuisChange(event.target.value)}
                    variant="standard"
                    fullWidth
                  >
        <MenuItem value={'Ja'}>Ja</MenuItem>
        <MenuItem value={'Nee'}>Nee</MenuItem>

                  </TextField>
                </Box>
              </div>


    { contractType === "E" || contractType === "G" || contractType === "C" ? ( <>
        <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="gender"
                    select
                    label="Geslacht"
                    // value={birthdatedd}
                    onChange={(event) => handleGenderChange(event.target.value)}
                    variant="standard"
                    required
                    fullWidth
                  >


        <MenuItem value={'dhr'}>Dhr</MenuItem>
        <MenuItem value={'mevr'}>Mevr</MenuItem>
      
                  </TextField>
                </Box>
              </div>
    </> ):<div></div>}

                          {contractType === "EP" ||
                          contractType === "GP" ||
                          contractType === "CP" ? (
                            <>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="Firmanaam"
                                    variant="standard"
                                    type="text"
                                    id="firmanaam"
                                    value={bedrijfsnaam}
                                    onChange={handleBedrijfsnaamChange}
                                    required
                                    fullWidth
                                  />
                                </Box>
                              </div>
                              {/* <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 
                      <TextField
                        label="Juridische vorm"
                        variant="standard"
                        type="text"
                        id="Juridische vorm"
                        value={vennootschapsvorm}
                        onChange={handleVennootschapsvormChange}
                        required
                        fullWidth
                      />
                    </Box>
                    </div> */}

                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="Vennootschapsvorm"
                                    select
                                    label="Vennootschapsvorm"
                                    value={vennootschapsvorm}
                                    onChange={handleVennootschapsvormChange}
                                    variant="standard"
                                    fullWidth
                                    required
                                  >
                                    <MenuItem value="Eenmanszaak">
                                      Eenmanszaak
                                    </MenuItem>
                                    <MenuItem value="Vennootschap met beperkte aansprakelijkheid (BV)">
                                      Vennootschap met beperkte
                                      aansprakelijkheid (BV)
                                    </MenuItem>
                                    <MenuItem value="Naamloze vennootschap (NV)">
                                      Naamloze vennootschap (NV)
                                    </MenuItem>
                                    <MenuItem value="Coöperatieve vennootschap (CV)">
                                      Coöperatieve vennootschap (CV)
                                    </MenuItem>
                                    <MenuItem value="Vennootschap onder firma (VOF)">
                                      Vennootschap onder firma (VOF)
                                    </MenuItem>
                                    <MenuItem value="Commanditaire vennootschap (CommV)">
                                      Commanditaire vennootschap (CommV)
                                    </MenuItem>
                                    <MenuItem value="Maatschap">
                                      Maatschap
                                    </MenuItem>
                                    <MenuItem value="Europese vennootschap (SE)">
                                      Europese vennootschap (SE)
                                    </MenuItem>
                                    <MenuItem value="Vereniging zonder winstoogmerk (VZW)">
                                      Vereniging zonder winstoogmerk (vzw)
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              </div>

                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="Ondernemingsnummer"
                                    variant="standard"
                                    type="text"
                                    id="Ondernemingsnummer"
                                    value={ondernemingsnummer}
                                    onChange={handleOndernemingsnummerChange}
                                    required
                                    fullWidth
                                  />
                                </Box>
                              </div>

                              {/* <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 
                      <TextField
                        label="RPR"
                        variant="standard"
                        type="text"
                        id="RPR"
                        value={RPR}
                        onChange={handleRPRChange}
                        required
                        fullWidth
                      />
                    </Box>
                    </div> */}

                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="NACE"
                                    variant="standard"
                                    type="text"
                                    id="NACE"
                                    value={nace}
                                    onChange={handleNaceChange}
                                    required
                                    fullWidth
                                  />
                                </Box>
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Naam"
                                variant="standard"
                                type="text"
                                id="nameFilter"
                                value={name}
                                onChange={handleNameChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Voornaam"
                                variant="standard"
                                type="text"
                                id="surNameFilter"
                                value={surname}
                                onChange={handleSurnameChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Klantnummer"
                                variant="standard"
                                type="text"
                                id="klantnummerFilter"
                                value={klantnummer}
                                onChange={handleKlantnummerChange}
                                fullWidth
                              />
                            </Box>
                          </div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Email"
                                variant="standard"
                                type="text"
                                id="emailFilter"
                                value={email}
                                onChange={handleEmailChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                        </div>

                        <div className="columnFill">
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Telefoonnummer"
                                variant="standard"
                                type="text"
                                id="phone"
                                value={phone}
                                onChange={handlePhoneChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Vaste Lijn"
                                variant="standard"
                                type="text"
                                id="telphone"
                                value={telphone}
                                onChange={handleTelphoneChange}
                                fullWidth
                              />
                            </Box>
                          </div>
                          {contractType === "E" ||
                          contractType === "G" ||
                          contractType === "C" ? (
                            <>
                              {/* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="Birthdatedd"
                    select
                    label="Geboorte dag"
                    value={birthdatedd}
                    onChange={handleBirthdateDDChange}
                    variant="standard"
                    required
                    fullWidth
                  >

{Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
        <MenuItem key={day} value={day}>{day}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div> 
         
              <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="Birthdatemm"
                    select
                    label="Geboorte maand"
                    value={birthdatemm}
                    onChange={handleBirthdateMMChange}
                    variant="standard"
                    required
                    fullWidth
                  >

{Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
        <MenuItem key={month} value={month}>{month}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div> 
        
              <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="Birthdateyy"
                    select
                    label="Geboorte jaar"
                    value={birthdateyy}
                    onChange={handleBirthdateYYChange}
                    variant="standard"
                    required
                    fullWidth
                  >

{Array.from({ length: new Date().getFullYear() - 1900 }, (_, index) => 1900 + index).map(year => (
        <MenuItem key={year} value={year}>{year}</MenuItem>
      ))}
                  </TextField>
                </Box>
                </div> */}
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="Birthdate"
                                    label="Geboortedatum (dd/mm/yyyy)"
                                    value={birthdate}
                                    onChange={handleBirthdateChange}
                                    variant="standard"
                                    required
                                    fullWidth
                                    placeholder="dd/mm/yyyy"
                                    // inputProps={{
                                    //   pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
                                    //   placeholder: "dd/mm/yyyy",
                                    // }}
                                  />
                                </Box>
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      <h3>Facturatieadres</h3>
                      <div className="userCardRequestColumnssolarCleaning">
                        <div className="columnFill">
                          {contractType === "EP" ||
                          contractType === "GP" ||
                          contractType === "CP" ? (
                            <>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="Firmanaam"
                                    variant="standard"
                                    type="text"
                                    id="Firmanaam"
                                    value={verzendfirmname}
                                    onChange={handleVerzendfirmnameChange}
                                    required
                                    fullWidth
                                  />
                                </Box>
                              </div>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="Juridische vorm"
                                    variant="standard"
                                    type="text"
                                    id="Juridische vorm"
                                    value={verzendjurform}
                                    onChange={handleVerzendjurformChange}
                                    required
                                    fullWidth
                                  />
                                </Box>
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Straatnaam"
                                variant="standard"
                                type="text"
                                id="streetname"
                                value={streetname}
                                onChange={handleStreetnameChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Huisnummer"
                                variant="standard"
                                type="text"
                                id="housenumber"
                                value={housenumber}
                                onChange={handleHousenumberChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Bus"
                                variant="standard"
                                type="text"
                                id="bus"
                                value={bus}
                                onChange={handleBusChange}
                                fullWidth
                              />
                            </Box>
                          </div>
                        </div>

                        <div className="columnFill">
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Postcode"
                                variant="standard"
                                type="text"
                                id="zipcode"
                                value={zipcode}
                                onChange={handleZipcodeChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Stad / Gemeente"
                                variant="standard"
                                type="text"
                                id="city"
                                value={city}
                                onChange={handleCityChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>
                        </div>
                      </div>
                      {/* Checkbox adres Engie */}
                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          Het leveringsadres (afnamepunt) is dezelfde als het
                          facturatieadres.
                        </Box>
                      </div>

                      <h3>Leveringsadres (Afnamepunt)</h3>
                      <div className="userCardRequestColumnssolarCleaning">
                        <div className="columnFill">
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Straatnaam Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installaddress"
                                value={installaddress}
                                onChange={handleInstallAddressChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Huisnummer Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installnumber"
                                value={installnumber}
                                onChange={handleInstallNumberChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Bus Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installbus"
                                value={installbus}
                                onChange={handleInstallBusChange}
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Verdiep Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installfloor"
                                value={installfloor}
                                onChange={handleInstallFloorChange}
                                fullWidth
                              />
                            </Box>
                          </div>
                        </div>

                        <div className="columnFill">
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Postcode Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installzipcode"
                                value={installzipcode}
                                onChange={handleInstallZipcodeChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Gemeente Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installcity"
                                value={installcity}
                                onChange={handleInstallCityChange}
                                required
                                fullWidth
                              />
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Ean elektriciteit *"
                                variant="standard"
                                type="text"
                                id="eane"
                                value={eane}
                                onChange={handleEaneChange}
                                fullWidth
                                inputProps={{
                                  pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                }}
                              />
                            </Box>
                          </div>
                          {eane.length !== 18 && (
                            <span style={{ color: "red" }}>
                              Vul alsjeblieft 18 getallen in startende met 54.
                            </span>
                          )}

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Ean aardgas *"
                                variant="standard"
                                type="text"
                                id="eang"
                                value={eang}
                                fullWidth
                                onChange={handleEangChange}
                                inputProps={{
                                  pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                }}
                              />
                            </Box>
                          </div>
                          {eang.length !== 18 && (
                            <span style={{ color: "red" }}>
                              Vul alsjeblieft 18 getallen in startende met 54.
                            </span>
                          )}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Ean exclusief nacht"
                                variant="standard"
                                type="text"
                                id="eann"
                                value={eann}
                                fullWidth
                                onChange={handleEannChange}
                                inputProps={{
                                  pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                }}
                              />
                            </Box>
                          </div>
                          {eann.length !== 18 && (
                            <span style={{ color: "red" }}>
                              Vul alsjeblieft 18 getallen in startende met 54.
                            </span>
                          )}

                          {contractType === "EP" ||
                          contractType === "GP" ||
                          contractType === "CP" ? (
                            <>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="usageRadio"
                                    select
                                    label="Gemengd of uitsluitend professioneel vebruik?"
                                    required
                                    onChange={(event) =>
                                      handleGpvUpvChange(event)
                                    }
                                    variant="standard"
                                    fullWidth
                                  >
                                    <MenuItem value={"gpv"}>
                                      Gemengd professioneel verbruik
                                    </MenuItem>
                                    <MenuItem value={"upv"}>
                                      Uitsluitend professioneel verbruikt
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      <h3>Klant</h3>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="aansluitingRadio"
                            select
                            label="Contract"
                            required
                            onChange={(event) => handleAceGceNegChange(event)}
                            variant="standard"
                            fullWidth
                          >
                            <MenuItem value={"ace"}>
                              U hebt al een contract bij ENGIE
                            </MenuItem>
                            <MenuItem value={"gce"}>
                              U hebt nog geen contract bij ENGIE
                            </MenuItem>
                            <MenuItem value={"neg"}>
                              U hebt nog nooit een energiecontract gehad
                            </MenuItem>
                          </TextField>
                        </Box>
                      </div>
                      {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="aansluitingRadio">Klant</InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="ace"
              value="ace"
              checked={ace === 'X'}
              onChange={handleAceChange}
            />
            U hebt al een contract bij ENGIE
          </label>
          <label>
            <input
              type="radio"
              name="gce"
              value="gce"
              checked={gce === 'X'}
              onChange={handleGceChange}
            />
            U hebt nog geen contract bij ENGIE
          </label>
          <label>
            <input
              type="radio"
              name="neg"
              value="neg"
              checked={neg === 'X'}
              onChange={handleNegChange}
            />
            U hebt nog nooit een energiecontract gehad
          </label>
        </div>
      </Box>
    </div> */}

                      {contractType === "E" ||
                      contractType === "C" ||
                      contractType === "EP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Elektriciteit</h3>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="supplierRadio"
                                select
                                label="Situatie"
                                required
                                onChange={(event) =>
                                  handleVbElaBewEenChange(event)
                                }
                                variant="standard"
                                fullWidth
                              >
                                <MenuItem value={"vb"}>
                                  U verhuist of bouwt
                                </MenuItem>
                                <MenuItem value={"ela"}>
                                  U hebt al een elektriciteitscontract bij een
                                  andere leverancier
                                </MenuItem>
                                <MenuItem value={"bew"}>
                                  U wilt uw bestaande elektriciteitsconstract
                                  wijzigen
                                </MenuItem>
                                <MenuItem value={"een"}>
                                  U wilt een contract voor een extra energievorm
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                          {ela === "X" && ( // Render the nested radio buttons only when ela is checked
                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  label="Huidige leverancier"
                                  variant="standard"
                                  type="text"
                                  id="leverancier"
                                  value={leverancier}
                                  onChange={handleLeverancierChange}
                                  fullWidth
                                />
                              </Box>
                            </div>
                          )}
                          {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="aansluitingRadio">Situatie</InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="vb"
              value="vb"
              checked={vb === 'X'}
              onChange={handleVbChange}
            />
            U verhuist of bouwt
          </label>
          <label>
            <input
              type="radio"
              name="ela"
              value="ela"
              checked={ela === 'X'}
              onChange={handleElaChange}
            />
            U hebt al een elektriciteitscontract bij een andere leverancier
          </label> */}

                          {/* <label>
            <input
              type="radio"
              name="bew"
              value="bew"
              checked={bew === 'X'}
              onChange={handleBewChange}
            />
           U wilt uw bestaande elektriciteitsconstract wijzigen
          </label>
          <label>
            <input
              type="radio"
              name="een"
              value="een"
              checked={een === 'X'}
              onChange={handleEenChange}
            />
            U wilt een contract voor een extra energievorm
          </label>
        </div>
      </Box>
    </div> */}

                          <h3>Meter</h3>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="meterRadio"
                                select
                                label="Staat meter"
                                required
                                onChange={(event) =>
                                  handleEbaEbnEboChange(event)
                                }
                                variant="standard"
                                fullWidth
                              >
                                <MenuItem value={"eba"}>
                                  Uw elektriciteitsmeter is al geopend
                                </MenuItem>
                                <MenuItem value={"ebn"}>
                                  U wilt een nieuwe elektriciteitsmeter openen
                                </MenuItem>
                                <MenuItem value={"ebo"}>
                                  U wilt een bestaande elektriciteitsmeter
                                  openen
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>

                          {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="aansluitingRadio">Situatie</InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="eba"
              value="eba"
              checked={eba === 'X'}
              onChange={handleEbaChange}
            />
            Uw elektriciteitsmeter is al geopend
          </label>
          <label>
            <input
              type="radio"
              name="ebn"
              value="ebn"
              checked={ebn === 'X'}
              onChange={handleEbnChange}
            />
            U wilt een nieuwe elektriciteitsmeter openen
          </label>
       
          <label>
            <input
              type="radio"
              name="ebo"
              value="ebo"
              checked={ebo === 'X'}
              onChange={handleEboChange}
            />
           U wilt een bestaande elektriciteitsmeter openen
          </label>
        </div>
      </Box>
    </div> */}

                          <h3>Voorschot</h3>
                          {/* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Gewenst voorschot"
                    variant="standard"
                    type="text"
                    id="voore"
                    value={voore}
                    onChange={handleVooreChange}
                    required
                    fullWidth
                  />
                </Box>
                </div> */}

                          {/*
                 <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="datumoverschakelingdd">Dag overschakeling</InputLabel>
        <Select
          native
          value={datumoverschakelingdd}
          onChange={handleDatumOverschakelingDDChange}
          inputProps={{
            name: 'datumoverschakelingdd',
            id: 'datumoverschakelingdd',
          }}
        >
          {Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
            <option key={day} value={day}>{day}</option>
          ))}
        </Select>
      </Box>
    </div> */}

                          {/* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="datumoverschakelingdd"
                    select
                    label="Dag van gewenste overschakeling"
                    value={datumoverschakelingdd}
                    onChange={handleDatumOverschakelingDDChange}
                    variant="standard"
                    fullWidth
                    
                  >

{Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
        <MenuItem key={day} value={day}>{day}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div>
    
              <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="datumoverschakelingmm"
                    select
                    label="Maand van gewenste overschakeling"
                    value={datumoverschakelingmm}
                    onChange={handleDatumOverschakelingMMChange}
                    variant="standard"
                    fullWidth
                    
                  >

{Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
        <MenuItem key={month} value={month}>{month}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div> 
    
              <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="datumoverschakelingyy"
                    select
                    label="Jaar van gewenste overschakeling"
                    value={datumoverschakelingyy}
                    onChange={handleDatumOverschakelingYYChange}
                    variant="standard"
                    fullWidth
                  >

{Array.from({ length: 2 }, (_, index) => new Date().getFullYear() + index).map(year => (
        <MenuItem key={year} value={year}>{year}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div>  */}

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="datumoverschakeling"
                                label="Datum gewenste overschakeling (dd/mm/yyyy)"
                                value={datumoverschakeling}
                                onChange={handleDatumoverschakelingChange}
                                variant="standard"
                                required
                                fullWidth
                                placeholder="dd/mm/yyyy"
                                //  inputProps={{
                                //    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
                                //    placeholder: "dd/mm/yyyy",
                                //  }}
                              />
                            </Box>
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}

                      {contractType === "G" ||
                      contractType === "C" ||
                      contractType === "GP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Gas</h3>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="supplierRadio"
                                select
                                label="Situatie"
                                required
                                onChange={(event) =>
                                  handleVbgElagBewgEengChange(event)
                                }
                                variant="standard"
                                fullWidth
                              >
                                <MenuItem value={"vbg"}>
                                  U verhuist of bouwt
                                </MenuItem>
                                <MenuItem value={"elag"}>
                                  U hebt al een gascontract bij een andere
                                  leverancier
                                </MenuItem>
                                <MenuItem value={"bewg"}>
                                  U wilt uw bestaande gascontract wijzigen
                                </MenuItem>
                                <MenuItem value={"eeng"}>
                                  U wilt een contract voor een extra energievorm
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                          {elag === "X" && ( // Render the nested radio buttons only when ela is checked
                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  label="Huidige leverancier"
                                  variant="standard"
                                  type="text"
                                  id="leverancierg"
                                  value={leverancierg}
                                  onChange={handleLeveranciergChange}
                                  fullWidth
                                />
                              </Box>
                            </div>
                          )}

                          <h3>Meter</h3>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="meterRadio"
                                select
                                label="Staat meter"
                                required
                                onChange={(event) =>
                                  handleGbaGbnGboChange(event)
                                }
                                variant="standard"
                                fullWidth
                              >
                                <MenuItem value={"gba"}>
                                  Uw gasmeter is al geopend
                                </MenuItem>
                                <MenuItem value={"gbn"}>
                                  U wilt een nieuwe gasmeter openen
                                </MenuItem>
                                <MenuItem value={"gbo"}>
                                  U wilt een bestaande gasmeter openen
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>

                          <h3>Voorschot</h3>
                          {/* <div className="form-group">
               <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 <TextField
                   label="Gewenst voorschot"
                   variant="standard"
                   type="text"
                   id="voore"
                   value={voorg}
                   onChange={handleVoorgChange}
                   fullWidth
                 />
               </Box>
               </div> */}

                          {/* <div className="form-group">
               <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 <TextField
                   id="datumoverschakelingdd"
                   select
                   label="Dag van gewenste overschakeling"
                   value={datumoverschakelingddg}
                   onChange={handleDatumoverschakelingddgChange}
                   variant="standard"
                   fullWidth
                 >

{Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
       <MenuItem key={day} value={day}>{day}</MenuItem>
     ))}
                 </TextField>
               </Box>
             </div>
   
             <div className="form-group">
               <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 <TextField
                   id="datumoverschakelingmm"
                   select
                   label="Maand van gewenste overschakeling"
                   value={datumoverschakelingmmg}
                   onChange={handleDatumoverschakelingmmgChange}
                   variant="standard"
                   fullWidth
                 >

{Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
       <MenuItem key={month} value={month}>{month}</MenuItem>
     ))}
                 </TextField>
               </Box>
             </div> 
   
             <div className="form-group">
               <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 <TextField
                   id="datumoverschakelingyy"
                   select
                   label="Jaar van gewenste overschakeling"
                   value={datumoverschakelingyyg}
                   onChange={handleDatumoverschakelingyygChange}
                   variant="standard"
                   fullWidth
                 >

{Array.from({ length: 2 }, (_, index) => new Date().getFullYear() + index).map(year => (
       <MenuItem key={year} value={year}>{year}</MenuItem>
     ))}
                 </TextField>
               </Box>
             </div>  */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="datumoverschakeling"
                                label="Datum gewenste overschakeling (dd/mm/yyyy)"
                                value={datumoverschakelingG}
                                onChange={handleDatumoverschakelingGChange}
                                variant="standard"
                                required
                                fullWidth
                                placeholder="dd/mm/yyyy"
                                //  inputProps={{
                                //    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
                                //    placeholder: "dd/mm/yyyy",
                                //  }}
                              />
                            </Box>
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}

                      <h3>Betalingsmodaliteiten</h3>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="opteertRadio"
                            select
                            label="U opteert voor"
                            required
                            onChange={(event) => handleMaandChange(event)}
                            variant="standard"
                            fullWidth
                            value="maandelijks" // Set the value directly
                            disabled // Make the field uneditable
                          >
                            <MenuItem value={"maandelijks"}>
                              maandelijks
                            </MenuItem>
                            {/* <MenuItem value={'tweemaandelijks'}>2 maandelijks</MenuItem>
        <MenuItem value={'driemaandelijks'}>3 maandelijks</MenuItem>
       */}
                          </TextField>
                        </Box>
                      </div>

                      {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}> */}
                      {/* <InputLabel htmlFor="voorschotfrequentieRadio">U opteert voor</InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="maandelijks"
              value="maandelijks"
              checked={maandelijks === 'X'}
              onChange={() => {
              setMaandelijks('X');
                setTweemaandelijks('');
                setDriemaandelijks('');
              }}
            />
            Maandelijks
          </label>
          <label>
            <input
              type="radio"
              name="tweemaandelijks"
              value="tweemaandelijks"
              checked={tweemaandelijks === 'X'}
              onChange={() => {
              setMaandelijks('');
                setTweemaandelijks('X');
                setDriemaandelijks('');
              }}
            />
            2 Maandelijks
          </label>
          <label>
            <input
              type="radio"
              name="driemaandelijks"
              value="driemaandelijks"
              checked={driemaandelijks === 'X'}
              onChange={() => {
              setMaandelijks('');
                setTweemaandelijks('');
                setDriemaandelijks('X');
              }}
            />
            3 Maandelijks
          </label>
        </div>
      </Box>
    </div> */}

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="vooRadio"
                            select
                            label="U wenst uw facturen en bijbehorende correspondentie te ontvangen"
                            required
                            onChange={(event) =>
                              handleCorrespondentieChange(event)
                            }
                            variant="standard"
                            fullWidth
                            defaultValue="df"
                          >
                            <MenuItem value={"df"}>Via e-mail</MenuItem>
                            <MenuItem value={"pp"}>Per post</MenuItem>
                          </TextField>
                        </Box>
                      </div>

                      {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="voorschotfrequentieRadio">U wenst uw facturen en bijbehorende correspondentie te ontvangen</InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="df"
              value="df"
              checked={df === 'X'}
              onChange={() => {
              setDf('X');
                setPp('');
            }}
            />
            Via e-mail
          </label>
          <label>
            <input
              type="radio"
              name="pp"
              value="pp"
              checked={pp === 'X'}
              onChange={() => {
                setDf('');
                setPp('X');
              }}
            />
            Per post
          </label>
        </div>
      </Box>
    </div> */}

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="betRadio"
                            select
                            label="U betaalt"
                            required
                            onChange={(event) => handleBetalingChange(event)}
                            variant="standard"
                            fullWidth
                            defaultValue="dom"
                          >
                            <MenuItem value={"dom"}>Via domiciliëring</MenuItem>
                            <MenuItem value={"ove"}>
                              Per overschrijving
                            </MenuItem>
                            <MenuItem value={"ib"}>
                              Via een nieuwe domiciliëring met een nieuw
                              rekeningnummer
                            </MenuItem>
                          </TextField>
                        </Box>
                      </div>

 
    {ib === 'X' && ( // Render the nested radio buttons only when ela is checked
             <div className="form-group">
             <Box sx={{ display: "flex", alignItems: "flex-end" }}>
               <TextField
                 label="IBAN rekeningnummer"
                 variant="standard"
                 type="text"
                 id="iban"
                 value={iban}
                 required
                 onChange={handleIbanChange}
                 fullWidth
               />
             </Box>
             </div>
          )}

{/*
<div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <InputLabel htmlFor="voorschotfrequentieRadio">U betaalt</InputLabel>
        <div>
          <label>
            <input
              type="radio"
              name="ove"
              value="ove"
              checked={ove === 'X'}
              onChange={() => {
              setOve('X');
                setDom('');
                setIb('');
            }}
            />
            Per overschrijving
          </label>
          <label>
            <input
              type="radio"
              name="dom"
              value="dom"
              checked={dom === 'X'}
              onChange={() => {
                setOve('');
                setDom('X');
                setIb('');
              }}
            />
            Via domiciliëring
          </label>
          <label>
            <input
              type="radio"
              name="ib"
              value="ib"
              checked={ib === 'X'}
              onChange={() => {
                setOve('');
                setDom('');
                setIb('X');
              }}
            />
            Via een nieuwe domiciliëring met een nieuw rekeningnummer
          </label>
        
        </div>
      </Box>
    </div>  */}
    
    
    
              </>) : (<div></div>)}


      {/*    <button
                    className='buttonstyle33'
                    onClick={() => handleSaveFilledDocument()}
                  >
                    Doorgaan
                  </button>
         */}
                  {documentTemplateId !==
                  "191daa35-8613-5bce-aa7d-z8c86p88n100" ? (
                    <button
                      className="buttonstyle33"
                      onClick={() => handleSaveFilledDocument()}
                    >
                      Doorgaan
                    </button>
                  ) : (
                    <div>Redirecting...</div>
                  )}
                </div>
              </div>
              <div className="textColumnsolar">
                <div className="textColumnElement">
                  <SquareComponent />
                  <SquareComponentIt />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default DocumentFill;

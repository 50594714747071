import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import "./MyContractsDetail.module.css";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface ContractInstance {
  id: string;
  contractId: string;
  userId: string;
  startDate: Date;
  endDate: Date;
}

interface Contract {
  id: string;
  name: string;
  description: string;
  category: number;
  supplierId: number;
}

interface EnergySupplier {
  id: string;
  name: string;
  logo: string;
  active: number;
}

const MyContractsDetail: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [contract, setContract] = useState<Contract | null>(null);
  const [contractInstance, setContractInstance] =
    useState<ContractInstance | null>(null);
  const { contractInstanceId } = useParams<{ contractInstanceId: string }>(); // Extract the contract ID from the URL
  const navigate = useNavigate();
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [roleId, setRoleId] = useState<number | null>(null);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const navigationmenutop: React.CSSProperties = {
    backgroundColor: "#ffffff",
    padding: "20px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchContract();
  }, []);

  const fetchContract = async () => {
    try {
      const responseInstance = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractInstances/${contractInstanceId}`,
      );
      const contractInstanceData: ContractInstance = responseInstance.data;
      setContractInstance(contractInstanceData);

      const contractId = contractInstanceData.contractId;

      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contracts/${contractId}`,
      );
      const contractData: Contract = response.data;
      setContract(contractData);

      const supplierResponse = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energysuppliers/${contractData.supplierId}`,
      );
      const energySupplier: EnergySupplier = supplierResponse.data;
      setEnergySuppliers([energySupplier]);
    } catch (error) {
      console.error("Error retrieving contract:", error);
      // Handle error state or display an error message
    }
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Mijn Energiecontract</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <hr className="divider" />
              <div className="userCard">
                {contract && (
                  <div className="contractDetailContainer">
                    <div className="label">Energieleverancier:</div>
                    {/* {energySuppliers && energySuppliers.length > 0 ?  <img src={energySuppliers[0].logo} style={{height: '40px'}}/> : <div className="text">{energySuppliers[0].name} : ""</div>}
                     */}
                    <div className="text">
                      {energySuppliers.length > 0
                        ? energySuppliers[0].name
                        : ""}
                    </div>
                    <div className="label">Naam:</div>
                    <div className="text">{contract.name}</div>
                    <div className="label">Beschrijving:</div>
                    <div className="text">{contract.description}</div>
                    <div className="label">Categorie:</div>
                    <div className="text">
                      {contract.category === 1
                        ? "Elektriciteit Vast"
                        : contract.category === 2
                          ? "Elektriciteit Variabel"
                          : contract.category === 3
                            ? "Gas Vast"
                            : contract.category === 4
                              ? "Gas Variabel"
                              : ""}
                    </div>

                    {contractInstance && (
                      <div className="contractDetailContainer">
                        <div className="label">Start datum:</div>
                        <div className="text">
                          {contractInstance.startDate.toLocaleString()}
                        </div>
                        <div className="label">Eind datum:</div>
                        <div className="text">
                          {contractInstance.endDate.toLocaleString()}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default MyContractsDetail;

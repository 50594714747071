import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import "./DetailEnergySupplier.css";
import { IoMenu } from "react-icons/io5";
import { useParams, useNavigate } from "react-router-dom";
import { IoIosCreate, IoIosTrash } from "react-icons/io";
import NavBar from "../../../../components/Navigation/NavBar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface EnergySupplier {
  id: number;
  name: string;
  logo: string;
  active: number;
  evaluation: number;
}

interface Contract {
  id: number;
  supplierId: number;
  name: string;
  description: string;
  category: number;
}

const DetailEnergySupplier = () => {
  const [energySupplier, setEnergySupplier] = useState<EnergySupplier | null>(
    null,
  );
  const [isMenuVisible, setMenuVisible] = useState(false);
  const { energySupplierId } = useParams<{ energySupplierId: string }>() ?? {
    energySupplierId: "",
  };

  const navigate = useNavigate();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [roleId, setRoleId] = useState<number | null>(null);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchEnergySupplier();
    fetchContracts();
  }, []);

  const fetchEnergySupplier = async () => {
    try {
      if (energySupplierId) {
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/energySuppliers/${energySupplierId}`,
        );
        setEnergySupplier(response.data);
      }
    } catch (error) {
      console.error("Error retrieving energy supplier:", error);
      // Handle error state or display an error message
    }
  };

  const fetchContracts = async () => {
    try {
      if (energySupplierId) {
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contracts/supplier/${energySupplierId}`,
        );
        const contracts: Contract[] = response.data;
        setContracts(contracts);
      }
    } catch (error) {
      console.error("Error retrieving contracts:", error);
      // Handle error state or display an error message
    }
  };
  const handleEditEnergySupplier = (energySupplierId: any) => {
    // Navigate to edit energy supplier page

    navigate(`/editEnergySupplier/${energySupplierId}`);
  };

  const handleDetailContract = (contractId: number) => {
    // Navigate to contract detail page
    navigate(`/DetailContract/${contractId}`);
  };

  //   const handleEditContract = (contractId: number) => {
  //   // Navigate to edit contract page
  //   navigate(`/EditContract/${contractId}`)
  // };

  const handleEditContract = (contractId: any, contract: Contract | null) => {
    // Navigate to edit contract page based on the contract category
    if (contract && contract.category === 1) {
      navigate(`/EditContract/${contractId}`);
    } else if (contract && contract.category === 2) {
      navigate(`/EditGasContract/${contractId}`);
    } else {
      console.log("Invalid contract category");
    }
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#272727",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Energieleverancier gegevens</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    <button
                      className="addButton"
                      onClick={() => handleEditEnergySupplier(energySupplierId)}
                    >
                      Energieleverancier aanpassen
                    </button>
                  </div>
                </div>
                <hr className="dividerDashed" />
                <div className="userCardes">
                  {energySupplier && (
                    <div className="energySupplierDetailContainer">
                      {/* <div className="label">ID:</div>
              <div className="text">{energySupplier.id}</div>  */}
                      <div className="label">Logo:</div>
                      <div className="text">
                        <img
                          src={energySupplier.logo}
                          style={{ height: "40px", marginRight: "10px" }}
                          onError={(e) => {
                            // Handle image loading errors
                            console.error("Error loading logo:", e);
                          }}
                        />
                      </div>
                      <div className="label">Naam:</div>
                      <div className="text">{energySupplier.name}</div>
                      <div className="label">Actief:</div>
                      <div className="text">
                        {energySupplier.active === 1 ? "Actief" : "Niet Actief"}
                      </div>
                      <div className="label">Tevredenheidscore:</div>
                      <div className="text">{energySupplier.evaluation}</div>
                    </div>
                  )}
                </div>
                {contracts.length > 0 && <hr className="dividerDashed" />}
                {contracts.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Contract Naam</StyledTableCell>
                          <StyledTableCell align="right">
                            Omschrijving
                          </StyledTableCell>
                          <StyledTableCell align="right">Type</StyledTableCell>
                          <StyledTableCell align="right">
                            Acties
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contracts.map((contract) => (
                          <StyledTableRow key={contract.id}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              onClick={() => handleDetailContract(contract.id)}
                            >
                              {contract.name}
                            </StyledTableCell>

                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {contract.description}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {contract.category === 1
                                ? "Elektriciteit Vast"
                                : contract.category === 2
                                  ? "Elektriciteit Variabel"
                                  : contract.category === 3
                                    ? "Gas Vast"
                                    : contract.category === 4
                                      ? "Gas Variabel"
                                      : contract.category === 5
                                        ? "Elektriciteit Dynamisch"
                                        : contract.category === 6
                                          ? "Gas Dynamisch"
                                          : contract.category === 7
                                            ? "Combi Vast"
                                            : contract.category === 8
                                              ? "Combi Variabel"
                                              : contract.category === 9
                                                ? "Combi Dynamisch"
                                                : contract.category === 10
                                                  ? "Elektriciteit Vast Professioneel"
                                                  : contract.category === 11
                                                    ? "Elektriciteit Variabel Professioneel"
                                                    : contract.category === 12
                                                      ? "Gas Vast Professioneel"
                                                      : contract.category === 13
                                                        ? "Gas Variabel Professioneel"
                                                        : contract.category ===
                                                            14
                                                          ? "Elektriciteit Dynamisch Professioneel"
                                                          : contract.category ===
                                                              15
                                                            ? "Gas Dynamisch Professioneel"
                                                            : contract.category ===
                                                                16
                                                              ? "Combi Vast Professioneel"
                                                              : contract.category ===
                                                                  17
                                                                ? "Combi Variabel Professioneel"
                                                                : contract.category ===
                                                                    18
                                                                  ? "Combi Dynamisch Professioneel"
                                                                  : "Gas"}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <IoIosCreate
                                size={20}
                                className="actionIcon"
                                onClick={() =>
                                  handleEditContract(contract.id, contract)
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default DetailEnergySupplier;

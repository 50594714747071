import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import "../Articles.css";
import ThermostatuseImage from "./thermostatuse.jpg";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import { getCookie } from "../../../../../cookieUtils";

const ThermostatUseArticle: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const subheadingStyles: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "30px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>
            Hoe verbruik ik minder door de thermostaat een graadje lager in de
            winter te zetten, en een graadje hoger in de zomer?
          </PageHeroColored>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <p className="section-paragraph">
                    <b>
                      {" "}
                      De thermostaat is een handig hulpmiddel om de temperatuur
                      in je huis te regelen. Door slim gebruik te maken van de
                      thermostaat, kun je energie besparen en je energierekening
                      verlagen. Een eenvoudige maar effectieve manier om dit te
                      doen, is door de thermostaat een graadje lager in de
                      winter te zetten en een graadje hoger in de zomer.
                    </b>
                  </p>
                  <h2 style={subheadingStyles}>
                    Winter: Thermostaat een graadje lager
                  </h2>
                  <p className="section-paragraph">
                    In de winter kun je veel energie besparen door de
                    thermostaat een graadje lager te zetten. Het is begrijpelijk
                    dat je het lekker warm wilt hebben in huis, maar elke graad
                    hoger betekent ook meer energieverbruik. Door de temperatuur
                    in huis iets lager te houden, verbruik je minder energie en
                    bespaar je op je stookkosten.
                    <br></br>
                    <br></br>
                    Een comfortabele temperatuur in huis ligt meestal tussen de
                    18°C en 20°C. Door een trui te dragen en een warme deken op
                    de bank te gebruiken, kun je het nog steeds aangenaam hebben
                    zonder de thermostaat hoger te zetten. Bovendien zul je snel
                    wennen aan deze iets lagere temperatuur en zul je het
                    verschil in je energierekening merken.
                  </p>
                </div>

                <div className="image-column">
                  <img
                    src={ThermostatuseImage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
              </div>

              <div className="section-container-full">
                {/* Zomer: Thermostaat een graadje hoger */}
                <h2 style={subheadingStyles}>
                  Zomer: Thermostaat een graadje hoger
                </h2>
                <div className="section-paragraph-full">
                  <p>
                    In de zomer kun je op dezelfde manier energie besparen door
                    de thermostaat een graadje hoger te zetten. Probeer de
                    temperatuur binnen rond de 25°C te houden, zodat het nog
                    steeds comfortabel is, maar niet te koel. Voor extra
                    verkoeling kun je gebruik maken van ventilatoren of
                    airconditioning, maar wees zuinig met deze apparaten om
                    energieverspilling te voorkomen.
                  </p>
                  <p>
                    Door de thermostaat iets hoger te zetten, voorkom je onnodig
                    energieverbruik en houd je je energierekening onder
                    controle. Het kan even wennen zijn aan een iets hogere
                    binnentemperatuur, maar je lichaam past zich snel aan en je
                    zult het verschil in je portemonnee waarderen.
                  </p>
                </div>

                {/* Conclusie */}
                <h2 style={subheadingStyles}>Conclusie</h2>
                <div className="section-paragraph-full">
                  <p>
                    Door bewust om te gaan met de thermostaat en deze een
                    graadje lager in de winter en een graadje hoger in de zomer
                    te zetten, kun je aanzienlijk besparen op je
                    energieverbruik. Dit heeft niet alleen een positief effect
                    op je energierekening, maar ook op het milieu.
                    Energiebesparing is een belangrijke stap in de richting van
                    een duurzamere en milieuvriendelijkere levensstijl.
                  </p>
                  <p>
                    Probeer daarnaast ook andere energiebesparende maatregelen
                    te nemen, zoals het goed isoleren van je huis, het
                    uitschakelen van apparaten wanneer ze niet worden gebruikt
                    en het gebruik van energiezuinige verlichting. Samen kunnen
                    we bijdragen aan een groenere toekomst en tegelijkertijd
                    onze eigen kosten verlagen.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default ThermostatUseArticle;

import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import "../Articles.css";
import houseisolationImage from "./houseisolation.jpg";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import { getCookie } from "../../../../../cookieUtils";

const HouseIsolationArticle: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const subheadingStyles: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "30px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>
            Waarom is het belangrijk je huis goed te isoleren om warmteverlies
            te voorkomen?
          </PageHeroColored>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <p className="section-paragraph">
                    <b>
                      {" "}
                      Een goed geïsoleerd huis is essentieel voor een
                      comfortabele en energiezuinige leefomgeving. Goede
                      isolatie helpt om warmteverlies te voorkomen, wat niet
                      alleen zorgt voor een aangename temperatuur in huis, maar
                      ook bijdraagt aan lagere energiekosten en een verminderde
                      milieubelasting. In dit artikel zullen we de voordelen van
                      huisisolatie bespreken en waarom het een slimme
                      investering is voor zowel je huis als het milieu.
                    </b>
                  </p>
                  <h2 style={subheadingStyles}>Energiebesparing</h2>
                  <p className="section-paragraph">
                    Een goed geïsoleerd huis zorgt ervoor dat warmte in de
                    winter binnen blijft en in de zomer buiten. Hierdoor hoef je
                    minder te stoken in de winter en blijft het huis koeler in
                    de zomer, zonder dat je extra moet koelen. Dit resulteert in
                    aanzienlijke energiebesparingen en een verlaagde
                    afhankelijkheid van verwarming en koelingssystemen, wat
                    resulteert in lagere energierekeningen en een vermindering
                    van de CO2-uitstoot.
                  </p>
                </div>

                <div className="image-column">
                  <img
                    src={houseisolationImage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
              </div>

              <div className="section-container-full">
                {/* Comfort en gezondheid */}
                <h2 style={subheadingStyles}>Comfort en gezondheid</h2>
                <div className="section-paragraph-full">
                  <p>
                    Een goed geïsoleerd huis zorgt voor een gelijkmatige en
                    comfortabele binnentemperatuur. Geen koude tocht in de
                    winter of oververhitting in de zomer. Dit draagt bij aan een
                    gezonde leefomgeving en vermindert het risico op
                    vochtproblemen en schimmelvorming. Bovendien creëert een
                    goed geïsoleerd huis een stille omgeving doordat het geluid
                    van buiten wordt gedempt.
                  </p>
                </div>

                {/* Milieuvriendelijk */}
                <h2 style={subheadingStyles}>Milieuvriendelijk</h2>
                <div className="section-paragraph-full">
                  <p>
                    Door je huis goed te isoleren, draag je bij aan een
                    duurzamere samenleving. Minder energieverbruik betekent
                    minder uitstoot van broeikasgassen en vermindering van de
                    impact op het milieu. Huisisolatie is een effectieve manier
                    om je ecologische voetafdruk te verkleinen en bij te dragen
                    aan de strijd tegen klimaatverandering.
                  </p>
                </div>

                {/* Conclusie */}
                <h2 style={subheadingStyles}>Conclusie</h2>
                <div className="section-paragraph-full">
                  <p>
                    Het goed isoleren van je huis heeft talloze voordelen. Naast
                    de energiebesparing en kostenverlaging, zorgt huisisolatie
                    voor een comfortabele en gezonde leefomgeving. Daarnaast
                    draag je bij aan een duurzamere toekomst door de
                    CO2-uitstoot te verminderen en het milieu te sparen.
                    Investeer in huisisolatie en pluk de vruchten van een
                    energiezuinig, comfortabel en milieuvriendelijk huis.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default HouseIsolationArticle;

import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import "../Articles.css";
import solarpanelsimage from "./solarpanelscleaning.jpg"; // Replace 'solarpanels.jpg' with the actual image file name and path
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import { getCookie } from "../../../../../cookieUtils";

const SolarPanelsCleaningArticle: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const subheadingStyles: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "30px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>
            Waarom is het belangrijk om zonnepanelen te reinigen?
          </PageHeroColored>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <p className="section-paragraph">
                    <b>
                      {" "}
                      Zonnepanelen zijn een uitstekende investering voor
                      huiseigenaren en bedrijven. Ze bieden talloze voordelen,
                      waaronder het verminderen van uw energierekening, het
                      verminderen van uw afhankelijkheid van het
                      elektriciteitsnet en het verminderen van uw ecologische
                      voetafdruk.
                    </b>
                  </p>
                  <h2 style={subheadingStyles}>
                    Verbeterde efficiëntie door regelmatige reiniging
                  </h2>
                  <p className="section-paragraph">
                    Hoewel zonnepanelen ontworpen zijn om langdurig te
                    functioneren, kunnen ze na verloop van tijd vuil, stof en
                    andere verontreinigingen verzamelen, vooral als ze zich in
                    een gebied bevinden met veel luchtverontreiniging, pollen of
                    vogeluitwerpselen. Deze ophoping kan de efficiëntie van de
                    zonnepanelen verminderen en daardoor de energieopbrengst
                    verlagen.
                    <br></br>
                    <br></br>
                    Door uw zonnepanelen regelmatig te laten reinigen, kunt u de
                    efficiëntie ervan optimaliseren en ervoor zorgen dat ze
                    optimaal blijven presteren. Schone zonnepanelen kunnen meer
                    zonlicht opvangen en omzetten in elektriciteit, wat
                    resulteert in een hogere energieopbrengst en een kortere
                    terugverdientijd van uw investering.
                  </p>
                </div>

                <div className="image-column">
                  <img
                    src={solarpanelsimage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
              </div>

              <div className="section-container-full">
                {/* Prolonging the Lifespan of Solar Panels */}
                <h2 style={subheadingStyles}>
                  Verlenging van de levensduur van zonnepanelen
                </h2>
                <div className="section-paragraph-full">
                  <p>
                    Naast het verbeteren van de efficiëntie, kan regelmatige
                    reiniging ook de levensduur van uw zonnepanelen verlengen.
                    Vervuiling en vuil kunnen na verloop van tijd schade
                    veroorzaken aan de zonnecellen en andere componenten van de
                    panelen. Als deze schade niet tijdig wordt aangepakt, kan
                    dit leiden tot verminderde prestaties en zelfs permanente
                    beschadiging van de panelen.
                  </p>
                  <p>
                    Door uw zonnepanelen schoon te houden, kunt u de kans op
                    schade minimaliseren en de levensduur van de panelen
                    verlengen. Dit zorgt ervoor dat uw investering in
                    zonnepanelen nog vele jaren rendabel blijft.
                  </p>
                </div>

                {/* Safety Considerations */}
                <h2 style={subheadingStyles}>Veiligheidsoverwegingen</h2>
                <div className="section-paragraph-full">
                  <p>
                    Hoewel zonnepanelen over het algemeen veilig zijn om te
                    gebruiken, kan vuil en ophoping van vuil bepaalde risico's
                    met zich meebrengen. Vuile panelen kunnen glad worden als
                    het regent, waardoor het risico op ongelukken toeneemt voor
                    degenen die het dak opklimmen om onderhoud uit te voeren.
                  </p>
                  <p>
                    Het inschakelen van professionele zonnepaneelreinigers zorgt
                    niet alleen voor een grondige reiniging, maar ook voor
                    veiligheid tijdens het proces. Deze professionals hebben de
                    juiste uitrusting en ervaring om het werk veilig en
                    effectief uit te voeren, waardoor het risico op ongevallen
                    wordt verminderd.
                  </p>
                  <button className="buttonstyle">Offerte aanvragen</button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default SolarPanelsCleaningArticle;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EditUserAddressCustomer.css";
import { useParams } from "react-router-dom";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import HouseIcon from "@mui/icons-material/House";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import PageHero from "../../../../components/Pagehero/PageHero";
import Footer from "../../../../components/Footer/Footer";
import { getCookie } from "../../../../cookieUtils";
import { useFormik } from "formik";
import * as Yup from "yup";

const EditUserAddressCustomer = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);
  const { userAddressId } = useParams<{ userAddressId: string }>();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchUserAddressDetails();
  }, []);

  const fetchUserAddressDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/user-addresses/${userAddressId}`,
      );
      const userAddress = response.data;
      formik.setValues({
        name: userAddress.name,
        streetname: userAddress.streetname,
        housenumber: userAddress.housenumber,
        zipcode: userAddress.zipcode,
        city: userAddress.city,
        eane: userAddress.eane,
        eang: userAddress.eang,
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Naam is verplicht"),
    streetname: Yup.string().required("Straatnaam is verplicht"),
    housenumber: Yup.string()
      .matches(/^[0-9]+$/, "Huisnummer mag alleen cijfers bevatten")
      .required("Huisnummer is verplicht"),
    zipcode: Yup.string()
      .matches(/^[0-9]+$/, "Postcode mag alleen cijfers bevatten")
      .required("Postcode is verplicht"),
    city: Yup.string().required("Stad / Gemeente is verplicht"),
    eane: Yup.string()
      .matches(
        /^54[0-9]{16}$/,
        "EAN Elektriciteit moet 18 cijfers bevatten en starten met 54",
      )
      .required("EAN Elektriciteit is verplicht"),
    eang: Yup.string()
      .matches(
        /^54[0-9]{16}$/,
        "EAN Gas moet 18 cijfers bevatten en starten met 54",
      )
      .required("EAN Gas is verplicht"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      streetname: "",
      housenumber: "",
      zipcode: "",
      city: "",
      eane: "54",
      eang: "54",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await axios.put(
          `${process.env.REACT_APP_DB_URL}/user-addresses/${userAddressId}`,
          values,
        );
        navigate("/MyProfile");
      } catch (error) {
        console.error("Error updating user:", error);
      }
    },
  });

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Gebruikers adres aanpassen</PageHero>
          <div style={{ display: "flex", backgroundColor: "#f8f9fa" }}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div
              className="contentContainer"
              style={{
                flex: "1",
                backgroundColor: "#f8f9fa",
                boxShadow: "none",
                padding: "20px",
                transition: "all 0.3s",
              }}
            >
              <div className="userCardEditAddressUser">
                <form
                  onSubmit={formik.handleSubmit}
                  className="userCardContainerEditAddressUser"
                >
                  <div className="userCardColumnEditAddressUser">
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Naam"
                          variant="standard"
                          type="text"
                          id="naam"
                          {...formik.getFieldProps("name")}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Straatnaam"
                          variant="standard"
                          type="text"
                          id="streetname"
                          {...formik.getFieldProps("streetname")}
                          error={
                            formik.touched.streetname &&
                            Boolean(formik.errors.streetname)
                          }
                          helperText={
                            formik.touched.streetname &&
                            formik.errors.streetname
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Huisnummer"
                          variant="standard"
                          type="text"
                          id="housenumber"
                          {...formik.getFieldProps("housenumber")}
                          error={
                            formik.touched.housenumber &&
                            Boolean(formik.errors.housenumber)
                          }
                          helperText={
                            formik.touched.housenumber &&
                            formik.errors.housenumber
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <button className="buttonstyle33" type="submit">
                        Gebruiker aanpassen
                      </button>
                    </div>
                  </div>

                  <div className="userCardColumnEditAddressUser">
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Postcode"
                          variant="standard"
                          type="text"
                          id="zipcode"
                          {...formik.getFieldProps("zipcode")}
                          error={
                            formik.touched.zipcode &&
                            Boolean(formik.errors.zipcode)
                          }
                          helperText={
                            formik.touched.zipcode && formik.errors.zipcode
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Stad / Gemeente"
                          variant="standard"
                          type="text"
                          id="city"
                          {...formik.getFieldProps("city")}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <ElectricBoltIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="EAN Elektriciteit"
                          variant="standard"
                          type="text"
                          id="eane"
                          {...formik.getFieldProps("eane")}
                          error={
                            formik.touched.eane && Boolean(formik.errors.eane)
                          }
                          helperText={formik.touched.eane && formik.errors.eane}
                          fullWidth
                        />
                      </Box>
                    </div>
                    {formik.touched.eane && formik.errors.eane && (
                      <span style={{ color: "red" }}>{formik.errors.eane}</span>
                    )}
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <GasMeterIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="EAN Gas"
                          variant="standard"
                          type="text"
                          id="eang"
                          {...formik.getFieldProps("eang")}
                          error={
                            formik.touched.eang && Boolean(formik.errors.eang)
                          }
                          helperText={formik.touched.eang && formik.errors.eang}
                          fullWidth
                        />
                      </Box>
                    </div>
                    {formik.touched.eang && formik.errors.eang && (
                      <span style={{ color: "red" }}>{formik.errors.eang}</span>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default EditUserAddressCustomer;

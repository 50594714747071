import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IoPersonSharp,
  IoBatteryFull,
  IoFileTrayStacked,
  IoGrid,
  IoExit,
  IoIdCard,
  IoClose,
  IoChevronDown,
  IoChevronUp,
} from "react-icons/io5";
import "./NavigationLeft.css";
import {
  MdCleaningServices,
  MdWbTwilight,
  MdEngineering,
} from "react-icons/md";
import { eraseCookie } from "../../../cookieUtils";

interface NavigationLeftProps {
  isMenuVisible: boolean;
  toggleMenu: () => void;
}

const NavigationLeft: React.FC<NavigationLeftProps> = ({
  isMenuVisible,
  toggleMenu,
}) => {
  const navigationStyles: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: isMenuVisible ? 0 : "-110%", // Hide the navigation by default
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.99)", // Black background with transparency
    display: "flex",
    justifyContent: "center", // Center items horizontally
    alignItems: "center", // Center items vertically
    zIndex: 1000, // Adjust z-index as needed
    transition: "all 0.3s",
  };

  const menuItemStyles: React.CSSProperties = {
    marginBottom: "20px", // Add spacing between menu items
    padding: "10px",
    cursor: "pointer",
    transition: "color 0.3s",
    color: "white", // Set text color to white
  };

  const arrowStyles: React.CSSProperties = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
  };

  const closeIconStyles: React.CSSProperties = {
    position: "fixed",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    zIndex: 1001, // Make sure it's above the navigation
    color: "white", // Set the icon color to white
  };

  const navigate = useNavigate();

  const closeMenu = () => {
    toggleMenu(); // Close the menu
  };

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const toggleSubmenu = () => {
    setIsSubmenuOpen((prevState: any) => !prevState);
  };

  const navigateAndCloseMenu = (path: string) => {
    navigate(path);
    closeMenu(); // Close the menu after navigation
  };

  const logoutAndCloseMenu = () => {
    // Perform logout actions (e.g., remove items from local storage)
    eraseCookie("user");
    eraseCookie("roleId");

    closeMenu(); // Close the menu after logout actions are completed
    navigate("/Login"); // Navigate to the login page
  };

  const handleMenuItemHover = (event: React.MouseEvent<HTMLDivElement>) => {
    const menuItem = event.target as HTMLDivElement;
    menuItem.style.backgroundColor = "#000000";
    menuItem.style.color = "#8dc63f";
    const icons = Array.from(
      menuItem.getElementsByTagName("span"),
    ) as HTMLSpanElement[];
    icons.forEach((icon) => {
      icon.style.color = "#8dc63f"; // Change icon color to green
    });
  };

  const handleMenuItemLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    // const menuItem = event.target as HTMLDivElement;
    // menuItem.style.backgroundColor = '';
    const menuItem = event.target as HTMLDivElement;
    menuItem.style.color = "white"; // Reset text color to white
    const icons = Array.from(
      menuItem.getElementsByTagName("span"),
    ) as HTMLSpanElement[];
    icons.forEach((icon) => {
      icon.style.color = "white"; // Reset icon color to white
    });
  };

  return (
    <div className="navigation" style={navigationStyles}>
      {isMenuVisible && (
        <div style={closeIconStyles} onClick={closeMenu}>
          <IoClose size={24} />
        </div>
      )}
      <div style={{ textAlign: "center", width: "100%" }}>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/IndexAdministrator")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoGrid />
          </span>{" "}
          Dashboard
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/Users")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoPersonSharp />
          </span>{" "}
          Gebruikers
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/marketPrice")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoBatteryFull />
          </span>{" "}
          Marktprijs aanpassen
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/EnergySuppliers")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoBatteryFull />
          </span>{" "}
          Energieleveranciers
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/Contracts")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoFileTrayStacked />
          </span>
          Energiecontracten
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/ChangeEnergyContract")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoIdCard />
          </span>
          Contract aanvragen
        </div>

        <div style={{ textAlign: "center", width: "100%" }}>
          <div
            className="menuItem"
            style={menuItemStyles}
            onClick={toggleSubmenu}
          >
            {/* <span style={{ marginRight: '5px' }}><MdCleaningServices /></span>Offerte Aanvragen
          <div style={arrowStyles}>
            {isSubmenuOpen ? <IoChevronUp /> : <IoChevronDown />}
          </div> */}
            <span style={{ marginRight: "5px" }}>
              {isSubmenuOpen ? <IoChevronUp /> : <IoChevronDown />}
            </span>
            Offerte Aanvragen
            <div style={arrowStyles}>
              {isSubmenuOpen ? <IoClose /> : <IoGrid />}
            </div>
            {isSubmenuOpen && (
              <>
                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() =>
                    navigateAndCloseMenu("/SolarPanelsApplication")
                  }
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdCleaningServices />
                  </span>
                  Zonnepanelen en thuisbatterij aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() =>
                    navigateAndCloseMenu("/SolarPanelCleaningApplication")
                  }
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdWbTwilight />
                  </span>
                  Zonnepanelen kuisen aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() =>
                    navigateAndCloseMenu("/BoilermaintenanceApplication")
                  }
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdEngineering />
                  </span>
                  ketelonderhoud aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() => navigateAndCloseMenu("/IsolationApplication")}
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdEngineering />
                  </span>
                  Isolatie aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() =>
                    navigateAndCloseMenu("/ChargingStationApplication")
                  }
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdEngineering />
                  </span>
                  Laadpaal aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() => navigateAndCloseMenu("/HeatPumpApplication")}
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdEngineering />
                  </span>
                  Warmtepomp aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() =>
                    navigateAndCloseMenu("/HeatPumpBoilerApplication")
                  }
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdEngineering />
                  </span>
                  Warmtepomp Boiler aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() => navigateAndCloseMenu("/AircoApplication")}
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdEngineering />
                  </span>
                  Airco aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() => navigateAndCloseMenu("/EmsApplication")}
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdEngineering />
                  </span>
                  EMS aanvragen
                </div>

                <div
                  className="menuItem"
                  style={menuItemStyles}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemLeave}
                  onClick={() =>
                    navigateAndCloseMenu("/InfraredPanelApplication")
                  }
                >
                  <span style={{ marginRight: "5px" }}>
                    <MdEngineering />
                  </span>
                  Infrarood panelen aanvragen
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={logoutAndCloseMenu}
        >
          <span style={{ marginRight: "5px" }}>
            <IoExit />
          </span>
          Uitloggen
        </div>
      </div>
    </div>
  );
};

export default NavigationLeft;

import React, { useState } from "react";
import "./PasswordForgotten.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import YouTube from "react-youtube";

const PasswordForgotten: React.FC = () => {
  const { handleSubmit, register, setError } = useForm();
  const [isEmailFocused, setEmailFocused] = useState(false);
  const navigate = useNavigate();

  const handlePasswordForgotten = async (data: any) => {
    try {
      // Make a POST request to your API endpoint
      await axios.post(`${process.env.REACT_APP_DB_URL}/passwordrequest`, data);

      // Redirect the user to the confirmation page
      navigate("/PasswordForgottenConfirm");
    } catch (error) {
      console.error(error);
      // Handle error and display error message
      setError("email", {
        type: "manual",
        message: "Failed to request password reset.",
      });
    }
  };

  const handleEmailFocus = () => {
    setEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setEmailFocused(true);
  };

  const opts = {
    playerVars: {
      autoplay: 1, // Autoplay the video
      controls: 0, // Hide the player controls
      modestbranding: 1, // Hide the YouTube logo
      loop: 1, // Play the video on repeat
      playlist: "wziCr2xFXX0", // Playlist to ensure the loop works
    },
  };

  return (
    <div className="login-page">
      {/* <div className="login-image"></div> */}
      <YouTube videoId="wziCr2xFXX0" className="login-video" opts={opts} />

      <div className="login-form">
        <form
          className="login-form"
          onSubmit={handleSubmit(handlePasswordForgotten)}
        >
          <h2 className="title">Wachtwoord vergeten</h2>
          <p>
            Vul hier in het onderstaande veld uw e-mail adres in om een nieuw
            wachtwoord aan te vragen.
          </p>
          <div className={`form-group ${isEmailFocused ? "focused" : ""}`}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                label="E-mail adres"
                variant="standard"
                {...register("email", { required: true })}
                onFocus={handleEmailFocus}
                onBlur={handleEmailBlur}
              />
            </Box>
          </div>
          <div className="link-container">
            <Link to="/Login" className="custom-link1">
              Inloggen
            </Link>
            <Link to="/Register" className="custom-link2">
              Registreren{" "}
            </Link>
          </div>
          <button className="login-button" type="submit">
            Herstellen
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordForgotten;

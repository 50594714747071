import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoClose } from "react-icons/io5";
import "./ContractDetailsPopup.css";
import { getCookie } from "../../../../../cookieUtils";

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface NetCostsZipCodeElectricity {
  zipcode: string;
  name: string;
  dataManagementPrice: number;
  capacityRatePriceDm: number;
  purchaseRatePriceDm: number;
  purchaseRateNightPriceDm: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  purchaseRateNightPrice: number;
  prosumentPrice: number;
  distributionDayPrice: number;
  ditributionCombiDayPrice: number;
  ditributionCombiNightPrice: number;
  distributionNightPrice: number;
  transportCostPrice: number;
  DNB: number;
  taxNonResidential: number;
  taxAccijns: number;
  contributionEnergy: number;
  connectionFee: number;
}

interface ContractDetailsProps {
  contractId: string;
  onClose: () => void;
}

const ContractDetailsPopup: React.FC<ContractDetailsProps> = ({
  contractId,
  onClose,
}) => {
  const [contractDetails, setContractDetails] =
    useState<ContractElectricityDetails | null>(null);
  const [netCostsZipCodeElectricity, setNetCostsZipCodeElectricity] =
    useState<NetCostsZipCodeElectricity | null>(null);
  const zipcode = getCookie("postcode");
  const excludedVerbruikValue = getCookie("verbruikValue");
  const isCheckedAlt = getCookie("isCheckedAlt");
  const excludedNettoAfnameDag = getCookie("nettoAfnameDag");
  const excludedNettoAfnameWeekend = getCookie("nettoAfnameWeekend");
  const excludedNettoAfnameUitNacht = getCookie("nettoAfnameUitNacht");
  const excludedOmvormersVermogen = getCookie("omvormersVermogen");
  const hasDigitalMeter = getCookie("hasDigitalMeter");
  const hasSolarPanels = getCookie("hasSolarPanels");

  const verbruikValue1 = excludedVerbruikValue
    ? parseFloat(excludedVerbruikValue)
    : 0;
  const nettoAfnameDag = excludedNettoAfnameDag
    ? parseFloat(excludedNettoAfnameDag)
    : 0;
  const nettoAfnameWeekend = excludedNettoAfnameWeekend
    ? parseFloat(excludedNettoAfnameWeekend)
    : 0;
  const nettoAfnameUitNacht = excludedNettoAfnameUitNacht
    ? parseFloat(excludedNettoAfnameUitNacht)
    : 0;
  const omvormersVermogen = excludedOmvormersVermogen
    ? parseFloat(excludedOmvormersVermogen)
    : 0;

  const verbruikValue =
    (verbruikValue1 || 0) +
    (nettoAfnameDag || 0) +
    (nettoAfnameWeekend || 0) +
    (nettoAfnameUitNacht || 0);

  const yearlySubscription = contractDetails?.yearlySubscription || 0;
  const kwhConsumptionPriceTwoDay =
    (contractDetails?.kwhConsumptionPriceTwoDay || 0) * verbruikValue1;
  const kwhConsumptionPriceTwoNight =
    (contractDetails?.kwhConsumptionPriceTwoNight || 0) * nettoAfnameWeekend;
  const kwhConsumptionPriceNight =
    (contractDetails?.kwhConsumptionPriceNight || 0) * nettoAfnameUitNacht;
  const fixedPriceElectricity = contractDetails?.fixedPriceElectricity || 0;
  const hewwkContribution = contractDetails?.hewwkContribution || 0;
  const dataManagementPrice =
    netCostsZipCodeElectricity?.dataManagementPrice || 0;
  const purchaseRatePriceDm =
    (netCostsZipCodeElectricity?.purchaseRatePriceDm || 0) * verbruikValue;
  const purchaseRateNightPriceDm =
    (netCostsZipCodeElectricity?.purchaseRateNightPriceDm || 0) *
    nettoAfnameUitNacht;
  const taxAccijns =
    (netCostsZipCodeElectricity?.taxAccijns || 0) * verbruikValue;
  const contributionEnergy =
    (netCostsZipCodeElectricity?.contributionEnergy || 0) * verbruikValue;
  const capacityRatePrice = netCostsZipCodeElectricity?.capacityRatePrice || 0;
  const distributionDayPrice =
    (netCostsZipCodeElectricity?.distributionDayPrice || 0) * verbruikValue1;
  const distributionNightPrice =
    (netCostsZipCodeElectricity?.distributionNightPrice || 0) *
    nettoAfnameUitNacht;
  const ditributionCombiDayPrice =
    (netCostsZipCodeElectricity?.ditributionCombiDayPrice || 0) *
    nettoAfnameDag;
  const ditributionCombiNightPrice =
    (netCostsZipCodeElectricity?.ditributionCombiNightPrice || 0) *
    nettoAfnameWeekend;
  const transportCostPrice =
    (netCostsZipCodeElectricity?.transportCostPrice || 0) * verbruikValue;
  const DNB = netCostsZipCodeElectricity?.DNB || 0;
  const prosumentPrice =
    (netCostsZipCodeElectricity?.prosumentPrice || 0) * verbruikValue;

  const totalValue = (
    yearlySubscription +
    kwhConsumptionPriceTwoDay +
    kwhConsumptionPriceTwoNight +
    kwhConsumptionPriceNight +
    fixedPriceElectricity +
    hewwkContribution +
    dataManagementPrice +
    purchaseRatePriceDm +
    purchaseRateNightPriceDm +
    taxAccijns +
    contributionEnergy +
    capacityRatePrice +
    distributionDayPrice +
    distributionNightPrice +
    ditributionCombiDayPrice +
    ditributionCombiNightPrice +
    transportCostPrice +
    DNB +
    prosumentPrice
  ).toFixed(2);

  useEffect(() => {
    fetchContractDetails();
    fetchNetCostsZipCodeElectricity();
  }, [contractId]);

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/ContractElectricityDetails/${contractId}`,
      );
      setContractDetails(response.data);
    } catch (error) {
      console.error("Error retrieving contract details:", error);
      // Handle error state or display an error message
    }
  };

  const fetchNetCostsZipCodeElectricity = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCosts/${zipcode}`,
      );
      setNetCostsZipCodeElectricity(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
      // Handle error state or display an error message
    }
  };

  if (!contractDetails) {
    // Display a loading state while fetching the contract details
    return <div>Loading...</div>;
  }

  return (
    <div className="popupContainer">
      {/* Display the contract details */}
      <div className="popupOverlay" onClick={onClose}></div>
      <div className="popupContent">
        <TableContainer sx={{ border: "none" }}>
          {/* <TableContainer component={Paper} sx={{border: 'none'}}> */}
          {/* <Table sx={{ minWidth: 650 }} aria-label="simple table"> */}
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>ContractDetails</h2>
                </TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell align="right">
                  <IoClose size={20} onClick={onClose} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="right">
                  <b>Eenheidsprijs</b>
                </TableCell>
                <TableCell align="right">
                  <b>Prijs</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows.map((row) => ( */}
              <TableRow
                key="energieprijs"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Energieprijs</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow
                key="Jaarlijks abonnement"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Jaarlijks abonnement
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {contractDetails.yearlySubscription.toFixed(2)} €
                </TableCell>
              </TableRow>
              {isCheckedAlt === "isChecked1Alt" && (
                <TableRow
                  key="kWh verbruikskost"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    kWh verbruikskost
                  </TableCell>
                  <TableCell align="right">
                    {contractDetails.kwhConsumptionPrice} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {verbruikValue !== null
                      ? (
                          verbruikValue1 * contractDetails.kwhConsumptionPrice
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}
              {isCheckedAlt === "isChecked3Alt" && (
                <TableRow
                  key="kWh verbruikskost"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    kWh verbruikskost
                  </TableCell>
                  <TableCell align="right">
                    {contractDetails.kwhConsumptionPrice} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {verbruikValue !== null
                      ? (
                          verbruikValue1 * contractDetails.kwhConsumptionPrice
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {/* <TableRow
              key="kWh korting op kWh-prijs*"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                kWh korting op kWh-prijs*
              </TableCell>
              <TableCell align="right"> - {contractDetails.kwhConsumptionPriceDiscount}</TableCell>
              <TableCell align="right">- {verbruikValue !== null
      ? verbruikValue * contractDetails.kwhConsumptionPriceDiscount
      : 0}</TableCell>
            </TableRow> */}

              {isCheckedAlt === "isChecked2Alt" && (
                <TableRow
                  key="Tweevoudig tarief (dag)"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tweevoudig tarief (dag)
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    + {contractDetails.kwhConsumptionPriceTwoDay} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameDag !== null
                      ? (
                          nettoAfnameDag *
                          contractDetails.kwhConsumptionPriceTwoDay
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked4Alt" && (
                <TableRow
                  key="Tweevoudig tarief (dag)"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tweevoudig tarief (dag)
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    + {contractDetails.kwhConsumptionPriceTwoDay} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameDag !== null
                      ? (
                          nettoAfnameDag *
                          contractDetails.kwhConsumptionPriceTwoDay
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked2Alt" && (
                <TableRow
                  key="Tweevoudig tarief (nacht)"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tweevoudig tarief (nacht)
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    + {contractDetails.kwhConsumptionPriceTwoNight} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameWeekend !== null
                      ? (
                          nettoAfnameWeekend *
                          contractDetails.kwhConsumptionPriceTwoNight
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked4Alt" && (
                <TableRow
                  key="Tweevoudig tarief (nacht)"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tweevoudig tarief (nacht)
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    + {contractDetails.kwhConsumptionPriceTwoNight} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameWeekend !== null
                      ? (
                          nettoAfnameWeekend *
                          contractDetails.kwhConsumptionPriceTwoNight
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked4Alt" && (
                <TableRow
                  key="Exclusief nacht tarief"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Exclusief nacht tarief
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    + {contractDetails.kwhConsumptionPriceNight} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameUitNacht !== null
                      ? (
                          nettoAfnameUitNacht *
                          contractDetails.kwhConsumptionPriceNight
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}
              {/* <TableRow
              key="Vaste vergoeding (Jaarlijks)"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Vaste vergoeding (Jaarlijks)
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">+ {contractDetails.fixedPriceElectricity.toFixed(2)} €</TableCell>
            </TableRow> */}

              {isCheckedAlt === "isChecked3Alt" && (
                <TableRow
                  key="Exclusiefnachtafnametarief"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Exclusief nacht tarief
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    + {contractDetails.kwhConsumptionPriceNight} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameUitNacht !== null
                      ? (
                          nettoAfnameUitNacht *
                          contractDetails.kwhConsumptionPriceNight
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              <TableRow
                key="Bijdrage hernieuwbare energie + wwk"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Bijdrage "hernieuwbare energie + wwk"
                </TableCell>
                <TableCell align="right">
                  {contractDetails.hewwkContribution} €/kWh
                </TableCell>
                <TableCell align="right">
                  +{" "}
                  {verbruikValue !== null
                    ? (
                        verbruikValue * contractDetails.hewwkContribution
                      ).toFixed(2)
                    : 0}{" "}
                  €
                </TableCell>
              </TableRow>

              <TableRow
                key="Nettarieven"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Nettarieven</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {/* {netCostsZipCodeElectricity && netCostsZipCodeElectricity.dataManagementPrice !== 0 && hasDigitalMeter === 'Nee' && ( */}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.dataManagementPrice !== 0 && (
                  <TableRow
                    key="Tarief Databeheer"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Tarief Databeheer
                    </TableCell>
                    <TableCell align="right"> </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.dataManagementPrice.toFixed(
                        2,
                      )}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePriceDm !== 0 &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Capaciteitstarief Dm"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}€
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePriceDm !== 0 &&
                hasDigitalMeter === "Ja" &&
                isCheckedAlt != "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief Dm "
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePriceDm} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.purchaseRatePriceDm
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePriceDm !== 0 &&
                hasDigitalMeter === "Ja" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief Dm "
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePriceDm} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue1 !== null
                        ? (
                            verbruikValue1 *
                            netCostsZipCodeElectricity.purchaseRatePriceDm
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRateNightPriceDm !== 0 &&
                hasDigitalMeter === "Ja" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief excl nacht Dm"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief excl. nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRateNightPriceDm}{" "}
                      €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameUitNacht !== null
                        ? (
                            nettoAfnameUitNacht *
                            netCostsZipCodeElectricity.purchaseRateNightPriceDm
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRateNightPriceDm !== 0 &&
                hasDigitalMeter === "Ja" &&
                isCheckedAlt === "isChecked4Alt" && (
                  <TableRow
                    key="Afnametarief excl nacht Dm"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief excl. nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRateNightPriceDm}{" "}
                      €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameUitNacht !== null
                        ? (
                            nettoAfnameUitNacht *
                            netCostsZipCodeElectricity.purchaseRateNightPriceDm
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked1Alt" && (
                  <TableRow
                    key="Capaciteitstarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked2Alt" && (
                  <TableRow
                    key="Capaciteitstarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Capaciteitstarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked4Alt" && (
                  <TableRow
                    key="Capaciteitstarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt != "isChecked3Alt" &&
                isCheckedAlt != "isChecked4Alt" && (
                  <TableRow
                    key="Afnametarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.purchaseRatePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue1 !== null
                        ? (
                            verbruikValue1 *
                            netCostsZipCodeElectricity.purchaseRatePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked4Alt" && (
                  <TableRow
                    key="Afnametarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameDag !== null
                        ? (
                            nettoAfnameDag *
                            netCostsZipCodeElectricity.purchaseRatePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRateNightPrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief excl nacht"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief excl. nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRateNightPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameUitNacht !== null
                        ? (
                            nettoAfnameUitNacht *
                            netCostsZipCodeElectricity.purchaseRateNightPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRateNightPrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked4Alt" && (
                  <TableRow
                    key="Afnametarief excl nacht"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief excl. nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRateNightPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameUitNacht !== null
                        ? (
                            nettoAfnameUitNacht *
                            netCostsZipCodeElectricity.purchaseRateNightPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.prosumentPrice !== 0 &&
                hasSolarPanels === "JA" &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Prosumenten tarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Prosumenten tarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.prosumentPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {omvormersVermogen !== null
                        ? (
                            omvormersVermogen *
                            netCostsZipCodeElectricity.prosumentPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.distributionDayPrice !== 0 && (
                  <TableRow
                    key="distributionDayPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributiekosten Dagteller
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.distributionDayPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.distributionDayPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.ditributionCombiDayPrice !== 0 && (
                  <TableRow
                    key="ditributionCombiDayPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributiekosten Dag- en nachtteller (Dag)
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.ditributionCombiDayPrice}{" "}
                      €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.ditributionCombiDayPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.ditributionCombiNightPrice !== 0 && (
                  <TableRow
                    key="ditributionCombiNightPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributiekosten Dag- en nachtteller (Nacht)
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.ditributionCombiNightPrice}{" "}
                      €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.ditributionCombiNightPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.distributionNightPrice !== 0 && (
                  <TableRow
                    key="distributionNightPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributiekosten Exclusief nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.distributionNightPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.distributionNightPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.transportCostPrice !== 0 && (
                  <TableRow
                    key="transportCostPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Transportkosten
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.transportCostPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.transportCostPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.DNB !== 0 && (
                  <TableRow
                    key="DNB"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Tarief databeheer / Meet- en telactiviteit
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.transportCostPrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              <TableRow
                key="Heffingen"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Heffingen</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {/* {netCostsZipCodeElectricity && netCostsZipCodeElectricity.taxNonResidential !== 0 && (
            <TableRow
              key="taxNonResidential"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Bijdrage Energiefonds (laagspanning niet-residentieel)
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">+ {netCostsZipCodeElectricity.taxNonResidential.toFixed(2)} €</TableCell>
            </TableRow>
)} */}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.taxAccijns !== 0 && (
                  <TableRow
                    key="taxAccijns"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Bijzondere accijns
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.taxAccijns} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.taxAccijns
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.contributionEnergy !== 0 && (
                  <TableRow
                    key="contributionEnergy"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Bijdrage op de energie
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.contributionEnergy} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.contributionEnergy
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.connectionFee !== 0 && (
                  <TableRow
                    key="connectionFee"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Aansluitingsvergoeding
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.connectionFee} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.connectionFee
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {/* <TableRow
              key="Totale kost verbruik"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong>Totale kost verbruik</strong>  
              </TableCell>
              <TableCell align="right">{contractDetails.totalConumptionCostPrice}</TableCell>
              <TableCell align="right">+ {verbruikValue !== null
      ? verbruikValue * contractDetails.totalConumptionCostPrice
      : 0}</TableCell>
            </TableRow> */}
              {/* {hasSolarPanels === 'JA' &&(
            <TableRow
              key="kWh injectie zonnepanelen"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              kWh injectie zonnepanelen
              </TableCell>
              <TableCell align="right">{contractDetails.kwhInjectionSolarPanelsDiscountPrice}</TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow>
)} */}
              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked1Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue1 +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked2Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPriceTwoDay *
                          nettoAfnameDag +
                        contractDetails.kwhConsumptionPriceTwoNight *
                          nettoAfnameWeekend +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked3Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue1 +
                        contractDetails.kwhConsumptionPriceNight *
                          nettoAfnameUitNacht +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.purchaseRateNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked4Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPriceTwoDay *
                          nettoAfnameDag +
                        contractDetails.kwhConsumptionPriceTwoNight *
                          nettoAfnameWeekend +
                        contractDetails.kwhConsumptionPriceNight *
                          nettoAfnameUitNacht +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.purchaseRateNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked1Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue1 +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePriceDm *
                          verbruikValue +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked2Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPriceTwoDay *
                          nettoAfnameDag +
                        contractDetails.kwhConsumptionPriceTwoNight *
                          nettoAfnameWeekend +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePriceDm *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked3Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue1 +
                        contractDetails.kwhConsumptionPriceNight *
                          nettoAfnameUitNacht +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePriceDm *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked4Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPriceTwoDay *
                          nettoAfnameDag +
                        contractDetails.kwhConsumptionPriceTwoNight *
                          nettoAfnameWeekend +
                        contractDetails.kwhConsumptionPriceNight *
                          nettoAfnameUitNacht +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePriceDm *
                          verbruikValue +
                        netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked1Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue1 +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePriceDm *
                          verbruikValue +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB +
                        netCostsZipCodeElectricity.capacityRatePrice
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked2Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPriceTwoDay *
                          nettoAfnameDag +
                        contractDetails.kwhConsumptionPriceTwoNight *
                          nettoAfnameWeekend +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePriceDm *
                          verbruikValue +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked3Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue1 +
                        contractDetails.kwhConsumptionPriceNight *
                          nettoAfnameUitNacht +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePriceDm *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked4Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPriceTwoDay *
                          nettoAfnameDag +
                        contractDetails.kwhConsumptionPriceTwoNight *
                          nettoAfnameWeekend +
                        contractDetails.kwhConsumptionPriceNight *
                          nettoAfnameUitNacht +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePriceDm *
                          verbruikValue +
                        netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked1Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue1 +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB +
                        netCostsZipCodeElectricity.prosumentPrice *
                          omvormersVermogen
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked2Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPriceTwoDay *
                          nettoAfnameDag +
                        contractDetails.kwhConsumptionPriceTwoNight *
                          nettoAfnameWeekend +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB +
                        netCostsZipCodeElectricity.prosumentPrice *
                          omvormersVermogen
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked3Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue1 +
                        contractDetails.kwhConsumptionPriceNight *
                          nettoAfnameUitNacht +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.purchaseRateNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB +
                        netCostsZipCodeElectricity.prosumentPrice *
                          omvormersVermogen
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked4Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPriceTwoDay *
                          verbruikValue1 +
                        contractDetails.kwhConsumptionPriceTwoNight *
                          nettoAfnameWeekend +
                        contractDetails.kwhConsumptionPriceNight *
                          nettoAfnameUitNacht +
                        contractDetails.fixedPriceElectricity +
                        contractDetails.hewwkContribution +
                        netCostsZipCodeElectricity.dataManagementPrice +
                        netCostsZipCodeElectricity.purchaseRatePrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.purchaseRateNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                        netCostsZipCodeElectricity.contributionEnergy *
                          verbruikValue +
                        netCostsZipCodeElectricity.capacityRatePrice +
                        netCostsZipCodeElectricity.distributionDayPrice *
                          verbruikValue1 +
                        netCostsZipCodeElectricity.distributionNightPrice *
                          nettoAfnameUitNacht +
                        netCostsZipCodeElectricity.ditributionCombiDayPrice *
                          nettoAfnameDag +
                        netCostsZipCodeElectricity.ditributionCombiNightPrice *
                          nettoAfnameWeekend +
                        netCostsZipCodeElectricity.transportCostPrice *
                          verbruikValue +
                        netCostsZipCodeElectricity.DNB +
                        netCostsZipCodeElectricity.prosumentPrice *
                          verbruikValue
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {/* ))} */}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <button onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};

export default ContractDetailsPopup;

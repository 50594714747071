import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosCreate, IoIosDownload, IoIosTrash } from "react-icons/io";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { colors } from "../../../../theme/colors";
import "./EnergySuppliers.css"; // Import the CSS file for styles
import { dividerClasses } from "@mui/material";
import { IoMenu } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PageHero from "../../../../components/Pagehero/PageHero";
import * as XLSX from "xlsx";
import { getCookie } from "../../../../cookieUtils";

interface EnergySupplier {
  id: number;
  name: string;
  logo: string;
  active: number;
  evaluation: number;
}

const EnergySuppliers = () => {
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [confirmDeleteSupplier, setConfirmDeleteSupplier] =
    useState<EnergySupplier | null>(null);

  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchEnergySuppliers();
  }, []);

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      setEnergySuppliers(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
      // Handle error state or display an error message
    }
  };

  const handleEditSupplier = (supplierId: number) => {
    // Navigate to edit supplier page
    navigate(`/EditEnergySupplier/${supplierId}`);
  };

  const handleDetailSupplier = (energySupplierId: number) => {
    // Navigate to supplier detail page
    navigate(`/DetailEnergySupplier/${energySupplierId}`);
  };

  const handleDeleteSupplier = (supplier: EnergySupplier) => {
    setConfirmDeleteSupplier(supplier);
  };

  const confirmDelete = async () => {
    if (confirmDeleteSupplier) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_DB_URL}/energySuppliers/${confirmDeleteSupplier.id}`,
        );
        fetchEnergySuppliers();
      } catch (error) {
        console.error("Error deleting energy supplier:", error);
        // Handle error state or display an error message
      }
    }
    setConfirmDeleteSupplier(null);
  };

  const cancelDelete = () => {
    setConfirmDeleteSupplier(null);
  };

  const exportToExcel = () => {
    const dataToExport = filteredEnergySuppliers.map((supplier) => ({
      Logo: supplier.logo,
      Naam: supplier.name,
      Actief: supplier.active === 1 ? "Actief" : "Niet Actief",
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "EnergySuppliers");

    XLSX.writeFile(wb, "EnergySuppliers.xlsx");
  };

  const filteredEnergySuppliers = energySuppliers.filter(
    (supplier: EnergySupplier) => {
      const nameMatch = supplier.name
        .toLowerCase()
        .includes(nameFilter.toLowerCase());
      return nameMatch;
    },
  );

  const handleAddSupplier = () => {
    // Navigate to add supplier page
    navigate("/AddEnergySupplier");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    padding: "20px",
    transition: "all 0.3s",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: "0", // Add this style to remove the default top margin
  };

  // Remove the 'backgroundColor' style from the contentContainerStyles

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#272727",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Energieleveranciers</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="filterRow">
                <div className="filterContainer">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AccountCircle
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <TextField
                      label="Filter op Naam: "
                      variant="standard"
                      type="text"
                      id="nameFilter"
                      value={nameFilter}
                      onChange={(e) => setNameFilter(e.target.value)}
                    />
                  </Box>
                </div>
                <button className="addButton" onClick={handleAddSupplier}>
                  Leverancier toevoegen
                </button>
                <button className="buttonstyleR33" onClick={exportToExcel}>
                  {/* <IoIosDownload size={20} /> */}
                  Exporteer naar Excel
                </button>
              </div>
              <hr className="dividerDashed" />
              <div className="recordCount">
                <p>{` ${filteredEnergySuppliers.length} van de ${energySuppliers.length} records zichtbaar`}</p>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Logo</StyledTableCell>
                      <StyledTableCell align="right">Naam</StyledTableCell>
                      <StyledTableCell align="right">Actief?</StyledTableCell>
                      <StyledTableCell align="right">
                        Tevredenheidscore
                      </StyledTableCell>
                      <StyledTableCell align="right">Acties</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredEnergySuppliers.map((energySupplier) => (
                      <StyledTableRow key={energySupplier.id}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          onClick={() =>
                            handleDetailSupplier(energySupplier.id)
                          }
                        >
                          <img
                            src={energySupplier.logo}
                            alt={energySupplier.name}
                            style={{ height: "40px", marginRight: "10px" }}
                            onError={(e) => {
                              // Handle image loading errors
                              console.error("Error loading logo:", e);
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="right"
                          onClick={() =>
                            handleDetailSupplier(energySupplier.id)
                          }
                        >
                          {energySupplier.name}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            handleDetailSupplier(energySupplier.id)
                          }
                          align="right"
                        >
                          {energySupplier.active === 1
                            ? "Actief"
                            : "Niet Actief"}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="right"
                          onClick={() =>
                            handleDetailSupplier(energySupplier.id)
                          }
                        >
                          {energySupplier.evaluation}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IoIosCreate
                            size={20}
                            className="actionIconEdit"
                            onClick={() =>
                              handleEditSupplier(energySupplier.id)
                            }
                          />
                          <IoIosTrash
                            size={20}
                            className="actionIconDelete"
                            onClick={() => handleDeleteSupplier(energySupplier)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* {confirmDeleteSupplier && (
            <div className="confirmationModal">
              <p className="confirmationText">Bent u zeker dat u deze energieleverancier wil verwijderen?</p>
              <div className="confirmationButtons">
                <button className="confirmButton" onClick={confirmDelete}>
                  Delete
                </button>
                <button className="cancelButton" onClick={cancelDelete}>
                  Cancel
                </button>
              </div>
            </div>
          )} */}
              {confirmDeleteSupplier && (
                <div className="confirmationModal">
                  <div className="confirmationContainer">
                    <p className="confirmationText">
                      Bent u zeker dat u deze energieleverancier wil
                      verwijderen?
                    </p>
                    <div className="confirmationButtons">
                      <button className="confirmButton" onClick={confirmDelete}>
                        Delete
                      </button>
                      <button className="cancelButton" onClick={cancelDelete}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default EnergySuppliers;

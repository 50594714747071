import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoClose } from "react-icons/io5";
import "./ContractDetailsPopup.css";
import { getCookie } from "../../../../../cookieUtils";

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  fixedPriceGas: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

interface NetCostsZipCodeGas {
  zipcode: string;
  name: string;
  dataManagementPrice: number;
  distributionSmallFixedPrice: number;
  distributionSmallVariablePrice: number;
  distributionMediumFixedPrice: number;
  distributionMediumVariablePrice: number;
  distributionLargeFixedPrice: number;
  distributionLargeVariablePrice: number;
  transportCostPrice: number;
  taxAccijns: number;
  contributionEnergy: number;
  connectionFee: number;
}

interface ContractDetailsProps {
  contractId: string;
  onClose: () => void;
}

const ContractDetailsPopupGas: React.FC<ContractDetailsProps> = ({
  contractId,
  onClose,
}) => {
  const [contractDetails, setContractDetails] =
    useState<ContractGasDetails | null>(null);
  const [netCostsZipCodeGas, setNetCostsZipCodeGas] =
    useState<NetCostsZipCodeGas | null>(null);
  const consumptionPrice1 = contractDetails?.kwhConsumptionPrice ?? 0;
  const kwhConsumptionPriceDiscount1 =
    contractDetails?.kwhConsumptionPriceDiscount ?? 0;
  const priceAfterDiscount = consumptionPrice1 - kwhConsumptionPriceDiscount1;

  const distributionCostPrice1 = contractDetails?.distributionCostPrice ?? 0;
  const transportCostPrice1 = contractDetails?.transportCostPrice ?? 0;
  const subtotal = distributionCostPrice1 + transportCostPrice1;
  const excludedVerbruikValue = getCookie("verbruikValue");
  const zipcode = getCookie("postcode");

  const verbruikValue = excludedVerbruikValue
    ? parseFloat(excludedVerbruikValue)
    : 0;

  const transportCostPrice = netCostsZipCodeGas?.transportCostPrice ?? 0;
  const dataManagementPrice = netCostsZipCodeGas?.dataManagementPrice ?? 0;
  const contributionEnergy = netCostsZipCodeGas?.contributionEnergy ?? 0;
  const taxAccijns = netCostsZipCodeGas?.taxAccijns ?? 0;
  const connectionFee = netCostsZipCodeGas?.connectionFee ?? 0;

  const distributionSmallFixedPrice =
    netCostsZipCodeGas?.distributionSmallFixedPrice ?? 0;
  const distributionSmallVariablePrice =
    netCostsZipCodeGas?.distributionSmallVariablePrice ?? 0;
  const distributionMediumFixedPrice =
    netCostsZipCodeGas?.distributionMediumFixedPrice ?? 0;
  const distributionMediumVariablePrice =
    netCostsZipCodeGas?.distributionMediumVariablePrice ?? 0;
  const distributionLargeFixedPrice =
    netCostsZipCodeGas?.distributionLargeFixedPrice ?? 0;
  const distributionLargeVariablePrice =
    netCostsZipCodeGas?.distributionLargeVariablePrice ?? 0;

  useEffect(() => {
    fetchContractDetails();
    fetchNetCostsZipCodeGas();
  }, [contractId]);

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/ContractGasDetails/${contractId}`,
      );
      setContractDetails(response.data);
    } catch (error) {
      console.error("Error retrieving contract details:", error);
      // Handle error state or display an error message
    }
  };

  const fetchNetCostsZipCodeGas = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCostsGas/${zipcode}`,
      );
      setNetCostsZipCodeGas(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
      // Handle error state or display an error message
    }
  };

  if (!contractDetails) {
    // Display a loading state while fetching the contract details
    return <div>Loading...</div>;
  }

  return (
    <div className="popupContainer">
      {/* Display the contract details */}
      <div className="popupOverlay" onClick={onClose}></div>
      <div className="popupContent">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>ContractDetails</h2>
                </TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell align="right">
                  <IoClose size={20} onClick={onClose} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="right">Eenheid</TableCell>
                <TableCell align="right">Prijs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows.map((row) => ( */}
              <TableRow
                key="energieprijs"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Energieprijs</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow
                key="Jaarlijks abonnement"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Jaarlijks abonnement
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {contractDetails.yearlySubscription.toFixed(2)} €
                </TableCell>
              </TableRow>

              {/* <TableRow
              key="korting domicilliering of e-factuur"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              korting domicilliëring of e-factuur
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">- {contractDetails.invoiceDiscount} €</TableCell>
            </TableRow> */}

              <TableRow
                key="kWh verbruikskost"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  kWh verbruikskost
                </TableCell>
                <TableCell align="right">
                  {contractDetails.kwhConsumptionPrice} €/kWh
                </TableCell>
                <TableCell align="right">
                  +{" "}
                  {verbruikValue !== null
                    ? (
                        verbruikValue * contractDetails.kwhConsumptionPrice
                      ).toFixed(2)
                    : 0}{" "}
                  €
                </TableCell>
              </TableRow>

              {/* <TableRow
              key="kWh korting op kWh-prijs*"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            > */}
              {/* <TableCell component="th" scope="row">
                kWh korting op kWh-prijs*
              </TableCell>
              <TableCell align="right">{contractDetails.kwhConsumptionPriceDiscount}</TableCell>
              <TableCell align="right">- {verbruikValue !== null
      ? verbruikValue * contractDetails.kwhConsumptionPriceDiscount
      : 0} €</TableCell>
            </TableRow>

            <TableRow
              key="Prijs na korting"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Prijs na korting
              </TableCell>
              <TableCell align="right">{priceAfterDiscount}</TableCell>
              <TableCell align="right">+ {verbruikValue !== null
      ? verbruikValue * contractDetails.kwhConsumptionPriceAfterDiscount
      : 0} €</TableCell>
            </TableRow> */}
              {/* <TableRow
              key="fixedPriceGas"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Vaste vergoeding (Jaarlijks)
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">+ {contractDetails.fixedPriceGas.toFixed(2)
      } €</TableCell>
            </TableRow> */}

              <TableRow
                key="Nettarieven"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Nettarieven</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionSmallFixedPrice !== 0 &&
                verbruikValue < 5000 && (
                  <TableRow
                    key="distributionSmallFixedPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost vast
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionSmallFixedPrice} €
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeGas.distributionSmallFixedPrice.toFixed(
                        2,
                      )}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionSmallVariablePrice !== 0 &&
                verbruikValue < 5000 && (
                  <TableRow
                    key="distributionSmallVariablePrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost variabel
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionSmallVariablePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeGas.distributionSmallVariablePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionMediumFixedPrice !== 0 &&
                verbruikValue > 5000 &&
                verbruikValue < 150000 && (
                  <TableRow
                    key="distributionMediumFixedPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost vast
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionMediumFixedPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeGas.distributionMediumFixedPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionMediumVariablePrice !== 0 &&
                verbruikValue > 5000 &&
                verbruikValue < 150000 && (
                  <TableRow
                    key="distributionMediumVariablePrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost variabel
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionMediumVariablePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeGas.distributionMediumVariablePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionLargeFixedPrice !== 0 &&
                verbruikValue > 150000 && (
                  <TableRow
                    key="distributionLargeFixedPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost vast
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionLargeFixedPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeGas.distributionLargeFixedPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionLargeVariablePrice !== 0 &&
                verbruikValue > 150000 && (
                  <TableRow
                    key="distributionLargeVariablePrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost variabel
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionLargeVariablePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeGas.distributionLargeVariablePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionLargeVariablePrice !== 0 && (
                  <TableRow
                    key="Transportkosten"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Transportkosten
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.transportCostPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeGas.transportCostPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeGas &&
                netCostsZipCodeGas.dataManagementPrice !== 0 && (
                  <TableRow
                    key="dataManagementPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Tarief databeheer / Meet- en telactiviteit
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      + {netCostsZipCodeGas.dataManagementPrice.toFixed(2)} €
                    </TableCell>
                  </TableRow>
                )}

              {/* <TableRow
              key="Subtotaal"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Subtotaal
              </TableCell>
              <TableCell align="right"> </TableCell>
              <TableCell align="right"> {subtotal} €</TableCell>
            </TableRow> */}

              <TableRow
                key="Heffingen"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Heffingen</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
              {netCostsZipCodeGas &&
                netCostsZipCodeGas.contributionEnergy !== 0 && (
                  <TableRow
                    key="contributionEnergy"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Bijdrage op de energie
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.contributionEnergy} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeGas.contributionEnergy
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeGas && netCostsZipCodeGas.taxAccijns !== 0 && (
                <TableRow
                  key="taxAccijns"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bijzondere accijns
                  </TableCell>
                  <TableCell align="right">
                    {netCostsZipCodeGas.taxAccijns} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {verbruikValue !== null
                      ? (verbruikValue * netCostsZipCodeGas.taxAccijns).toFixed(
                          2,
                        )
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}
              {netCostsZipCodeGas && netCostsZipCodeGas.connectionFee !== 0 && (
                <TableRow
                  key="connectionFee"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Aansluitingsvergoeding
                  </TableCell>
                  <TableCell align="right">
                    {netCostsZipCodeGas.connectionFee} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {verbruikValue !== null
                      ? (
                          verbruikValue * netCostsZipCodeGas.connectionFee
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {/* 
            <TableRow
              key="Totale kost verbruik"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong>Totale kost verbruik</strong>  
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow> */}

              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                verbruikValue < 5000 && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    {/* <TableCell align="right">{contractDetails.totalYearlyCost}</TableCell> */}
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue +
                        distributionSmallFixedPrice +
                        verbruikValue * distributionSmallVariablePrice +
                        transportCostPrice * verbruikValue +
                        dataManagementPrice +
                        contributionEnergy * verbruikValue +
                        taxAccijns * verbruikValue +
                        connectionFee * verbruikValue
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}
              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                verbruikValue > 5000 &&
                verbruikValue < 150000 && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    {/* <TableCell align="right">{contractDetails.totalYearlyCost}</TableCell> */}
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue +
                        distributionMediumFixedPrice +
                        verbruikValue * distributionMediumVariablePrice +
                        transportCostPrice * verbruikValue +
                        dataManagementPrice +
                        contributionEnergy * verbruikValue +
                        taxAccijns * verbruikValue +
                        connectionFee * verbruikValue
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}
              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                verbruikValue > 150000 && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost </strong>
                    </TableCell>
                    {/* <TableCell align="right">{contractDetails.totalYearlyCost}</TableCell> */}
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {(
                        contractDetails.yearlySubscription +
                        contractDetails.kwhConsumptionPrice * verbruikValue +
                        distributionLargeFixedPrice +
                        verbruikValue * distributionLargeVariablePrice +
                        transportCostPrice * verbruikValue +
                        dataManagementPrice +
                        contributionEnergy * verbruikValue +
                        taxAccijns * verbruikValue +
                        connectionFee * verbruikValue
                      ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}
              {/* ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ContractDetailsPopupGas;

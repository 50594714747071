import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import "./BoilerMaintenanceApplicationDetail.css";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import { MenuItem, TextField } from "@mui/material";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  password: string;
  roleId: number;
}

interface BoilerMaintenanceRequest {
  id: string;
  userId: string;
  brand: string;
  type: string;
  age: number;
  fuel: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comments: string;
  status: number;
  datetimeCreated: Date;
}

const SolarPanelsApplicationDetail = () => {
  const [user, setUser] = useState<User | null>(null);
  const [solarPanelRequest, setSolarPanelRequest] =
    useState<BoilerMaintenanceRequest | null>(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [status, setStatus] = useState(0);
  const [statusChangeSuccess, setStatusChangeSuccess] = useState(false);
  const [lastChangedBy, setLastChangedBy] = useState<string | null>(null);
  const { id } = useParams<{ id?: string }>(); // Extract the request ID from the URL
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    if (id) {
      fetchDetails(id);
    }
  }, [id]);

  const fetchDetails = async (id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/BoilerMaintenanceRequest/${id}`,
      );
      const solarPanelRequest = response.data;
      const userId = solarPanelRequest.userId;

      const userResponse = await axios.get(
        `${process.env.REACT_APP_DB_URL}/users/${userId}`,
      );
      const userData = userResponse.data;

      setSolarPanelRequest(solarPanelRequest);
      setUser(userData);
    } catch (error) {
      console.error("Error retrieving data:", error);
      // Handle error state or display an error message
    }
  };

  useEffect(() => {
    if (solarPanelRequest) {
      setStatus(solarPanelRequest.status);
      fetchLastChangedBy(solarPanelRequest.id);
    }
  }, [solarPanelRequest]);

  const fetchLastChangedBy = async (requestId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/RequestProcessor/byRequestId/${requestId}`,
      );
      const requestProcessors = response.data;
      setLastChangedBy(
        `${requestProcessors.Surname} ${requestProcessors.Name}`,
      );
    } catch (error) {
      console.error("Error retrieving last changed by:", error);
      setLastChangedBy(null);
    }
  };

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      const newStatus = parseInt(event.target.value);
      setStatus(newStatus);

      if (solarPanelRequest) {
        const updatedSolarPanelRequest = {
          ...solarPanelRequest,
          status: newStatus,
        };
        await axios.put(
          `${process.env.REACT_APP_DB_URL}/BoilerMaintenanceRequest/${id}`,
          updatedSolarPanelRequest,
        );
        setSolarPanelRequest(updatedSolarPanelRequest);

        // Show the success message
        setStatusChangeSuccess(true);
        // Hide the success message after 3 seconds (adjust the time as needed)
        setTimeout(() => {
          setStatusChangeSuccess(false);
        }, 3000);

        const storedUser = getCookie("user");
        const user = storedUser ? JSON.parse(storedUser) : null;

        // Create a new RequestProcessor using the user's data
        await axios.post(`${process.env.REACT_APP_DB_URL}/RequestProcessor`, {
          userId: user.id,
          requestId: solarPanelRequest.id,
          Surname: user.surname,
          Name: user.name,
          Email: user.email,
        });
      }
    } catch (error) {
      console.error("Error updating status:", error);
      // Handle error state or display an error message
    }
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Detailoverzicht aanvraag ketelonderhoud</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        select
                        label="Verander status van aanvraag:"
                        variant="standard"
                        value={status}
                        onChange={handleStatusChange}
                      >
                        <MenuItem key={1} value={1}>
                          Openstaand
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Bewerkt
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Verkocht
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                          Afgewezen
                        </MenuItem>
                      </TextField>
                    </Box>

                    {statusChangeSuccess && (
                      <div className="successMessage">
                        De status is succesvol veranderd.
                      </div>
                    )}
                    {lastChangedBy && (
                      <div className="userCard">
                        {lastChangedBy} heeft de status laatst gewijzigd.
                      </div>
                    )}
                  </div>
                </div>
                <hr className="dividerDashed" />
                <div className="userCardsRow">
                  <div className="userCard">
                    {user && (
                      <div className="userDetailContainer">
                        <h2>Gebruiker gegevens</h2>
                        <div className="label">Voornaam:</div>
                        <div className="text">{user.surname}</div>
                        <div className="label">Achternaam:</div>
                        <div className="text">{user.name}</div>
                        <div className="label">Email:</div>
                        <div className="text">{user.email}</div>
                        <div className="label">Telefoonnummer:</div>
                        <div className="text">{user.phone}</div>
                        <div className="label">Adres:</div>
                        <div className="text">
                          {user.streetname}, {user.housenumber}
                        </div>

                        <div className="label">Postcode:</div>
                        <div className="text">{user.zipcode}</div>
                        <div className="label">Stad:</div>
                        <div className="text">{user.city}</div>
                      </div>
                    )}
                  </div>
                  <div className="userCard">
                    {user && (
                      <div className="userDetailContainer">
                        <h2>Aanvraag gegevens</h2>
                        <div className="label">Merk:</div>
                        <div className="text">{solarPanelRequest?.brand}</div>
                        <div className="label">Type:</div>
                        <div className="text">{solarPanelRequest?.type}</div>
                        <div className="label">Brandstof:</div>
                        <div className="text">
                          {solarPanelRequest?.fuel === 1
                            ? "Mazout"
                            : solarPanelRequest?.fuel === 2
                              ? "Gas"
                              : ""}
                        </div>

                        <div className="label">Leeftijd installatie:</div>
                        <div className="text">
                          {solarPanelRequest?.age} jaar oud
                        </div>

                        <div className="label">Adres:</div>
                        <div className="text">
                          {solarPanelRequest?.streetname},{" "}
                          {solarPanelRequest?.housenumber}{" "}
                        </div>
                        <div className="label">Postcode:</div>
                        <div className="text">{solarPanelRequest?.zipcode}</div>
                        <div className="label">Stad:</div>
                        <div className="text">{solarPanelRequest?.city}</div>
                        <div className="label">Opmerkingen:</div>
                        <div className="text">
                          {solarPanelRequest?.comments}
                        </div>
                        <div className="label">Datum en tijd van aanvraag:</div>
                        <div className="text">
                          {solarPanelRequest?.datetimeCreated.toLocaleString()}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}{" "}
    </>
  );
};

export default SolarPanelsApplicationDetail;

import React from "react";
import "./InfoSquaresIt.css";
import "./../../../theme/styles.css";
import { useNavigate } from "react-router";

const SquareComponentIt: React.FC = () => {
  const navigate = useNavigate();

  const handleContact = () => {
    navigate("/Contact");
  };

  return (
    <div className="squareNoti">
      {/* <PersonIcon style={{ fontSize: '50px', color: '#8dc63f' }} /> */}
      <h4>Een vraag voor IT?</h4>
      <p>
        Aarzel niet en neem contact op door op onderstaande knop te drukken.
      </p>
      <button className="buttonstyle" onClick={handleContact}>
        Contacteren
      </button>
      <div className="greenLine"></div>
    </div>
  );
};

export default SquareComponentIt;

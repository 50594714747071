import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import "./DetailContract.css";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface Contract {
  id: string;
  supplierId: string;
  documentTemplateId: string;
  contractDetailsId: string;
  name: string;
  description: string;
  category: number;
  minimalDurationLength: number;
  commission: number;
  clawback: number;
  vme: number;
}

interface EnergySupplier {
  id: string;
  name: string;
  logo: string;
  active: number;
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

interface ContractCombiDetails {
  id: string;
  contractId: string;
  contractIdElectricity: string;
  contractElectricityDetailsId: string;
  contractIdGas: string;
  contractGasDetailsId: string;
}

interface DocumentTemplate {
  id: string;
  documentPath: string;
  documentName: string;
  status: number;
}

const DetailContract = () => {
  const [contract, setContract] = useState<Contract | null>(null);
  const [contractElectricityDetails, setElectricityDetails] = useState<
    ContractElectricityDetails[] | null
  >(null);
  const [contractCombiDetails, setCombiDetails] = useState<
    ContractCombiDetails[] | null
  >(null);
  const [contractGasDetails, setGasDetails] = useState<
    ContractGasDetails[] | null
  >(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const { contractId } = useParams<{ contractId: string }>(); // Extract the contract ID from the URL
  const navigate = useNavigate();
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [documentTemplates, setDocumentTemplates] =
    useState<DocumentTemplate | null>(null);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchContract();
  }, []);

  const fetchContract = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contracts/${contractId}`,
      );
      const contractData: Contract = response.data;
      setContract(contractData);

      const supplierResponse = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energysuppliers/${contractData.supplierId}`,
      );
      const energySupplier: EnergySupplier = supplierResponse.data;
      setEnergySuppliers([energySupplier]);

      if (
        contractData.category === 1 ||
        contractData.category === 2 ||
        contractData.category === 5 ||
        contractData.category === 10 ||
        contractData.category === 11 ||
        contractData.category === 14
      ) {
        const electricityResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contractElectricityDetails`,
        );
        const electricityContracts: ContractElectricityDetails[] =
          electricityResponse.data;

        const matchingElectricityContract = electricityContracts.find(
          (electricityContract) =>
            electricityContract.contractId === contractId,
        );

        if (matchingElectricityContract) {
          const electricityDetailsResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/contractElectricityDetails/${matchingElectricityContract.id}`,
          );
          const electricityDetails: ContractElectricityDetails =
            electricityDetailsResponse.data;
          setElectricityDetails([electricityDetails]); // Wrap in array
        } else {
          console.log("Error retrieving Electricity contract details");
        }
      } else if (
        contractData.category === 3 ||
        contractData.category === 4 ||
        contractData.category === 6 ||
        contractData.category === 12 ||
        contractData.category === 15
      ) {
        const gasResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contractGasDetails`,
        );
        const gasContracts: ContractGasDetails[] = gasResponse.data;

        const matchingGasContract = gasContracts.find(
          (gasContract) => gasContract.contractId === contractId,
        );

        if (matchingGasContract) {
          const gasDetailsResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/contractGasDetails/${matchingGasContract.id}`,
          );
          const gasDetails: ContractGasDetails = gasDetailsResponse.data;
          setGasDetails([gasDetails]); // Wrap in array
        } else {
          console.log("Error retrieving Gas contract details");
        }
      } else if (
        contractData.category === 7 ||
        contractData.category === 8 ||
        contractData.category === 9 ||
        contractData.category === 16 ||
        contractData.category === 17 ||
        contractData.category === 18
      ) {
        const combiResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contractCombiDetails`,
        );
        const combiContracts: ContractCombiDetails[] = combiResponse.data;

        const matchingCombiContract = combiContracts.find(
          (combiContract) => combiContract.contractId === contractId,
        );

        const matchingElectricityContract =
          matchingCombiContract?.contractElectricityDetailsId;
        const matchingGasContract = matchingCombiContract?.contractGasDetailsId;

        if (matchingElectricityContract) {
          const electricityDetailsResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/contractElectricityDetails/${matchingElectricityContract}`,
          );
          const electricityDetails: ContractElectricityDetails =
            electricityDetailsResponse.data;
          setElectricityDetails([electricityDetails]); // Wrap in array
        } else {
          console.log("Error retrieving Electricity contract details");
        }

        if (matchingGasContract) {
          const gasDetailsResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/contractGasDetails/${matchingGasContract}`,
          );
          const gasDetails: ContractGasDetails = gasDetailsResponse.data;
          setGasDetails([gasDetails]); // Wrap in array
        } else {
          console.log("Error retrieving Gas contract details");
        }
      }
      const responseDocumentTemplate = await axios.get(
        `${process.env.REACT_APP_DB_URL}/documentTemplates/${contractData.documentTemplateId}`,
      );
      const fetchedDocumentTemplate: DocumentTemplate =
        responseDocumentTemplate.data;
      fetchedDocumentTemplate.documentPath = `${process.env.REACT_APP_DB_URL}/${fetchedDocumentTemplate.documentPath}`;

      setDocumentTemplates(fetchedDocumentTemplate);
    } catch (error) {
      console.error("Error retrieving contract:", error);
      // Handle error state or display an error message
    }
  };

  const handleEditContract = (contractId: any) => {
    // Navigate to edit contract page based on the contract category
    if (
      (contract && contract.category === 1) ||
      (contract && contract.category === 2) ||
      (contract && contract.category === 5) ||
      (contract && contract.category === 10) ||
      (contract && contract.category === 11) ||
      (contract && contract.category === 14)
    ) {
      navigate(`/EditContract/${contractId}`);
    } else if (
      (contract && contract.category === 3) ||
      (contract && contract.category === 4) ||
      (contract && contract.category === 6) ||
      (contract && contract.category === 12) ||
      (contract && contract.category === 13) ||
      (contract && contract.category === 15)
    ) {
      navigate(`/EditGasContract/${contractId}`);
    } else if (
      (contract && contract.category === 7) ||
      (contract && contract.category === 8) ||
      (contract && contract.category === 9) ||
      (contract && contract.category === 16) ||
      (contract && contract.category === 17) ||
      (contract && contract.category === 18)
    ) {
      navigate(`/EditCombiContract/${contractId}`);
    } else {
      console.log("Invalid contract category");
    }
  };

  const downloadDocument = (documentPath: string) => {
    window.open(documentPath, "_blank");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#272727",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Contract detail</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    <button
                      className="addButton"
                      onClick={() => handleEditContract(contractId)}
                    >
                      Contract aanpassen
                    </button>
                  </div>
                </div>
                <hr className="dividerDashed" />
                <div className="userCarddc">
                  {contract && (
                    <div className="contractDetailContainerdc">
                      {/* <div className="label">ID:</div>
              <div className="text">{contract.id}</div> */}
                      <div className="label">Naam:</div>
                      <div className="text">{contract.name}</div>
                      <div className="label">Beschrijving:</div>
                      <div className="text">{contract.description}</div>
                      <div className="label">Categorie:</div>
                      <div className="text">
                        {contract.category === 1
                          ? "Elektriciteit Vast"
                          : contract.category === 2
                            ? "Elektriciteit Variabel"
                            : contract.category === 3
                              ? "Gas Vast"
                              : contract.category === 4
                                ? "Gas Variabel"
                                : contract.category === 5
                                  ? "Elektriciteit Dynamisch"
                                  : contract.category === 6
                                    ? "Gas Dynamisch"
                                    : contract.category === 7
                                      ? "Combi Vast"
                                      : contract.category === 8
                                        ? "Combi Variabel"
                                        : contract.category === 9
                                          ? "Combi Dynamisch"
                                          : contract.category === 10
                                            ? "Elektriciteit Vast Professioneel"
                                            : contract.category === 11
                                              ? "Elektriciteit Variabel Professioneel"
                                              : contract.category === 12
                                                ? "Gas Vast Professioneel"
                                                : contract.category === 13
                                                  ? "Gas Variabel Professioneel"
                                                  : contract.category === 14
                                                    ? "Elektriciteit Dynamisch Professioneel"
                                                    : contract.category === 15
                                                      ? "Gas Dynamisch Professioneel"
                                                      : contract.category === 16
                                                        ? "Combi Vast Professioneel"
                                                        : contract.category ===
                                                            17
                                                          ? "Combi Variabel Professioneel"
                                                          : contract.category ===
                                                              18
                                                            ? "Combi Dynamisch Professioneel"
                                                            : "Gas"}{" "}
                      </div>
                      <div className="label">Energieleverancier:</div>
                      <div className="text">
                        {energySuppliers.length > 0
                          ? energySuppliers[0].name
                          : ""}
                      </div>

                      <div className="label">Minimale looptijd:</div>
                      <div className="text">
                        {contract.minimalDurationLength}
                      </div>
                      <div className="label">Commissie:</div>
                      <div className="text">{contract.commission}</div>
                      <div className="label">Clawback:</div>
                      <div className="text">{contract.clawback}</div>
                      <div className="label">VME:</div>
                      <div className="text">{contract.vme}</div>
                    </div>
                  )}
                </div>
                {documentTemplates && (
                  <div>
                    <hr className="dividerDashed" />
                    <h1 className="pageTitle" style={pageTitleStyles}>
                      Document template
                    </h1>
                    <div className="documentGrid">
                      <div key={documentTemplates?.id} className="documentCard">
                        <a
                          href={documentTemplates?.documentPath}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="documentName"
                        >
                          {documentTemplates?.documentName}
                        </a>
                        <div className="documentActions">
                          {/* <button
                    className="deleteButton"
                    onClick={() => deleteDocument(document.id)}
                  >
                    Delete
                  </button> */}
                          <button
                            className="downloadButton"
                            onClick={() =>
                              documentTemplates &&
                              downloadDocument(documentTemplates.documentPath)
                            }
                          >
                            Bekijken
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {contract &&
                  (contract.category === 1 ||
                    contract.category === 5 ||
                    contract.category === 7 ||
                    contract.category === 10 ||
                    contract.category === 14 ||
                    contract.category === 16) &&
                  Array.isArray(contractElectricityDetails) && (
                    <div>
                      <hr className="dividerDashed" />
                      <h1 className="pageTitle" style={pageTitleStyles}>
                        Prijs Details Elektriciteit
                      </h1>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Energieprijs</StyledTableCell>
                              <StyledTableCell align="right">
                                Eenheidsprijs
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {contractElectricityDetails.map(
                              (electricityDetail) => (
                                <React.Fragment key={electricityDetail.id}>
                                  <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                      Jaarlijks abonnement
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {electricityDetail.yearlySubscription} €
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                      kWh verbruikskost
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      + {electricityDetail.kwhConsumptionPrice}{" "}
                                      c€/kWh
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              kWh korting op kWh-prijs*
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              - {electricityDetail.kwhConsumptionPriceDiscount}{" "}
                              c€/kWh
                            </StyledTableCell>
                          </StyledTableRow> */}
                                  <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                      Tweevoudig tarief (dag)
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      +{" "}
                                      {
                                        electricityDetail.kwhConsumptionPriceTwoDay
                                      }{" "}
                                      c€/kWh
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                      Tweevoudig tarief (nacht)
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      +{" "}
                                      {
                                        electricityDetail.kwhConsumptionPriceTwoNight
                                      }{" "}
                                      c€/kWh
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                      Exclusief nacht tarief
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      +{" "}
                                      {
                                        electricityDetail.kwhConsumptionPriceNight
                                      }{" "}
                                      c€/kWh
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                            Vaste vergoeding (Jaarlijks)
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {electricityDetail.fixedPriceElectricity} €
                            </StyledTableCell>
                          </StyledTableRow> */}
                                  {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Bijdrage "hernieuwbare energie + wwk"
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {electricityDetail.hewwkContribution} c€/kWh
                            </StyledTableCell>
                          </StyledTableRow> */}
                                </React.Fragment>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}

                {/* {contract &&
                       (contract.category === 1 || contract.category === 5 || contract.category === 7 || contract.category === 10 || contract.category === 14 || contract.category === 16)  &&
              Array.isArray(contractElectricityDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nettarieven</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((electricityDetail) => (
                        <React.Fragment key={electricityDetail.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Tarief Databeheer
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {electricityDetail.dataManagementPrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Capaciteitstarief
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {electricityDetail.capacityRatePrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Afnametarief
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {electricityDetail.purchaseRatePrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              0.00€
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}
                {/* {contract &&
                       (contract.category === 1 || contract.category === 5 || contract.category === 7 || contract.category === 10 || contract.category === 14 || contract.category === 16)  &&
              Array.isArray(contractElectricityDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Heffingen</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((electricityDetail) => (
                        <React.Fragment key={electricityDetail.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {electricityDetail.taxPrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

                {/* Totale kost verbruik */}
                {/* {contract &&
                       (contract.category === 1 || contract.category === 5 || contract.category === 7 || contract.category === 10 || contract.category === 14 || contract.category === 16)  &&
              Array.isArray(contractElectricityDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Totale kost verbruik</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((electricityDetail) => (
                        <React.Fragment key={electricityDetail.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              kWh injectie zonnepanelen
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {
                                electricityDetail.kwhInjectionSolarPanelsDiscountPrice
                              }{" "}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

                {contract &&
                  (contract.category === 2 ||
                    contract.category === 8 ||
                    contract.category === 11 ||
                    contract.category === 17) &&
                  Array.isArray(contractElectricityDetails) && (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Energieprijs</StyledTableCell>
                            <StyledTableCell align="right">
                              Eenheidsprijs
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contractElectricityDetails.map(
                            (electricityDetail) => (
                              <React.Fragment key={electricityDetail.id}>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Jaarlijks abonnement
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {electricityDetail.yearlySubscription} €
                                  </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    kWh verbruikskost
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    + {electricityDetail.kwhConsumptionPrice}{" "}
                                    c€/kWh
                                  </StyledTableCell>
                                </StyledTableRow>
                                {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              kWh korting op kWh-prijs*
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              - {electricityDetail.kwhConsumptionPriceDiscount}{" "}
                              c€/kWh
                            </StyledTableCell>
                          </StyledTableRow> */}
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Tweevoudig tarief (dag)
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    +{" "}
                                    {
                                      electricityDetail.kwhConsumptionPriceTwoDay
                                    }{" "}
                                    c€/kWh
                                  </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Tweevoudig tarief (nacht)
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    +{" "}
                                    {
                                      electricityDetail.kwhConsumptionPriceTwoNight
                                    }{" "}
                                    c€/kWh
                                  </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Exclusief nacht tarief
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    +{" "}
                                    {electricityDetail.kwhConsumptionPriceNight}{" "}
                                    c€/kWh
                                  </StyledTableCell>
                                </StyledTableRow>
                                {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                            Vaste vergoeding (Jaarlijks)
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {electricityDetail.fixedPriceElectricity} €
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Bijdrage "hernieuwbare energie + wwk"
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {electricityDetail.hewwkContribution} c€/kWh
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Bijdrage "hernieuwbare energie + wwk"
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {electricityDetail.hewwkContribution} c€/kWh
                            </StyledTableCell>
                          </StyledTableRow> */}
                              </React.Fragment>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                {/* {contract &&
              (contract.category === 2 || contract.category === 8 || contract.category === 11 || contract.category === 17) &&
              Array.isArray(contractElectricityDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nettarieven</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((electricityDetail) => (
                        <React.Fragment key={electricityDetail.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Tarief Databeheer
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {electricityDetail.dataManagementPrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Capaciteitstarief
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {electricityDetail.capacityRatePrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Afnametarief
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {electricityDetail.purchaseRatePrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              0.00€
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            {contract &&
              (contract.category === 2 || contract.category === 8 || contract.category === 11 || contract.category === 17) &&
              Array.isArray(contractElectricityDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Heffingen</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((electricityDetail) => (
                        <React.Fragment key={electricityDetail.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {electricityDetail.taxPrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

                {/* Totale kost verbruik */}
                {/* {contract &&
              (contract.category === 2 || contract.category === 8 || contract.category === 11 || contract.category === 17) &&
              Array.isArray(contractElectricityDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Totale kost verbruik</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((electricityDetail) => (
                        <React.Fragment key={electricityDetail.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              kWh injectie zonnepanelen
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {
                                electricityDetail.kwhInjectionSolarPanelsDiscountPrice
                              }{" "}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

                {contract &&
                  (contract.category === 3 ||
                    contract.category === 6 ||
                    contract.category === 7 ||
                    contract.category === 9 ||
                    contract.category === 12 ||
                    contract.category === 15 ||
                    contract.category === 16 ||
                    contract.category === 18) &&
                  Array.isArray(contractGasDetails) && (
                    <div>
                      <hr className="dividerDashed" />
                      <h1 className="pageTitle" style={pageTitleStyles}>
                        Prijs Details Gas
                      </h1>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Energieprijs</StyledTableCell>
                              <StyledTableCell align="right">
                                Eenheidsprijs
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {contractGasDetails.map((gasDetails) => (
                              <React.Fragment key={gasDetails.id}>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Jaarlijks abonnement
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {gasDetails.yearlySubscription} €
                                  </StyledTableCell>
                                </StyledTableRow>

                                {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Korting domiciliëring of e-factuur
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {gasDetails.invoiceDiscount} €
                            </StyledTableCell>
                          </StyledTableRow> */}

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    kWh verbruikskost
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    + {gasDetails.kwhConsumptionPrice} c€/kWh
                                  </StyledTableCell>
                                </StyledTableRow>
                                {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              kWh korting op kWh-prijs*
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              - {gasDetails.kwhConsumptionPriceDiscount} c€/kWh
                            </StyledTableCell>
                          </StyledTableRow> */}
                                {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Prijs na korting
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {gasDetails.kwhConsumptionPriceAfterDiscount}{" "}
                              c€/kWh
                            </StyledTableCell>
                          </StyledTableRow> */}
                                {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Vaste vergoeding (Jaarlijks)
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {gasDetails.fixedPriceGas}{" "}
                              €
                            </StyledTableCell>
                          </StyledTableRow> */}
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}

                {/* {contract &&
              (contract.category === 3 || contract.category === 6 || contract.category === 7 || contract.category === 9 || contract.category === 12 || contract.category === 15 || contract.category === 16 || contract.category ===18) &&
              Array.isArray(contractGasDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nettarieven</StyledTableCell>
                        <StyledTableCell>
                          
                          </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractGasDetails.map((gasDetails) => (
                        <React.Fragment key={gasDetails.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Distributiekosten
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              + {gasDetails.distributionCostPrice} €
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Transportkosten
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              + {gasDetails.transportCostPrice} €
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              {gasDetails.kwhConsumptionPrice +
                                gasDetails.transportCostPrice}{" "}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

            {contract &&
              (contract.category === 3 || contract.category === 6 || contract.category === 7 || contract.category === 9 || contract.category === 12 || contract.category === 15 || contract.category === 16 || contract.category ===18) &&
              Array.isArray(contractGasDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          Heffingen (taks en bijdragen)
                        </StyledTableCell>
                        <StyledTableCell>
                          
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractGasDetails.map((gasDetails) => (
                        <React.Fragment key={gasDetails.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              
                              {gasDetails.taxPrice}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

                {contract &&
                  (contract.category === 4 ||
                    contract.category === 8 ||
                    contract.category === 13 ||
                    contract.category === 17) &&
                  Array.isArray(contractGasDetails) && (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Energieprijs</StyledTableCell>
                            <StyledTableCell align="right">
                              Eenheidsprijs
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contractGasDetails.map((gasDetails) => (
                            <React.Fragment key={gasDetails.id}>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  Jaarlijks abonnement
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {gasDetails.yearlySubscription} €
                                </StyledTableCell>
                              </StyledTableRow>

                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  Korting domiciliëring of e-factuur
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {gasDetails.invoiceDiscount} €
                                </StyledTableCell>
                              </StyledTableRow>

                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  kWh verbruikskost
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  + {gasDetails.kwhConsumptionPrice} c€/kWh
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  kWh korting op kWh-prijs*
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  - {gasDetails.kwhConsumptionPriceDiscount}{" "}
                                  c€/kWh
                                </StyledTableCell>
                              </StyledTableRow>
                              {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Prijs na korting
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {gasDetails.kwhConsumptionPriceAfterDiscount}{" "}
                              c€/kWh
                            </StyledTableCell>
                          </StyledTableRow> */}
                              {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Vaste vergoeding (Jaarlijks)
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {gasDetails.fixedPriceGas}{" "}
                              €
                            </StyledTableCell>
                          </StyledTableRow> */}
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                {/* {contract &&
              (contract.category === 4  || contract.category === 8 || contract.category === 13 || contract.category === 17 ) &&
              Array.isArray(contractGasDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nettarieven</StyledTableCell>
                        <StyledTableCell>
                          
                          </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractGasDetails.map((gasDetails) => (
                        <React.Fragment key={gasDetails.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Distributiekosten
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              + {gasDetails.distributionCostPrice} €
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Transportkosten
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              + {gasDetails.transportCostPrice} €
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              {gasDetails.kwhConsumptionPrice +
                                gasDetails.transportCostPrice}{" "}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

                {/* {contract &&
              (contract.category === 4  || contract.category === 8 || contract.category === 13 || contract.category === 17 ) &&
              Array.isArray(contractGasDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          Heffingen (taks en bijdragen)
                        </StyledTableCell>
                        <StyledTableCell>
                          
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractGasDetails.map((gasDetails) => (
                        <React.Fragment key={gasDetails.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              
                              {gasDetails.taxPrice}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default DetailContract;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoClose } from "react-icons/io5";
import "./ContractDetailsPopup.css";
import { getCookie } from "../../../../../cookieUtils";

interface ContractCombiDetails {
  id: string;
  contractId: string;
  contractIdElectricity: string;
  contractElectricityDetailsId: string;
  contractIdGas: string;
  contractGasDetailsId: string;
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  fixedPriceGas: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  fixedPriceGas: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

interface NetCostsZipCodeGas {
  zipcode: string;
  name: string;
  dataManagementPrice: number;
  distributionSmallFixedPrice: number;
  distributionSmallVariablePrice: number;
  distributionMediumFixedPrice: number;
  distributionMediumVariablePrice: number;
  distributionLargeFixedPrice: number;
  distributionLargeVariablePrice: number;
  transportCostPrice: number;
  taxAccijns: number;
  contributionEnergy: number;
  connectionFee: number;
}

interface NetCostsZipCodeElectricity {
  zipcode: string;
  name: string;
  dataManagementPrice: number;
  capacityRatePriceDm: number;
  purchaseRatePriceDm: number;
  purchaseRateNightPriceDm: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  purchaseRateNightPrice: number;
  prosumentPrice: number;
  distributionDayPrice: number;
  ditributionCombiDayPrice: number;
  ditributionCombiNightPrice: number;
  distributionNightPrice: number;
  transportCostPrice: number;
  DNB: number;
  taxNonResidential: number;
  taxAccijns: number;
  contributionEnergy: number;
  connectionFee: number;
}

interface ContractDetailsProps {
  contractId: string;
  onClose: () => void;
}

const ContractDetailsPopupCombi: React.FC<ContractDetailsProps> = ({
  contractId,
  onClose,
}) => {
  const [contractDetails, setContractDetails] =
    useState<ContractGasDetails | null>(null);
  const [contractElectricityDetails, setContractElectricityDetails] =
    useState<ContractElectricityDetails | null>(null);
  const [netCostsZipCodeElectricity, setNetCostsZipCodeElectricity] =
    useState<NetCostsZipCodeElectricity | null>(null);
  const [netCostsZipCodeGas, setNetCostsZipCodeGas] =
    useState<NetCostsZipCodeGas | null>(null);
  // const [contractGasDetails, setContractGasDetails] = useState<ContractGasDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const excludedVerbruikValue = getCookie("verbruikValue");
  const excludedVerbruikGasValue = getCookie("verbruikGasValue");

  const zipcode = getCookie("postcode");
  const isCheckedAlt = getCookie("isCheckedAlt");
  const excludedNettoAfnameDag = getCookie("nettoAfnameDag");
  const excludedNettoAfnameWeekend = getCookie("nettoAfnameWeekend");
  const excludedNettoAfnameUitNacht = getCookie("nettoAfnameUitNacht");
  const excludedOmvormersVermogen = getCookie("omvormersVermogen");
  const hasDigitalMeter = getCookie("hasDigitalMeter");
  const hasSolarPanels = getCookie("hasSolarPanels");

  const verbruikGasValue = excludedVerbruikGasValue
    ? parseFloat(excludedVerbruikGasValue)
    : 0;
  const verbruikValue1 = excludedVerbruikValue
    ? parseFloat(excludedVerbruikValue)
    : 0;
  const nettoAfnameDag = excludedNettoAfnameDag
    ? parseFloat(excludedNettoAfnameDag)
    : 0;
  const nettoAfnameWeekend = excludedNettoAfnameWeekend
    ? parseFloat(excludedNettoAfnameWeekend)
    : 0;
  const nettoAfnameUitNacht = excludedNettoAfnameUitNacht
    ? parseFloat(excludedNettoAfnameUitNacht)
    : 0;
  const omvormersVermogen = excludedOmvormersVermogen
    ? parseFloat(excludedOmvormersVermogen)
    : 0;

  const verbruikValue =
    (verbruikValue1 || 0) +
    (nettoAfnameDag || 0) +
    (nettoAfnameWeekend || 0) +
    (nettoAfnameUitNacht || 0);

  const transportCostPrice = netCostsZipCodeGas?.transportCostPrice ?? 0;
  const dataManagementPrice = netCostsZipCodeGas?.dataManagementPrice ?? 0;
  const contributionEnergy = netCostsZipCodeGas?.contributionEnergy ?? 0;
  const taxAccijns = netCostsZipCodeGas?.taxAccijns ?? 0;
  const connectionFee = netCostsZipCodeGas?.connectionFee ?? 0;

  const distributionSmallFixedPrice =
    netCostsZipCodeGas?.distributionSmallFixedPrice ?? 0;
  const distributionSmallVariablePrice =
    netCostsZipCodeGas?.distributionSmallVariablePrice ?? 0;
  const distributionMediumFixedPrice =
    netCostsZipCodeGas?.distributionMediumFixedPrice ?? 0;
  const distributionMediumVariablePrice =
    netCostsZipCodeGas?.distributionMediumVariablePrice ?? 0;
  const distributionLargeFixedPrice =
    netCostsZipCodeGas?.distributionLargeFixedPrice ?? 0;
  const distributionLargeVariablePrice =
    netCostsZipCodeGas?.distributionLargeVariablePrice ?? 0;

  // const yearlySubscription = contractElectricityDetails?.yearlySubscription || 0;
  // const kwhConsumptionPriceTwoDay = (contractElectricityDetails?.kwhConsumptionPriceTwoDay || 0) * verbruikValue1;
  // const kwhConsumptionPriceTwoNight = (contractElectricityDetails?.kwhConsumptionPriceTwoNight || 0) * nettoAfnameWeekend;
  // const kwhConsumptionPriceNight = (contractElectricityDetails?.kwhConsumptionPriceNight || 0) * nettoAfnameUitNacht;
  // const fixedPriceElectricity = contractElectricityDetails?.fixedPriceElectricity || 0;
  // const hewwkContribution = contractElectricityDetails?.hewwkContribution || 0;
  // const dataManagementPrice = netCostsZipCodeElectricity?.dataManagementPrice || 0;
  // const purchaseRatePriceDm = (netCostsZipCodeElectricity?.purchaseRatePriceDm || 0) * verbruikValue;
  // const purchaseRateNightPriceDm = (netCostsZipCodeElectricity?.purchaseRateNightPriceDm || 0) * nettoAfnameUitNacht;
  // const taxAccijns = (netCostsZipCodeElectricity?.taxAccijns || 0) * verbruikValue;
  // const contributionEnergy = (netCostsZipCodeElectricity?.contributionEnergy || 0) * verbruikValue;
  // const capacityRatePrice = netCostsZipCodeElectricity?.capacityRatePrice || 0;
  // const distributionDayPrice = (netCostsZipCodeElectricity?.distributionDayPrice || 0) * verbruikValue1;
  // const distributionNightPrice = (netCostsZipCodeElectricity?.distributionNightPrice || 0) * nettoAfnameUitNacht;
  // const ditributionCombiDayPrice = (netCostsZipCodeElectricity?.ditributionCombiDayPrice || 0) * nettoAfnameDag;
  // const ditributionCombiNightPrice = (netCostsZipCodeElectricity?.ditributionCombiNightPrice || 0) * nettoAfnameWeekend;
  // const transportCostPrice = (netCostsZipCodeElectricity?.transportCostPrice || 0) * verbruikValue;
  // const DNB = netCostsZipCodeElectricity?.DNB || 0;
  // const prosumentPrice = (netCostsZipCodeElectricity?.prosumentPrice || 0) * verbruikValue;

  // const totalValue = (
  //   yearlySubscription +
  //   kwhConsumptionPriceTwoDay +
  //   kwhConsumptionPriceTwoNight +
  //   kwhConsumptionPriceNight +
  //   fixedPriceElectricity +
  //   hewwkContribution +
  //   dataManagementPrice +
  //   purchaseRatePriceDm +
  //   purchaseRateNightPriceDm +
  //   taxAccijns +
  //   contributionEnergy +
  //   capacityRatePrice +
  //   distributionDayPrice +
  //   distributionNightPrice +
  //   ditributionCombiDayPrice +
  //   ditributionCombiNightPrice +
  //   transportCostPrice +
  //   DNB +
  //   prosumentPrice
  // ).toFixed(2);

  useEffect(() => {
    fetchContractDetails();
    fetchNetCostsZipCodeElectricity();
    fetchNetCostsZipCodeGas();
    //fetchContractList();
  }, [contractId]);

  const fetchContractDetails = async () => {
    try {
      const response = await axios
        .get(
          `${process.env.REACT_APP_DB_URL}/ContractCombiDetails/${contractId}`,
        )
        .then((response) => {
          const responsedata = response.data;
          axios
            .get(
              `${process.env.REACT_APP_DB_URL}/ContractElectricityDetails/${responsedata.contractElectricityDetailsId}`,
            )
            .then((response) => {
              const electricityData = response.data;
              setContractElectricityDetails(electricityData);
            });

          axios
            .get(
              `${process.env.REACT_APP_DB_URL}/ContractGasDetails/${responsedata.contractGasDetailsId}`,
            )
            .then((response) => {
              const gasData = response.data;
              setContractDetails(gasData);
            });
        });
      // setContractDetails(response.data);
    } catch (error) {
      console.error("Error retrieving contract details:", error);
      // Handle error state or display an error message
    }
  };

  const fetchNetCostsZipCodeElectricity = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCosts/${zipcode}`,
      );
      setNetCostsZipCodeElectricity(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
      // Handle error state or display an error message
    }
  };

  const fetchNetCostsZipCodeGas = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCostsGas/${zipcode}`,
      );
      setNetCostsZipCodeGas(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
      // Handle error state or display an error message
    }
  };

  return (
    <div className="popupContainer">
      {/* Display the contract details */}
      <div className="popupOverlay" onClick={onClose}></div>
      <div className="popupContent">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>ContractDetails</h2>
                </TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell align="right">
                  <IoClose size={20} onClick={onClose} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="right">
                  <b>Eenheidsprijs</b>
                </TableCell>
                <TableCell align="right">
                  <b>Prijs</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="energieprijs"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Energieprijs Electriciteit</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow
                key="Jaarlijks abonnement"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Jaarlijks abonnement
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {contractElectricityDetails &&
                    contractElectricityDetails.yearlySubscription.toFixed(
                      2,
                    )}{" "}
                  €
                </TableCell>
              </TableRow>

              {isCheckedAlt === "isChecked1Alt" && (
                <TableRow
                  key="kWh verbruikskost"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    kWh verbruikskost
                  </TableCell>
                  <TableCell align="right">
                    {contractElectricityDetails &&
                      contractElectricityDetails.kwhConsumptionPrice}{" "}
                    €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {verbruikValue !== null && contractElectricityDetails
                      ? (
                          verbruikValue1 *
                          contractElectricityDetails.kwhConsumptionPrice
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}
              {isCheckedAlt === "isChecked3Alt" && (
                <TableRow
                  key="kWh verbruikskost"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    kWh verbruikskost
                  </TableCell>
                  <TableCell align="right">
                    {contractElectricityDetails &&
                      contractElectricityDetails.kwhConsumptionPrice}{" "}
                    €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {verbruikValue !== null && contractElectricityDetails
                      ? (
                          verbruikValue1 *
                          contractElectricityDetails.kwhConsumptionPrice
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked2Alt" && (
                <TableRow
                  key="Tweevoudig tarief (dag)"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tweevoudig tarief (dag)
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    +{" "}
                    {contractElectricityDetails &&
                      contractElectricityDetails.kwhConsumptionPriceTwoDay}{" "}
                    €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameDag !== null && contractElectricityDetails
                      ? (
                          nettoAfnameDag *
                          contractElectricityDetails.kwhConsumptionPriceTwoDay
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked4Alt" && (
                <TableRow
                  key="Tweevoudig tarief (dag)"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tweevoudig tarief (dag)
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    +{" "}
                    {contractElectricityDetails &&
                      contractElectricityDetails.kwhConsumptionPriceTwoDay}{" "}
                    €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameDag !== null && contractElectricityDetails
                      ? (
                          nettoAfnameDag *
                          contractElectricityDetails.kwhConsumptionPriceTwoDay
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked2Alt" && (
                <TableRow
                  key="Tweevoudig tarief (nacht)"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tweevoudig tarief (nacht)
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    +{" "}
                    {contractElectricityDetails &&
                      contractElectricityDetails.kwhConsumptionPriceTwoNight}{" "}
                    €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameWeekend !== null && contractElectricityDetails
                      ? (
                          nettoAfnameWeekend *
                          contractElectricityDetails.kwhConsumptionPriceTwoNight
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked4Alt" && (
                <TableRow
                  key="Tweevoudig tarief (nacht)"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tweevoudig tarief (nacht)
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    +{" "}
                    {contractElectricityDetails &&
                      contractElectricityDetails.kwhConsumptionPriceTwoNight}{" "}
                    €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameWeekend !== null && contractElectricityDetails
                      ? (
                          nettoAfnameWeekend *
                          contractElectricityDetails.kwhConsumptionPriceTwoNight
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked4Alt" && (
                <TableRow
                  key="Exclusief nacht tarief"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Exclusief nacht tarief
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    +{" "}
                    {contractElectricityDetails &&
                      contractElectricityDetails.kwhConsumptionPriceNight}{" "}
                    €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameUitNacht !== null && contractElectricityDetails
                      ? (
                          nettoAfnameUitNacht *
                          contractElectricityDetails.kwhConsumptionPriceNight
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {isCheckedAlt === "isChecked3Alt" && (
                <TableRow
                  key="Exclusiefnachtafnametarief"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Exclusief nacht tarief
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    +{" "}
                    {contractElectricityDetails &&
                      contractElectricityDetails.kwhConsumptionPriceNight}{" "}
                    €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {nettoAfnameUitNacht !== null && contractElectricityDetails
                      ? (
                          nettoAfnameUitNacht *
                          contractElectricityDetails.kwhConsumptionPriceNight
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {/*    <TableRow
              key="kWh korting op kWh-prijs*"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                kWh korting op kWh-prijs*
              </TableCell>
              <TableCell align="right"> - {contractElectricityDetails?.kwhConsumptionPriceDiscount}</TableCell>
              <TableCell align="right">- {verbruikValue !== null
  ? (verbruikValue * (contractElectricityDetails?.kwhConsumptionPriceDiscount ?? 0))
  : 0} €</TableCell>
            </TableRow>
*/}
              <TableRow
                key="Bijdrage hernieuwbare energie + wwk"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Bijdrage "hernieuwbare energie + wwk"
                </TableCell>
                <TableCell align="right">
                  {contractElectricityDetails?.hewwkContribution}
                </TableCell>
                <TableCell align="right">
                  +{" "}
                  {verbruikValue !== null
                    ? verbruikValue *
                      (contractElectricityDetails?.hewwkContribution ?? 0)
                    : 0}{" "}
                  €
                </TableCell>
              </TableRow>

              <TableRow
                key="Nettarieven"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Nettarieven Electriciteit</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.dataManagementPrice !== 0 && (
                  <TableRow
                    key="Tarief Databeheer"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Tarief Databeheer
                    </TableCell>
                    <TableCell align="right"> </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.dataManagementPrice.toFixed(
                        2,
                      )}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePriceDm !== 0 &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Capaciteitstarief Dm"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}€
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePriceDm !== 0 &&
                hasDigitalMeter === "Ja" &&
                isCheckedAlt != "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief Dm "
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePriceDm} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.purchaseRatePriceDm
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePriceDm !== 0 &&
                hasDigitalMeter === "Ja" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief Dm "
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePriceDm} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue1 !== null
                        ? (
                            verbruikValue1 *
                            netCostsZipCodeElectricity.purchaseRatePriceDm
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRateNightPriceDm !== 0 &&
                hasDigitalMeter === "Ja" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief excl nacht Dm"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief excl. nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRateNightPriceDm}{" "}
                      €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameUitNacht !== null
                        ? (
                            nettoAfnameUitNacht *
                            netCostsZipCodeElectricity.purchaseRateNightPriceDm
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRateNightPriceDm !== 0 &&
                hasDigitalMeter === "Ja" &&
                isCheckedAlt === "isChecked4Alt" && (
                  <TableRow
                    key="Afnametarief excl nacht Dm"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief excl. nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRateNightPriceDm}{" "}
                      €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameUitNacht !== null
                        ? (
                            nettoAfnameUitNacht *
                            netCostsZipCodeElectricity.purchaseRateNightPriceDm
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked1Alt" && (
                  <TableRow
                    key="Capaciteitstarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked2Alt" && (
                  <TableRow
                    key="Capaciteitstarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Capaciteitstarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.capacityRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked4Alt" && (
                  <TableRow
                    key="Capaciteitstarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Capaciteitstarief
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.capacityRatePrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt != "isChecked3Alt" &&
                isCheckedAlt != "isChecked4Alt" && (
                  <TableRow
                    key="Afnametarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.purchaseRatePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue1 !== null
                        ? (
                            verbruikValue1 *
                            netCostsZipCodeElectricity.purchaseRatePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRatePrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked4Alt" && (
                  <TableRow
                    key="Afnametarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRatePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameDag !== null
                        ? (
                            nettoAfnameDag *
                            netCostsZipCodeElectricity.purchaseRatePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRateNightPrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked3Alt" && (
                  <TableRow
                    key="Afnametarief excl nacht"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief excl. nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRateNightPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameUitNacht !== null
                        ? (
                            nettoAfnameUitNacht *
                            netCostsZipCodeElectricity.purchaseRateNightPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.purchaseRateNightPrice !== 0 &&
                hasDigitalMeter === "Nee" &&
                isCheckedAlt === "isChecked4Alt" && (
                  <TableRow
                    key="Afnametarief excl nacht"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Afnametarief excl. nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.purchaseRateNightPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {nettoAfnameUitNacht !== null
                        ? (
                            nettoAfnameUitNacht *
                            netCostsZipCodeElectricity.purchaseRateNightPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.prosumentPrice !== 0 &&
                hasSolarPanels === "JA" &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Prosumenten tarief"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Prosumenten tarief
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.prosumentPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {omvormersVermogen !== null
                        ? (
                            omvormersVermogen *
                            netCostsZipCodeElectricity.prosumentPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.distributionDayPrice !== 0 && (
                  <TableRow
                    key="distributionDayPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributiekosten Dagteller
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.distributionDayPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.distributionDayPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.ditributionCombiDayPrice !== 0 && (
                  <TableRow
                    key="ditributionCombiDayPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributiekosten Dag- en nachtteller (Dag)
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.ditributionCombiDayPrice}{" "}
                      €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.ditributionCombiDayPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.ditributionCombiNightPrice !== 0 && (
                  <TableRow
                    key="ditributionCombiNightPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributiekosten Dag- en nachtteller (Nacht)
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.ditributionCombiNightPrice}{" "}
                      €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.ditributionCombiNightPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.distributionNightPrice !== 0 && (
                  <TableRow
                    key="distributionNightPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributiekosten Exclusief nacht
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.distributionNightPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.distributionNightPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.transportCostPrice !== 0 && (
                  <TableRow
                    key="transportCostPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Transportkosten
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.transportCostPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.transportCostPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.DNB !== 0 && (
                  <TableRow
                    key="DNB"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Tarief databeheer / Meet- en telactiviteit
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeElectricity.transportCostPrice.toFixed(2)}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              <TableRow
                key="Heffingen Elektriciteit"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Heffingen Electriciteit</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.taxAccijns !== 0 && (
                  <TableRow
                    key="taxAccijns"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Bijzondere accijns
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.taxAccijns} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.taxAccijns
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.contributionEnergy !== 0 && (
                  <TableRow
                    key="contributionEnergy"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Bijdrage op de energie
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.contributionEnergy} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.contributionEnergy
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeElectricity &&
                netCostsZipCodeElectricity.connectionFee !== 0 && (
                  <TableRow
                    key="connectionFee"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Aansluitingsvergoeding
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeElectricity.connectionFee} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikValue !== null
                        ? (
                            verbruikValue *
                            netCostsZipCodeElectricity.connectionFee
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked1Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPrice *
                            verbruikValue1 +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked2Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPriceTwoDay *
                            nettoAfnameDag +
                          contractElectricityDetails.kwhConsumptionPriceTwoNight *
                            nettoAfnameWeekend +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked3Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPrice *
                            verbruikValue1 +
                          contractElectricityDetails.kwhConsumptionPriceNight *
                            nettoAfnameUitNacht +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.purchaseRateNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked4Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPriceTwoDay *
                            nettoAfnameDag +
                          contractElectricityDetails.kwhConsumptionPriceTwoNight *
                            nettoAfnameWeekend +
                          contractElectricityDetails.kwhConsumptionPriceNight *
                            nettoAfnameUitNacht +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.purchaseRateNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked1Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPrice *
                            verbruikValue1 +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePriceDm *
                            verbruikValue +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked2Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPriceTwoDay *
                            nettoAfnameDag +
                          contractElectricityDetails.kwhConsumptionPriceTwoNight *
                            nettoAfnameWeekend +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePriceDm *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked3Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPrice *
                            verbruikValue1 +
                          contractElectricityDetails.kwhConsumptionPriceNight *
                            nettoAfnameUitNacht +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePriceDm *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "NEE" &&
                isCheckedAlt === "isChecked4Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPriceTwoDay *
                            nettoAfnameDag +
                          contractElectricityDetails.kwhConsumptionPriceTwoNight *
                            nettoAfnameWeekend +
                          contractElectricityDetails.kwhConsumptionPriceNight *
                            nettoAfnameUitNacht +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePriceDm *
                            verbruikValue +
                          netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked1Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPrice *
                            verbruikValue1 +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePriceDm *
                            verbruikValue +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB +
                          netCostsZipCodeElectricity.capacityRatePrice
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked2Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPriceTwoDay *
                            nettoAfnameDag +
                          contractElectricityDetails.kwhConsumptionPriceTwoNight *
                            nettoAfnameWeekend +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePriceDm *
                            verbruikValue +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked3Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPrice *
                            verbruikValue1 +
                          contractElectricityDetails.kwhConsumptionPriceNight *
                            nettoAfnameUitNacht +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePriceDm *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked4Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Ja" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPriceTwoDay *
                            nettoAfnameDag +
                          contractElectricityDetails.kwhConsumptionPriceTwoNight *
                            nettoAfnameWeekend +
                          contractElectricityDetails.kwhConsumptionPriceNight *
                            nettoAfnameUitNacht +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePriceDm *
                            verbruikValue +
                          netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked1Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPrice *
                            verbruikValue1 +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB +
                          netCostsZipCodeElectricity.prosumentPrice *
                            omvormersVermogen
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked2Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPriceTwoDay *
                            nettoAfnameDag +
                          contractElectricityDetails.kwhConsumptionPriceTwoNight *
                            nettoAfnameWeekend +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB +
                          netCostsZipCodeElectricity.prosumentPrice *
                            omvormersVermogen
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked3Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPrice *
                            verbruikValue1 +
                          contractElectricityDetails.kwhConsumptionPriceNight *
                            nettoAfnameUitNacht +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.purchaseRateNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB +
                          netCostsZipCodeElectricity.prosumentPrice *
                            omvormersVermogen
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {hasSolarPanels === "JA" &&
                isCheckedAlt === "isChecked4Alt" &&
                netCostsZipCodeElectricity &&
                hasDigitalMeter === "Nee" && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Elektriciteit</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {" "}
                      {contractElectricityDetails &&
                        (
                          contractElectricityDetails.yearlySubscription +
                          contractElectricityDetails.kwhConsumptionPriceTwoDay *
                            verbruikValue1 +
                          contractElectricityDetails.kwhConsumptionPriceTwoNight *
                            nettoAfnameWeekend +
                          contractElectricityDetails.kwhConsumptionPriceNight *
                            nettoAfnameUitNacht +
                          contractElectricityDetails.fixedPriceElectricity +
                          contractElectricityDetails.hewwkContribution +
                          netCostsZipCodeElectricity.dataManagementPrice +
                          netCostsZipCodeElectricity.purchaseRatePrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.purchaseRateNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.taxAccijns *
                            verbruikValue +
                          netCostsZipCodeElectricity.contributionEnergy *
                            verbruikValue +
                          netCostsZipCodeElectricity.capacityRatePrice +
                          netCostsZipCodeElectricity.distributionDayPrice *
                            verbruikValue1 +
                          netCostsZipCodeElectricity.distributionNightPrice *
                            nettoAfnameUitNacht +
                          netCostsZipCodeElectricity.ditributionCombiDayPrice *
                            nettoAfnameDag +
                          netCostsZipCodeElectricity.ditributionCombiNightPrice *
                            nettoAfnameWeekend +
                          netCostsZipCodeElectricity.transportCostPrice *
                            verbruikValue +
                          netCostsZipCodeElectricity.DNB +
                          netCostsZipCodeElectricity.prosumentPrice *
                            verbruikValue
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {/* <TableRow
              key="kWh injectie zonnepanelen"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              kWh injectie zonnepanelen
              </TableCell>
              <TableCell align="right">{contractElectricityDetails?.kwhInjectionSolarPanelsDiscountPrice}</TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow> */}

              {/* <TableRow
              key="Totale kost verbruik"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <strong>Totale Jaarlijkse kost </strong>  
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow> */}

              <TableRow
                key="energieprijs"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Energieprijs Gas</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow
                key="Jaarlijks abonnement"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Jaarlijks abonnement
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {contractDetails?.yearlySubscription.toFixed(2)} €
                </TableCell>
              </TableRow>

              {/* <TableRow
              key="korting domicilliering of e-factuur"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              korting domicilliëring of e-factuur
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">- {contractDetails?.invoiceDiscount} €</TableCell>
            </TableRow> */}

              <TableRow
                key="kWh verbruikskost"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  kWh verbruikskost
                </TableCell>
                <TableCell align="right">
                  {contractDetails?.kwhConsumptionPrice} €/KwH
                </TableCell>
                <TableCell align="right">
                  {" "}
                  +{" "}
                  {verbruikGasValue !== null &&
                  contractDetails?.kwhConsumptionPrice !== undefined
                    ? `${
                        verbruikGasValue * contractDetails.kwhConsumptionPrice
                      }`
                    : 0}{" "}
                  €
                </TableCell>
              </TableRow>

              {/* <TableRow
              key="kWh korting op kWh-prijs*"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                kWh korting op kWh-prijs*
              </TableCell>
              <TableCell align="right">{contractDetails?.kwhConsumptionPriceDiscount} €/KwH</TableCell>
              <TableCell align="right">- {verbruikGasValue !== null && contractDetails?.kwhConsumptionPriceDiscount !== undefined
      ? `${verbruikGasValue * contractDetails.kwhConsumptionPriceDiscount}`
      : 0} €</TableCell>
            </TableRow>

            <TableRow
              key="Prijs na korting"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Prijs na korting
              </TableCell>
               <TableCell align="right">{priceAfterDiscount}</TableCell> 
              <TableCell align="right"> {contractDetails?.kwhConsumptionPriceAfterDiscount}€/KwH</TableCell>
              <TableCell align="right">+ {verbruikGasValue !== null && contractDetails?.kwhConsumptionPriceAfterDiscount !== undefined
      ? `${verbruikGasValue * contractDetails.kwhConsumptionPriceAfterDiscount}`
      : 'N/A'} €</TableCell>
            </TableRow> */}

              <TableRow
                key="Nettarieven"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Nettarieven Gas</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {verbruikGasValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionSmallFixedPrice !== 0 &&
                verbruikGasValue < 5000 && (
                  <TableRow
                    key="distributionSmallFixedPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost vast
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionSmallFixedPrice} €
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {netCostsZipCodeGas.distributionSmallFixedPrice.toFixed(
                        2,
                      )}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {verbruikGasValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionSmallVariablePrice !== 0 &&
                verbruikGasValue < 5000 && (
                  <TableRow
                    key="distributionSmallVariablePrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost variabel
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionSmallVariablePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikGasValue !== null
                        ? (
                            verbruikGasValue *
                            netCostsZipCodeGas.distributionSmallVariablePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {verbruikGasValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionMediumFixedPrice !== 0 &&
                verbruikGasValue > 5000 &&
                verbruikGasValue < 150000 && (
                  <TableRow
                    key="distributionMediumFixedPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost vast
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionMediumFixedPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikGasValue !== null
                        ? (
                            verbruikGasValue *
                            netCostsZipCodeGas.distributionMediumFixedPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {verbruikGasValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionMediumVariablePrice !== 0 &&
                verbruikGasValue > 5000 &&
                verbruikGasValue < 150000 && (
                  <TableRow
                    key="distributionMediumVariablePrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost variabel
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionMediumVariablePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikGasValue !== null
                        ? (
                            verbruikGasValue *
                            netCostsZipCodeGas.distributionMediumVariablePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {verbruikGasValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionLargeFixedPrice !== 0 &&
                verbruikGasValue > 150000 && (
                  <TableRow
                    key="distributionLargeFixedPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost vast
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionLargeFixedPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikGasValue !== null
                        ? (
                            verbruikGasValue *
                            netCostsZipCodeGas.distributionLargeFixedPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {verbruikGasValue !== null &&
                netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionLargeVariablePrice !== 0 &&
                verbruikGasValue > 150000 && (
                  <TableRow
                    key="distributionLargeVariablePrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Distributie kost variabel
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.distributionLargeVariablePrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikGasValue !== null
                        ? (
                            verbruikGasValue *
                            netCostsZipCodeGas.distributionLargeVariablePrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}

              {netCostsZipCodeGas &&
                netCostsZipCodeGas.distributionLargeVariablePrice !== 0 && (
                  <TableRow
                    key="Transportkosten"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Transportkosten
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.transportCostPrice} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikGasValue !== null
                        ? (
                            verbruikGasValue *
                            netCostsZipCodeGas.transportCostPrice
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeGas &&
                netCostsZipCodeGas.dataManagementPrice !== 0 && (
                  <TableRow
                    key="dataManagementPrice"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Tarief databeheer / Meet- en telactiviteit
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      + {netCostsZipCodeGas.dataManagementPrice.toFixed(2)} €
                    </TableCell>
                  </TableRow>
                )}

              <TableRow
                key="Heffingen"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Heffingen Gas</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {netCostsZipCodeGas &&
                netCostsZipCodeGas.contributionEnergy !== 0 && (
                  <TableRow
                    key="contributionEnergy"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Bijdrage op de energie
                    </TableCell>
                    <TableCell align="right">
                      {netCostsZipCodeGas.contributionEnergy} €/kWh
                    </TableCell>
                    <TableCell align="right">
                      +{" "}
                      {verbruikGasValue !== null
                        ? (
                            verbruikGasValue *
                            netCostsZipCodeGas.contributionEnergy
                          ).toFixed(2)
                        : 0}{" "}
                      €
                    </TableCell>
                  </TableRow>
                )}
              {netCostsZipCodeGas && netCostsZipCodeGas.taxAccijns !== 0 && (
                <TableRow
                  key="taxAccijns"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bijzondere accijns
                  </TableCell>
                  <TableCell align="right">
                    {netCostsZipCodeGas.taxAccijns} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {verbruikGasValue !== null
                      ? (
                          verbruikGasValue * netCostsZipCodeGas.taxAccijns
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}
              {netCostsZipCodeGas && netCostsZipCodeGas.connectionFee !== 0 && (
                <TableRow
                  key="connectionFee"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Aansluitingsvergoeding
                  </TableCell>
                  <TableCell align="right">
                    {netCostsZipCodeGas.connectionFee} €/kWh
                  </TableCell>
                  <TableCell align="right">
                    +{" "}
                    {verbruikGasValue !== null
                      ? (
                          verbruikGasValue * netCostsZipCodeGas.connectionFee
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </TableCell>
                </TableRow>
              )}

              {verbruikGasValue !== null &&
                netCostsZipCodeGas &&
                verbruikGasValue < 5000 && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Gas</strong>
                    </TableCell>
                    {/* <TableCell align="right">{contractDetails.totalYearlyCost}</TableCell> */}
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {contractDetails &&
                        (
                          contractDetails.yearlySubscription +
                          contractDetails.kwhConsumptionPrice *
                            verbruikGasValue +
                          distributionSmallFixedPrice +
                          verbruikGasValue * distributionSmallVariablePrice +
                          transportCostPrice * verbruikGasValue +
                          dataManagementPrice +
                          contributionEnergy * verbruikGasValue +
                          taxAccijns * verbruikGasValue +
                          connectionFee * verbruikGasValue
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}
              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                verbruikValue > 5000 &&
                verbruikValue < 150000 && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Gas</strong>
                    </TableCell>
                    {/* <TableCell align="right">{contractDetails.totalYearlyCost}</TableCell> */}
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {contractDetails &&
                        (
                          contractDetails.yearlySubscription +
                          contractDetails.kwhConsumptionPrice *
                            verbruikGasValue +
                          distributionMediumFixedPrice +
                          verbruikGasValue * distributionMediumVariablePrice +
                          transportCostPrice * verbruikGasValue +
                          dataManagementPrice +
                          contributionEnergy * verbruikGasValue +
                          taxAccijns * verbruikGasValue +
                          connectionFee * verbruikGasValue
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}
              {verbruikValue !== null &&
                netCostsZipCodeGas &&
                verbruikValue > 150000 && (
                  <TableRow
                    key="Totale kost verbruik"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Totale Jaarlijkse kost Gas</strong>
                    </TableCell>
                    {/* <TableCell align="right">{contractDetails.totalYearlyCost}</TableCell> */}
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {contractDetails &&
                        (
                          contractDetails.yearlySubscription +
                          contractDetails.kwhConsumptionPrice *
                            verbruikGasValue +
                          distributionLargeFixedPrice +
                          verbruikGasValue * distributionLargeVariablePrice +
                          transportCostPrice * verbruikGasValue +
                          dataManagementPrice +
                          contributionEnergy * verbruikGasValue +
                          taxAccijns * verbruikGasValue +
                          connectionFee * verbruikGasValue
                        ).toFixed(2)}{" "}
                      € incl. BTW
                    </TableCell>
                  </TableRow>
                )}

              {/* ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ContractDetailsPopupCombi;

import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import DocViewer from "@cyntler/react-doc-viewer"; // Import the DocViewer component
import axios from "axios";
import { useNavigate } from "react-router";
import Footer from "../../../../../components/Footer/Footer";
import PageHero from "../../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../../cookieUtils";

interface Document {
  id: string;
  userId: string;
  documentName: string;
  documentPath: string;
  status: number;
}

const DocumentCheck: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [documentId, setDocumentId] = useState<string | null>(null);
  const [document, setDocument] = useState<Document | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    // const documentIdFill = getCookie('documentIdFill');
    // setDocumentId(documentIdFill);
    // console.log(documentIdFill)

    // Fetch the document data based on the documentId
    // if (documentIdFill) {
    //   axios.get(`${process.env.REACT_APP_DB_URL}/documents/${documentIdFill}`)
    //     .then(response => {
    //       // Assuming the response.data is the document object
    //       setDocument(response.data);
    //       console.log(response.data)
    //     })
    //     .catch(error => {
    //       console.error('Error fetching document:', error);
    //     });
    // }
    // eraseCookie('documentIdFill')
  }, []);

  const navigateToDocumentSign = () => {
    navigate("/DocumentSign");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const navigationmenutop: React.CSSProperties = {
    backgroundColor: "#ffffff",
    padding: "20px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Document controle</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <h1 className="pageTitle" style={pageTitleStyles}>
                Document Check
              </h1>
              <p>
                Is dit document volledig juist ingevuld? Zo niet, ga terug naar
                de vorige stap en upload het contract opnieuw.{" "}
              </p>

              {/* {document && (
                <DocViewer
                  documents={[
                    {
                      uri: `${process.env.REACT_APP_DB_URL}/${document.documentPath}`, // Assuming that documentPath is the URL of the document
                      fileType: 'pdf', // Optional, specify the file type if known
                    },
                  ]}
                />
              )}  */}

              {document && (
                <iframe
                  style={{ height: "1000px", width: "1350px" }}
                  src={`${process.env.REACT_APP_DB_URL}/${document.documentPath}`}
                ></iframe>
              )}
            </div>
            <button onClick={() => navigateToDocumentSign()}>
              Document Sign
            </button>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default DocumentCheck;

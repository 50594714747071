import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.css"; // Import your CSS styles for the footer
import sitelogo from "../../assets/sitelogo-white.png";
import { IoChevronForwardOutline, IoHome, IoCall } from "react-icons/io5";
import { getCookie } from "../../cookieUtils";

const Footer: React.FC = () => {
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  // New handler for site logo click
  const handleSiteLogoClick = () => {
    if (roleId === 1) {
      navigate("/IndexAdministrator"); // Navigate to IndexAdministrator for roleId 1
    } else if (roleId === 2) {
      navigate("/IndexCustomer"); // Navigate to IndexCustomer for roleId 2
    }
  };

  return (
    <footer className="footer">
      <div className="logo-info">
        <div className="logo">
          <img
            src={sitelogo}
            style={{ height: "50px", cursor: "pointer" }}
            alt="Site Logo"
            onClick={handleSiteLogoClick} // Attach the site logo click handler
          />
        </div>
        <div className="slogan">De partner voor energie</div>
        <div className="address">
          <span style={{ marginRight: "5px", color: "#8dc63f" }}>
            <IoHome />
          </span>
          Molenheide 2 <br></br> 2980, Zoersel
        </div>
        <Link to="/Contact">
          <div className="phone">
            <span style={{ marginRight: "5px", color: "#8dc63f" }}>
              <IoCall />
            </span>{" "}
            Contacteer ons
          </div>
        </Link>
      </div>
      <div className="menu-section">
        <h3>Menu</h3>
        <ul>
          <li>
            <Link to="/IndexCustomer">
              {" "}
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>{" "}
              Dashboard
            </Link>
          </li>
          <li>
            <Link to="/Advice">
              {" "}
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>{" "}
              Advies
            </Link>
          </li>
          <li>
            <Link to="/MyContracts">
              {" "}
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>{" "}
              Mijn Contracten
            </Link>
          </li>
          <li>
            <Link to="/FilesCustomer">
              {" "}
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>{" "}
              Mijn Bestanden
            </Link>
          </li>
          <li>
            <Link to="/Quotes">
              {" "}
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>{" "}
              Offerte aanvragen
            </Link>
          </li>
          <li>
            <Link to="/ChangeEnergyContractCustomer">
              {" "}
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>{" "}
              Energiecontract veranderen
            </Link>
          </li>
          <li>
            <Link to="/MyProfile">
              {" "}
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>{" "}
              Mijn Profiel
            </Link>
          </li>
          <li>
            <Link to="/Contact">
              {" "}
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>{" "}
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className="menu-section">
        <h3>Algemene Voorwaarden</h3>
        <ul>
          <li>
            <Link to="https://app.energyneeds.be/energyneeds_algemene_voorwaarden.pdf">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Algemene voorwaarden
            </Link>
          </li>
          <li>
            <Link to="https://energyneeds.be/privacybeleid">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Privacy policy
            </Link>
          </li>
        </ul>
      </div>
      <div className="menu-section">
        <h3>Artikels</h3>
        <ul>
          <li>
            <Link to="/waarom-zijn-zonnepanelen-voordelig">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Voordeligheid zonnepanelen{" "}
            </Link>
          </li>
          <li>
            <Link to="/waarom-is-het-belangrijk-om-zonnepanelen-te-reinigen">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Zonnepanelen reinigen
            </Link>
          </li>
          <li>
            <Link to="/waarom-is-het-belangrijk-om-ketelonderhoud-uit-te-voeren">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Waarom zijn ketelonderhouden belangrijk?{" "}
            </Link>
          </li>
          <li>
            <Link to="/energie-zuinige-apparaten">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Energie zuinige apparaten
            </Link>
          </li>
          <li>
            <Link to="/thermostaat-gebruik">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Thermostaat verbruik
            </Link>
          </li>
          <li>
            <Link to="/electronische-apparaten-uitschakelen">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Uitschakelen elektronische apparaten
            </Link>
          </li>
          <li>
            <Link to="/huisisolatie">
              <span style={{ marginRight: "5px", color: "#8dc63f" }}>
                <IoChevronForwardOutline />
              </span>
              Huis isoleren
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import axios from "axios";
import ContractDetailsPopup from "../popups/ContractDetailsPopup";
import { useNavigate } from "react-router";
import Footer from "../../../../../components/Footer/Footer";
import PageHero from "../../../../../components/Pagehero/PageHero";
import "./ElectricityContractOverview.module.css";
import CenterTitle from "../../../../../components/CenterTitle/CenterTitle";
import { GiCoinsPile } from "react-icons/gi";
import { getCookie, setCookie } from "../../../../../cookieUtils";
import ScoreStars from "../../../../../components/ScoreStars/ScoreStars";

interface Contract {
  id: string;
  supplierId: string;
  documentTemplateId: string;
  contractDetailsId: string;
  name: string;
  description: string;
  category: number;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
}

interface EnergySupplier {
  id: string;
  name: string;
  logo: string;
  active: number;
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface NetCostsZipCodeElectricity {
  zipcode: string;
  name: string;
  dataManagementPrice: number;
  capacityRatePriceDm: number;
  purchaseRatePriceDm: number;
  purchaseRateNightPriceDm: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  purchaseRateNightPrice: number;
  prosumentPrice: number;
  distributionDayPrice: number;
  ditributionCombiDayPrice: number;
  ditributionCombiNightPrice: number;
  distributionNightPrice: number;
  transportCostPrice: number;
  DNB: number;
  taxNonResidential: number;
  taxAccijns: number;
  contributionEnergy: number;
  connectionFee: number;
}

const ElectricityContractOverviewPro: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [contractElectricityDetails, setContractElectricityDetails] = useState<
    ContractElectricityDetails[]
  >([]);
  const [netCostsZipCodeElectricity, setNetCostsZipCodeElectricity] =
    useState<NetCostsZipCodeElectricity | null>(null);
  const [nameFilter, setNameFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [selectedContractId, setSelectedContractId] = useState<string | null>(
    null,
  );
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate();

  const excludedSupplierId = getCookie("supplierId");
  const excludedVerbruikValue = getCookie("verbruikValue");
  const isCheckedAlt = getCookie("isCheckedAlt");
  const excludedNettoAfnameDag = getCookie("nettoAfnameDag");
  const excludedNettoAfnameWeekend = getCookie("nettoAfnameWeekend");
  const excludedNettoAfnameUitNacht = getCookie("nettoAfnameUitNacht");
  const excludedOmvormersVermogen = getCookie("omvormersVermogen");
  const hasDigitalMeter = getCookie("hasDigitalMeter");
  const hasSolarPanels = getCookie("hasSolarPanels");
  const zipcode = getCookie("postcode");

  setCookie("contractType", "EP", 2);

  const verbruikValue1 = excludedVerbruikValue
    ? parseFloat(excludedVerbruikValue)
    : 0;
  const nettoAfnameDag = excludedNettoAfnameDag
    ? parseFloat(excludedNettoAfnameDag)
    : 0;
  const nettoAfnameWeekend = excludedNettoAfnameWeekend
    ? parseFloat(excludedNettoAfnameWeekend)
    : 0;
  const nettoAfnameUitNacht = excludedNettoAfnameUitNacht
    ? parseFloat(excludedNettoAfnameUitNacht)
    : 0;
  const omvormersVermogen = excludedOmvormersVermogen
    ? parseFloat(excludedOmvormersVermogen)
    : 0;

  const verbruikValue =
    (verbruikValue1 || 0) +
    (nettoAfnameDag || 0) +
    (nettoAfnameWeekend || 0) +
    (nettoAfnameUitNacht || 0);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchContracts();
    fetchEnergySuppliers();
    fetchContractDetails();
    fetchNetCostsZipCodeElectricity();
  }, []);

  const fetchContracts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractsSortedPEVV`,
        {
          params: {
            verbruikValue: excludedVerbruikValue,
            nettoAfnameDag: excludedNettoAfnameDag,
            nettoAfnameWeekend: excludedNettoAfnameWeekend,
            nettoAfnameUitNacht: excludedNettoAfnameUitNacht,
          },
        },
      );
      const fetchedContracts: Contract[] = response.data;
      // Filter out contracts with excludedSupplierId
      const filteredContracts = fetchedContracts.filter(
        (contract) => contract.supplierId !== excludedSupplierId,
      );

      //console.log('FILTERED' + filteredContracts);

      setContracts(filteredContracts);
    } catch (error) {
      console.error("Error retrieving contracts:", error);
      // Handle error state or display an error message
    }
  };

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      setEnergySuppliers(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
      // Handle error state or display an error message
    }
  };

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractElectricityDetails`,
      );
      setContractElectricityDetails(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
      // Handle error state or display an error message
    }
  };

  const fetchNetCostsZipCodeElectricity = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCosts/${zipcode}`,
      );
      setNetCostsZipCodeElectricity(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
      // Handle error state or display an error message
    }
  };

  const NavigateToDocumentSign = (
    contractId: string,
    documentTemplateid: string,
  ) => {
    setCookie("verandercontract", contractId, 2);
    navigate(`/DocumentFill/${documentTemplateid}`);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const squareContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: "wrap",
  };

  const squareStyles: React.CSSProperties = {
    width: "200px",
    height: "200px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "100px",
    paddingTop: "100px",
    margin: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const squareStylesPreferred: React.CSSProperties = {
    width: "200px",
    height: "200px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "100px",
    paddingTop: "100px",
    margin: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    border: "5px solid",
    borderImage: "linear-gradient(45deg, #269b66, #FFD700) 1",
    position: "relative",
  };

  const preferredTextStyles: React.CSSProperties = {
    position: "absolute",
    top: "-30px", // Slightly adjust to ensure visibility
    left: "50%",
    transform: "translateX(-50%)", // Center horizontally
    background: "linear-gradient(45deg, #269b66, #FFD700)", // Gradient background for the text
    padding: "10px 20px", // Increase padding to make the background bigger
    borderRadius: "10px", // Rounded corners for the text background
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional box shadow
    zIndex: 1, // Ensure the text is above other elements
    color: "#fff", // Text color for contrast
    fontSize: "18px", // Increase text size
    fontWeight: "bold", // Make the text bold
    textAlign: "center", // Center the text horizontally
  };

  const buttonStyles: React.CSSProperties = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    outline: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid rgb(211, 230, 243)",
    width: "100%",
    textTransform: "none",
    borderRadius: "4px",
    boxShadow: "none",
    padding: "0.6rem 1.5rem",
    fontSize: "12px",
    fontWeight: 600,
    minWidth: "84px",
    color: "rgb(18, 31, 67)",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  // const filteredContracts = contracts.filter((contract: Contract) => {
  //   const nameMatch = contract.name.toLowerCase().includes(nameFilter.toLowerCase());
  //   const descriptionMatch = contract.description.toLowerCase().includes(descriptionFilter.toLowerCase());

  //   // Filter contracts with category === 1
  //   const categoryMatch = contract.category === 1 || contract.category === 2;

  //   return categoryMatch;
  // });

  // console.log("LAST:" + filteredContracts);

  // console.log('FILTER2:')
  // const filteredContracts2 = contracts.filter((contract) => contract.category === 1 || contract.category === 2);
  // const sortedFilteredContracts2 = filteredContracts2.sort
  // filteredContracts2.map(contract => {
  //   console.log(contract.name)
  // })

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Elektriciteits contracten</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <CenterTitle
                title="Contracten vergelijken"
                subtitle="Hieronder worden alle contracten teruggegeven die interessant zijn in uw situatie. We raden aan alle details te bekijken."
              ></CenterTitle>

              <div className="squareContainer" style={squareContainerStyles}>
                {contracts.map((contract, index) => {
                  const supplier = energySuppliers.find(
                    (supplier) => supplier.id === contract.supplierId,
                  );

                  // Find the ContractElectricityDetails associated with this contract
                  const contractElectricityDetail =
                    contractElectricityDetails.find(
                      (detail) => detail.contractId === contract.id,
                    );

                  // Convert excludedVerbruikValue to a number if it's not null
                  const verbruikValue =
                    excludedVerbruikValue !== null
                      ? parseFloat(excludedVerbruikValue)
                      : null;

                  // Calculate the total consumption cost price
                  let totalConsumptionCostPrice = 0;
                  if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "NEE" &&
                    isCheckedAlt === "isChecked1Alt" &&
                    hasDigitalMeter === "Nee"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue1 +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "NEE" &&
                    isCheckedAlt === "isChecked2Alt" &&
                    hasDigitalMeter === "Nee"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPriceTwoDay *
                        nettoAfnameDag +
                      contractElectricityDetail.kwhConsumptionPriceTwoNight *
                        nettoAfnameWeekend +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "NEE" &&
                    isCheckedAlt === "isChecked3Alt" &&
                    hasDigitalMeter === "Nee"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue1 +
                      contractElectricityDetail.kwhConsumptionPriceNight *
                        nettoAfnameUitNacht +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.purchaseRateNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "NEE" &&
                    isCheckedAlt === "isChecked4Alt" &&
                    hasDigitalMeter === "Nee"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPriceTwoDay *
                        nettoAfnameDag +
                      contractElectricityDetail.kwhConsumptionPriceTwoNight *
                        nettoAfnameWeekend +
                      contractElectricityDetail.kwhConsumptionPriceNight *
                        nettoAfnameUitNacht +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.purchaseRateNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "NEE" &&
                    isCheckedAlt === "isChecked1Alt" &&
                    hasDigitalMeter === "Ja"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue1 +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePriceDm *
                        verbruikValue +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "NEE" &&
                    isCheckedAlt === "isChecked2Alt" &&
                    hasDigitalMeter === "Ja"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPriceTwoDay *
                        nettoAfnameDag +
                      contractElectricityDetail.kwhConsumptionPriceTwoNight *
                        nettoAfnameWeekend +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePriceDm *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "NEE" &&
                    isCheckedAlt === "isChecked3Alt" &&
                    hasDigitalMeter === "Ja"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue1 +
                      contractElectricityDetail.kwhConsumptionPriceNight *
                        nettoAfnameUitNacht +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePriceDm *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "NEE" &&
                    isCheckedAlt === "isChecked4Alt" &&
                    hasDigitalMeter === "Ja"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPriceTwoDay *
                        nettoAfnameDag +
                      contractElectricityDetail.kwhConsumptionPriceTwoNight *
                        nettoAfnameWeekend +
                      contractElectricityDetail.kwhConsumptionPriceNight *
                        nettoAfnameUitNacht +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePriceDm *
                        verbruikValue +
                      netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "JA" &&
                    isCheckedAlt === "isChecked1Alt" &&
                    hasDigitalMeter === "Ja"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue1 +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePriceDm *
                        verbruikValue +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB +
                      netCostsZipCodeElectricity.capacityRatePrice;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "JA" &&
                    isCheckedAlt === "isChecked2Alt" &&
                    hasDigitalMeter === "Ja"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPriceTwoDay *
                        nettoAfnameDag +
                      contractElectricityDetail.kwhConsumptionPriceTwoNight *
                        nettoAfnameWeekend +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePriceDm *
                        verbruikValue +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "JA" &&
                    isCheckedAlt === "isChecked3Alt" &&
                    hasDigitalMeter === "Ja"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue1 +
                      contractElectricityDetail.kwhConsumptionPriceNight *
                        nettoAfnameUitNacht +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePriceDm *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "JA" &&
                    isCheckedAlt === "isChecked4Alt" &&
                    hasDigitalMeter === "Ja"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPriceTwoDay *
                        nettoAfnameDag +
                      contractElectricityDetail.kwhConsumptionPriceTwoNight *
                        nettoAfnameWeekend +
                      contractElectricityDetail.kwhConsumptionPriceNight *
                        nettoAfnameUitNacht +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePriceDm *
                        verbruikValue +
                      netCostsZipCodeElectricity.purchaseRateNightPriceDm *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "JA" &&
                    isCheckedAlt === "isChecked1Alt" &&
                    hasDigitalMeter === "Nee"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue1 +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB +
                      netCostsZipCodeElectricity.prosumentPrice *
                        omvormersVermogen;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "JA" &&
                    isCheckedAlt === "isChecked2Alt" &&
                    hasDigitalMeter === "Nee"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPriceTwoDay *
                        nettoAfnameDag +
                      contractElectricityDetail.kwhConsumptionPriceTwoNight *
                        nettoAfnameWeekend +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB +
                      netCostsZipCodeElectricity.prosumentPrice *
                        omvormersVermogen;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "JA" &&
                    isCheckedAlt === "isChecked3Alt" &&
                    hasDigitalMeter === "Nee"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPrice *
                        verbruikValue1 +
                      contractElectricityDetail.kwhConsumptionPriceNight *
                        nettoAfnameUitNacht +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.purchaseRateNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB +
                      netCostsZipCodeElectricity.prosumentPrice *
                        omvormersVermogen;
                  } else if (
                    netCostsZipCodeElectricity &&
                    contractElectricityDetail &&
                    verbruikValue !== null &&
                    !isNaN(verbruikValue) &&
                    hasSolarPanels === "JA" &&
                    isCheckedAlt === "isChecked4Alt" &&
                    hasDigitalMeter === "Nee"
                  ) {
                    totalConsumptionCostPrice =
                      contractElectricityDetail.yearlySubscription +
                      contractElectricityDetail.kwhConsumptionPriceTwoDay *
                        verbruikValue1 +
                      contractElectricityDetail.kwhConsumptionPriceTwoNight *
                        nettoAfnameWeekend +
                      contractElectricityDetail.kwhConsumptionPriceNight *
                        nettoAfnameUitNacht +
                      contractElectricityDetail.fixedPriceElectricity +
                      contractElectricityDetail.hewwkContribution +
                      netCostsZipCodeElectricity.dataManagementPrice +
                      netCostsZipCodeElectricity.purchaseRatePrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.purchaseRateNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
                      netCostsZipCodeElectricity.contributionEnergy *
                        verbruikValue +
                      netCostsZipCodeElectricity.capacityRatePrice +
                      netCostsZipCodeElectricity.distributionDayPrice *
                        verbruikValue1 +
                      netCostsZipCodeElectricity.distributionNightPrice *
                        nettoAfnameUitNacht +
                      netCostsZipCodeElectricity.ditributionCombiDayPrice *
                        nettoAfnameDag +
                      netCostsZipCodeElectricity.ditributionCombiNightPrice *
                        nettoAfnameWeekend +
                      netCostsZipCodeElectricity.transportCostPrice *
                        verbruikValue +
                      netCostsZipCodeElectricity.DNB +
                      netCostsZipCodeElectricity.prosumentPrice * verbruikValue;
                  }

                  return index === 0 ? (
                    <div className="square" style={squareStylesPreferred}>
                      <div style={preferredTextStyles}>Beste keuze!</div>
                      <img
                        src={supplier?.logo}
                        alt={supplier?.name}
                        style={{ height: "60px", marginRight: "10px" }}
                        onError={(e) => {
                          console.error(
                            "Er liep iets mis tijdens het laden van de afbeelding:",
                            e,
                          );
                        }}
                      />
                      <h2>{contract.name}</h2>
                      <p>{contract.description}</p>
                      <p>
                        Jaarlijks: €{totalConsumptionCostPrice.toFixed(2)} incl.
                        BTW
                      </p>
                      {supplier?.id ? (
                        <ScoreStars energySupplierId={supplier.id} /> // Pass supplier ID
                      ) : (
                        <div>Supplier information is not available</div>
                      )}
                      <button
                        style={buttonStyles}
                        onClick={() =>
                          NavigateToDocumentSign(
                            contract.id,
                            contract.documentTemplateId,
                          )
                        }
                      >
                        Contract Aanvragen
                      </button>
                      <p
                        onClick={() =>
                          setSelectedContractId(contract.contractDetailsId)
                        }
                      >
                        <u>Details bekijken</u>
                      </p>
                    </div>
                  ) : (
                    <div className="square" style={squareStyles}>
                      <img
                        src={supplier?.logo}
                        alt={supplier?.name}
                        style={{ height: "60px", marginRight: "10px" }}
                        onError={(e) => {
                          console.error(
                            "Er liep iets mis tijdens het laden van de afbeelding:",
                            e,
                          );
                        }}
                      />
                      <h2>{contract.name}</h2>
                      <p>{contract.description}</p>
                      <p>
                        Jaarlijks: €{totalConsumptionCostPrice.toFixed(2)} incl.
                        BTW
                      </p>
                      {supplier?.id ? (
                        <ScoreStars energySupplierId={supplier.id} /> // Pass supplier ID
                      ) : (
                        <div>Supplier information is not available</div>
                      )}
                      <button
                        style={buttonStyles}
                        onClick={() =>
                          NavigateToDocumentSign(
                            contract.id,
                            contract.documentTemplateId,
                          )
                        }
                      >
                        Contract Aanvragen
                      </button>
                      <p
                        onClick={() =>
                          setSelectedContractId(contract.contractDetailsId)
                        }
                      >
                        <u>Details bekijken</u>
                      </p>
                    </div>
                  );
                })}
              </div>
              {selectedContractId && (
                <ContractDetailsPopup
                  contractId={selectedContractId}
                  onClose={() => setSelectedContractId(null)}
                />
              )}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ElectricityContractOverviewPro;

import React, { forwardRef } from "react";
import { IoExit, IoAlbums, IoDocuments, IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { eraseCookie } from "../../cookieUtils";

interface ProfilePopupProps {
  isVisible: boolean;
}

const ProfilePopup = forwardRef<HTMLDivElement, ProfilePopupProps>(
  ({ isVisible }, ref) => {
    const navigate = useNavigate();

    if (!isVisible) {
      return null;
    }

    const popupStyles: React.CSSProperties = {
      position: "absolute",
      top: "calc(100% + 5px)",
      left: "calc(100% - 90px)", // Adjust the left position if needed
      width: "100px", // Adjust the width if needed
      backgroundColor: "white",
      padding: "10px",
      borderRadius: "10px",
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
      zIndex: 1002,
    };

    const menuItemStyles: React.CSSProperties = {
      color: "black",
      padding: "5px 10px",
      cursor: "pointer",
      borderRadius: "5px",
      transition: "background-color 0.3s ease",
    };

    const hoverStyles: React.CSSProperties = {
      backgroundColor: "#f3f3f3",
    };

    const navigateAndCloseMenu = (path: string) => {
      navigate(path);
    };

    const logoutAndCloseMenu = () => {
      // Perform logout actions
      eraseCookie("user");
      eraseCookie("roleId");
      eraseCookie("selectedAddress");
      navigate("/Login"); // Navigate to the login page
    };

    return (
      <div ref={ref} style={popupStyles}>
        {/* <div style={popupStyles}> */}
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyles)}
          onMouseOut={(e) =>
            Object.assign(e.currentTarget.style, menuItemStyles)
          }
          onClick={() => navigateAndCloseMenu("/MyProfile")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoPerson />
          </span>{" "}
          Mijn Profiel
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyles)}
          onMouseOut={(e) =>
            Object.assign(e.currentTarget.style, menuItemStyles)
          }
          onClick={() => navigateAndCloseMenu("/MyContracts")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoDocuments />
          </span>{" "}
          Mijn Diensten
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyles)}
          onMouseOut={(e) =>
            Object.assign(e.currentTarget.style, menuItemStyles)
          }
          onClick={() => navigateAndCloseMenu("/FilesCustomer")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoAlbums />
          </span>{" "}
          Mijn Bestanden
        </div>
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyles)}
          onMouseOut={(e) =>
            Object.assign(e.currentTarget.style, menuItemStyles)
          }
          onClick={logoutAndCloseMenu}
        >
          <span style={{ marginRight: "5px" }}>
            <IoExit />
          </span>{" "}
          Uitloggen
        </div>
      </div>
    );
  },
);

export default ProfilePopup;

import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationLeft from "../../../../../components/Navigation/NavigationLeft/NavigationLeft";
import "./DetailContractInstance.css";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../../../../components/Navigation/NavBar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PageHero from "../../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../../cookieUtils";

interface ContractInstance {
  id: string;
  contractId: string;
  userId: string;
  userAddressId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  startDate: Date;
  endDate: Date;
}

interface Contract {
  id: number;
  supplierId: number;
  documentTemplateId: string;
  name: string;
  description: string;
  category: number;
  partnerId: string;
  minimalDurationLength: number;
  commission: number;
  clawback: number;
  vme: number;
}

interface EnergySupplier {
  id: string;
  name: string;
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface ContractGasDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  invoiceDiscount: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  distributionCostPrice: number;
  transportCostPrice: number;
  taxPrice: number;
  totalYearlyCost: number;
}

const DetailContractInstance = () => {
  const [contract, setContract] = useState<Contract | null>(null);
  const [contractElectricityDetails, setElectricityDetails] = useState<
    ContractElectricityDetails[] | null
  >(null);
  const [contractInstance, setContractInstance] =
    useState<ContractInstance | null>(null);
  const [contractGasDetails, setGasDetails] = useState<
    ContractGasDetails[] | null
  >(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const { contractInstanceId } = useParams<{ contractInstanceId: string }>(); // Extract the contract ID from the URL
  const navigate = useNavigate();
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [roleId, setRoleId] = useState<number | null>(null);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchContract();
  }, []);

  const fetchContract = async () => {
    try {
      const responseInstance = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractInstances/${contractInstanceId}`,
      );
      const contractInstanceData: ContractInstance = responseInstance.data;
      setContractInstance(contractInstanceData);

      const contractId = contractInstanceData.contractId;

      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contracts/${contractId}`,
      );
      const contractData: Contract = response.data;
      setContract(contractData);

      const supplierResponse = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energysuppliers/${contractData.supplierId}`,
      );
      const energySupplier: EnergySupplier = supplierResponse.data;
      setEnergySuppliers([energySupplier]);

      if (contractData.category === 1 || contractData.category === 2) {
        const electricityResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contractElectricityDetails`,
        );
        const electricityContracts: ContractElectricityDetails[] =
          electricityResponse.data;

        const matchingElectricityContract = electricityContracts.find(
          (electricityContract) =>
            electricityContract.contractId === contractId,
        );

        if (matchingElectricityContract) {
          const electricityDetailsResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/contractElectricityDetails/${matchingElectricityContract.id}`,
          );
          const electricityDetails: ContractElectricityDetails =
            electricityDetailsResponse.data;
          setElectricityDetails([electricityDetails]); // Wrap in array
        } else {
          console.log("Error retrieving Electricity contract details");
        }
      } else if (contractData.category === 3 || contractData.category === 4) {
        const gasResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contractGasDetails`,
        );
        const gasContracts: ContractGasDetails[] = gasResponse.data;

        const matchingGasContract = gasContracts.find(
          (gasContract) => gasContract.contractId === contractId,
        );

        if (matchingGasContract) {
          const gasDetailsResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/contractGasDetails/${matchingGasContract.id}`,
          );
          const gasDetails: ContractGasDetails = gasDetailsResponse.data;
          setGasDetails([gasDetails]); // Wrap in array
        } else {
          console.log("Error retrieving Gas contract details");
        }
      }
    } catch (error) {
      console.error("Error retrieving contract:", error);
      // Handle error state or display an error message
    }
  };

  const handleEditContract = (contractId: any) => {
    // Navigate to edit contract page
    //navigate(`/EditContract/${contractId}`);
    console.log(contractId);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#272727",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Detailoverzicht contract instantie</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    {/* <button className="addButton" onClick={() => console.log("hello")}>
                Contract aanpassen
              </button> */}
                  </div>
                </div>
                <hr className="dividerci" />

                <div className="userCardsRow">
                  <div className="userCard">
                    {contract && (
                      <div className="contractDetailContainer">
                        {/* <div className="label">ID:</div>
              <div className="text">{contract.id}</div> */}
                        <div className="label">Naam:</div>
                        <div className="text">{contract.name}</div>
                        <div className="label">Beschrijving:</div>
                        <div className="text">{contract.description}</div>
                        <div className="label">Categorie:</div>
                        <div className="text">
                          {" "}
                          {contract.category === 1
                            ? "Elektriciteit Vast"
                            : contract.category === 2
                              ? "Elektriciteit Variabel"
                              : contract.category === 3
                                ? "Gas Vast"
                                : contract.category === 4
                                  ? "Gas Variabel"
                                  : contract.category === 5
                                    ? "Elektriciteit Dynamisch"
                                    : contract.category === 6
                                      ? "Gas Dynamisch"
                                      : contract.category === 7
                                        ? "Combi Vast"
                                        : contract.category === 8
                                          ? "Combi Variabel"
                                          : contract.category === 9
                                            ? "Combi Dynamisch"
                                            : contract.category === 10
                                              ? "Elektriciteit Vast Professioneel"
                                              : contract.category === 11
                                                ? "Elektriciteit Variabel Professioneel"
                                                : contract.category === 12
                                                  ? "Gas Vast Professioneel"
                                                  : contract.category === 13
                                                    ? "Gas Variabel Professioneel"
                                                    : contract.category === 14
                                                      ? "Elektriciteit Dynamisch Professioneel"
                                                      : contract.category === 15
                                                        ? "Gas Dynamisch Professioneel"
                                                        : contract.category ===
                                                            16
                                                          ? "Combi Vast Professioneel"
                                                          : contract.category ===
                                                              17
                                                            ? "Combi Variabel Professioneel"
                                                            : contract.category ===
                                                                18
                                                              ? "Combi Dynamisch Professioneel"
                                                              : "Gas"}{" "}
                        </div>
                        <div className="label">Energieleverancier:</div>
                        <div className="text">
                          {energySuppliers.length > 0
                            ? energySuppliers[0].name
                            : ""}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="userCard">
                    {contractInstance && (
                      <div className="contractDetailContainer">
                        <div className="label">Straatnaam:</div>
                        <div className="text">
                          {contractInstance.streetname}
                        </div>
                        <div className="label">Huisnummer:</div>
                        <div className="text">
                          {contractInstance.housenumber}
                        </div>
                        <div className="label">Postcode:</div>
                        <div className="text">{contractInstance.zipcode}</div>
                        <div className="label">Stad:</div>
                        <div className="text">{contractInstance.city}</div>
                        {contractInstance.eane && (
                          <div>
                            <div className="label">EAN Elektriciteit:</div>
                            <div className="text">{contractInstance.eane}</div>
                          </div>
                        )}
                        {contractInstance.eang && (
                          <div>
                            <div className="label">EAN Gas:</div>
                            <div className="text">{contractInstance.eang}</div>
                          </div>
                        )}
                        <div className="label">Start datum:</div>
                        <div className="text">
                          {contractInstance.startDate.toLocaleString()}
                        </div>
                        <div className="label">Eind datum:</div>
                        <div className="text">
                          {contractInstance.endDate.toLocaleString()}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="dividerci" />
                <h1 className="pageTitle" style={pageTitleStyles}>
                  Prijs Details
                </h1>
                {contract &&
                  contract.category === 1 &&
                  Array.isArray(contractElectricityDetails) && (
                    <>
                      <>
                        <>
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 700 }}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>
                                      Energieprijs
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Eenheidsprijs
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {contractElectricityDetails.map(
                                    (
                                      contractElectricityDetail: ContractElectricityDetails,
                                    ) => {
                                      return (
                                        <>
                                          <StyledTableRow
                                            key={contractElectricityDetail.id}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              Jaarlijks abonnement
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="right"
                                            >
                                              {
                                                contractElectricityDetail.yearlySubscription
                                              }{" "}
                                              €
                                            </StyledTableCell>
                                            <StyledTableRow
                                              key={contractElectricityDetail.id}
                                            >
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                kWh verbruikskost
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                                align="right"
                                              >
                                                +{" "}
                                                {
                                                  contractElectricityDetail.kwhConsumptionPrice
                                                }{" "}
                                                c€/kWh
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </StyledTableRow>
                                          <StyledTableRow
                                            key={contractElectricityDetail.id}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              kWh verbruikskost (dag)
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="right"
                                            >
                                              +{" "}
                                              {
                                                contractElectricityDetail.kwhConsumptionPriceTwoDay
                                              }{" "}
                                              c€/kWh
                                            </StyledTableCell>
                                          </StyledTableRow>
                                          <StyledTableRow
                                            key={contractElectricityDetail.id}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              kWh verbruikskost (nacht)
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="right"
                                            >
                                              +{" "}
                                              {
                                                contractElectricityDetail.kwhConsumptionPriceNight
                                              }{" "}
                                              c€/kWh
                                            </StyledTableCell>
                                          </StyledTableRow>
                                          <StyledTableRow
                                            key={contractElectricityDetail.id}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              kWh verbruikskost excl. nacht
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="right"
                                            >
                                              +{" "}
                                              {
                                                contractElectricityDetail.kwhConsumptionPriceNight
                                              }{" "}
                                              c€/kWh
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        </>
                                      );
                                    },
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {/* <TableContainer component={Paper}>
                   <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nettarieven</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((contractElectricityDetail: ContractElectricityDetails) => {
                        return (
                          <><StyledTableRow key={contractElectricityDetail.id}>
                            <StyledTableCell component="th" scope="row">
                              Tarief Databeheer
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align="right">
                              + {contractElectricityDetail.dataManagementPrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                            <StyledTableRow key={contractElectricityDetail.id}>
                              <StyledTableCell component="th" scope="row">
                                Capaciteitstarief
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row" align="right">
                                + {contractElectricityDetail.capacityRatePrice} €
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key={contractElectricityDetail.id}>
                              <StyledTableCell component="th" scope="row">
                                Afnametarief
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row" align="right">
                                + {contractElectricityDetail.purchaseRatePrice} €
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key={contractElectricityDetail.id}>
                              <StyledTableCell component="th" scope="row">
                                Subtotaal
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row" align="right">
                                0.00€
                              </StyledTableCell>
                            </StyledTableRow></>
                        );
                      })}
                    </TableBody>
                  </Table> 
                </TableContainer> */}
                          </>
                          {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Heffingen</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((contractElectricityDetail: ContractElectricityDetails) => {
                        return (
                          <><StyledTableRow key={contractElectricityDetail.id}>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align="right">
                              {contractElectricityDetail.taxPrice} €
                            </StyledTableCell>
                          </StyledTableRow></>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                 */}
                        </>
                        {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Totale kost verbruik</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((contractElectricityDetail: ContractElectricityDetails) => {
                        return (
                          <><StyledTableRow key={contractElectricityDetail.id}>
                            <StyledTableCell component="th" scope="row">
                              kWh injectie zonnepanelen
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align="right">
                              {contractElectricityDetail.kwhInjectionSolarPanelsDiscountPrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                          
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                 */}
                      </>

                      {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Totale jaarlijkse kostprijs</StyledTableCell>
                        <StyledTableCell align="right">295,56 €</StyledTableCell>
                      </TableRow>
                    </TableHead>

                  </Table>
                </TableContainer>
                 */}
                    </>
                  )}

                {contract &&
                  contract.category === 2 &&
                  Array.isArray(contractElectricityDetails) && (
                    <>
                      <>
                        <>
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 700 }}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>
                                      Energieprijs
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Eenheidsprijs
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {contractElectricityDetails.map(
                                    (
                                      contractElectricityDetail: ContractElectricityDetails,
                                    ) => {
                                      return (
                                        <>
                                          <StyledTableRow
                                            key={contractElectricityDetail.id}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              Jaarlijks abonnement
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="right"
                                            >
                                              {
                                                contractElectricityDetail.yearlySubscription
                                              }{" "}
                                              €
                                            </StyledTableCell>
                                            <StyledTableRow
                                              key={contractElectricityDetail.id}
                                            >
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                kWh verbruikskost
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                                align="right"
                                              >
                                                +{" "}
                                                {
                                                  contractElectricityDetail.kwhConsumptionPrice
                                                }{" "}
                                                c€/kWh
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </StyledTableRow>
                                          <StyledTableRow
                                            key={contractElectricityDetail.id}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              kWh verbruikskost (dag)
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="right"
                                            >
                                              +{" "}
                                              {
                                                contractElectricityDetail.kwhConsumptionPriceTwoDay
                                              }{" "}
                                              c€/kWh
                                            </StyledTableCell>
                                          </StyledTableRow>
                                          <StyledTableRow
                                            key={contractElectricityDetail.id}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              kWh verbruikskost (nacht)
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="right"
                                            >
                                              +{" "}
                                              {
                                                contractElectricityDetail.kwhConsumptionPriceNight
                                              }{" "}
                                              c€/kWh
                                            </StyledTableCell>
                                          </StyledTableRow>
                                          <StyledTableRow
                                            key={contractElectricityDetail.id}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              kWh verbruikskost excl. nacht
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="right"
                                            >
                                              +{" "}
                                              {
                                                contractElectricityDetail.kwhConsumptionPriceNight
                                              }{" "}
                                              c€/kWh
                                            </StyledTableCell>
                                          </StyledTableRow>

                                          {/* <StyledTableRow key={contractElectricityDetail.id}>
                            <StyledTableCell component="th" scope="row">
                              kWh korting op kWh-prijs*
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align="right">
                              - {contractElectricityDetail.kwhConsumptionPriceDiscount} c€/kWh
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow key={contractElectricityDetail.id}>
                            <StyledTableCell component="th" scope="row">
                              Bijdrage "hernieuwbare energie + wwk"
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align="right">
                              + {contractElectricityDetail.hewwkContribution} c€/kWh
                            </StyledTableCell>
                          </StyledTableRow>
                           */}
                                        </>
                                      );
                                    },
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nettarieven</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((contractElectricityDetail: ContractElectricityDetails) => {
                        return (
                          <><StyledTableRow key={contractElectricityDetail.id}>
                            <StyledTableCell component="th" scope="row">
                              Tarief Databeheer
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align="right">
                              + {contractElectricityDetail.dataManagementPrice} €
                            </StyledTableCell>
                          </StyledTableRow>
                            <StyledTableRow key={contractElectricityDetail.id}>
                              <StyledTableCell component="th" scope="row">
                                Capaciteitstarief
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row" align="right">
                                + {contractElectricityDetail.capacityRatePrice} €
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key={contractElectricityDetail.id}>
                              <StyledTableCell component="th" scope="row">
                                Afnametarief
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row" align="right">
                                + {contractElectricityDetail.purchaseRatePrice} €
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key={contractElectricityDetail.id}>
                              <StyledTableCell component="th" scope="row">
                                Subtotaal
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row" align="right">
                                0.00€
                              </StyledTableCell>
                            </StyledTableRow></>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                 */}
                          </>
                          {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Heffingen</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((contractElectricityDetail: ContractElectricityDetails) => {
                        return (
                          <><StyledTableRow key={contractElectricityDetail.id}>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align="right">
                              {contractElectricityDetail.taxPrice} €
                            </StyledTableCell>
                          </StyledTableRow></>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer></> */}
                        </>
                        {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Totale kost verbruik</StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractElectricityDetails.map((contractElectricityDetail: ContractElectricityDetails) => {
                        return (
                          <><StyledTableRow key={contractElectricityDetail.id}>
                            <StyledTableCell component="th" scope="row">
                              kWh injectie zonnepanelen
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align="right">
                              {contractElectricityDetail.kwhInjectionSolarPanelsDiscountPrice} €
                            </StyledTableCell>
                          </StyledTableRow></>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer> */}
                      </>
                      {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Totale jaarlijkse kostprijs</StyledTableCell>
                        <StyledTableCell align="right">295,56 €</StyledTableCell>
                      </TableRow>
                    </TableHead>

                  </Table>
                </TableContainer></> */}
                    </>
                  )}

                {contract &&
                  contract.category === 3 &&
                  Array.isArray(contractGasDetails) && (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Energieprijs</StyledTableCell>
                            <StyledTableCell align="right">
                              Eenheidsprijs
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contractGasDetails.map((gasDetails) => (
                            <React.Fragment key={gasDetails.id}>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  Jaarlijks abonnement
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {gasDetails.yearlySubscription} €
                                </StyledTableCell>
                              </StyledTableRow>

                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  kWh verbruikskost
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  + {gasDetails.kwhConsumptionPrice} c€/kWh
                                </StyledTableCell>
                              </StyledTableRow>
                              {/* <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              kWh korting op kWh-prijs*
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              - {gasDetails.kwhConsumptionPriceDiscount} c€/kWh
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Prijs na korting
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              + {gasDetails.kwhConsumptionPriceAfterDiscount}{" "}
                              c€/kWh
                            </StyledTableCell>
                          </StyledTableRow> */}
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                {/* {contract &&
              contract.category === 3 &&
              Array.isArray(contractGasDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nettarieven</StyledTableCell>
                        <StyledTableCell>
                          
                          </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractGasDetails.map((gasDetails) => (
                        <React.Fragment key={gasDetails.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Distributiekosten
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              + {gasDetails.distributionCostPrice} €
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Transportkosten
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              + {gasDetails.transportCostPrice} €
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              {gasDetails.kwhConsumptionPrice +
                                gasDetails.transportCostPrice}{" "}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

            {contract &&
              contract.category === 3 &&
              Array.isArray(contractGasDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          Heffingen (taks en bijdragen)
                        </StyledTableCell>
                        <StyledTableCell>
                          
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractGasDetails.map((gasDetails) => (
                        <React.Fragment key={gasDetails.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              
                              {gasDetails.taxPrice}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

                {contract &&
                  contract.category === 4 &&
                  Array.isArray(contractGasDetails) && (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Energieprijs</StyledTableCell>
                            <StyledTableCell align="right">
                              Eenheidsprijs
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contractGasDetails.map((gasDetails) => (
                            <React.Fragment key={gasDetails.id}>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  Jaarlijks abonnement
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {gasDetails.yearlySubscription} €
                                </StyledTableCell>
                              </StyledTableRow>

                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  kWh verbruikskost
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  + {gasDetails.kwhConsumptionPrice} c€/kWh
                                </StyledTableCell>
                              </StyledTableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                {/* {contract &&
              contract.category === 4 &&
              Array.isArray(contractGasDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nettarieven</StyledTableCell>
                        <StyledTableCell>
                          
                          </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractGasDetails.map((gasDetails) => (
                        <React.Fragment key={gasDetails.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Distributiekosten
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              + {gasDetails.distributionCostPrice} €
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Transportkosten
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              + {gasDetails.transportCostPrice} €
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              {gasDetails.kwhConsumptionPrice +
                                gasDetails.transportCostPrice}{" "}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

                {/* {contract &&
              contract.category === 4 &&
              Array.isArray(contractGasDetails) && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          Heffingen (taks en bijdragen)
                        </StyledTableCell>
                        <StyledTableCell>
                          
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractGasDetails.map((gasDetails) => (
                        <React.Fragment key={gasDetails.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Subtotaal
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              
                              {gasDetails.taxPrice}
                              €
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default DetailContractInstance;

import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import "../Articles.css";
import energyEfficientDevicesImage from "./energyefficientdevices.jpg";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import { getCookie } from "../../../../../cookieUtils";

const EnergyEfficientDevices: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const subheadingStyles: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "30px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>
            Waarom is het voordelig om te investeren in energiezuinige
            apparaten?
          </PageHeroColored>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <p className="section-paragraph">
                    <b>
                      {" "}
                      Het investeren in energiezuinige apparaten is niet alleen
                      goed voor het milieu, maar ook voor uw portemonnee. In dit
                      artikel bespreken we de voordelen van het gebruik van
                      energiezuinige apparaten en hoeveel u hiermee gemiddeld
                      kunt besparen op uw energierekening.
                    </b>
                  </p>
                  <h2 style={subheadingStyles}>Efficiëntie</h2>
                  <p className="section-paragraph">
                    Een goed onderhouden ketel werkt efficiënter en verbruikt
                    minder energie. Dit resulteert in lagere energiekosten en
                    een kleinere ecologische voetafdruk. Tijdens het
                    ketelonderhoud zal de verwarmingsmonteur alle componenten
                    van de ketel controleren en eventuele problemen oplossen.
                    Hierdoor kan uw ketel zijn optimale prestaties behouden,
                    waardoor u geld bespaart op uw energierekening.
                  </p>
                </div>

                <div className="image-column">
                  <img
                    src={energyEfficientDevicesImage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
              </div>

              <div className="section-container-full">
                {/* Energiebesparing */}
                <h2 style={subheadingStyles}>Energiebesparing</h2>
                <div className="section-paragraph-full">
                  <p>
                    Energiezuinige apparaten zijn ontworpen om minder energie te
                    verbruiken dan conventionele apparaten. Ze maken gebruik van
                    geavanceerde technologieën en functies die het
                    energieverbruik verminderen zonder in te leveren op
                    prestaties. Door te kiezen voor energiezuinige apparaten,
                    kunt u aanzienlijk besparen op uw energierekening, vooral op
                    de lange termijn. Studies hebben aangetoond dat
                    huiseigenaren die overstappen op energiezuinige apparaten
                    tot wel 30% kunnen besparen op hun energiekosten.
                  </p>
                </div>

                {/* Milieuvriendelijk */}
                <h2 style={subheadingStyles}>Milieuvriendelijk</h2>
                <div className="section-paragraph-full">
                  <p>
                    Het verminderen van uw energieverbruik door gebruik te maken
                    van energiezuinige apparaten heeft een positieve impact op
                    het milieu. Minder energieverbruik betekent dat er minder
                    fossiele brandstoffen worden verbrand, wat resulteert in een
                    lagere uitstoot van broeikasgassen en een vermindering van
                    uw ecologische voetafdruk. Door uw steentje bij te dragen
                    aan een duurzamere wereld, helpt u mee aan de strijd tegen
                    klimaatverandering en de bescherming van de natuurlijke
                    hulpbronnen van onze planeet.
                  </p>
                </div>

                {/* Return on Investment (ROI) */}
                <h2 style={subheadingStyles}>Return on Investment (ROI)</h2>
                <div className="section-paragraph-full">
                  <p>
                    Hoewel energiezuinige apparaten mogelijk een iets hogere
                    aanschafprijs hebben dan conventionele apparaten, is de
                    investering de moeite waard op de lange termijn. Door de
                    energiebesparingen die u realiseert, verdient u de initiële
                    kosten van de apparaten terug en bespaart u uiteindelijk
                    geld. De gemiddelde levensduur van energiezuinige apparaten
                    is vaak langer dan die van niet-zuinige apparaten, waardoor
                    u nog meer besparingen kunt realiseren gedurende de
                    levensduur van de apparaten.
                  </p>
                </div>

                {/* Conclusie */}
                <h2 style={subheadingStyles}>Conclusie</h2>
                <div className="section-paragraph-full">
                  <p>
                    Het investeren in energiezuinige apparaten is een
                    verstandige keuze voor zowel uw financiën als het milieu.
                    Door te kiezen voor energiezuinige opties, kunt u
                    aanzienlijk besparen op uw energierekening en uw ecologische
                    voetafdruk verkleinen. Hoewel de initiële kosten mogelijk
                    iets hoger zijn, verdient u de investering terug door de
                    energiebesparingen en een langere levensduur van de
                    apparaten. Als u op zoek bent naar een duurzamere en
                    kostenefficiënte oplossing, overweeg dan om over te stappen
                    op energiezuinige apparaten en geniet van de vele voordelen
                    die ze te bieden hebben.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default EnergyEfficientDevices;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EditContract.css";
import { useParams } from "react-router-dom";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MenuItem from "@mui/material/MenuItem";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface EnergySupplier {
  id: string;
  name: string;
  // Add other properties if necessary
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConsumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface Contract {
  id: string;
  supplierId: string;
  documentTemplateId: string;
  contractDetailsId: string;
  name: string;
  description: string;
  category: number;
}

const EditContractCombi = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(0);
  const [supplierId, setSupplierId] = useState("");
  const [contractDetailsId, setContractDetailsId] = useState("");
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]); // Update the type
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const [contractElectricityDetailsId, setContractElectricityDetailsId] =
    useState("");
  const [contractElectricityId, setContractElectricityId] = useState("");
  const [contractGasDetailsId, setContractGasDetailsId] = useState("");
  const [contractGasId, setContractGasId] = useState("");
  const [ContractsElectricity, setContractsElectricity] = useState<Contract[]>(
    [],
  );
  const [ContractsGas, setContractsGas] = useState<Contract[]>([]);

  const [roleId, setRoleId] = useState<number | null>(null);

  const { contractId } = useParams<{ contractId: string }>(); // Extract the contract ID from the URL

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchContractDetails();
    fetchEnergySuppliers();
    fetchElectricityContracts();
    fetchGasContracts();
  }, []);

  useEffect(() => {
    if (contractDetailsId) {
      fetchCombiContractDetails();
    }
  }, [contractDetailsId]);

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contracts/${contractId}`,
      );
      const contract = response.data;
      setName(contract.name);
      setDescription(contract.description);
      setCategory(contract.category);
      setSupplierId(contract.supplierId);
      setContractDetailsId(contract.contractDetailsId);
    } catch (error) {
      console.error("Error fetching contract details:", error);
    }
  };

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      const suppliers = response.data;
      setEnergySuppliers(suppliers);
    } catch (error) {
      console.error("Error fetching energy suppliers:", error);
    }
  };

  const fetchElectricityContracts = async () => {
    try {
      const responseE = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contracts/categories/1,2,5,10,11,14`,
      );
      const electricityContracts: Contract[] = responseE.data;
      setContractsElectricity(electricityContracts);
    } catch (error) {
      console.error("Error fetching electricity contracts:", error);
    }
  };

  const fetchGasContracts = async () => {
    try {
      const responseG = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contracts/categories/3,4,6,12,13,15`,
      );
      const gasContracts: Contract[] = responseG.data;
      setContractsGas(gasContracts);
    } catch (error) {
      console.error("Error fetching energy suppliers:", error);
    }
  };

  const fetchCombiContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractCombiDetails/${contractDetailsId}`,
      );
      const contractDetails = response.data;
      console.log(contractDetails);
      setContractElectricityId(contractDetails.contractIdElectricity);
      setContractElectricityDetailsId(
        contractDetails.contractElectricityDetailsId,
      );
      setContractGasId(contractDetails.contractIdGas);
      setContractGasDetailsId(contractDetails.contractGasDetailsId);
    } catch (error) {
      console.error("Error fetching contract details:", error);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDescription(event.target.value);
  };

  const handleCategoryChange = (event: any) => {
    const categoryUpdate = parseInt(event.target.value, 10);
    setCategory(categoryUpdate);
  };

  const handleSupplierIdChange = (event: any) => {
    setSupplierId(event.target.value);
  };

  const handleContractElectricityIdChange = (event: any) => {
    const selectedContractIdE = event.target.value;
    setContractElectricityId(selectedContractIdE);
  };

  const handleContractGasIdChange = (event: any) => {
    const selectedContractIdG = event.target.value;
    setContractGasId(selectedContractIdG);
  };

  const handleUpdateContract = async () => {
    try {
      const updatedContract = { name, description, category, supplierId };
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/contracts/${contractId}`,
        updatedContract,
      );
      console.log("Contract updated successfully");

      const updatedContractDetails = {
        contractElectricityDetailsId,
        contractIdElectricity: contractElectricityId,
        contractGasDetailsId,
        contractIdGas: contractGasId,
      };
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/contractCombiDetails/${contractDetailsId}`,
        updatedContractDetails,
      );
      console.log("Contract details updated successfully");

      navigate("/Contracts");
    } catch (error) {
      console.error("Error updating contract:", error);
      // Handle error state or display an error message
    }
  };

  const containerStyles = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Contract aanpassen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              {/* <hr className="dividerDashed" /> */}
              {/* <div className="contentContainer"> */}
              <div>
                <div className="formContainer">
                  {errorMessage ? (
                    <p className="errorMessage">{errorMessage}</p>
                  ) : null}
                  <div className="userCardec">
                    <div className="userCardContainerec">
                      <div className="userCardColumnec">
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <ElectricBoltIcon
                              sx={{ color: "action.active", mr: 1, my: 0.5 }}
                            />
                            <TextField
                              label="Naam"
                              variant="standard"
                              type="text"
                              id="nameFilter"
                              value={name}
                              onChange={handleNameChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <ElectricBoltIcon
                              sx={{ color: "action.active", mr: 1, my: 0.5 }}
                            />
                            <TextField
                              label="Beschrijving"
                              variant="standard"
                              type="text"
                              value={description}
                              onChange={handleDescriptionChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="Electriciteitscontract"
                              select
                              label="Electriciteitscontract"
                              value={contractElectricityId}
                              onChange={handleContractElectricityIdChange}
                              fullWidth
                              variant="standard"
                            >
                              <MenuItem value={0}>
                                Selecteer een Electriciteitscontract
                              </MenuItem>
                              {ContractsElectricity.map(
                                (ContractsElectricity) => (
                                  <MenuItem
                                    key={ContractsElectricity.id}
                                    value={ContractsElectricity.id}
                                  >
                                    {ContractsElectricity.name}
                                  </MenuItem>
                                ),
                              )}
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="Gascontract"
                              select
                              label="Gascontract"
                              value={contractGasId}
                              onChange={handleContractGasIdChange}
                              fullWidth
                              variant="standard"
                            >
                              <MenuItem value={0}>
                                Selecteer een Gascontract
                              </MenuItem>
                              {ContractsGas.map((ContractsGas) => (
                                <MenuItem
                                  key={ContractsGas.id}
                                  value={ContractsGas.id}
                                >
                                  {ContractsGas.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="category"
                              select
                              label="Categorie"
                              value={category}
                              onChange={handleCategoryChange}
                              fullWidth
                              variant="standard"
                            >
                              <MenuItem value={0}>
                                Selecteer een categorie
                              </MenuItem>
                              <MenuItem value={7}>Combi Vast</MenuItem>
                              <MenuItem value={8}>Combi Variabel</MenuItem>
                              <MenuItem value={9}>Combi Dynamisch</MenuItem>
                              <MenuItem value={16}>
                                Combi Vast Professioneel
                              </MenuItem>
                              <MenuItem value={17}>
                                Combi Variabel Professioneel
                              </MenuItem>
                              <MenuItem value={18}>
                                Combi Dynamisch Professioneel
                              </MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="Energieleverancier"
                              select
                              label="Energieleverancier"
                              value={supplierId}
                              onChange={handleSupplierIdChange}
                              fullWidth
                              variant="standard"
                            >
                              <MenuItem value={0}>
                                Selecteer een energieleverancier
                              </MenuItem>
                              {energySuppliers.map((supplier) => (
                                <MenuItem key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <button
                      className="updateButton"
                      onClick={handleUpdateContract}
                    >
                      Contract aanpassen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default EditContractCombi;

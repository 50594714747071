import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavigationLeft from "../../../components/Navigation/NavigationLeft/NavigationLeft";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import zonnepanelenReinigen from "./images/zonnepanelenReinigen.jpg";
import airco from "./images/airco.jpg";
import energiemeter from "./images/energiemeter.jpg";
import ketel from "./images/ketel.jpg";
import monitorEuro from "./images/monitor-euro.jpg";
import monitoring from "./images/monitoring.jpg";
import fluviusLogo from "./images/fluvius.jpg";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import "./Services.css";
import "./../../../theme/styles.css";
import { getCookie } from "../../../cookieUtils";

const Services: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate();
  const isLargeScreen = window.innerWidth > 768;

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Diensten</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="image-grid">
              <div className="text-image-combo">
                <div className="image-container">
                  <p className="text-with-background">
                    Reclamevrije Monitoring in €
                  </p>
                  <Link to="/ChangeEnergyContractCustomer">
                    <img
                      src={monitorEuro}
                      alt="diensten"
                      className="section-image"
                    />
                  </Link>
                </div>
              </div>
              <div className="text-image-combo">
                <div className="image-container">
                  <p className="text-with-background">
                    Koppelen van Digitale meter
                  </p>
                  <Link to="/FluviusConnect">
                    <img
                      src={fluviusLogo}
                      alt="diensten"
                      className="section-image"
                    />
                  </Link>
                </div>
              </div>
              <div className="text-image-combo">
                <div className="image-container">
                  <p className="text-with-background">
                    Onderhoud Airco/Warmtepomp
                  </p>
                  <Link to="/AircoApplicationCustomer">
                    <img src={airco} alt="diensten" className="section-image" />
                  </Link>
                </div>
              </div>
              <div className="text-image-combo">
                <div className="image-container">
                  <p className="text-with-background">Ketelonderhoud</p>
                  <Link to="/BoilerMaintenanceApplicationCustomer">
                    <img src={ketel} alt="diensten" className="section-image" />
                  </Link>
                </div>
              </div>
              <div className="text-image-combo">
                <div className="image-container">
                  <p className="text-with-background">
                    Monitoring PV installatie
                  </p>
                  <Link to="/IndexCustomer">
                    <img
                      src={monitoring}
                      alt="diensten"
                      className="section-image"
                    />
                  </Link>
                </div>
              </div>
              <div className="text-image-combo">
                <div className="image-container">
                  <p className="text-with-background">Reinigen Zonnepanelen</p>
                  <Link to="/SolarPanelCleaningApplicationCustomer">
                    <img
                      src={zonnepanelenReinigen}
                      alt="diensten"
                      className="section-image"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Services;

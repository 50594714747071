import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Footer from "../../../components/Footer/Footer";
import "./FilesCustomer.css"; // Add a custom CSS file for styling
import PageHero from "../../../components/Pagehero/PageHero";
import { getCookie } from "../../../cookieUtils";

interface Document {
  id: string;
  userId: string;
  documentName: string;
  documentPath: string;
  status: number;
}

const FilesCustomer: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [showDropField, setShowDropField] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchDocuments();
  }, []);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const storedUser = getCookie("user");
      if (storedUser) {
        const user = JSON.parse(storedUser);
        const formData = new FormData();
        let validFiles = true;

        acceptedFiles.forEach((file) => {
          // Check if the file is in PDF format
          if (file.type === "application/pdf") {
            formData.append("document", file);
            formData.append("documentName", file.name); // Set the document name to the file's name
          } else {
            console.log(
              "U gaf een verkeerd bestandsformaat in, enkel PDF bestanden zijn toegelaten.",
            );
            validFiles = false;
          }
        });

        if (validFiles) {
          formData.append("userId", user.id); // Add the userId to the form data

          await axios.post(
            `${process.env.REACT_APP_DB_URL}/documents`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          console.log("Files uploaded successfully");
          fetchDocuments();
          setShowDropField(false); // Close the drop field after successful upload
        } else {
          showError(
            "U gaf een verkeerd bestandsformaat in, enkel PDF bestanden zijn toegelaten.",
          );
        }
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      showError("Er ging iets mis tijdens het uploaden van uw bestand.");
    }
  }, []);

  const fetchDocuments = async () => {
    try {
      const storedUser = getCookie("user");
      if (storedUser) {
        const user = JSON.parse(storedUser);
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/documents/user/${user.id}`,
        );
        const fetchedDocuments: Document[] = response.data
          .filter((document: Document) => document.status === 1) // Filter documents with status 1
          .map((document: Document) => ({
            ...document,
            documentPath: `${process.env.REACT_APP_DB_URL}/${document.documentPath}`,
          }));
        setDocuments(fetchedDocuments);
      }
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const deleteDocument = async (documentId: string) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/documents/${documentId}`,
        {
          status: 2,
        },
      );
      console.log("Document status updated successfully");
      fetchDocuments();
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  const downloadDocument = (documentPath: string) => {
    window.open(documentPath, "_blank");
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleButtonClick = () => {
    setShowDropField(!showDropField);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const showError = (message: string) => {
    setErrorMessage(message);
    setShowErrorMessage(true);
  };

  const hideError = () => {
    setShowErrorMessage(false);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Mijn bestanden</PageHero>
          <div className="pageContainer">
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer">
              <div>
                <button className="uploadButton" onClick={handleButtonClick}>
                  {showDropField ? "Annuleren" : "+"}
                </button>
              </div>
              {showDropField && (
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragActive ? "active" : ""}`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <p>
                      Klik op deze tekst om een bestand te selecteren of sleep
                      het document in dit vak.
                    </p>
                  )}
                </div>
              )}
              <div className="documentGrid">
                {documents.map((document) => (
                  <div key={document.id} className="documentCard">
                    <a
                      href={document.documentPath}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="documentName"
                    >
                      {document.documentName}
                    </a>
                    <div className="documentActions">
                      <button
                        className="deleteButton"
                        onClick={() => deleteDocument(document.id)}
                      >
                        Delete
                      </button>
                      <button
                        className="downloadButton"
                        onClick={() => downloadDocument(document.documentPath)}
                      >
                        Bekijken
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
      {showErrorMessage && (
        <div className="confirmationModal">
          <div className="confirmationContainer">
            <p className="confirmationText">{errorMessage}</p>
            <div className="confirmationButtons">
              <button className="confirmButton" onClick={hideError}>
                Sluiten
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilesCustomer;

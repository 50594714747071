import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EditEnergySupplier.css";
import { useParams, useNavigate } from "react-router-dom";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MenuItem from "@mui/material/MenuItem";
import PageHero from "../../../../components/Pagehero/PageHero";
import { Typography } from "@mui/material";
import { getCookie } from "../../../../cookieUtils";

const EditEnergySupplier = () => {
  const [name, setName] = useState("");
  const [active, setActive] = useState(0);
  const [evaluation, setEvaluation] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [image, setImage] = useState<File | null>(null); // State for storing the selected image
  const navigate = useNavigate();
  const { energySupplierId } = useParams<{ energySupplierId: string }>();
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchEnergySupplierDetails();
  }, []);

  const fetchEnergySupplierDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers/${energySupplierId}`,
      );
      const energySupplier = response.data;
      setName(energySupplier.name);
      setActive(energySupplier.active);
    } catch (error) {
      console.error("Error fetching energy supplier details:", error);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(Number(event.target.value));
  };

  const handleEvaluationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEvaluation(Number(event.target.value));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setImage(files[0]);
    }
  };

  const handleUpdateEnergySupplier = async () => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("active", active.toString());
      formData.append("evaluation", evaluation.toString());
      if (image) {
        formData.append("image", image);
      }

      await axios.put(
        `${process.env.REACT_APP_DB_URL}/energySuppliers/${energySupplierId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      console.log("Energy supplier updated successfully");
      navigate("/EnergySuppliers");
    } catch (error) {
      console.error("Error updating energy supplier:", error);
      setErrorMessage(
        "Failed to update the energy supplier. Please try again.",
      );
    }
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none",
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Energieleverancier aanpassen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="formContainer">
                {errorMessage && <p className="errorMessage">{errorMessage}</p>}
                <div className="form-group">
                  <Typography>Update het logo</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </Box>
                </div>

                <div className="form-group">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <ElectricBoltIcon
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <TextField
                      label="Naam"
                      variant="standard"
                      type="text"
                      id="nameFilter"
                      value={name}
                      onChange={handleNameChange}
                      fullWidth
                    />
                  </Box>
                </div>

                <div className="form-group">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Rol"
                      value={active}
                      onChange={handleActiveChange}
                      variant="standard"
                      fullWidth
                    >
                      <MenuItem value={1}>Actief</MenuItem>
                      <MenuItem value={0}>Niet Actief</MenuItem>
                    </TextField>
                  </Box>
                </div>

                <div className="form-group">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <TextField
                      id="tevredenheidsscore"
                      select
                      label="Tevredenheidsscore"
                      value={evaluation}
                      onChange={handleEvaluationChange}
                      variant="standard"
                      fullWidth
                    >
                      {[0, 1, 2, 3, 4, 5].map((score) => (
                        <MenuItem key={score} value={score}>
                          {score}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </div>

                <button
                  className="updateButton"
                  onClick={handleUpdateEnergySupplier}
                >
                  Energieleverancier aanpassen
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default EditEnergySupplier;

import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationLeft from "../../../../../components/Navigation/NavigationLeft/NavigationLeft";
import "./DetailUserAddress.css";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../../../../components/Navigation/NavBar";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PageHero from "../../../../../components/Pagehero/PageHero";
import CenterTitle from "../../../../../components/CenterTitle/CenterTitle";
import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
} from "recharts";
import { getCookie } from "../../../../../cookieUtils";

interface UserAddress {
  id: string;
  userId: string;
  name: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  dateTimeCreated: Date;
}

interface UserConsumptionElectricity {
  id: string;
  userId: string;
  date: Date;
  consumption: number;
}

interface UserConsumptionGas {
  id: string;
  userId: string;
  date: Date;
  consumption: number;
}

const DetailUserAddress = () => {
  const [userAddress, setUserAddress] = useState<UserAddress | null>(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const { userAddressId } = useParams<{ userAddressId: string }>();
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);
  const [userConsumptionElectricity, setUserConsumptionElectricity] = useState<
    UserConsumptionElectricity[]
  >([]);
  const [userConsumptionGas, setUserConsumptionGas] = useState<
    UserConsumptionGas[]
  >([]);
  const [selectedTimePeriod, setSelectedTimePeriod] =
    useState<string>("yearly"); // Default to daily
  const [selectedTimePeriodG, setSelectedTimePeriodG] =
    useState<string>("yearly"); // Default time period for gas is daily
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchUserAddress();
  }, []);

  const fetchUserAddress = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/user-addresses/${userAddressId}`,
      );
      setUserAddress(response.data);
    } catch (error) {
      console.error("Error retrieving user:", error);
    }
  };

  useEffect(() => {
    // Fetch consumption data based on the selected user address whenever userAddressId changes
    if (userAddress) {
      fetchConsumptionData();
    }
  }, [userAddress]); // Add userAddressId as a dependency

  const fetchConsumptionData = async () => {
    try {
      const UserConsumptionElectricityResponse = await axios.get(
        `${process.env.REACT_APP_DB_URL}/user-consumption-electricity/address/${userAddress?.id}`,
      );
      const electricityData = UserConsumptionElectricityResponse.data.map(
        (item: UserConsumptionElectricity) => ({
          date: new Date(item.date),
          consumption: item.consumption,
        }),
      );

      const UserConsumptionGasResponse = await axios.get(
        `${process.env.REACT_APP_DB_URL}/user-consumption-gas/address/${userAddress?.id}`,
      );
      const gasData = UserConsumptionGasResponse.data.map(
        (item: UserConsumptionGas) => ({
          date: new Date(item.date),
          consumption: item.consumption,
        }),
      );

      setUserConsumptionElectricity(electricityData);
      setUserConsumptionGas(gasData);
    } catch (error) {
      console.error("Error retrieving usagedata:", error);
    }
  };

  const handleEditUserAddress = (userAddressId: any) => {
    navigate(`/EditUserAddress/${userAddressId}`);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const dashboardContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  };

  const chartContainerStyles: React.CSSProperties = {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    background: "#ffffff",
    borderRadius: "8px",
    alignItems: "center",
    marginLeft: "10px",
    marginRight: "10px",
    paddingBottom: "50px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const chartStyles: React.CSSProperties = {
    flex: "1",
    height: "400px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    paddingLeft: "10px",
    position: "relative",
    paddingTop: "15px",
    margin: "0 auto", // Center the charts within the squares
    alignItems: "center", // Center the charts horizontally
  };

  // Function to sort data by date
  const sortByDate = (data: UserConsumptionElectricity[]) => {
    return data.sort((a, b) => a.date.getTime() - b.date.getTime());
  };

  // Function to sort data by date
  const sortByDateG = (data: UserConsumptionGas[]) => {
    return data.sort((a, b) => a.date.getTime() - b.date.getTime());
  };

  const filterDataByTimePeriod = (
    data: UserConsumptionElectricity[],
    timePeriod: string,
  ) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "daily":
        return data.filter(
          (item) =>
            item.date.getFullYear() === currentDate.getFullYear() &&
            item.date.getMonth() === currentDate.getMonth() &&
            item.date.getDate() === currentDate.getDate(),
        );
      case "weekly":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= oneWeekAgo);
      case "monthly":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneMonthAgo);
      case "yearly":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneYearAgo);
      default:
        return data;
    }
  };

  const filterDataByTimePeriodG = (
    data: UserConsumptionGas[],
    timePeriod: string,
  ) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "daily":
        return data.filter(
          (item) =>
            item.date.getFullYear() === currentDate.getFullYear() &&
            item.date.getMonth() === currentDate.getMonth() &&
            item.date.getDate() === currentDate.getDate(),
        );
      case "weekly":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= oneWeekAgo);
      case "monthly":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneMonthAgo);
      case "yearly":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneYearAgo);
      default:
        return data;
    }
  };

  // Calculate the maximum consumption value of electricity
  const maxConsumption = Math.max(
    ...userConsumptionElectricity.map((item) => item.consumption),
  );

  // Calculate the maximum consumption value of gas
  const maxConsumptionGas = Math.max(
    ...userConsumptionGas.map((item) => item.consumption),
  );

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Detailoverzicht gebruikersadres</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    <button
                      className="addButton"
                      onClick={() => handleEditUserAddress(userAddressId)}
                    >
                      Gebruiker Adres aanpassen
                    </button>
                  </div>
                </div>
                <hr className="dividerDashed" />
                {userAddress && (
                  <div className="userCardDetailAddressUser">
                    <div className="userCardContainerDetailAddressUser">
                      <div className="userCardColumnDetailAddressUser">
                        <div className="userDetailContainer">
                          <div className="label">Naam:</div>
                          <div className="text">{userAddress.name}</div>
                          <div className="label">Straatnaam:</div>
                          <div className="text">{userAddress.streetname}</div>
                          <div className="label">Huisnummer:</div>
                          <div className="text">{userAddress.housenumber}</div>
                          <div className="label">Postcode:</div>
                          <div className="text">{userAddress.zipcode}</div>
                          <div className="label">Stad:</div>
                          <div className="text">{userAddress.city}</div>
                          <div className="label">EANE:</div>
                          {userAddress.eane && (
                            <div className="text">{userAddress.eane}</div>
                          )}
                          <div className="label">EANG:</div>
                          {userAddress.eang && (
                            <div className="text">{userAddress.eang}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {userConsumptionElectricity.length > 0 &&
                userConsumptionGas.length > 0 && (
                  <div>
                    <CenterTitle
                      title="Verbruik"
                      subtitle="Hier kan u het verbruik van de klant op deze locatie terugvinden."
                    ></CenterTitle>
                    <div
                      className="dashboardContainer"
                      style={dashboardContainerStyles}
                    >
                      <div style={chartContainerStyles}>
                        <div className="square" style={chartStyles}>
                          <p>Uw elektriciteitsverbruik: </p>
                          <select
                            value={selectedTimePeriod}
                            onChange={(event) =>
                              setSelectedTimePeriod(event.target.value)
                            }
                          >
                            <option value="daily">Dagelijks</option>
                            <option value="weekly">Wekelijks</option>
                            <option value="monthly">Maandelijks</option>
                            <option value="yearly">Jaarlijks</option>
                          </select>

                          {userConsumptionElectricity.length === 0 ? (
                            <p>No data available</p>
                          ) : (
                            <ResponsiveContainer
                              width={screenWidth > 500 ? 700 : 280}
                            >
                              <AreaChart
                                data={filterDataByTimePeriod(
                                  sortByDate(userConsumptionElectricity),
                                  selectedTimePeriod,
                                )}
                                margin={{
                                  top: 10,
                                  right: 0,
                                  left: 0,
                                  bottom: 0,
                                }}
                              >
                                <defs>
                                  <linearGradient
                                    id="colorUv"
                                    x1="1"
                                    y1="0"
                                    x2="0"
                                    y2="0"
                                  >
                                    <stop
                                      offset="30%"
                                      stopColor="#269B66"
                                      stopOpacity={0.8}
                                    />
                                    <stop
                                      offset="100%"
                                      stopColor="#87BD45"
                                      stopOpacity={0.2}
                                    />
                                  </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  dataKey="date"
                                  domain={["dataMin", "dataMax + 45"]}
                                  scale="time"
                                  tickFormatter={(timestamp) =>
                                    new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                    }).format(new Date(timestamp))
                                  }
                                />
                                {/* <YAxis domain={['dataMin - 15', 'dataMax + 15']} /> */}
                                {/* <YAxis  /> */}
                                <YAxis
                                  domain={[0, Math.ceil(maxConsumption) + 45]}
                                />
                                <Tooltip
                                  labelFormatter={(label) =>
                                    new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                    }).format(new Date(label))
                                  }
                                />
                                <Legend />
                                <Area
                                  type="monotone"
                                  dataKey="consumption"
                                  stroke="#8dc63f"
                                  fill="url(#colorUv)"
                                  fillOpacity={1}
                                />
                              </AreaChart>
                            </ResponsiveContainer>
                          )}
                        </div>
                      </div>
                      <div style={chartContainerStyles}>
                        <div className="square" style={chartStyles}>
                          <p>Uw gas verbruik: </p>
                          <select
                            value={selectedTimePeriodG}
                            onChange={(event) =>
                              setSelectedTimePeriodG(event.target.value)
                            }
                          >
                            <option value="daily">Dagelijks</option>
                            <option value="weekly">Wekelijks</option>
                            <option value="monthly">Maandelijks</option>
                            <option value="yearly">Jaarlijks</option>
                          </select>
                          {userConsumptionGas.length === 0 ? (
                            <p>No data available</p>
                          ) : (
                            <ResponsiveContainer
                              width={screenWidth > 500 ? 700 : 280}
                            >
                              <AreaChart
                                data={filterDataByTimePeriodG(
                                  sortByDateG(userConsumptionGas),
                                  selectedTimePeriodG,
                                )}
                                margin={{
                                  top: 10,
                                  right: 0,
                                  left: 0,
                                  bottom: 0,
                                }}
                              >
                                <defs>
                                  <linearGradient
                                    id="colorGv"
                                    x1="1"
                                    y1="0"
                                    x2="0"
                                    y2="0"
                                  >
                                    <stop
                                      offset="30%"
                                      stopColor="#269B66"
                                      stopOpacity={0.8}
                                    />
                                    <stop
                                      offset="100%"
                                      stopColor="#87BD45"
                                      stopOpacity={0.2}
                                    />
                                  </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  dataKey="date"
                                  domain={["dataMin", "dataMax"]}
                                  scale="time"
                                  tickFormatter={(timestamp) =>
                                    new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                    }).format(new Date(timestamp))
                                  }
                                />
                                {/* <YAxis domain={['dataMin - 15', 'dataMax + 15']} /> */}
                                {/* <YAxis /> */}
                                <YAxis
                                  domain={[
                                    0,
                                    Math.ceil(maxConsumptionGas) + 45,
                                  ]}
                                />
                                <Tooltip
                                  labelFormatter={(label) =>
                                    new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                    }).format(new Date(label))
                                  }
                                />
                                <Legend />
                                <Area
                                  type="monotone"
                                  dataKey="consumption"
                                  stroke="#8dc63f"
                                  fill="url(#colorGv)"
                                  fillOpacity={1}
                                />
                              </AreaChart>
                            </ResponsiveContainer>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default DetailUserAddress;

import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import "./SolarPanelsApplicationDetail.css";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import { MenuItem, TextField } from "@mui/material";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  password: string;
  roleId: number;
}

interface SolarPanelRequest {
  id: string;
  userId: string;
  annualConsumption: number;
  roofingMaterial: number;
  connection: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  interestedInChargingStation: number;
  interestedInAirco: number;
  interestedInBattery: number;
  presentTechniquesSolar: number;
  presentTechniquesBattery: number;
  presentTechniquesPoints: number;
  presentTechniquesHeat: number;
  presentTechniquesEMS: number;
  presentTechniquesNone: number;
  presentTechniquesUnknown: number;
  quoteFor: number;
  powerSolar: string;
  powerConverter: string;
  brandConverter: string;
  batteryCapacity: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

const SolarPanelsApplicationDetail = () => {
  const [user, setUser] = useState<User | null>(null);
  const [solarPanelRequest, setSolarPanelRequest] =
    useState<SolarPanelRequest | null>(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [status, setStatus] = useState(0);
  const [statusChangeSuccess, setStatusChangeSuccess] = useState(false);
  const [lastChangedBy, setLastChangedBy] = useState<string | null>(null);
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    if (id) {
      fetchDetails(id);
    }
  }, [id]);

  const fetchDetails = async (id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/SolarPanelRequest/${id}`,
      );
      const solarPanelRequest = response.data;
      const userId = solarPanelRequest.userId;

      const userResponse = await axios.get(
        `${process.env.REACT_APP_DB_URL}/users/${userId}`,
      );
      const userData = userResponse.data;

      setSolarPanelRequest(solarPanelRequest);
      setUser(userData);
    } catch (error) {
      console.error("Error retrieving data:", error);
    }
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  useEffect(() => {
    if (solarPanelRequest) {
      setStatus(solarPanelRequest.status);
      fetchLastChangedBy(solarPanelRequest.id);
    }
  }, [solarPanelRequest]);

  const fetchLastChangedBy = async (requestId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/RequestProcessor/byRequestId/${requestId}`,
      );
      const requestProcessors = response.data;
      setLastChangedBy(
        `${requestProcessors.Surname} ${requestProcessors.Name}`,
      );
    } catch (error) {
      console.error("Error retrieving last changed by:", error);
      setLastChangedBy(null);
    }
  };

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      const newStatus = parseInt(event.target.value);
      setStatus(newStatus);

      if (solarPanelRequest) {
        const updatedSolarPanelRequest = {
          ...solarPanelRequest,
          status: newStatus,
        };
        await axios.put(
          `${process.env.REACT_APP_DB_URL}/SolarPanelRequest/${id}`,
          updatedSolarPanelRequest,
        );
        setSolarPanelRequest(updatedSolarPanelRequest);

        setStatusChangeSuccess(true);
        setTimeout(() => {
          setStatusChangeSuccess(false);
        }, 3000);

        const storedUser = getCookie("user");
        const user = storedUser ? JSON.parse(storedUser) : null;

        await axios.post(`${process.env.REACT_APP_DB_URL}/RequestProcessor`, {
          userId: user.id,
          requestId: id,
          Surname: user.surname,
          Name: user.name,
          Email: user.email,
        });
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none",
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const renderPresentTechniques = () => {
    if (!solarPanelRequest) return "";

    const techniques = [];

    if (solarPanelRequest.presentTechniquesSolar === 1) {
      techniques.push("Zonnepanelen");
    }
    if (solarPanelRequest.presentTechniquesBattery === 1) {
      techniques.push("Thuisbatterij");
    }
    if (solarPanelRequest.presentTechniquesPoints === 1) {
      techniques.push("Laadpunt(en)");
    }
    if (solarPanelRequest.presentTechniquesHeat === 1) {
      techniques.push("(Hybride) Warmtepomp");
    }
    if (solarPanelRequest.presentTechniquesEMS === 1) {
      techniques.push("EMS");
    }
    if (solarPanelRequest.presentTechniquesNone === 1) {
      return "Geen van bovenstaande";
    }
    if (solarPanelRequest.presentTechniquesUnknown === 1) {
      return "Onbekend";
    }

    return techniques.length > 0
      ? techniques.join(", ")
      : "Geen technieken geselecteerd";
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Detailoverzicht aanvraag zonnepanelen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        select
                        label="Verander status van aanvraag:"
                        variant="standard"
                        value={status}
                        onChange={handleStatusChange}
                      >
                        <MenuItem key={1} value={1}>
                          Openstaand
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Bewerkt
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Verkocht
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                          Afgewezen
                        </MenuItem>
                      </TextField>
                    </Box>

                    {statusChangeSuccess && (
                      <div className="successMessage">
                        De status is succesvol veranderd.
                      </div>
                    )}
                    {lastChangedBy && (
                      <div className="userCard">
                        {lastChangedBy} heeft de status laatst gewijzigd.
                      </div>
                    )}
                  </div>
                </div>
                <hr className="dividerDashed" />
                <div className="userCardsRow">
                  <div className="userCard">
                    {user && (
                      <div className="userDetailContainer">
                        <h2>Gebruiker gegevens</h2>
                        <div className="label">Voornaam:</div>
                        <div className="text">{user.surname}</div>
                        <div className="label">Achternaam:</div>
                        <div className="text">{user.name}</div>
                        <div className="label">Email:</div>
                        <div className="text">{user.email}</div>
                        <div className="label">Telefoonnummer:</div>
                        <div className="text">{user.phone}</div>
                        <div className="label">Adres:</div>
                        <div className="text">
                          {user.streetname}, {user.housenumber}
                        </div>
                        <div className="label">Postcode:</div>
                        <div className="text">{user.zipcode}</div>
                        <div className="label">Stad:</div>
                        <div className="text">{user.city}</div>
                      </div>
                    )}
                  </div>
                  <div className="userCard">
                    {solarPanelRequest && (
                      <div className="userDetailContainer">
                        <h2>Aanvraag gegevens</h2>
                        <div className="label">Jaarlijks verbruik:</div>
                        <div className="text">
                          {solarPanelRequest?.annualConsumption}
                        </div>
                        <div className="label">Type dakbedekking:</div>
                        <div className="text">
                          {solarPanelRequest?.roofingMaterial === 1
                            ? "Plat"
                            : solarPanelRequest?.roofingMaterial === 2
                              ? "Pannen"
                              : solarPanelRequest?.roofingMaterial === 3
                                ? "Leien"
                                : solarPanelRequest?.roofingMaterial === 4
                                  ? "Andere"
                                  : ""}
                        </div>
                        <div className="label">Aansluiting:</div>
                        <div className="text">
                          {solarPanelRequest?.connection === 1
                            ? "Monofase"
                            : solarPanelRequest?.connection === 2
                              ? "3 fase"
                              : solarPanelRequest?.connection === 3
                                ? "Onbekend"
                                : ""}
                        </div>
                        <div className="label">Adres:</div>
                        <div className="text">
                          {solarPanelRequest?.streetname},{" "}
                          {solarPanelRequest?.housenumber}
                        </div>
                        <div className="label">Postcode:</div>
                        <div className="text">{solarPanelRequest?.zipcode}</div>
                        <div className="label">Stad:</div>
                        <div className="text">{solarPanelRequest?.city}</div>
                        <div className="label">Aanwezig technieken:</div>
                        <div className="text">{renderPresentTechniques()}</div>
                        {solarPanelRequest?.powerSolar && (
                          <>
                            <div className="label">
                              Vermogen Huidige zonnepanelen (KWP):
                            </div>
                            <div className="text">
                              {solarPanelRequest?.powerSolar}
                            </div>
                          </>
                        )}
                        {solarPanelRequest?.powerConverter && (
                          <>
                            <div className="label">
                              Vermogen omvormer (KVA):
                            </div>
                            <div className="text">
                              {solarPanelRequest?.powerConverter}
                            </div>
                          </>
                        )}
                        {solarPanelRequest?.brandConverter && (
                          <>
                            <div className="label">Merk/Type omvormer:</div>
                            <div className="text">
                              {solarPanelRequest?.brandConverter}
                            </div>
                          </>
                        )}
                        {solarPanelRequest?.batteryCapacity && (
                          <>
                            <div className="label">
                              Opslagcapaciteit huidige batterij (KWH):
                            </div>
                            <div className="text">
                              {solarPanelRequest?.batteryCapacity}
                            </div>
                          </>
                        )}
                        <div className="label">Opmerkingen:</div>
                        <div className="text">{solarPanelRequest?.comment}</div>
                        <div className="label">Datum en tijd van aanvraag:</div>
                        <div className="text">
                          {solarPanelRequest?.datetimeCreated.toLocaleString()}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default SolarPanelsApplicationDetail;

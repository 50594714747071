import React, { useEffect, useState } from "react";
import NavigationLeft from "../../../components/Navigation/NavigationLeft/NavigationLeft";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import { IoGrid, IoCall, IoMail } from "react-icons/io5";
import salesImage from "./sales.jpg";
import algemeenImage from "./algemeen.jpg";
import itImage from "./it.jpg";
import "./Contact.css";
import "./../../../theme/styles.css";
import { getCookie } from "../../../cookieUtils";

const Contact: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Contact</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <h2 className="section-title">Sales</h2>
                  <div className="green-divider"></div>
                  <p className="section-paragraph">
                    Als u vragen heeft over onze producten, diensten of
                    mogelijkheden, aarzel dan niet om contact op te nemen met
                    ons sales team. Onze toegewijde sales professionals staan
                    klaar om u te helpen met al uw vragen en om u te voorzien
                    van de informatie die u nodig heeft.
                  </p>

                  <div className="iconlist-spacing">
                    <a href="mailto:sales@energyneeds.be">
                      {" "}
                      <span style={{ marginRight: "5px" }}>
                        <IoMail />
                      </span>{" "}
                      sales@energyneeds.be
                    </a>
                  </div>
                  {/* <div className="iconlist-spacing">
      <a href='tel:+32472854697'> <span style={{ marginRight: '5px' }}><IoCall /></span> +32 472 85 46 97</a>
       </div> */}
                  <button
                    className="buttonstyle33"
                    onClick={() =>
                      (window.location.href =
                        "whatsapp://send?phone=+32472854697")
                    }
                  >
                    Contacteer via Whats'app
                  </button>
                </div>
                <div className="image-column">
                  <img
                    src={salesImage}
                    alt="Section Image"
                    className="section-image"
                  />

                  {/* <div className="section-container">
  <div className="text-column">
    <h2 className="section-title">Sales</h2>
    <div className="green-divider"></div>
    <p className="section-paragraph">
      Als u vragen heeft over onze producten, diensten of mogelijkheden, aarzel dan niet om contact op te nemen met ons sales team. Onze toegewijde sales professionals staan klaar om u te helpen met al uw vragen en om u te voorzien van de informatie die u nodig heeft.
    </p>
    <div className="iconlist-spacing">
      <a href='mailto:sales@energyneeds.be'> <span style={{ marginRight: '5px' }}><IoMail /></span> sales@energyneeds.be</a>
    </div>
    <button className='buttonstyle33' onClick={() => window.location.href = 'whatsapp://send?phone=+32472854697'}>
      Contacteer via Whats'app
    </button>
  </div>
  <div className="image-column">
    <div className="grid-container">
      <div className="grid-row">
        <img src={salesImage} alt="Section Image 1" className="section-image" />
        <img src={salesImage} alt="Section Image 2" className="section-image hide-on-mobile" />
      </div>
      <div className="grid-row">
        <img src={salesImage} alt="Section Image 3" className="section-image hide-on-mobile" />
        <img src={salesImage} alt="Section Image 4" className="section-image hide-on-mobile" />
      </div>
    </div> */}
                </div>

                <div className="image-columntwo">
                  <img
                    src={algemeenImage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
                <div className="text-columntwo">
                  <h2 className="section-title">Algemeen</h2>
                  <div className="green-divider"></div>
                  <p className="section-paragraph">
                    Heeft u vragen, opmerkingen of wilt u graag meer informatie
                    ontvangen over onze diensten en mogelijkheden? Aarzel niet
                    om contact op te nemen met ons algemene team. Onze
                    toegewijde teamleden staan klaar om u te assisteren en uw
                    vragen te beantwoorden.
                  </p>
                  <div className="iconlist-spacing">
                    <a href="mailto:info@energyneeds.be">
                      {" "}
                      <span style={{ marginRight: "5px" }}>
                        <IoMail />
                      </span>{" "}
                      info@energyneeds.be
                    </a>
                  </div>
                  {/* <div className="iconlist-spacing">
      <a href='tel:+32476477571'> <span style={{ marginRight: '5px' }}><IoCall /></span> +32 476 47 75 71</a>
       </div> */}
                  <button
                    className="buttonstyle33"
                    onClick={() =>
                      (window.location.href =
                        "whatsapp://send?phone=+32476477571")
                    }
                  >
                    {" "}
                    Contacteer via Whats'app
                  </button>
                </div>
                <div className="text-columnthree">
                  <h2 className="section-title">IT</h2>
                  <div className="green-divider"></div>
                  <p className="section-paragraph">
                    Heeft u vragen, problemen of opmerkingen met betrekking tot
                    ons platform? Ons IT-team staat voor u klaar om u te helpen.
                    Of u nu technische ondersteuning nodig heeft, vragen heeft
                    over de functionaliteit van ons platform of feedback wilt
                    geven om de gebruikerservaring te verbeteren, wij zijn er om
                    te assisteren.
                  </p>
                  <div className="iconlist-spacing">
                    <a href="mailto:support@energyneeds.be">
                      {" "}
                      <span style={{ marginRight: "5px" }}>
                        <IoMail />
                      </span>{" "}
                      support@energyneeds.be
                    </a>
                  </div>
                  {/* <div className="iconlist-spacing">
      <a href='tel:+32472822890'> <span style={{ marginRight: '5px' }}><IoCall /></span> +32 465 09 37 57</a>
       </div> */}
                  <button
                    className="buttonstyle33"
                    onClick={() =>
                      (window.location.href =
                        "whatsapp://send?phone=+32472822890")
                    }
                  >
                    Contacteer via Whats'app
                  </button>
                </div>
                <div className="image-columnthree">
                  <img
                    src={itImage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default Contact;

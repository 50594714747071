import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import "../Articles.css";
import solarpanelsimage from "./solarpanels.jpg";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import { getCookie } from "../../../../../cookieUtils";

const SolarPanelsArticle: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const subheadingStyles: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "30px",
  };

  const subheadingStylesa: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "15px",
    marginBottom: "-0px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>Waarom zijn zonnepanelen voordelig?</PageHeroColored>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-containera">
                <div className="text-columna">
                  <p className="section-paragraph">
                    <b>
                      {" "}
                      Zonnepanelen zijn een uitstekende investering voor
                      huiseigenaren en bedrijven. Ze bieden talloze voordelen,
                      waaronder het verminderen van uw energierekening, het
                      verminderen van uw afhankelijkheid van het
                      elektriciteitsnet en het verminderen van uw ecologische
                      voetafdruk.
                    </b>
                  </p>
                  <h2 style={subheadingStylesa}>
                    Schone en hernieuwbare energie
                  </h2>
                  <p className="section-paragraph">
                    Het grootste voordeel van zonnepanelen is dat ze
                    gebruikmaken van een hernieuwbare en schone energiebron: de
                    zon. In tegenstelling tot fossiele brandstoffen, die
                    schadelijke broeikasgassen produceren, stoten zonnepanelen
                    geen vervuilende stoffen uit. Dit draagt bij aan de strijd
                    tegen klimaatverandering en helpt de luchtkwaliteit te
                    verbeteren.
                  </p>
                  <h2 style={subheadingStyles}>
                    Verlaging van de elektriciteitskosten
                  </h2>
                  <div className="section-paragraph-full">
                    <p>
                      Een ander belangrijk voordeel van zonnepanelen is dat ze
                      uw elektriciteitsrekening aanzienlijk kunnen verlagen.
                      Zodra de panelen zijn geïnstalleerd, kunt u profiteren van
                      gratis elektriciteit van de zon. Hierdoor hoeft u minder
                      elektriciteit van uw energieleverancier af te nemen, wat
                      resulteert in lagere maandelijkse kosten.
                    </p>
                  </div>
                </div>

                <div className="image-column">
                  <img
                    src={solarpanelsimage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
              </div>

              <div className="section-container-full">
                {/* Reduced Electricity Costs */}
                {/* <h2 style={subheadingStyles}>Verlaging van de elektriciteitskosten</h2>
              <div className='section-paragraph-full'>
            <p>
              Een ander belangrijk voordeel van zonnepanelen is dat ze uw elektriciteitsrekening aanzienlijk kunnen verlagen. Zodra de panelen zijn geïnstalleerd, kunt u profiteren van gratis elektriciteit van de zon. Hierdoor hoeft u minder elektriciteit van uw energieleverancier af te nemen, wat resulteert in lagere maandelijkse kosten.
            </p>
          </div> */}

                {/* Energy Independence */}
                <h2 style={subheadingStyles}>Energieonafhankelijkheid</h2>
                <div className="section-paragraph-full">
                  <p>
                    Bovendien kunnen zonnepanelen u helpen om minder afhankelijk
                    te zijn van het elektriciteitsnet. Op zonnige dagen kunnen
                    ze meer elektriciteit produceren dan u verbruikt, waardoor
                    het overschot terug wordt geleverd aan het net. Dit kan
                    resulteren in een krediet op uw elektriciteitsrekening.
                    Daarnaast bieden zonnepanelen een vorm van
                    energieonafhankelijkheid, wat vooral handig kan zijn in
                    geval van stroomstoringen.
                  </p>
                </div>

                {/* Conclusion */}
                <h2 style={subheadingStyles}>Conclusie</h2>
                <div className="section-paragraph-full">
                  <p>
                    Zonnepanelen zijn niet alleen goed voor het milieu, maar ook
                    voor uw portemonnee. Door te investeren in zonnepanelen kunt
                    u uw energiekosten verlagen en bijdragen aan een duurzamere
                    toekomst. Met de voortdurende technologische ontwikkelingen
                    worden zonnepanelen steeds efficiënter en betaalbaarder,
                    waardoor ze een slimme keuze zijn voor iedereen die op zoek
                    is naar duurzame energieoplossingen.
                  </p>
                  <p>
                    Als u overweegt om zonnepanelen te installeren, is het
                    raadzaam om contact op te nemen met een professionele
                    zonnepaneleninstallateur om de mogelijkheden voor uw
                    specifieke situatie te bespreken. Zo kunt u maximaal
                    profiteren van de voordelen die zonnepanelen te bieden
                    hebben.
                  </p>
                  <button className="buttonstyle">
                    Bereken hier uw voordeel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default SolarPanelsArticle;

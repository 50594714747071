import React, { useEffect, useState } from "react";
import "./PasswordForgottenReset.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import YouTube from "react-youtube";

interface PasswordRequest {
  id: string;
  userId: string;
  email: string;
  dateTimeCreated: Date;
}

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  password: string;
  roleId: number;
  dateTimeCreated: Date;
}

const PasswordForgottenReset: React.FC = () => {
  const { handleSubmit, register, setError } = useForm();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailFocused, setEmailFocused] = useState(false);
  const [passwordrequest, setPasswordRequest] =
    useState<PasswordRequest | null>(null);
  const { passwordrequestId } = useParams<{ passwordrequestId: string }>(); // Extract the user ID from the URL
  const [user, setUser] = useState<User | null>(null);
  const [isPasswordFocused, setPasswordFocused] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_DB_URL}/passwordrequest/${passwordrequestId}`,
        )
        .then((response) => {
          const responsePas = response.data;
          setPasswordRequest(responsePas);
          axios
            .get(`${process.env.REACT_APP_DB_URL}/users/${responsePas.userId}`)
            .then((responseUser) => {
              const ResponseU = responseUser.data;
              setUser(ResponseU);
            });
        });

      console.log("success");
    } catch (error) {
      console.error("Error retrieving data:", error);
      // Handle error state or display an error message
    }
  };

  const handlePasswordForgotten = async () => {
    try {
      const updatedUser = {
        companyUserId: user?.companyUserId,
        surname: user?.surname,
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
        streetname: user?.streetname,
        housenumber: user?.housenumber,
        zipcode: user?.zipcode,
        city: user?.city,
        password: newPassword,
        roleId: user?.roleId,
      };
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/users/${user?.id}`,
        updatedUser,
      );
      navigate("/login");
    } catch (error) {
      console.error("Error updating user:", error);
      // Handle error state or display an error message
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handlePasswordBlur = () => {
    setPasswordFocused(false);
  };

  const handleEmailFocus = () => {
    setEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setEmailFocused(true);
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewPassword(event.target.value);
  };

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };

  const opts = {
    playerVars: {
      autoplay: 1, // Autoplay the video
      controls: 0, // Hide the player controls
      modestbranding: 1, // Hide the YouTube logo
      loop: 1, // Play the video on repeat
      playlist: "wziCr2xFXX0", // Playlist to ensure the loop works
    },
  };

  return (
    <div className="login-page">
      {/* <div className="login-image"></div> */}
      <YouTube videoId="wziCr2xFXX0" className="login-video" opts={opts} />
      <div className="login-form">
        <h2 className="title">Stel een nieuw wachtwoord in</h2>
        <p>
          Vul hier in het onderstaande veld een nieuw wachtwoord voor uw account
          in.
        </p>
        <div className={`form-group ${isPasswordFocused ? "focused" : ""}`}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel htmlFor="password">Wachtwoord</InputLabel>
              <Input
                type={isPasswordVisible ? "text" : "password"}
                id="password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                onFocus={handlePasswordFocus}
                onBlur={handlePasswordBlur}
                endAdornment={
                  <InputAdornment position="end">
                    {isPasswordVisible ? (
                      <IoEyeOffSharp
                        size={24}
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <IoEyeSharp
                        size={24}
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </div>
        <div className="link-container">
          <Link to="/Login" className="custom-link1">
            Inloggen
          </Link>

          <Link to="/Register" className="custom-link2">
            Registreren{" "}
          </Link>
        </div>
        <button
          className="login-button"
          type="submit"
          onClick={handlePasswordForgotten}
        >
          Wachtwoord instellen
        </button>

        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default PasswordForgottenReset;

import React from "react";
import "./SectionHeroColored.css"; // Import your CSS styles for the Hero component

interface SectionHeroColoredProps {
  children: React.ReactNode;
  subText: string;
}

const SectionHeroColored: React.FC<SectionHeroColoredProps> = ({
  children,
  subText,
}) => {
  return (
    <div className="SectionHeroColored">
      <h1>{children}</h1>
      <p className="SubText">{subText}</p>
    </div>
  );
};

export default SectionHeroColored;

import React from "react";
import "./PageHero.css"; // Import your CSS styles for the Hero component

interface HeroProps {
  children: React.ReactNode;
}

const Hero: React.FC<HeroProps> = ({ children }) => {
  return (
    <div className="hero">
      <h1>{children}</h1>
    </div>
  );
};

export default Hero;

import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import { useNavigate } from "react-router";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import CenterTitle from "../../../../components/CenterTitle/CenterTitle";
import mailSuccess from "./success.json";
import Lottie from "lottie-react";
import "../../../../theme/styles.css";
import "./ProfilePasswordReset.css";
import { getCookie } from "../../../../cookieUtils";

const ProfilePasswordReset: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate();

  const containerStyles: React.CSSProperties = {
    display: "flex",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleNavigateToIndex = () => {
    navigate("/IndexCustomer");
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Succesvolle aanvraag</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <h2 className="section-title">
                    Succesvolle aanvraag tot wachtwoord herstel!
                  </h2>
                  <div className="green-divider"></div>
                  <p className="section-paragraph">
                    U heeft succesvol een nieuw wachtwoord aangevraagd.
                    Controleer uw e-mail adres dat gekoppeld is aan dit account
                    om het wachtwoord opnieuw in te stellen.
                  </p>

                  <button
                    className="buttonstyle33"
                    onClick={handleNavigateToIndex}
                  >
                    Ga naar startpagina
                  </button>
                </div>
                <div className="image-column">
                  <Lottie animationData={mailSuccess} loop={false} autoplay />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default ProfilePasswordReset;

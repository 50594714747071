import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import "../../SolarPanelsApplicationCustomer/SolarPanelsApplicationCustomer/SolarPanelsApplicationCustomer.css";
import SquareComponent from "../../../../components/InfoSquares/InfoSquaresSales/InfoSquares";
import SquareComponentIt from "../../../../components/InfoSquares/InfoSquaresIt/InfoSquaresIt";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import "./../../../../theme/styles.css";
import { v4 as uuidv4 } from "uuid";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import { getCookie } from "../../../../cookieUtils";

interface EnergySupplier {
  id: string;
  name: string;
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

const AddContractsElectricity: React.FC = () => {
  // params users
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(0);
  const [supplierId, setSupplierId] = useState("");
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  // const [contractElectricityDetailsId, setcontractElectricityDetailsId] = useState("");
  const [yearlySubscription, setyearlySubscription] = useState(0.01);
  const [kwhConsumptionPrice, setkwhConsumptionPrice] = useState(0.01);
  const [kwhConsumptionPriceDiscount, setkwhConsumptionPriceDiscount] =
    useState(0.01);
  const [
    kwhConsumptionPriceAfterDiscount,
    setkwhConsumptionPriceAfterDiscount,
  ] = useState(0.01);

  const [kwhConsumptionPriceTwoDay, setKwhConsumptionPriceTwoDay] =
    useState(0.01);
  const [kwhConsumptionPriceTwoNight, setKwhConsumptionPriceTwoNight] =
    useState(0.01);
  const [kwhConsumptionPriceNight, setKwhConsumptionPriceNight] =
    useState(0.01);
  const [fixedPriceElectricity, setFixedPriceElectricity] = useState(0.01);

  const [hewwkContribution, sethewwkContribution] = useState(0.01);
  const [dataManagementPrice, setdataManagementPrice] = useState(0.01);
  const [totalConumptionCostPrice, settotalConumptionCostPrice] =
    useState(0.01);
  const [capacityRatePrice, setcapacityRatePrice] = useState(0.01);
  const [purchaseRatePrice, setpurchaseRatePrice] = useState(0.01);
  const [taxPrice, settaxPrice] = useState(0.01);
  const [
    kwhInjectionSolarPanelsDiscountPrice,
    setkwhInjectionSolarPanelsDiscountPrice,
  ] = useState(0.01);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [userAddressId, setUserAddressId] = useState("");
  const [streetname, setStreetname] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [eane, setEane] = useState("54");
  const [eang, setEang] = useState("54");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [isStreetnameTouched, setIsStreetnameTouched] = useState(false);
  const [isHousenumberTouched, setIsHousenumberTouched] = useState(false);
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false);
  const [isCityTouched, setIsCityTouched] = useState(false);
  // const [isDescriptionTouched, setDescriptionTouched] = useState(false);
  // const [isCategoryTouched, setIsCategoryTouched] = useState(false);
  // const [isEnergySuppliersTouched, setIsEnergySuppliersTouched] = useState(false);
  // const [isYearlySubscriptionTouched, setIsYearlySubscriptionTouched] = useState(false);
  // const [iskwhConsumptionPriceTouched, setIskwhConsumptionPriceTouched] = useState(false);
  // const [iskwhConsumptionPriceTwoDayTouched, setIskwhConsumptionPriceTwoDayTouched] = useState(false);
  // const [iskwhConsumptionPriceTouchedTwoNight, setIskwhConsumptionPriceTwoNightTouched] = useState(false);
  // const [iskwhConsumptionPriceNightTouched, setIskwhConsumptionPriceNightTouched] = useState(false);

  const navigate = useNavigate();

  // Get stored userdata
  const storedUser = getCookie("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const userId = user.id;
  console.log(user);
  // Handle data
  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      const suppliers: EnergySupplier[] = response.data;
      setEnergySuppliers(suppliers);
    } catch (error) {
      console.error("Error fetching energy suppliers:", error);
    }
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleSupplierIdChange = (event: any) => {
    setSupplierId(event.target.value);
  };

  const handleYearlySubscriptionChange = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setyearlySubscription(categorychange);
  };

  const handleKwhConsumptionPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPrice(categorychange);
  };

  const handleKwhConsumptionPriceTwoDay = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setKwhConsumptionPriceTwoDay(categorychange);
  };

  const handleKwhConsumptionPriceTwoNight = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setKwhConsumptionPriceTwoNight(categorychange);
  };
  const handleKwhConsumptionPriceNight = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setKwhConsumptionPriceNight(categorychange);
  };

  const handleCategoryChange = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setCategory(categorychange);
  };

  const handleStreetnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStreetname(event.target.value);
    setIsStreetnameTouched(true);
  };

  const handleHousenumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHousenumber(event.target.value);
    setIsHousenumberTouched(true);
  };

  const handleZipcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value);
    setIsZipcodeTouched(true);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
    setIsCityTouched(true);
  };

  const handleEaneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEane(event.target.value);
  };

  const handleEangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEang(event.target.value);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    padding: "20px",
    transition: "all 0.3s",
    backgroundColor: "#f8f9fa",
    boxShadow: "none",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    const storedUserAddress = getCookie("selectedAddress");
    if (storedUserAddress) {
      setUserAddressId(storedUserAddress); // Set the userAddressId based on the selected user address
    }
    fetchEnergySuppliers();
  }, []);

  useEffect(() => {
    if (user && !isStreetnameTouched) {
      setStreetname(user.streetname);
    }
    if (user && !isHousenumberTouched) {
      setHousenumber(user.housenumber);
    }
    if (user && !isZipcodeTouched) {
      setZipcode(user.zipcode);
    }
    if (user && !isCityTouched) {
      setCity(user.city);
    }
  }, [
    user,
    isStreetnameTouched,
    isHousenumberTouched,
    isZipcodeTouched,
    isCityTouched,
  ]);

  // Get all the data of user and predefine it
  // Set initial state values for the input fields
  // useEffect(() => {
  //   if (user && !isStreetnameTouched) {
  //     setStreetname(user.streetname);
  //   }
  //   if (user && !isHousenumberTouched) {
  //     setHousenumber(user.housenumber);
  //   }
  //   if (user && !isZipcodeTouched) {
  //     setZipcode(user.zipcode);
  //   }
  //   if (user && !isCityTouched) {
  //     setCity(user.city);
  //   }
  // }, [user, isStreetnameTouched, isHousenumberTouched, isZipcodeTouched, isCityTouched]);

  const handleAddContract = async () => {
    try {
      const contractId = uuidv4();
      const detailsId = uuidv4();
      const contractElectricityDetails = {
        id: detailsId,
        contractId,
        yearlySubscription,
        kwhConsumptionPrice,
        kwhConsumptionPriceDiscount,
        kwhConsumptionPriceAfterDiscount,
        kwhConsumptionPriceTwoDay,
        kwhConsumptionPriceTwoNight,
        kwhConsumptionPriceNight,
        fixedPriceElectricity,
        hewwkContribution,
        dataManagementPrice,
        capacityRatePrice,
        purchaseRatePrice,
        taxPrice,
        totalConumptionCostPrice,
        kwhInjectionSolarPanelsDiscountPrice,
      };

      // Step 1: Create the contractElectricityDetails record
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/contractElectricityDetailsOwn`,
        contractElectricityDetails,
      );
      // const contractElectricityDetailsId = response.data.contractElectricityDetails.id;

      // const id = uuidv4();
      // Step 2: Create the main contract
      const newContract = {
        id: contractId,
        name,
        description,
        category,
        supplierId,
        contractDetailsId: detailsId,
      };
      await axios.post(
        `${process.env.REACT_APP_DB_URL}/contractsOwn`,
        newContract,
      );
      console.log("Contract created successfully");

      const instanceId = uuidv4();
      const contractInstanceData = {
        id: instanceId,
        contractId: contractId,
        userId: userId,
        userAddressId: userAddressId,
        streetname: streetname,
        housenumber: housenumber,
        zipcode: zipcode,
        city: city,
        eane: eane,
        eang: eang,
        startDate: startDate,
        endDate: endDate,
      };

      await axios.post(
        `${process.env.REACT_APP_DB_URL}/contractinstancesOwn`,
        contractInstanceData,
      );
      console.log("Contract instance created successfully");

      navigate("/MyContracts");
    } catch (error) {
      console.error("Error creating contract:", error);
      // Handle error state or display an error message
    }
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Elektriciteitscontract toevoegen</PageHero>
          <div className="pageContainerSP" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="parentContainer">
              <div
                className="contentContainersolar"
                style={contentContainerStyles}
              >
                <div className="userCardRequestsolarSP">
                  <div className="userCardRequestColumnssolarSP">
                    <div className="addressFieldsColumnSP">
                      {errorMessage ? (
                        <p className="errorMessagesolar">{errorMessage}</p>
                      ) : null}

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Naam"
                            variant="standard"
                            type="text"
                            id="nameFilter"
                            value={name}
                            onChange={handleNameChange}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Beschrijving"
                            variant="standard"
                            type="text"
                            value={description}
                            onChange={handleDescriptionChange}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="category"
                            select
                            label="Categorie"
                            value={category}
                            onChange={handleCategoryChange}
                            variant="standard"
                            required
                          >
                            <MenuItem value={0}>
                              Selecteer een categorie
                            </MenuItem>
                            <MenuItem value={1}>Elektriciteit Vast</MenuItem>
                            <MenuItem value={2}>
                              Elektriciteit Variabel
                            </MenuItem>
                            <MenuItem value={5}>
                              Elektriciteit Dynamisch
                            </MenuItem>
                            <MenuItem value={10}>
                              Elektriciteit Vast Professioneel
                            </MenuItem>
                            <MenuItem value={11}>
                              Elektriciteit Variabel Professioneel
                            </MenuItem>
                            <MenuItem value={14}>
                              Elektriciteit Dynamisch Professioneel
                            </MenuItem>
                          </TextField>
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="Energieleverancier"
                            select
                            label="Energieleverancier"
                            value={supplierId}
                            onChange={handleSupplierIdChange}
                            variant="standard"
                            required
                          >
                            <MenuItem value={0}>
                              Selecteer een energieleverancier
                            </MenuItem>
                            {energySuppliers.map((supplier) => (
                              <MenuItem key={supplier.id} value={supplier.id}>
                                {supplier.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      </div>
                    </div>

                    <div className="addressFieldsColumn">
                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Jaarlijks abonnement"
                            variant="standard"
                            type="number"
                            id="abbofilteryearly"
                            value={yearlySubscription}
                            onChange={handleYearlySubscriptionChange}
                            required
                          />
                        </Box>
                      </div>
                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="kWh verbruikskost"
                            variant="standard"
                            type="number"
                            id="kwhusage"
                            value={kwhConsumptionPrice}
                            onChange={handleKwhConsumptionPriceChange}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Tweevoudig tarief (dag)"
                            variant="standard"
                            type="number"
                            id="twoday"
                            value={kwhConsumptionPriceTwoDay}
                            onChange={handleKwhConsumptionPriceTwoDay}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Tweevoudig tarief (nacht)"
                            variant="standard"
                            type="number"
                            id="twonight"
                            value={kwhConsumptionPriceTwoNight}
                            onChange={handleKwhConsumptionPriceTwoNight}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Exclusief nacht tarief"
                            variant="standard"
                            type="number"
                            id="exclnight"
                            value={kwhConsumptionPriceNight}
                            onChange={handleKwhConsumptionPriceNight}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Straatnaam "
                            variant="standard"
                            type="text"
                            id="streetname"
                            value={streetname}
                            onChange={handleStreetnameChange}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Huisnummer "
                            variant="standard"
                            type="text"
                            id="housenumber"
                            value={housenumber}
                            onChange={handleHousenumberChange}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Postcode "
                            variant="standard"
                            type="text"
                            id="zipcode"
                            value={zipcode}
                            onChange={handleZipcodeChange}
                            required
                          />
                        </Box>
                      </div>
                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Stad/Gemeente "
                            variant="standard"
                            type="text"
                            id="city"
                            value={city}
                            onChange={handleCityChange}
                            required
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <ElectricBoltIcon
                            sx={{ color: "action.active", mr: 1, my: 0.5 }}
                          />
                          <TextField
                            label="EAN Elektriciteit"
                            variant="standard"
                            type="text"
                            id="eane"
                            value={eane}
                            onChange={handleEaneChange}
                            inputProps={{
                              pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                            }}
                            required
                          />
                        </Box>
                      </div>
                      {eane.length !== 18 && (
                        <span style={{ color: "red" }}>
                          Vul alsjeblieft 18 getallen in startende met 54.
                        </span>
                      )}
                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <GasMeterIcon
                            sx={{ color: "action.active", mr: 1, my: 0.5 }}
                          />
                          <TextField
                            label="EAN Gas"
                            variant="standard"
                            type="text"
                            id="eang"
                            value={eang}
                            onChange={handleEangChange}
                            inputProps={{
                              pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                            }}
                            required
                          />
                        </Box>
                      </div>
                      {eang.length !== 18 && (
                        <span style={{ color: "red" }}>
                          Vul alsjeblieft 18 getallen in startende met 54.
                        </span>
                      )}

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            variant="standard"
                            type="date"
                            id="gasdatetime"
                            onChange={(event) =>
                              setStartDate(new Date(event.target.value))
                            }
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            variant="standard"
                            type="date"
                            id="gasdatetime"
                            onChange={(event) =>
                              setEndDate(new Date(event.target.value))
                            }
                          />
                        </Box>
                      </div>
                    </div>
                  </div>

                  <button className="buttonstyle33" onClick={handleAddContract}>
                    Contract toevoegen
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default AddContractsElectricity;

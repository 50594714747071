import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoIosTrash } from "react-icons/io";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import NavBar from "../../../../components/Navigation/NavBar";
import PageHero from "../../../../components/Pagehero/PageHero";
import "../../../../theme/styles.css";
import "../../../customer/Files/FilesCustomer.css";
import "./DetailUser.css";
import { getCookie } from "../../../../cookieUtils";

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  password: string;
  roleId: number;
}

interface ContractInstance {
  id: string;
  contractId: string;
  userId: string;
  userAddressId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  startDate: Date;
  endDate: Date;
}

interface EnergySupplier {
  id: string;
  name: string;
}

interface Contract {
  id: string;
  name: string;
  supplierId: string;
  description: string;
  category: number;
}

interface Document {
  id: string;
  userId: string;
  documentName: string;
  documentPath: string;
  status: number;
}

interface UserAddress {
  id: string;
  name: string;
  userId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  dateTimeCreated: Date;
}

const DetailUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [contractInstances, setContractInstances] = useState<
    ContractInstance[]
  >([]);
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [userAddresses, setUserAddresses] = useState<UserAddress[]>([]);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [confirmDeleteUserAddress, setConfirmDeleteUserAddress] =
    useState<UserAddress | null>(null);
  const [confirmDeleteContract, setConfirmDeleteContract] =
    useState<ContractInstance | null>(null);
  const [confirmDeleteDocument, setConfirmDeleteDocument] =
    useState<Document | null>(null);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchUser();
    fetchContractInstances();
    fetchDocuments();
    fetchUserAddress();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/users/${userId}`,
      );
      setUser(response.data);
    } catch (error) {
      console.error("Error retrieving user:", error);
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/documents/user/${userId}`,
      );
      const fetchedDocuments: Document[] = response.data
        .filter((document: Document) => document.status === 1)
        .map((document: Document) => ({
          ...document,
          documentPath: `${process.env.REACT_APP_DB_URL}/${document.documentPath}`,
        }));
      setDocuments(fetchedDocuments);
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const fetchContractInstances = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractinstances/user/${userId}`,
      );
      const contractInstances: ContractInstance[] = response.data;
      setContractInstances(contractInstances);

      const contractIds = Array.from(
        new Set(contractInstances.map((instance) => instance.contractId)),
      );

      if (contractIds.length > 0) {
        const energySuppliersResponse = await axios.get(
          `${
            process.env.REACT_APP_DB_URL
          }/energysuppliers?contractIds=${contractIds.join(",")}`,
        );
        const energySuppliers: EnergySupplier[] = energySuppliersResponse.data;
        setEnergySuppliers(energySuppliers);

        const contractsResponse = await axios.get(
          `${
            process.env.REACT_APP_DB_URL
          }/contracts?contractIds=${contractIds.join(",")}`,
        );
        const contracts: Contract[] = contractsResponse.data;
        setContracts(contracts);
      } else {
        setEnergySuppliers([]);
        setContracts([]);
      }
    } catch (error) {
      console.error("Error retrieving contract instances:", error);
    }
  };

  const fetchUserAddress = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/user-addresses/user/${userId}`,
      );
      const fetchedUserAddresses: UserAddress[] = response.data;
      setUserAddresses(fetchedUserAddresses);
    } catch (error) {
      console.error("Error retrieving user addresses:", error);
    }
  };

  const handleContractInstance = (contractInstanceId: any) => {
    navigate(`/DetailContractInstance/${contractInstanceId}`);
  };

  const handleUserAddress = (userAddressId: any) => {
    navigate(`/DetailUserAddress/${userAddressId}`);
  };

  const getEnergySupplierName = (contractId: string) => {
    const energySupplier = energySuppliers.find(
      (energySupplier) => energySupplier.id === contractId.toString(),
    );
    return energySupplier ? energySupplier.name : "";
  };

  const getContractInfo = (contractId: any) => {
    const contract = contracts.find(
      (contract) => contract.id === contractId.toString(),
    );
    return contract;
  };

  const handleEditUser = (userId: any) => {
    navigate(`/EditUser/${userId}`);
  };

  const handleAddUserAddress = (userId: any) => {
    navigate(`/AddUserAddress/${userId}`);
  };

  const handleDeleteDocument = async (document: Document) => {
    setConfirmDeleteDocument(document);
  };

  const deleteDocument = async () => {
    if (confirmDeleteDocument) {
      try {
        await axios.put(
          `${process.env.REACT_APP_DB_URL}/documents/${confirmDeleteDocument.id}`,
          { status: 2 },
        );
        console.log("Document status updated successfully");
        fetchDocuments();
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
    setConfirmDeleteDocument(null);
  };

  const cancelDeleteDocument = () => {
    setConfirmDeleteDocument(null);
  };

  const handleDeleteContract = async (contract: ContractInstance) => {
    setConfirmDeleteContract(contract);
  };

  const deleteContract = async () => {
    if (confirmDeleteContract) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_DB_URL}/contractinstances/${confirmDeleteContract.id}`,
        );
        console.log("Contract instance deleted successfully");
        fetchContractInstances();
      } catch (error) {
        console.error("Error deleting contract instance:", error);
      }
    }
    setConfirmDeleteContract(null);
  };

  const cancelDeleteContract = () => {
    setConfirmDeleteContract(null);
  };

  const handleDeleteUserAddress = async (userAddress: UserAddress) => {
    setConfirmDeleteUserAddress(userAddress);
  };

  const deleteUserAddress = async () => {
    if (confirmDeleteUserAddress) {
      if (!confirmDeleteUserAddress.id) {
        console.error("Invalid user address ID:", confirmDeleteUserAddress.id);
        return;
      }

      try {
        await axios.delete(
          `${process.env.REACT_APP_DB_URL}/user-addresses/${confirmDeleteUserAddress.id}`,
        );
        console.log("User address deleted successfully");
        fetchUserAddress();
      } catch (error) {
        console.error("Error deleting user address:", error);
      }
    }
    setConfirmDeleteUserAddress(null);
  };

  const cancelDeleteUserAddress = () => {
    setConfirmDeleteUserAddress(null);
  };

  const downloadDocument = (documentPath: string) => {
    window.open(documentPath, "_blank");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none",
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#272727",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const renderUserCard = (contractInstances: ContractInstance[]) => {
    return (
      <div>
        <hr className="dividerDashed" />
        <h1 className="pageTitle" style={pageTitleStyles}>
          Contracten van gebruiker
        </h1>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Contract Naam</StyledTableCell>
                <StyledTableCell align="right">Leverancier</StyledTableCell>
                <StyledTableCell align="right">Omschrijving</StyledTableCell>
                <StyledTableCell align="right">Type</StyledTableCell>
                <StyledTableCell align="right">Adres</StyledTableCell>
                <StyledTableCell align="right">Postcode</StyledTableCell>
                <StyledTableCell align="right">Stad</StyledTableCell>
                <StyledTableCell align="right">
                  Ean Elektriciteit
                </StyledTableCell>
                <StyledTableCell align="right">Ean Gas</StyledTableCell>
                <StyledTableCell align="right">Startdatum</StyledTableCell>
                <StyledTableCell align="right">Einddatum</StyledTableCell>
                <StyledTableCell align="right">Acties</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contractInstances.map((contractInstance) => (
                <StyledTableRow key={contractInstance.id}>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {getContractInfo(contractInstance.contractId)?.name}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {getEnergySupplierName(contractInstance.contractId)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {getContractInfo(contractInstance.contractId)?.description}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {(() => {
                      const category = getContractInfo(
                        contractInstance.contractId,
                      )?.category;
                      switch (category) {
                        case 2:
                          return "Elektriciteit Variabel";
                        case 3:
                          return "Gas Vast";
                        case 4:
                          return "Gas Variabel";
                        default:
                          return category === 1 ? "Elektriciteit Vast" : "Gas";
                      }
                    })()}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {contractInstance.streetname},{" "}
                    {contractInstance.housenumber}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {contractInstance.zipcode}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {contractInstance.city}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {contractInstance.eane}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {contractInstance.eang}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {contractInstance.startDate.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => handleContractInstance(contractInstance.id)}
                  >
                    {contractInstance.endDate.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IoIosTrash
                      size={24}
                      className="actionIconDelete"
                      onClick={() => handleDeleteContract(contractInstance)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderUserCardAddress = (userAddresses: UserAddress[]) => {
    return (
      <div>
        <hr className="dividerDashed" />
        <h1 className="pageTitle" style={pageTitleStyles}>
          Verbruiksadressen van gebruiker
        </h1>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Naam</StyledTableCell>
                <StyledTableCell>Adres</StyledTableCell>
                <StyledTableCell align="left">Postcode</StyledTableCell>
                <StyledTableCell align="left">Stad</StyledTableCell>
                <StyledTableCell align="left">
                  Ean Elektriciteit
                </StyledTableCell>
                <StyledTableCell align="left">Ean Gas</StyledTableCell>
                <StyledTableCell align="right">Acties</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userAddresses.map((userAddress) => (
                <StyledTableRow key={userAddress.id}>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleUserAddress(userAddress.id)}
                  >
                    {userAddress.name}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleUserAddress(userAddress.id)}
                  >
                    {userAddress.streetname}, {userAddress.housenumber}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleUserAddress(userAddress.id)}
                  >
                    {userAddress.zipcode}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleUserAddress(userAddress.id)}
                  >
                    {userAddress.city}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleUserAddress(userAddress.id)}
                  >
                    {userAddress.eane}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleUserAddress(userAddress.id)}
                  >
                    {userAddress.eang}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IoIosTrash
                      size={24}
                      className="actionIconDelete"
                      onClick={() => handleDeleteUserAddress(userAddress)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Detail overzicht gebruiker</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    <button
                      className="buttonstyle"
                      onClick={() => handleEditUser(userId)}
                    >
                      Gebruiker aanpassen
                    </button>
                  </div>
                  <div className="filterContainer">
                    <button
                      className="buttonstyle"
                      onClick={() => handleAddUserAddress(userId)}
                    >
                      Gebruiker adres toevoegen
                    </button>
                  </div>
                </div>
                <hr className="dividerDashed" />
                <div className="userCardDetailUser">
                  <div className="userCardContainerDetailUser">
                    <div className="userCardColumnDetailUser">
                      {user && (
                        <div className="userDetailContainerDetailUser">
                          <div className="label">Voornaam:</div>
                          <div className="text">{user.surname}</div>
                          <div className="label">Achternaam:</div>
                          <div className="text">{user.name}</div>
                          <div className="label">Email:</div>
                          <div className="text">{user.email}</div>
                          <div className="label">Telefoonnummer:</div>
                          <div className="text">{user.phone}</div>
                          <div className="label">Straatnaam:</div>
                          <div className="text">{user.streetname}</div>
                          <div className="label">Huisnummer:</div>
                          <div className="text">{user.housenumber}</div>
                        </div>
                      )}
                    </div>
                    <div className="userCardColumnDetailUser">
                      {user && (
                        <div className="userDetailContainerDetailUser">
                          <div className="label">Postcode:</div>
                          <div className="text">{user.zipcode}</div>
                          <div className="label">Stad:</div>
                          <div className="text">{user.city}</div>
                          <div className="label">Rol:</div>
                          <div className="text">
                            {user.roleId === 1
                              ? "Administrator"
                              : user.roleId === 2
                                ? "Klant"
                                : ""}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {contractInstances.length > 0 &&
                  renderUserCard(contractInstances)}
                {userAddresses.length > 0 &&
                  renderUserCardAddress(userAddresses)}
                {documents.length > 0 && (
                  <div>
                    <hr className="dividerDashed" />
                    <h1 className="pageTitle" style={pageTitleStyles}>
                      Documenten van gebruiker
                    </h1>
                    <div className="documentGrid">
                      {documents.map((document) => (
                        <div key={document.id} className="documentCard">
                          <a
                            href={document.documentPath}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="documentName"
                          >
                            {document.documentName}
                          </a>
                          <div className="documentActions">
                            <button
                              className="deleteButton"
                              onClick={() => handleDeleteDocument(document)}
                            >
                              Delete
                            </button>
                            <button
                              className="downloadButton"
                              onClick={() =>
                                downloadDocument(document.documentPath)
                              }
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {confirmDeleteUserAddress && (
                <div className="confirmationModal">
                  <p className="confirmationText">
                    Bent u zeker dat u dit gebruikersadres wil verwijderen?
                  </p>
                  <div className="confirmationButtons">
                    <button
                      className="confirmButton"
                      onClick={deleteUserAddress}
                    >
                      Delete
                    </button>
                    <button
                      className="cancelButton"
                      onClick={cancelDeleteUserAddress}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {confirmDeleteContract && (
                <div className="confirmationModal">
                  <p className="confirmationText">
                    Bent u zeker dat u dit contract wil verwijderen?
                  </p>
                  <div className="confirmationButtons">
                    <button className="confirmButton" onClick={deleteContract}>
                      Delete
                    </button>
                    <button
                      className="cancelButton"
                      onClick={cancelDeleteContract}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {confirmDeleteDocument && (
                <div className="confirmationModal">
                  <p className="confirmationText">
                    Bent u zeker dat u dit document wil verwijderen?
                  </p>
                  <div className="confirmationButtons">
                    <button className="confirmButton" onClick={deleteDocument}>
                      Delete
                    </button>
                    <button
                      className="cancelButton"
                      onClick={cancelDeleteDocument}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default DetailUser;

import React, { useState } from "react";
import "./PasswordForgottenExpired.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import YouTube from "react-youtube";

const PasswordForgottenExpired: React.FC = () => {
  const { handleSubmit, register, setError } = useForm();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailFocused, setEmailFocused] = useState(false);
  const navigate = useNavigate();

  const handlePasswordForgotten = async (data: any) => {
    console.log("wachtwoord reset aangevraagd.");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleEmailFocus = () => {
    setEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setEmailFocused(true);
  };

  const opts = {
    playerVars: {
      autoplay: 1, // Autoplay the video
      controls: 0, // Hide the player controls
      modestbranding: 1, // Hide the YouTube logo
      loop: 1, // Play the video on repeat
      playlist: "wziCr2xFXX0", // Playlist to ensure the loop works
    },
  };

  return (
    <div className="login-page">
      {/* <div className="login-image"></div> */}
      <YouTube videoId="wziCr2xFXX0" className="login-video" opts={opts} />
      <div className="login-form">
        <form
          className="login-form"
          onSubmit={handleSubmit(handlePasswordForgotten)}
        >
          <h2 className="title">Wachtwoord reset link verlopen</h2>
          <p>
            Vul hier in het onderstaande veld uw e-mail adres in om opnieuw een
            nieuw wachtwoord in te stellen.
          </p>
          <div className={`form-group ${isEmailFocused ? "focused" : ""}`}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                label="E-mail adres"
                variant="standard"
                {...register("email", { required: true })}
                onFocus={handleEmailFocus}
                onBlur={handleEmailBlur}
              />
            </Box>
          </div>
          <div className="link-container">
            <Link to="/Login" className="custom-link1">
              Inloggen
            </Link>
            <Link to="/Register" className="custom-link2">
              Registreren{" "}
            </Link>
          </div>
          <button className="login-button" type="submit">
            Herstellen
          </button>
        </form>
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default PasswordForgottenExpired;

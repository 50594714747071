import React, { useEffect, useState } from "react";
import NavigationLeft from "../../../components/Navigation/NavigationLeft/NavigationLeft";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import "./FluviusConnect.css";
import "./../../../theme/styles.css";
import { getCookie } from "../../../cookieUtils";

const FluviusConnect: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate();
  const isLargeScreen = window.innerWidth > 768;

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const navigateToSolarPanelsApplicationCustomer = () => {
    navigate("/SolarPanelsApplicationCustomer");
  };

  const navigateToSolarPanelsCleaningApplicationCustomer = () => {
    navigate("/SolarPanelCleaningApplicationCustomer");
  };

  const navigateToBoilerMaintenanceApllicationCustomer = () => {
    navigate("/BoilerMaintenanceApplicationCustomer");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Fluvius aansluiten</PageHero>
          <div className="pageContainerFlu" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainerFlu" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <h2 className="section-title">
                    Hoe koppel ik mijn Fluvius meter aan mijn EnergyNeeds
                    account?
                  </h2>
                  <div className="green-divider"></div>
                  {/* <p className="section-paragraph">
        Bent u klaar om een stap te zetten richting een groenere toekomst? Ontdek de kracht van zonne-energie met onze hoogwaardige zonnepanelen! Door nu een offerte aan te vragen, opent u de deur naar een wereld van duurzame energieopwekking en financiële besparingen.
        <br></br> <br></br>
        Door te kiezen voor zonnepanelen investeert u niet alleen in een schonere planeet, maar ook in uw eigen financiële welzijn op de lange termijn. Wacht niet langer! Vraag vandaag nog uw offerte aan en ontdek hoe zonne-energie uw leven kan transformeren.
        </p> */}
                  <p className="section-paragraph">
                    Om de gegevens van je digitale meter uit te kunnen lezen op
                    jouw account hebben we je toestemming nodig. Kijk het
                    filmpje van Fluvius hiernaast hoe je dit in een paar
                    eenvoudige stappen doet. De toestemming is geldig voor 3
                    jaar. Herhaal deze stappen per meter/EAN-code.
                  </p>
                  <button
                    className="buttonstyle33"
                    onClick={navigateToSolarPanelsApplicationCustomer}
                  >
                    Connecteer Fluvius
                  </button>
                </div>
                <div className="image-column">
                  <iframe
                    src="https://player.vimeo.com/video/694345745?h=e7c4d8fb06&title=0&byline=0&portrait=0"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default FluviusConnect;

import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import "./ChangeEnergyContractDetail.css";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import { MenuItem, TextField } from "@mui/material";
import PageHero from "../../../../components/Pagehero/PageHero";
import CenterTitle from "../../../../components/CenterTitle/CenterTitle";
import { getCookie } from "../../../../cookieUtils";

interface EnergySupplier {
  id: string;
  name: string;
  logo: string;
}

interface Contract {
  id: string;
  supplierId: string;
  name: string;
  description: string;
  category: number;
}

interface ChangeContractRequest {
  id: string;
  contractIdElectricity: string;
  contractIdGas: string;
  userId: string;
  surname: string;
  name: string;
  email: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  phone: string;
  status: number;
  verhuis: number;
  datetimeCreated: Date;
  electricityContract?: Contract; // Add this property for the electricity contract details
  gasContract?: Contract; // Add this property for the gas contract details
}

interface Document {
  id: string;
  userId: string;
  changeContractRequestId: string;
  documentName: string;
  documentPath: string;
  status: number;
}

interface ContractInstance {
  id: string;
  contractId: string;
  userId: string;
  userAddressId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  startDate: string;
  endDate: string;
}

const ChangeEnergyContractDetail = () => {
  const [contracts, setContracts] = useState<{
    electricityContract: Contract | null;
    gasContract: Contract | null;
  }>({
    electricityContract: null,
    gasContract: null,
  });
  const [changeContractRequest, setChangeContractRequests] =
    useState<ChangeContractRequest | null>(null);
  const [energySuppliers, setEnergySuppliers] = useState<{
    [key: string]: EnergySupplier;
  }>({});
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [status, setStatus] = useState(0);
  const [statusChangeSuccess, setStatusChangeSuccess] = useState(false);
  const [lastChangedBy, setLastChangedBy] = useState<string | null>(null);
  const { id } = useParams<{ id?: string }>(); // Extract the request ID from the URL
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    if (id) {
      fetchDetails(id);
    }
  }, [id]);

  const fetchDetails = async (id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/ChangeContractRequests/${id}`,
      );
      const changeContractRequest = response.data;
      setChangeContractRequests(changeContractRequest);
      console.log(changeContractRequest);
      // Fetch the electricity contract details
      if (changeContractRequest.contractIdElectricity) {
        const electricityContractResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contracts/${changeContractRequest.contractIdElectricity}`,
        );
        setContracts((prevContracts) => ({
          ...prevContracts,
          electricityContract: electricityContractResponse.data,
        }));

        // Fetch the energy supplier details for electricity contract
        const electricitySupplierResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/energySuppliers/${electricityContractResponse.data.supplierId}`,
        );
        setEnergySuppliers((prevSuppliers) => ({
          ...prevSuppliers,
          [electricityContractResponse.data.supplierId]:
            electricitySupplierResponse.data,
        }));

        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/documents/changeContractRequestId/${id}`,
        );
        const fetchedDocuments: Document[] = response.data
          .filter((document: Document) => document.status === 1) // Filter documents with status 1
          .map((document: Document) => ({
            ...document,
            documentPath: `${process.env.REACT_APP_DB_URL}/${document.documentPath}`,
          }));
        setDocuments(fetchedDocuments);
      }

      // Fetch the gas contract details
      if (changeContractRequest.contractIdGas) {
        const gasContractResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/contracts/${changeContractRequest.contractIdGas}`,
        );
        setContracts((prevContracts) => ({
          ...prevContracts,
          gasContract: gasContractResponse.data,
        }));

        // Fetch the energy supplier details for gas contract
        const gasSupplierResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/energySuppliers/${gasContractResponse.data.supplierId}`,
        );
        setEnergySuppliers((prevSuppliers) => ({
          ...prevSuppliers,
          [gasContractResponse.data.supplierId]: gasSupplierResponse.data,
        }));
      }
    } catch (error) {
      console.error("Error retrieving data:", error);
      // Handle error state or display an error message
    }
  };

  useEffect(() => {
    if (changeContractRequest) {
      setStatus(changeContractRequest.status);
      fetchLastChangedBy(changeContractRequest.id);
    }
  }, [changeContractRequest]);

  const fetchLastChangedBy = async (requestId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/RequestProcessor/byRequestId/${requestId}`,
      );
      const requestProcessors = response.data;
      setLastChangedBy(
        `${requestProcessors.Surname} ${requestProcessors.Name}`,
      );
    } catch (error) {
      console.error("Error retrieving last changed by:", error);
      setLastChangedBy(null);
    }
  };

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      const newStatus = parseInt(event.target.value);
      setStatus(newStatus);

      if (changeContractRequest) {
        const updatedChangeContractRequest = {
          ...changeContractRequest,
          status: newStatus,
        };
        await axios.put(
          `${process.env.REACT_APP_DB_URL}/ChangeContractRequests/${id}`,
          updatedChangeContractRequest,
        );
        setChangeContractRequests(updatedChangeContractRequest);

        // Fetch contract details again (using optional chaining)
        fetchDetails(id!); // Add ! after id to tell TypeScript that we know it's not undefined

        // Show the success message
        setStatusChangeSuccess(true);
        // Hide the success message after 3 seconds (adjust the time as needed)
        setTimeout(() => {
          setStatusChangeSuccess(false);
        }, 3000);

        const storedUser = getCookie("user");
        const user = storedUser ? JSON.parse(storedUser) : null;

        // Create a new RequestProcessor using the user's data
        await axios.post(`${process.env.REACT_APP_DB_URL}/RequestProcessor`, {
          userId: user.id,
          requestId: id,
          Surname: user.surname,
          Name: user.name,
          Email: user.email,
        });
      }

      if (newStatus === 3) {
        const electricityContractId =
          changeContractRequest!.contractIdElectricity;
        const gasContractId = changeContractRequest!.contractIdGas;

        if (electricityContractId && electricityContractId !== "") {
          // Fetch the electricity contract details
          const electricityContractResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/contracts/${electricityContractId}`,
          );
          const electricityContract: Contract =
            electricityContractResponse.data;

          // Check if there is already a contract instance with category 1 or 2 for this user
          const electricityContractInstanceResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/ContractInstances?userId=${
              changeContractRequest!.userId
            }&categoryId=in:[1,2]`,
          );
          const electricityContractInstances: ContractInstance[] =
            electricityContractInstanceResponse.data;

          // Find the existing instance with the same eane, if any
          const existingInstanceWithEANE = electricityContractInstances.find(
            (instance) => instance.eane === changeContractRequest!.eane,
          );

          if (existingInstanceWithEANE) {
            // An instance with the same eane already exists, update the existing instance
            await axios.put(
              `${process.env.REACT_APP_DB_URL}/ContractInstances/${existingInstanceWithEANE.id}`,
              {
                contractId: electricityContractId,
                userId: changeContractRequest!.userId,
                userAdressId: "",
                streetname: changeContractRequest!.streetname,
                housenumber: changeContractRequest!.housenumber,
                zipcode: changeContractRequest!.zipcode,
                city: changeContractRequest!.city,
                eane: changeContractRequest!.eane,
                eang: changeContractRequest!.eang,
              },
            );
          } else {
            // No existing instance with the same eane found, create a new ContractInstance
            await axios.post(
              `${process.env.REACT_APP_DB_URL}/ContractInstances`,
              {
                contractId: electricityContractId,
                userId: changeContractRequest!.userId,
                userAdressId: "",
                streetname: changeContractRequest!.streetname,
                housenumber: changeContractRequest!.housenumber,
                zipcode: changeContractRequest!.zipcode,
                city: changeContractRequest!.city,
                eane: changeContractRequest!.eane,
                eang: changeContractRequest!.eang,
              },
            );
          }
        }

        if (gasContractId && gasContractId !== "") {
          // Fetch the gas contract details
          const gasContractResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/contracts/${gasContractId}`,
          );
          const gasContract: Contract = gasContractResponse.data;

          // Check if there is already a contract instance with category 3 or 4 for this user
          const gasContractInstanceResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/ContractInstances?userId=${
              changeContractRequest!.userId
            }&categoryId=in:[3,4]`,
          );
          const gasContractInstances: ContractInstance[] =
            gasContractInstanceResponse.data;

          // Find the existing instance with the same eang, if any
          const existingInstanceWithEANG = gasContractInstances.find(
            (instance) => instance.eang === changeContractRequest!.eang,
          );

          if (existingInstanceWithEANG) {
            // An instance with the same eang already exists, update the existing instance
            await axios.put(
              `${process.env.REACT_APP_DB_URL}/ContractInstances/${existingInstanceWithEANG.id}`,
              {
                contractId: gasContractId,
                userId: changeContractRequest!.userId,
                userAdressId: "",
                streetname: changeContractRequest!.streetname,
                housenumber: changeContractRequest!.housenumber,
                zipcode: changeContractRequest!.zipcode,
                city: changeContractRequest!.city,
                eane: changeContractRequest!.eane,
                eang: changeContractRequest!.eang,
              },
            );
          } else {
            // No existing instance with the same eang found, create a new ContractInstance
            await axios.post(
              `${process.env.REACT_APP_DB_URL}/ContractInstances`,
              {
                contractId: gasContractId,
                userId: changeContractRequest!.userId,
                userAdressId: "",
                streetname: changeContractRequest!.streetname,
                housenumber: changeContractRequest!.housenumber,
                zipcode: changeContractRequest!.zipcode,
                city: changeContractRequest!.city,
                eane: changeContractRequest!.eane,
                eang: changeContractRequest!.eang,
              },
            );
          }
        }
      }
    } catch (error) {
      console.error("Error updating status:", error);
      // Handle error state or display an error message
    }
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const downloadDocument = (documentPath: string) => {
    window.open(documentPath, "_blank");
  };
  const deleteDocument = async (documentId: string) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/documents/${documentId}`,
        {
          status: 2,
        },
      );
      console.log("Document status updated successfully");
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>
            Detailoverzicht aanvraag veranderen energiecontract
          </PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        select
                        label="Verander status van aanvraag:"
                        variant="standard"
                        value={status}
                        onChange={handleStatusChange}
                      >
                        <MenuItem key={1} value={1}>
                          Openstaand
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Bewerkt
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Geaccepteerd
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                          Afgewezen
                        </MenuItem>
                      </TextField>
                    </Box>

                    {statusChangeSuccess && (
                      <div className="successMessage">
                        De status is succesvol veranderd.
                      </div>
                    )}
                    {lastChangedBy && (
                      <div className="userCard">
                        {lastChangedBy} heeft de status laatst gewijzigd.
                      </div>
                    )}
                  </div>
                </div>
                <hr className="dividerDashed" />
                {/*
            <h1 className="pageTitle" style={pageTitleStyles}>
              Aanvraag gegevens
            </h1> */}
                <CenterTitle
                  title="Aanvraag gegevens"
                  subtitle="Vind hier informatie terug over de gebruiker die een aanvraag deed."
                ></CenterTitle>

                <div className="userCardsRow">
                  <div className="userCard">
                    {changeContractRequest && (
                      <div className="userDetailContainer">
                        <h2>Gebruiker gegevens</h2>
                        {/* <div className="label">ID:</div>
              <div className="text">{user.id}</div> */}
                    <div className="label">Voornaam:</div>
                    <div className="text">{changeContractRequest.surname}</div>
                    <div className="label">Achternaam:</div>
                    <div className="text">{changeContractRequest.name}</div>
                    <div className="label">Email:</div>
                    <div className="text">{changeContractRequest.email}</div>
                    <div className="label">Telefoonnummer:</div>
                    <div className="text">{changeContractRequest.phone}</div>
                    <div className="label">Datum van aanvraag:</div>
                    <div className="text">
                      {changeContractRequest.datetimeCreated.toLocaleString()}
                    </div>
                    <div className="label">Verhuis?</div>
                    <div className="text"> {changeContractRequest.verhuis === 0 ? 'Nee' : changeContractRequest.verhuis === 1 ? 'Ja' : 'Nee'}</div>
                  </div>
                )}
              </div>

                  {/* Render the electricity contract details card */}

                  <div className="userCardsRow">
                    {/* <div className="userCard"> */}
                    {changeContractRequest?.contractIdElectricity &&
                      contracts.electricityContract && (
                        <div className="userCard">
                          <div className="userDetailContainer">
                            <h2>Elektriciteitscontract gegevens</h2>
                            {energySuppliers[
                              contracts.electricityContract.supplierId
                            ] && (
                              <div>
                                <div className="label">Logo:</div>
                                <img
                                  src={
                                    energySuppliers[
                                      contracts.electricityContract.supplierId
                                    ].logo
                                  }
                                  alt="Energy Supplier Logo"
                                  style={{ maxWidth: "300px" }}
                                />
                                <div className="label">Energieleverancier:</div>
                                <div className="text">
                                  {
                                    energySuppliers[
                                      contracts.electricityContract.supplierId
                                    ].name
                                  }
                                </div>
                              </div>
                            )}
                            <div className="label">Contractnaam:</div>
                            <div className="text">
                              {contracts.electricityContract.name}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="userCardsRow">
                    {/* Render the gas contract details card */}
                    {changeContractRequest?.contractIdGas &&
                      contracts.gasContract && (
                        <div className="userCard">
                          <div className="userDetailContainer">
                            <h2>Gas contract gegevens</h2>
                            {energySuppliers[
                              contracts.gasContract.supplierId
                            ] && (
                              <div>
                                <div className="label">Logo:</div>
                                <img
                                  src={
                                    energySuppliers[
                                      contracts.gasContract.supplierId
                                    ].logo
                                  }
                                  style={{ maxWidth: "300px" }}
                                  alt="Energy Supplier Logo"
                                />
                                <div className="label">Energieleverancier:</div>
                                <div className="text">
                                  {
                                    energySuppliers[
                                      contracts.gasContract.supplierId
                                    ].name
                                  }
                                </div>
                              </div>
                            )}
                            <div className="label">Contractnaam:</div>
                            <div className="text">
                              {contracts.gasContract.name}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <hr className="dividerDashed" />
              {/* <h1 className="pageTitle" style={pageTitleStyles}>
              Aanvraag document
            </h1> */}
              <CenterTitle
                title="Aanvraag document"
                subtitle="Vind hier het ingevuld document van de aanvraag terug"
              ></CenterTitle>
              <div className="documentGrid">
                {documents.map((document) => (
                  <div key={document.id} className="documentCard">
                    <a
                      href={document.documentPath}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="documentName"
                    >
                      {document.documentName}
                    </a>
                    <div className="documentActions">
                      <button
                        className="deleteButton"
                        onClick={() => deleteDocument(document.id)}
                      >
                        Delete
                      </button>
                      <button
                        className="downloadButton"
                        onClick={() => downloadDocument(document.documentPath)}
                      >
                        Bekijken
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default ChangeEnergyContractDetail;

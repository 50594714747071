import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import solar_assistance from "./solar_assistance.png";
import heatme from "./heatme.png";
import solarco from "./solarco.png";
import vastgoed_experts from "./vastgoed_experts.png";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import "./Partners.css";
import "./../../../theme/styles.css";
import { getCookie } from "../../../cookieUtils";

const Partners: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    padding: "20px",
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Partners</PageHero>
          <div style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="partnerContainer">
              <div className="partnerLogoName">
                <div className="partnerLogoBox">
                  <img
                    src={solar_assistance}
                    alt="Solar Assistance"
                    className="partnerLogo"
                  />
                </div>
                <div className="partnerNameBox">
                  <p className="partnerName">Solar Assistance</p>
                </div>
              </div>
              <div className="divider-partners"></div>
              <div className="partnerLogoName">
                <div className="partnerLogoBox">
                  <img src={solarco} alt="Solarco" className="partnerLogo" />
                </div>
                <div className="partnerNameBox">
                  <p className="partnerName">Solarco</p>
                </div>
              </div>
              <div className="divider-partners"></div>
              <div className="partnerLogoName">
                <div className="partnerLogoBox">
                  <img src={heatme} alt="Heatme" className="partnerLogo" />
                </div>
                <div className="partnerNameBox">
                  <p className="partnerName">Heat Me</p>
                </div>
              </div>
              <div className="divider-partners"></div>
              <div className="partnerLogoName">
                <div className="partnerLogoBox">
                  <img
                    src={vastgoed_experts}
                    alt="Vastgoed Experts"
                    className="partnerLogo"
                  />
                </div>
                <div className="partnerNameBox">
                  <p className="partnerName">Vastgoed Experts</p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Partners;

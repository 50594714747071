import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import "../Articles.css";
import boilerMaintenanceImage from "./boilermaintenance.jpg";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import { getCookie } from "../../../../../cookieUtils";

const BoilerMaintenanceArticle: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);
  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const subheadingStyles: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "30px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>
            Waarom is het belangrijk om regelmatig ketelonderhoud te laten
            uitvoeren?
          </PageHeroColored>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="section-container">
                <div className="text-column">
                  <p className="section-paragraph">
                    <b>
                      {" "}
                      Een goed functionerende ketel is essentieel voor een
                      comfortabel en veilig huis. Om ervoor te zorgen dat uw
                      ketel optimaal blijft werken, is regelmatig ketelonderhoud
                      van groot belang. In dit artikel bespreken we waarom het
                      cruciaal is om uw ketel periodiek te laten controleren en
                      onderhouden door een professionele verwarmingsmonteur.
                    </b>
                  </p>
                  <h2 style={subheadingStyles}>Veiligheid</h2>
                  <p className="section-paragraph">
                    De veiligheid van uw huishouden staat voorop, en een slecht
                    onderhouden ketel kan gevaarlijke situaties veroorzaken. Een
                    van de risico's van een verwaarloosde ketel is de kans op
                    koolmonoxidevergiftiging. Koolmonoxide is een kleurloos en
                    reukloos gas dat kan vrijkomen als de ketel niet goed wordt
                    verbrand. Jaarlijks zijn er meldingen van
                    koolmonoxidevergiftiging, met soms fatale afloop. Regelmatig
                    ketelonderhoud helpt dit risico te minimaliseren door ervoor
                    te zorgen dat uw ketel efficiënt en veilig blijft werken.
                  </p>
                </div>

                <div className="image-column">
                  <img
                    src={boilerMaintenanceImage}
                    alt="Section Image"
                    className="section-image"
                  />
                </div>
              </div>

              <div className="section-container-full">
                {/* Efficiëntie */}

                <h2 style={subheadingStyles}>Efficiëntie</h2>
                <div className="section-paragraph-full">
                  <p>
                    Een goed onderhouden ketel werkt efficiënter en verbruikt
                    minder energie. Dit resulteert in lagere energiekosten en
                    een kleinere ecologische voetafdruk. Tijdens het
                    ketelonderhoud zal de verwarmingsmonteur alle componenten
                    van de ketel controleren en eventuele problemen oplossen.
                    Hierdoor kan uw ketel zijn optimale prestaties behouden,
                    waardoor u geld bespaart op uw energierekening.
                  </p>
                </div>

                {/* Levensduur */}
                <h2 style={subheadingStyles}>Verlenging van de levensduur</h2>
                <div className="section-paragraph-full">
                  <p>
                    Regelmatig onderhoud kan de levensduur van uw ketel
                    aanzienlijk verlengen. Kleine problemen worden vroegtijdig
                    opgemerkt en gerepareerd voordat ze tot grotere en
                    kostbaardere schade leiden. Door uw ketel goed te laten
                    onderhouden, kunt u de levensduur ervan maximaliseren, wat
                    uiteindelijk kosten bespaart op vervanging en reparatie.
                  </p>
                </div>

                {/* Conclusie */}
                <h2 style={subheadingStyles}>Conclusie</h2>
                <div className="section-paragraph-full">
                  <p>
                    Het regelmatig laten uitvoeren van ketelonderhoud is een
                    verstandige investering voor uw veiligheid, portemonnee en
                    het milieu. Een goed functionerende ketel zorgt niet alleen
                    voor een warm en comfortabel huis, maar minimaliseert ook
                    het risico op gevaarlijke situaties zoals
                    koolmonoxidevergiftiging. Bovendien bespaart u op
                    energiekosten en verlengt u de levensduur van uw ketel door
                    tijdig kleine problemen aan te pakken. Neem contact op met
                    een professionele verwarmingsmonteur om een afspraak te
                    maken voor ketelonderhoud en geniet van de vele voordelen
                    die het met zich meebrengt.
                  </p>
                  <button className="buttonstyle">Offerte aanvragen</button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default BoilerMaintenanceArticle;

import React, { useState } from "react";
import "./Register.css";
import axios from "axios";
import { useForm, FieldError } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import YouTube from "react-youtube";

const Register: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/register`,
        {
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone: data.phone,
          streetname: data.streetname,
          housenumber: data.housenumber,
          zipcode: data.zipcode,
          city: data.city,
          password: data.password,
        },
      );

      console.log(response.data); // You can handle the response data here

      // Login successful
      const { roleId, user } = response.data;

      navigate("/Login");
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Er ging iets mis tijdens het registreren.");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const opts = {
    playerVars: {
      autoplay: 1, // Autoplay the video
      controls: 0, // Hide the player controls
      modestbranding: 1, // Hide the YouTube logo
      loop: 1, // Play the video on repeat
      playlist: "wziCr2xFXX0", // Playlist to ensure the loop works
    },
  };

  return (
    <div className="login-page">
      <YouTube videoId="wziCr2xFXX0" className="login-video" opts={opts} />

      <div className="login-form">
        <form className="login-form" onSubmit={handleSubmit(handleRegister)}>
          <h2 className="title">Registreren</h2>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Voornaam"
                variant="standard"
                type="text"
                {...register("surname", {
                  required: "Voornaam is verplicht.",
                  pattern: {
                    value: /^[A-Za-z]+$/,
                    message: "Voornaam mag geen nummers bevatten.",
                  },
                })}
                error={!!errors.surname}
                helperText={(errors.surname as FieldError)?.message}
              />
            </Box>
          </div>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Naam"
                variant="standard"
                type="text"
                {...register("name", {
                  required: "Naam is verplicht.",
                  pattern: {
                    value: /^[A-Za-z]+$/,
                    message: "Naam mag geen nummers bevatten.",
                  },
                })}
                error={!!errors.name}
                helperText={(errors.name as FieldError)?.message}
              />
            </Box>
          </div>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AlternateEmailIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
              <TextField
                label="Email"
                variant="standard"
                type="email"
                {...register("email", {
                  required: "Email is verplicht.",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Ongeldig emailadres.",
                  },
                })}
                error={!!errors.email}
                helperText={(errors.email as FieldError)?.message}
              />
            </Box>
          </div>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <ContactPhoneIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
              <TextField
                label="Telefoonnummer"
                variant="standard"
                type="tel"
                {...register("phone", {
                  required: "Telefoonnummer is verplicht.",
                  pattern: {
                    value: /^[+\d]+$/,
                    message:
                      "Telefoonnummer mag alleen cijfers en '+' bevatten.",
                  },
                })}
                error={!!errors.phone}
                helperText={(errors.phone as FieldError)?.message}
              />
            </Box>
          </div>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <HouseIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Straatnaam"
                variant="standard"
                type="text"
                {...register("streetname", {
                  required: "Straatnaam is verplicht.",
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: "Straatnaam mag geen nummers bevatten.",
                  },
                })}
                error={!!errors.streetname}
                helperText={(errors.streetname as FieldError)?.message}
              />
            </Box>
          </div>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <HouseIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Huisnummer"
                variant="standard"
                type="text"
                {...register("housenumber", {
                  required: "Huisnummer is verplicht.",
                  pattern: {
                    value: /^[0-9a-zA-Z]+$/,
                    message:
                      "Huisnummer moet een nummer bevatten maar mag ook letters hebben.",
                  },
                })}
                error={!!errors.housenumber}
                helperText={(errors.housenumber as FieldError)?.message}
              />
            </Box>
          </div>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <HouseIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Postcode"
                variant="standard"
                type="text"
                {...register("zipcode", {
                  required: "Postcode is verplicht.",
                  pattern: {
                    value: /^[0-9a-zA-Z]+$/,
                    message:
                      "Postcode moet een nummer bevatten maar mag ook letters hebben.",
                  },
                })}
                error={!!errors.zipcode}
                helperText={(errors.zipcode as FieldError)?.message}
              />
            </Box>
          </div>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <HouseIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Stad"
                variant="standard"
                type="text"
                {...register("city", {
                  required: "Stad is verplicht.",
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: "Stad mag geen nummers bevatten.",
                  },
                })}
                error={!!errors.city}
                helperText={(errors.city as FieldError)?.message}
              />
            </Box>
          </div>

          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel htmlFor="password">Wachtwoord</InputLabel>
                <Input
                  type={isPasswordVisible ? "text" : "password"}
                  id="password"
                  {...register("password", {
                    required: "Wachtwoord is verplicht.",
                  })}
                  error={!!errors.password}
                  endAdornment={
                    <InputAdornment position="end">
                      {isPasswordVisible ? (
                        <IoEyeOffSharp
                          size={24}
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <IoEyeSharp
                          size={24}
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  }
                />
                <p className="helper-text">
                  {(errors.password as FieldError)?.message}
                </p>
              </FormControl>
            </Box>
          </div>

          <div className="link-container">
            <Link to="/Login" className="custom-link1">
              Inloggen
            </Link>
            <Link to="/PasswordForgotten" className="custom-link2">
              Wachtwoord vergeten
            </Link>
          </div>

          <button className="login-button" type="submit">
            Registreer
          </button>
          {errorMessage && <p className="error-text">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default Register;

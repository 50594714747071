import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EditContract.css";
import { useParams } from "react-router-dom";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MenuItem from "@mui/material/MenuItem";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface EnergySupplier {
  id: string;
  name: string;
  // Add other properties if necessary
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

const EditContract = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(0);
  const [supplierId, setSupplierId] = useState("");
  const [contractDetailsId, setContractDetailsId] = useState("");
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]); // Update the type
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  const [yearlySubscription, setyearlySubscription] = useState(0.01);
  const [kwhConsumptionPrice, setkwhConsumptionPrice] = useState(0.01);
  const [kwhConsumptionPriceDiscount, setkwhConsumptionPriceDiscount] =
    useState(0.01);
  const [
    kwhConsumptionPriceAfterDiscount,
    setkwhConsumptionPriceAfterDiscount,
  ] = useState(0.01);

  const [kwhConsumptionPriceTwoDay, setKwhConsumptionPriceTwoDay] =
    useState(0.01);
  const [kwhConsumptionPriceTwoNight, setKwhConsumptionPriceTwoNight] =
    useState(0.01);
  const [kwhConsumptionPriceNight, setKwhConsumptionPriceNight] =
    useState(0.01);
  const [fixedPriceElectricity, setFixedPriceElectricity] = useState(0.01);

  const [hewwkContribution, sethewwkContribution] = useState(0.01);
  const [dataManagementPrice, setdataManagementPrice] = useState(0.01);
  const [totalConumptionCostPrice, settotalConumptionCostPrice] =
    useState(0.01);
  const [capacityRatePrice, setcapacityRatePrice] = useState(0.01);
  const [purchaseRatePrice, setpurchaseRatePrice] = useState(0.01);
  const [taxPrice, settaxPrice] = useState(0.01);
  const [
    kwhInjectionSolarPanelsDiscountPrice,
    setkwhInjectionSolarPanelsDiscountPrice,
  ] = useState(0.01);
  const [roleId, setRoleId] = useState<number | null>(null);

  const [minimalDurationLength, setMinimalDurationLength] = useState(0);
  const [commission, setCommission] = useState(0.01);
  const [clawback, setClawback] = useState(0.01);
  const [VME, setVME] = useState(1);

  const { contractId } = useParams<{ contractId: string }>(); // Extract the contract ID from the URL

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchContractDetails();
    fetchEnergySuppliers();
  }, []);

  useEffect(() => {
    if (contractDetailsId) {
      fetchElectricityContractDetails();
    }
  }, [contractDetailsId]);

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contracts/${contractId}`,
      );
      const contract = response.data;
      setName(contract.name);
      setDescription(contract.description);
      setCategory(contract.category);
      setSupplierId(contract.supplierId);
      setContractDetailsId(contract.contractDetailsId);
      setMinimalDurationLength(contract.minimalDurationLength);
      setCommission(contract.commission);
      setClawback(contract.clawback);
      setVME(contract.vme);
    } catch (error) {
      console.error("Error fetching contract details:", error);
    }
  };

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      const suppliers = response.data;
      setEnergySuppliers(suppliers);
    } catch (error) {
      console.error("Error fetching energy suppliers:", error);
    }
  };

  const fetchElectricityContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractElectricityDetails/${contractDetailsId}`,
      );
      const contractDetails = response.data;
      setyearlySubscription(contractDetails.yearlySubscription);
      setkwhConsumptionPrice(contractDetails.kwhConsumptionPrice);
      setkwhConsumptionPriceDiscount(
        contractDetails.kwhConsumptionPriceDiscount,
      );
      setkwhConsumptionPriceAfterDiscount(
        contractDetails.kwhConsumptionPriceAfterDiscount,
      );
      setKwhConsumptionPriceTwoDay(contractDetails.kwhConsumptionPriceTwoDay);
      setKwhConsumptionPriceTwoNight(
        contractDetails.kwhConsumptionPriceTwoNight,
      );
      setKwhConsumptionPriceNight(contractDetails.kwhConsumptionPriceNight);
      setFixedPriceElectricity(contractDetails.fixedPriceElectricity);
      sethewwkContribution(contractDetails.hewwkContribution);
      setdataManagementPrice(contractDetails.dataManagementPrice);
      settotalConumptionCostPrice(contractDetails.totalConumptionCostPrice);
      setcapacityRatePrice(contractDetails.capacityRatePrice);
      setpurchaseRatePrice(contractDetails.purchaseRatePrice);
      settaxPrice(contractDetails.taxPrice);
      setkwhInjectionSolarPanelsDiscountPrice(
        contractDetails.kwhInjectionSolarPanelsDiscountPrice,
      );
    } catch (error) {
      console.error("Error fetching contract details:", error);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDescription(event.target.value);
  };

  const handleCategoryChange = (event: any) => {
    const categoryUpdate = parseInt(event.target.value, 10);
    setCategory(categoryUpdate);
  };

  const handleKwhConsumptionPriceTwoDay = (event: any) => {
    setKwhConsumptionPriceTwoDay(event.target.value);
  };

  const handleKwhConsumptionPriceTwoNight = (event: any) => {
    setKwhConsumptionPriceTwoNight(event.target.value);
  };
  const handleKwhConsumptionPriceNight = (event: any) => {
    const passingNumber = parseFloat(event.target.value);
    setKwhConsumptionPriceNight(passingNumber);
  };
  const handleFixedPriceElectricity = (event: any) => {
    setFixedPriceElectricity(event.target.value);
  };
  const handleSupplierIdChange = (event: any) => {
    setSupplierId(event.target.value);
  };

  const handleYearlySubscriptionChange = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setyearlySubscription(categorychange);
  };

  const handleKwhConsumptionPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPrice(categorychange);
  };

  const handleKwhConsumptionPriceDiscountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPriceDiscount(categorychange);
  };
  const handleKwhConsumptionPriceAfterDiscountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPriceAfterDiscount(categorychange);
  };
  const handleHewwkContributionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    sethewwkContribution(categorychange);
  };
  const handleDataManagementPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setdataManagementPrice(categorychange);
  };
  const handleTotalConsumptionCostPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    settotalConumptionCostPrice(categorychange);
  };
  const handleCapacityRatePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setcapacityRatePrice(categorychange);
  };
  const handlePurchaseRatePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setpurchaseRatePrice(categorychange);
  };
  const handleTaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const categorychange = parseFloat(event.target.value);
    settaxPrice(categorychange);
  };
  const handleKwhInjectionSolarPanelsDiscountPriceChange = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setkwhInjectionSolarPanelsDiscountPrice(categorychange);
  };

  const handleMinimalDurationLength = (event: any) => {
    setMinimalDurationLength(event.target.value);
  };

  const handleCommission = (event: any) => {
    setCommission(event.target.value);
  };

  const handleClawback = (event: any) => {
    setClawback(event.target.value);
  };

  const handleVME = (event: any) => {
    setVME(event.target.value);
  };

  const handleUpdateContract = async () => {
    try {
      const updatedContract = {
        name,
        description,
        category,
        supplierId,
        minimalDurationLength,
        commission,
        clawback,
        VME,
      };
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/contracts/${contractId}`,
        updatedContract,
      );
      console.log("Contract updated successfully");

      const updatedContractDetails = {
        yearlySubscription,
        kwhConsumptionPrice,
        kwhConsumptionPriceDiscount,
        kwhConsumptionPriceAfterDiscount,
        kwhConsumptionPriceTwoDay,
        kwhConsumptionPriceTwoNight,
        kwhConsumptionPriceNight,
        fixedPriceElectricity,
        hewwkContribution,
        dataManagementPrice,
        capacityRatePrice,
        purchaseRatePrice,
        taxPrice,
        totalConumptionCostPrice,
        kwhInjectionSolarPanelsDiscountPrice,
      };
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/contractElectricityDetails/${contractDetailsId}`,
        updatedContractDetails,
      );
      console.log("Contract details updated successfully");

      navigate("/Contracts");
    } catch (error) {
      console.error("Error updating contract:", error);
      // Handle error state or display an error message
    }
  };

  const containerStyles = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Contract aanpassen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              {/* <hr className="dividerDashed" /> */}
              {/* <div className="contentContainer"> */}
              <div>
                <div className="formContainer">
                  {errorMessage ? (
                    <p className="errorMessage">{errorMessage}</p>
                  ) : null}
                  <div className="userCardec">
                    <div className="userCardContainerec">
                      <div className="userCardColumnec">
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            {/* <ElectricBoltIcon
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  /> */}
                            <TextField
                              label="Naam"
                              variant="standard"
                              type="text"
                              id="nameFilter"
                              value={name}
                              onChange={handleNameChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            {/* <ElectricBoltIcon
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  /> */}
                            <TextField
                              label="Beschrijving"
                              variant="standard"
                              type="text"
                              value={description}
                              onChange={handleDescriptionChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="category"
                              select
                              label="Categorie"
                              value={category}
                              onChange={handleCategoryChange}
                              variant="standard"
                              fullWidth
                            >
                              <MenuItem value={0}>
                                Selecteer een categorie
                              </MenuItem>
                              <MenuItem value={1}>Elektriciteit Vast</MenuItem>
                              <MenuItem value={2}>
                                Elektriciteit Variabel
                              </MenuItem>
                              <MenuItem value={5}>
                                Elektriciteit Dynamisch
                              </MenuItem>
                              <MenuItem value={10}>
                                Elektriciteit Vast Professioneel
                              </MenuItem>
                              <MenuItem value={11}>
                                Elektriciteit Variabel Professioneel
                              </MenuItem>
                              <MenuItem value={14}>
                                Elektriciteit Dynamisch Professioneel
                              </MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="Energieleverancier"
                              select
                              label="Energieleverancier"
                              value={supplierId}
                              onChange={handleSupplierIdChange}
                              variant="standard"
                              fullWidth
                            >
                              <MenuItem value={0}>
                                Selecteer een energieleverancier
                              </MenuItem>
                              {energySuppliers.map((supplier) => (
                                <MenuItem key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Minimale looptijd"
                              variant="standard"
                              type="number"
                              id="minimaldurationlength"
                              value={minimalDurationLength}
                              onChange={handleMinimalDurationLength}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Commissie"
                              variant="standard"
                              type="number"
                              id="commission"
                              value={commission}
                              onChange={handleCommission}
                              fullWidth
                            />
                          </Box>
                        </div>
                      </div>

                      <div className="userCardColumnec">
                        {/* <label className="label">Jaarlijks abonnement:</label>
             <input type="number" className="input" step={0.01} value={yearlySubscription} onChange={handleYearlySubscriptionChange} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Clawback"
                              variant="standard"
                              type="number"
                              id="clawback"
                              value={clawback}
                              onChange={handleClawback}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="VME"
                              variant="standard"
                              type="number"
                              id="vme"
                              value={VME}
                              onChange={handleVME}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Jaarlijks abonnement"
                              variant="standard"
                              type="number"
                              id="abbofilteryearly"
                              value={yearlySubscription}
                              onChange={handleYearlySubscriptionChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* <label className="label">kWh verbruikskost:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPrice} onChange={handleKwhConsumptionPriceChange} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="kWh verbruikskost"
                              variant="standard"
                              type="number"
                              id="kwhusage"
                              value={kwhConsumptionPrice}
                              onChange={handleKwhConsumptionPriceChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* <label className="label">kWh korting op kWh-prijs*:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceDiscount} onChange={handleKwhConsumptionPriceDiscountChange} />

              <label className="label">Prijs na korting:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceAfterDiscount} onChange={handleKwhConsumptionPriceAfterDiscountChange} /> */}

                        {/* <label className="label">Tweevoudig tarief (dag):</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceTwoDay} onChange={handleKwhConsumptionPriceTwoDay} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Tweevoudig tarief (dag)"
                              variant="standard"
                              type="number"
                              id="twoday"
                              value={kwhConsumptionPriceTwoDay}
                              onChange={handleKwhConsumptionPriceTwoDay}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* <label className="label">Tweevoudig tarief (nacht):</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceTwoNight} onChange={handleKwhConsumptionPriceTwoNight} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Tweevoudig tarief (nacht)"
                              variant="standard"
                              type="number"
                              id="twonight"
                              value={kwhConsumptionPriceTwoNight}
                              onChange={handleKwhConsumptionPriceTwoNight}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* <label className="label">Exclusief nacht tarief:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceNight} onChange={handleKwhConsumptionPriceNight} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Exclusief nacht tarief"
                              variant="standard"
                              type="number"
                              id="exclnight"
                              value={kwhConsumptionPriceNight}
                              onChange={handleKwhConsumptionPriceNight}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* <label className="label">Vaste vergoeding (Jaarlijks):</label>
             <input type="number" className="input" step={0.01} value={fixedPriceElectricity} onChange={handleFixedPriceElectricity} />



              <label className="label">Bijdrage hernieuwbare energie + wwk:</label>
             <input type="number" className="input" step={0.01} value={hewwkContribution} onChange={handleHewwkContributionChange} /> */}
                        {/* 
              <label className="label">Tarief Databeheer:</label>
             <input type="number" className="input" step={0.01} value={dataManagementPrice} onChange={handleDataManagementPriceChange} /> */}

                        {/* 
              <label className="label">Capaciteitstarief:</label>
             <input type="number" className="input" step={0.01} value={capacityRatePrice} onChange={handleCapacityRatePriceChange} />

              <label className="label">Afnametarief:</label>
             <input type="number" className="input" step={0.01} value={purchaseRatePrice} onChange={handlePurchaseRatePriceChange} />

                <label className="label">Heffingen:</label>
             <input type="number" className="input" step={0.01} value={taxPrice} onChange={handleTaxPriceChange} />
   */}

                        {/* <label className="label">kWh injectie zonnepanelen:</label>
             <input type="number" className="input" step={0.01} value={kwhInjectionSolarPanelsDiscountPrice} onChange={handleKwhInjectionSolarPanelsDiscountPriceChange} />

             <label className="label">Totale kost verbruik:</label>
             <input type="number" className="input" step={0.01} value={totalConumptionCostPrice} onChange={handleTotalConsumptionCostPriceChange} /> */}
                      </div>

                      <button
                        className="updateButton"
                        onClick={handleUpdateContract}
                      >
                        Contract aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default EditContract;

import React from "react";
import "./PageHeroColored.css"; // Import your CSS styles for the Hero component

interface HeroColoredProps {
  children: React.ReactNode;
}

const HeroColored: React.FC<HeroColoredProps> = ({ children }) => {
  return (
    <div className="heroColored">
      <h1>{children}</h1>
    </div>
  );
};

export default HeroColored;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosCreate, IoIosTrash } from "react-icons/io";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import "./Contracts.css"; // Import the CSS file for styles
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import PageHero from "../../../../components/Pagehero/PageHero";
import * as XLSX from "xlsx";
import { getCookie } from "../../../../cookieUtils";

interface Contract {
  id: number;
  supplierId: number;
  documentTemplateId: string;
  name: string;
  description: string;
  category: number;
  partnerId: string;
  minimalDurationLength: number;
  commission: number;
  clawback: number;
  vme: number;
}

interface EnergySupplier {
  id: number;
  name: string;
  active: number;
}

const Contracts = () => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [confirmDeleteContract, setConfirmDeleteContract] =
    useState<Contract | null>(null);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchContracts();
    fetchEnergySuppliers();
  }, []);

  const fetchContracts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contracts`,
      );
      setContracts(response.data);
    } catch (error) {
      console.error("Error retrieving contracts:", error);
      // Handle error state or display an error message
    }
  };

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      setEnergySuppliers(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
      // Handle error state or display an error message
    }
  };

  const handleEditContract = (contractId: number) => {
    navigate(`/EditContract/${contractId}`);
  };

  const handleDetailContract = (contractId: number) => {
    navigate(`/DetailContract/${contractId}`);
  };

  const handleDeleteContract = (contract: Contract) => {
    setConfirmDeleteContract(contract);
  };

  const confirmDelete = async () => {
    if (confirmDeleteContract) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_DB_URL}/contracts/${confirmDeleteContract.id}`,
        );
        fetchContracts();
      } catch (error) {
        console.error("Error deleting contract:", error);
        // Handle error state or display an error message
      }
    }
    setConfirmDeleteContract(null);
  };

  const cancelDelete = () => {
    setConfirmDeleteContract(null);
  };

  const getCategoryLabel = (category: number): string => {
    switch (category) {
      case 1:
        return "Elektriciteit Vast";
      case 2:
        return "Elektriciteit Variabel";
      case 3:
        return "Gas Vast";
      case 4:
        return "Gas Variabel";
      case 5:
        return "Elektriciteit Dynamisch";
      case 6:
        return "Gas Dynamisch";
      case 7:
        return "Combi Vast";
      case 8:
        return "Combi Variabel";
      case 9:
        return "Combi Dynamisch";
      case 10:
        return "Professioneel Elektriciteit Vast";
      case 11:
        return "Professioneel Elektriciteit Variabel";
      case 12:
        return "Professioneel Gas Vast";
      case 13:
        return "Professioneel Gas Variabel";
      case 14:
        return "Professioneel Elektriciteit Dynamisch";
      case 15:
        return "Professioneel Gas Dynamisch";
      case 16:
        return "Professioneel Combi Vast";
      case 17:
        return "Professioneel Combi Variabel";
      case 18:
        return "Professioneel Combi Dynamisch";
      default:
        return "Unknown Category";
    }
  };

  const exportToExcel = () => {
    const dataToExport = filteredContracts.map((contract) => ({
      "Contract Naam": contract.name,
      Leverancier:
        energySuppliers.find((supplier) => supplier.id === contract.supplierId)
          ?.name || "",
      Omschrijving: contract.description,
      Type: getCategoryLabel(contract.category),
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Contracts");

    XLSX.writeFile(wb, "Contracts.xlsx");
  };

  const filteredContracts = contracts
    .filter((contract: Contract) => {
      const nameMatch = contract.name
        .toLowerCase()
        .includes(nameFilter.toLowerCase());
      const descriptionMatch = contract.description
        .toLowerCase()
        .includes(descriptionFilter.toLowerCase());
      return nameMatch && descriptionMatch;
    })
    .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by name

  const filteredEnergySuppliers = energySuppliers.filter(
    (supplier: EnergySupplier) => {
      const nameMatch = supplier.name
        .toLowerCase()
        .includes(nameFilter.toLowerCase());
      return nameMatch;
    },
  );

  const handleAddContract = () => {
    navigate("/AddContract");
  };

  const handleAddGasContract = () => {
    navigate("/AddGasContract");
  };

  const handleAddCombiContract = () => {
    navigate("/AddContractCombi");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none",
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#272727",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Contracten beheren</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div>
                <div className="filterRow">
                  <div className="filterContainer">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <AccountCircle
                        sx={{ color: "action.active", mr: 1, my: 0.5 }}
                      />
                      <TextField
                        label="Filter op Naam: "
                        variant="standard"
                        type="text"
                        id="nameFilter"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                      />
                    </Box>
                  </div>
                  <div className="filterContainer">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <DescriptionIcon
                        sx={{ color: "action.active", mr: 1, my: 0.5 }}
                      />
                      <TextField
                        label="Filter op Omschrijving: "
                        variant="standard"
                        type="text"
                        id="descriptionFilter"
                        value={descriptionFilter}
                        onChange={(e) => setDescriptionFilter(e.target.value)}
                      />
                    </Box>
                  </div>
                  <button className="addButton" onClick={handleAddContract}>
                    Elektriciteitscontract toevoegen
                  </button>
                  <button className="addButton" onClick={handleAddGasContract}>
                    Gascontract toevoegen
                  </button>
                  <button
                    className="addButton"
                    onClick={handleAddCombiContract}
                  >
                    Combi contract toevoegen
                  </button>
                  <button className="buttonstyleR33" onClick={exportToExcel}>
                    Exporteer naar Excel
                  </button>
                </div>
                <hr className="dividerDashed" />
                <div className="recordCount">
                  <p>{`${filteredContracts.length} van de ${contracts.length} records zichtbaar`}</p>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Contract Naam</StyledTableCell>
                        <StyledTableCell align="right">
                          Leverancier
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          Omschrijving
                        </StyledTableCell>
                        <StyledTableCell align="right">Type</StyledTableCell>
                        <StyledTableCell align="right">
                          Minimale duurtijd
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          Commissie
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          Clawback
                        </StyledTableCell>
                        <StyledTableCell align="right">VME</StyledTableCell>
                        <StyledTableCell align="right">Acties</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredContracts.map((contract) => {
                        const supplier = energySuppliers.find(
                          (supplier) => supplier.id === contract.supplierId,
                        );
                        return (
                          <StyledTableRow key={contract.id}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              onClick={() => handleDetailContract(contract.id)}
                            >
                              {contract.name}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {supplier?.name}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {contract.description}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {contract.category === 1
                                ? "Elektriciteit Vast"
                                : contract.category === 2
                                  ? "Elektriciteit Variabel"
                                  : contract.category === 3
                                    ? "Gas Vast"
                                    : contract.category === 4
                                      ? "Gas Variabel"
                                      : contract.category === 5
                                        ? "Elektriciteit Dynamisch"
                                        : contract.category === 6
                                          ? "Gas Dynamisch"
                                          : contract.category === 7
                                            ? "Combi Vast"
                                            : contract.category === 8
                                              ? "Combi Variabel"
                                              : contract.category === 9
                                                ? "Combi Dynamisch"
                                                : contract.category === 10
                                                  ? "Elektriciteit Vast Professioneel"
                                                  : contract.category === 11
                                                    ? "Elektriciteit Variabel Professioneel"
                                                    : contract.category === 12
                                                      ? "Gas Vast Professioneel"
                                                      : contract.category === 13
                                                        ? "Gas Variabel Professioneel"
                                                        : contract.category ===
                                                            14
                                                          ? "Elektriciteit Dynamisch Professioneel"
                                                          : contract.category ===
                                                              15
                                                            ? "Gas Dynamisch Professioneel"
                                                            : contract.category ===
                                                                16
                                                              ? "Combi Vast Professioneel"
                                                              : contract.category ===
                                                                  17
                                                                ? "Combi Variabel Professioneel"
                                                                : contract.category ===
                                                                    18
                                                                  ? "Combi Dynamisch Professioneel"
                                                                  : "Gas"}{" "}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {contract.minimalDurationLength}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {contract.commission}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {contract.clawback}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => handleDetailContract(contract.id)}
                              align="right"
                            >
                              {contract.vme}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <IoIosCreate
                                size={20}
                                className="actionIconEdit"
                                onClick={() => handleEditContract(contract.id)}
                              />
                              <IoIosTrash
                                size={20}
                                className="actionIconDelete"
                                onClick={() => handleDeleteContract(contract)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            {confirmDeleteContract && (
              <div className="confirmationModal">
                <p className="confirmationText">
                  Bent u zeker dat u dit contract wil verwijderen?
                </p>
                <div className="confirmationButtons">
                  <button className="confirmButton" onClick={confirmDelete}>
                    Delete
                  </button>
                  <button className="cancelButton" onClick={cancelDelete}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Contracts;
